import React from 'react';
import styled from '@emotion/styled';
import { EdgeProps, getBezierPath } from '@xyflow/react';

const EdgePath = styled.path<{ isValid?: boolean }>`
  stroke: ${({ isValid }) => isValid ? '#b1b1b7' : '#ff4444'};
  stroke-width: 2;
  fill: none;
`;

const EdgeButton = styled.button`
  background: white;
  border: 1px solid #b1b1b7;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  &:hover {
    background: #f8f8f8;
  }
`;

interface CustomEdgeProps extends EdgeProps {
  isValid?: boolean;
  data?: {
    label?: string;
  };
}

export const CustomEdge: React.FC<CustomEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  isValid = true,
  data,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <EdgePath
        id={id}
        d={edgePath}
        isValid={isValid}
        markerEnd={markerEnd}
      />
      {data?.label && (
        <foreignObject
          width={100}
          height={40}
          x={labelX - 50}
          y={labelY - 20}
          className="edge-label"
        >
          <div style={{
            background: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            textAlign: 'center',
          }}>
            {data.label}
          </div>
        </foreignObject>
      )}
    </>
  );
};
