import { AxiosResponse } from "axios";
import { queryOptions } from "@tanstack/react-query";
import TicketService from "../ticket.service";
import CustomerAdminService from "../customerAdmin.service";



export const createCustomerOptionsQuery = () => queryOptions<any>({
  queryKey: ['customers'],
  queryFn: async () => {
    const ticketService = TicketService();
    return new Promise(async (resolve) => {
      await ticketService
        .getCustomers({
          onSuccess: (res: AxiosResponse) => {
            resolve(res.data);
          },
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  refetchOnWindowFocus: false,
});

export const createCustomerAdminQuery = () => queryOptions<any>({
  queryKey: ['customerAdmin'],
  queryFn: async () => {
    const customerAdminService = CustomerAdminService();
    return new Promise(async (resolve) => {
      await customerAdminService
        .getCustomerAdmin({
          onSuccess: (res: AxiosResponse) => {
            resolve(res.data);
          },
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  refetchOnWindowFocus: false,
});
