import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { NodeProps, Node } from '@xyflow/react';
import { NodeWrapper } from './NodeWrapper';
import { Select } from './Select';
import { Input } from './Input';
import { z } from 'zod';
import { useFormValidation } from './useFormValidation';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import WorkflowRuleStep2 from '@/app/pages/WorkflowRulesDrawer/WorkflowRuleStep2';

export const conditionSchema = z.object({
  field: z.string().min(1, 'Field is required'),
  operator: z.string().min(1, 'Operator is required'),
  value: z.string().optional(),
  type: z.enum(['string', 'number', 'date', 'dropdown', 'toggle']),
});

export const conditionNodeSchema = z.object({
  label: z.string().min(1, 'Label is required'),
  matchType: z.enum(['all', 'any']),
  conditions: z.array(conditionSchema).min(1, 'At least one condition is required'),
});

export type ConditionData = z.infer<typeof conditionSchema>;
export type ConditionNodeData = z.infer<typeof conditionNodeSchema>;

const NodeContent = styled.div`
  min-width: 320px;
  padding: 8px;
`;

const AddConditionButton = styled.button`
  background: #45b7d1;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 8px;
  
  &:hover {
    background: #3da1b9;
  }
`;

const ConditionRow = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

export type ConditionNode = Node<
  {
    label: string;
    conditions: {
      field: string;
      operator: string;
      value: string;
    }[];
    matchType: 'all' | 'any';
  },
  'condition'
>;

const matchTypeOptions = [
  { value: 'all', label: 'Match All Conditions' },
  { value: 'any', label: 'Match Any Condition' }
];

const fields = [
  { value: 'name', label: 'Name', type: 'string' },
  { value: 'email', label: 'Email', type: 'string' },
  { value: 'age', label: 'Age', type: 'number' },
  { value: 'status', label: 'Status', type: 'dropdown' },
  { value: 'date', label: 'Date', type: 'date' },
  { value: 'active', label: 'Active', type: 'toggle' }
];

const FIELD_OPTIONS = [
  { value: 'email', label: 'Email' },
  { value: 'status', label: 'Status' },
  { value: 'country', label: 'Country' }
];

const OPERATOR_OPTIONS = [
  { value: 'equals', label: 'Equals' },
  { value: 'contains', label: 'Contains' },
  { value: 'startsWith', label: 'Starts with' }
];

export const ConditionNode = (props: NodeProps<ConditionNode>) => {
  const { data } = props;
  const { errors, validate } = useFormValidation(conditionNodeSchema);

  // const handleChange = useCallback((updates: Partial<ConditionNodeData>) => {
  //   const newData = { ...data, ...updates };
  //   onChange?.(newData);
  //   const isValid = validate(newData);
  //   onValidated?.(isValid);
  // }, [data, onChange, onValidated, validate]);

  // const addCondition = () => {
  //   handleChange({
  //     conditions: [
  //       ...data.conditions,
  //       { field: '', operator: 'isequal', value: '', type: 'string' }
  //     ]
  //   });
  // };

  // const removeCondition = (index: number) => {
  //   handleChange({
  //     conditions: data.conditions.filter((_, i) => i !== index)
  //   });
  // };

  // const updateCondition = (index: number, updates: Partial<typeof data.conditions[0]>) => {
  //   const newConditions = [...data.conditions];
  //   newConditions[index] = { ...newConditions[index], ...updates };
  //   handleChange({ conditions: newConditions });
  // };


  return (
    <NodeWrapper type="condition" label={data.label}>
      <NodeContent>
        <WorkflowRuleStep2 />
        {/* <TextBox
          name="label"
          label="Label"
          value={data.label}
        />
        <Select
          label="Match Type"
          options={matchTypeOptions}
          value={data.matchType}
          onChange={(value) => {

          }}
          // onChange={(value) => handleChange({ matchType: value as 'all' | 'any' })}
          error={errors.matchType}
        />
        {data.conditions.map((condition: any, index: number) => (
          <ConditionRow key={index}>
            <Select
              options={FIELD_OPTIONS}
              value={condition.field}
              onChange={(value) => {
                // Handle change through callback
              }}
            />
            <Select
              options={OPERATOR_OPTIONS}
              value={condition.operator}
              onChange={(value) => {
                // Handle change through callback
              }}
            />
            <Input
              value={condition.value}
              onChange={(e) => {
                // Handle change through callback
              }}
            />
          </ConditionRow>
        ))} */}
        {/* <AddConditionButton onClick={addCondition}> */}
        {/* Add Condition */}
        {/* </AddConditionButton> */}
      </NodeContent>
    </NodeWrapper>
  );
};
