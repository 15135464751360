import TextField from '@/components/Atoms/TextField/TextField.component';
import { TicketData, TicketExt } from '@/interfaces/dropsource.interfaces';
import { Box, Chip } from '@mui/material';
import dayjs from 'dayjs';
import { MRT_ColumnDef } from 'material-react-table';

export const reportTableColumns: MRT_ColumnDef<TicketData | TicketExt>[] = [
  {
    header: 'ID',
    //accessorKey required for ColumnOrdering
    accessorKey: 'TId',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.TId;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.TId;
      } else {
        return null;
      }
    },
    size: 105,
    enableResizing: false,
    enableEditing: false,
  },
  {
    header: 'Status',
    accessorKey: 'Status',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Status.Name;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Status.Name;
      } else {
        return null;
      }
    },

    Cell: (props: any) => {

      const color = props.row.original.TicketExt.Status.Color;
      const hexString = color.toString(16).padStart(6, '0');
      const hexCode = `#${hexString}`;

      function getContrastTextColor(hexColor: string): 'black' | 'white' {
        // Remove the '#' if present
        const hex = hexColor.replace(/^#/, '');

        // Convert hex to RGB
        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);

        // Calculate luminance
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Return black for light backgrounds, white for dark backgrounds
        return luminance > 0.5 ? 'black' : 'white';
      }

      /**
 * Returns the first letter of a given string.
 * @param str - The input string
 * @returns The first letter of the string, or an empty string if the input is empty
 */
      function getFirstLetter(str: string): string {
        if (str.length === 0) {
          return '';
        }

        // Trim leading whitespace and get the first character
        return str.trim().charAt(0);
      }

      return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Chip size='small' label={props.row.original.TicketExt.Status.Name} sx={{ bgcolor: hexCode, color: getContrastTextColor(hexCode), height: '18px' }} color='default' />
        </Box>
      )
    },
    Header: <i style={{ color: '' }}>Status</i>, //optional custom markup
    size: 150,
  },
  {
    header: 'Type',
    accessorKey: 'Type',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Type) {
        return row.TicketExt.Type.Name;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Type.Name;
      } else {
        return null;
      }
    },
    size: 105,
    enableResizing: false,
    enableEditing: false,
  },
  {
    header: 'Priority',
    accessorKey: 'Priority',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.Priority;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.Priority;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Priority</i>, //optional custom markup
    size: 125,
  },
  {
    header: 'Description',
    accessorKey: 'Title',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.Title;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.Title;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Title</i>, //optional custom markup
    // maxSize: 500,
    size: 250,
    muiEditTextFieldProps: {
      type: 'text',
      required: true,

      //remove any previous validation errors when user focuses on the input
    },
    Edit(props: any) {
      // console.log(props.row)
      return (
        <TextField
          id={props.row.original.TicketExt.Ticket.Tid}
          value={props.row.original.TicketExt.Ticket.Title}
          placeholder={props.row.original.TicketExt.Ticket.Title}
          // onChange={changer}
          label={'Title'}
          required
        // fullWidth
        />
      );
    },
  },
  {
    header: 'Assignee',
    accessorKey: 'Assignee',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Assignee.Name;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Assignee.Name;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Assignee</i>, //optional custom markup
    enableEditing: false,
  },
  {
    header: 'Customer',
    accessorKey: 'CustomerName',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.CustomerEntity.EntName;
      } else if ('Ticket' in row && row.Ticket) {
        return row.CustomerEntity.EntName;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Customer</i>, //optional custom markup
  },
  {
    header: 'Raised By',
    accessorKey: 'RaisedBy',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Raised.Name;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Raised.Name;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Raised By</i>, //optional custom markup
    enableEditing: false,
  },
  {
    header: 'Due Date',
    accessorKey: 'DueDate',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        const date = row.TicketExt.Ticket.DueDate;
        if (date === '0001-01-01T00:00:00Z') {
          return '';
        }
        return date ? dayjs(date).format('M/DD/YY') : '';
      } else if ('Ticket' in row && row.Ticket) {
        const date = row.Ticket.DueDate;
        if (date === '0001-01-01T00:00:00Z') {
          return '';
        }
        return date ? dayjs(date).format('M/DD/YY') : '';
      } else {
        return null;
      }
    },
    Cell: (props: any) => {
      const date = props.row.original.TicketExt?.Ticket?.DueDate || props.row.original.Ticket?.DueDate;
      const formattedDate = date && date !== '0001-01-01T00:00:00Z' ? dayjs(date).format('M/DD/YY') : '';
      const isPastDate = dayjs(date).isValid() && dayjs(date).isBefore(dayjs());

      return (
        <Box
          sx={{
            color: isPastDate ? 'red' : 'inherit',
          }}
        >
          {formattedDate}
        </Box>
      );
    },
    Header: <i style={{ color: '' }}>Due Date</i>,
    enableEditing: false,
  },

  {
    header: 'Created',
    accessorKey: 'Created',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        const date = row.TicketExt.Ticket.CreateTime;
        return date ? dayjs(date).format('M/DD/YY hh:mm a') : '';
      } else if ('Ticket' in row && row.Ticket) {
        const date = row.Ticket.CreateTime;
        return date ? dayjs(date).format('M/DD/YY hh:mm a') : '';
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Created</i>,
    enableEditing: false,
  },
  {
    header: 'Client Name',
    accessorKey: 'ClientName',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.ClientName;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.ClientName;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Client Name</i>, //optional custom markup
    enableEditing: false,
  },
  {
    header: 'Client Email',
    accessorKey: 'ClientEmail',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.ClientEmail;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.ClientEmail;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Client Email</i>, //optional custom markup
    enableEditing: false,
  },
  {
    header: 'Client Phone',
    accessorKey: 'ClientPhone',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.ClientPhone;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.ClientPhone;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Client Phone</i>, //optional custom markup
    enableEditing: false,
  },
  {
    header: 'Client Address',
    accessorKey: 'ClientAddress',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.ClientAddress;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.ClientPhone;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Client Address</i>, //optional custom markup
    enableEditing: false,
  },
];
