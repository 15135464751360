import { Description } from '@mui/icons-material';
import {z} from 'zod';

const TicketTypeCustomerSchema = z.object({
    CustomerID: z.number(), 
    CustomerName: z.string()
})

export const TicketTypeSchema = z.object({
    TypeName: z.string(),
    TypeDescription: z.string().optional().default(''),
    TypeID: z.number(),
    Customer: z.array(TicketTypeCustomerSchema)
})