import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ReportHeaderService from '@/services/reportHeader.service';
import { useNavigate } from '@tanstack/react-router';

type ActionType = 'upsertReportHeader' | 'restoreReportHeader' | 'archiveReportHeader';

interface MutationAction {
  actionType: ActionType;
  payload: any;
}

const useReportHeadersMutation = (): {
  executeReportHeaderAction: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  isUpdating: Record<string, boolean>;
  setIsUpdating: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const [isUpdating, setIsUpdating] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();

  const reportHeaderService = ReportHeaderService();

  const actionMap: { [key in ActionType]: Function } = {
    upsertReportHeader: reportHeaderService.upsertReportHeader,
    restoreReportHeader: reportHeaderService.restoreReportHeader,
    archiveReportHeader: reportHeaderService.archiveReportHeader,
  };

  const executeMutation = async ({ actionType, payload }: MutationAction) => {
    const serviceMethod = actionMap[actionType];
    if (!serviceMethod) {
      enqueueSnackbar('Error processing your request', { variant: 'error' });
    } else {
      return serviceMethod(payload);
    }
  };

  const { mutate: executeReportHeaderAction } = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType }) => {
      setIsUpdating((prev) => ({ ...prev, [actionType]: true }));
    },

    onSuccess: (data: any, { actionType, payload }) => {
      // right now nothing will happen if the operation was successful
    },
    onError: (error: any, { actionType }) => {
      const errorMessage = `Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSettled: ({ actionType }) => {
      queryClient.invalidateQueries({ queryKey: ['report-headers'] });
    },
  });

  return { executeReportHeaderAction, isUpdating, setIsUpdating };
};

export default useReportHeadersMutation;
