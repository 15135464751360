import type { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Card,
  IconButton,
  MuiDialog,
  TextField,
  Typography,
} from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { Box, Divider, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface IProps extends DialogProps {
  setOpen: (open: boolean) => void;
  methods: any;
  hideCloseIcon?: boolean;
  hideOkayBtn?: boolean;
  hideCancelBtn?: boolean;
  onSuccess?: () => void;
  onClose?: () => void;
  disabled?: boolean;
  data: any;
}

const EditDropdownModal = (props: IProps) => {
  const {
    data,
    onSuccess,
    methods,
    hideCloseIcon,
    hideCancelBtn,
    hideOkayBtn,
    setOpen,
    onClose,
    disabled,
    ...dialogProps
  } = props;

  const [error, setError] = useState<string>('');
  const [formattedData, setFormattedData] = useState<string>('');

  useEffect(() => {
    if (data && Array.isArray(data)) {
      setFormattedData(data.join('\n'));
    } else {
      setFormattedData('');
    }
  }, [data]);

  const handleSortAsending = () => {
    let formattedArray = formattedData
      ?.split('\n')
      ?.map((item) => item.trim())
      ?.sort((a: any, b: any) => a.localeCompare(b))?.filter((item) => item !== '');
      ;
    setFormattedData(formattedArray?.join('\n'));
  };

  const handleSortDesending = () => {
    let formattedArray = formattedData
      ?.split('\n')
      ?.map((item) => item.trim())
      ?.sort((a: any, b: any) => b.localeCompare(a))?.filter((item) => item !== '');
      ;
    setFormattedData(formattedArray?.join('\n'));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormattedData(e.target.value);
    if (error) {
      setError(''); // Clear error when user starts typing
    }
  };

  const handleAutoFormat = () => {
    const formattedArray = formattedData
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item !== '');

    const formattedString = formattedArray.join('\n');

    setFormattedData(formattedString);
  };

  const handleSave = () => {
    let dataToBeSaved = formattedData.split('\n');
    methods.setValue('metaData.OptLabels', dataToBeSaved);
    methods.setValue('metaData.SingleOptLabel', dataToBeSaved[0]);

    onClose?.();
  };

  return (
    <MuiDialog
      {...dialogProps}
      sx={{
        '& .MuiDialog-paper': {
          width: '25%', // Adjust the percentage as needed
        },
      }}>
      <DialogTitle sx={{ m: 2 }}>Dropdown List Items</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Tooltip title="Sort Ascending">
              <Button id="sortAscending" onClick={handleSortAsending}>
                <SortByAlphaIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Sort Descending">
              <Button id="sortDescending" onClick={handleSortDesending}>
                <SwapVertIcon />
              </Button>
            </Tooltip>
            <Tooltip title="AutoFormat">
              <Button id="autoFormat" onClick={handleAutoFormat}>
                <AutoFixHighIcon />
              </Button>
            </Tooltip>
          </Box>
          <Tooltip title="Help">
            <Button
              id="helpBtn"
              onClick={() =>
                window.alert(
                  'Place each list item on its own line.' +
                    '\nPaste a CSV list of items into the text box and click the Auto Format button to parse onto separate lines.' +
                    '\nUse the sort buttons to sort ascending or descending. Cut and paste items to move them to change the list order.' +
                    '\nList items will be displayed in the order they appear here.' +
                    '\n** List items cannot contain commas.',
                )
              }>
              <InfoOutlinedIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Card sx={{ p: 2, display: 'flex', gap: 5 }}>
          <TextField
            id="formattedData"
            label="Multiline"
            multiline
            fullWidth
            value={formattedData}
            onChange={handleInputChange}
            rows={10}
          />
        </Card>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <IconButton
          id="closeModalIcon"
          color="default"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography>{`Item count: ${formattedData?.split('\n')?.length}`}</Typography>
        <Box>
          <Button id="cancelBtn" color="error" onClick={onClose} size="small" variant="outlined">
            Cancel
          </Button>
          <Button
            id="okayBtn"
            disabled={disabled}
            size="small"
            disableElevation
            variant="contained"
            onClick={handleSave}
            sx={{ ml: 2 }}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </MuiDialog>
  );
};

export default EditDropdownModal;
