import { AxiosRequestConfig } from "axios";
import { BASE_UAPI_URL } from "@/constants/commonStrings.constant";
import { apiService } from "./api.service"

export type UapiCustomerEntitySwag = {
  /**
   * @type string | undefined
   */
  archiveTime?: string | undefined;
  /**
   * @description c u\n\nignores date of time.Time, only uses Minute and Hour
   * @type string | undefined
   */
  close?: string | undefined;
  /**
   * @description P Gc U
   * @type integer | undefined
   */
  customerEntId?: number | undefined;
  /**
   * @description c u
   * @type string | undefined
   */
  description?: string | undefined;
  /**
   * @description (kwt 11/07/2022) added EmailMatch and ArchiveTime
   * @type string | undefined
   */
  emailMatch?: string | undefined;
  /**
   * @description c u
   * @type string | undefined
   */
  entAddress?: string | undefined;
  /**
   * @description C u
   * @type string | undefined
   */
  entName?: string | undefined;
  /**
   * @description c u\n\nignores date of time.Time, only uses Minute and Hour
   * @type string | undefined
   */
  open?: string | undefined;
  /**
   * @description c u
   * @type string | undefined
   */
  phone?: string | undefined;
  /**
   * @description p(rolesid) cg(2) u\n\nTo update: you must use RoleManagement.CustomerSetRole
   * @type integer | undefined
   */
  roleId?: number | undefined;
};

const CustomerAdminService = () => {

  const getCustomerAdmin = async (
    // params: GetAgentsParams,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {

    const requestId = `admincustomers_${Date.now()}`;
      const response = await apiService({
        method: 'post',
        url: `${BASE_UAPI_URL}/admincustomers`,
        ...config,
        id: requestId,
      });
      
      return response;
  };  

  const createCustomerAdmin = async (
    data: UapiCustomerEntitySwag,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {
    const response = await apiService({
      method: 'post',
      url: `${BASE_UAPI_URL}/customer`,
      ...config,
      data,
    });
    return response;
  }

  const updateCustomerAdmin = async (
    data: UapiCustomerEntitySwag,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {
    const response = await apiService({
      method: 'post',
      url: `${BASE_UAPI_URL}/customerupdate`,
      ...config,
      data,
    });
    return response;
  }

  const deleteCustomerAdmin = async (
    data: UapiCustomerEntitySwag,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {
    const response = await apiService({
      method: 'post',
      url: `${BASE_UAPI_URL}/customerdelete`,
      ...config,
      data,
    });
    return response;
  }

  return {
    getCustomerAdmin,
    createCustomerAdmin,
    updateCustomerAdmin,
    deleteCustomerAdmin
  }
}

export default CustomerAdminService