import * as React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import TicketTypeDrawer from '@/app/pages/TicketType/TicketTypeDrawer'
import { z } from 'zod'

export const Route = createFileRoute('/_auth/type-editor/$typeId')({
  component: () => <TicketTypeDrawer />,
  params: {
    parse: (params) => ({
      typeId: z.number().int().parse(Number(params.typeId)),
    }),
    stringify: ({ typeId }) => ({ typeId: `${typeId}` }),
  },
  validateSearch: (search) => {
    return z
      .object({
        variant: z.enum(['edit', 'new']).default('edit')
        // step: z.number().min(0).max(1).default(0), 
        // Based on your steps array length
        // showSecondaryContact: z.boolean().default(false)
      })
      .parse(search)
  },
})