import { queryOptions, useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';
import PermissionService from '../../services/permission.service';
import { AxiosResponse } from 'axios';
import { AuthContext } from '@/lib/auth';

export interface IPermissions {
  "All-seer": boolean;
  "Customer Administrator": boolean;
  "Customer Manager": boolean;
  "Mass Update Manager": boolean;
  "Notification Author": boolean;
  "Report Executor": boolean;
  "System Manager": boolean;
  "Ticket Assign-other": boolean;
  "Ticket Creator": boolean;
  "Ticket Deleter": boolean;
  "Ticket Editor": boolean;
  "Ticket Mover": boolean;
  "Ticket Staff": boolean;
  "User Manager": boolean;
}

interface IUsePermission {
  permissions: UseQueryResult<IPermissions | null>;
}

// const usePermission = (): IUsePermission => {


//   const permissionService = PermissionService();

//   const permissions = useQuery<any>({
//     queryKey: ['permissions'],
//     queryFn: async () => {
//       return new Promise(async (resolve) => {
//         await permissionService
//           .getPermissions({
//             onSuccess: (res: AxiosResponse) => {
//               resolve(res.data || null);
//             },
//           })
//           .then((res) => {
//             resolve(res?.data || null);
//           });
//       });
//     },
//     staleTime: 1000 * 60 * 5, // 5 minutes
//     refetchOnWindowFocus: false,
//   });

//   return {
//     permissions,
//   };
// };

export const createPermissionsQueryOptions = (checkAuthStatus: any) => {

  const isLoggedIn = checkAuthStatus()
  const permissionService = PermissionService()


  return queryOptions({
    queryKey: ['permissions'],
    queryFn: async () => {
      const response = await permissionService.getPermissions({})
      return response?.data
    },
    staleTime: 1000 * 60 * 50,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    enabled: isLoggedIn,
    gcTime: 0,
    retry: (failureCount, error) => {
      if (!isLoggedIn) return false
      return failureCount < 3
    }

  })
}

export const createRoleIdQueryOptions = () => {
  const permissionService = PermissionService()

  return queryOptions({
    queryKey: ['role-id'],
    queryFn: async () => {
      const response = await permissionService.getRoleId({})
      return response.data || null
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    enabled: true,

  })
}

export const createRolesQueryOptions = () => {
  const permissionService = PermissionService()

  return queryOptions({
    queryKey: ['roles'],
    queryFn: async () => {
      const response = await permissionService.getRoles({})
      return response.data || null
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    enabled: true,

  })
}


// export default usePermission;
