import { queryOptions } from '@tanstack/react-query';

import TicketService from '../ticket.service';

// Query Key Factory
export const customFields = {
  all: ['customFieldsAdmin'] as const,
  // types: (customerId?: number) => [...customFields.all, 'types', customerId] as const,
  // statuses: (customerId?: number) => [...customFields.all, 'statuses', customerId] as const
};

export const createCustomFieldsQueryOptions = () => {
  const ticketService = TicketService();
  return queryOptions({
    queryKey: customFields.all,
    queryFn: async () => {
      const response = await ticketService.getTicketCustomFieldGroupsById({});
      return response;
    },
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
  });
};