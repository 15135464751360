import React, { ChangeEvent } from 'react';
import { Radio, FormControlLabel } from '@mui/material';

interface IProps {
  id: string;
  label: string;
  checked: boolean;
  onDataChange: (id: string, value: string) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  hintText?: string;
  sxProps?: any;
  value?: boolean;
  name?: string;
}

const RadioButton = ({ onDataChange, ...props }: IProps) => {
  // No local `useState` for checked here!

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onDataChange(props.id, event.target.value);
  };

  return (
    <FormControlLabel
      label={props.label}
      control={
        <Radio
          checked={props.checked}
          onChange={handleChange}
          value={props.value}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          inputProps={props.inputProps}
          sx={props.sxProps}
        />
      }
    />
  );
};

export default RadioButton;
