import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { enqueueSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { useNavigate } from '@tanstack/react-router';
import CustomerAdminService from '@/services/customerAdmin.service';

type ActionType = 'createCustomer' | 'updateCustomer';

interface MutationAction {
  actionType: ActionType;
  [key: string]: any;
}

const useCustomerMutation = (): {
  createCustomer: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  updateCustomer: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  createCustomerMutation: UseMutationResult<any, unknown, MutationAction, unknown>;
  updateCustomerMutation: UseMutationResult<any, unknown, MutationAction, unknown>;
  isUpdatingCreateCustomer: Record<string, boolean>;
  setIsUpdatingCreateCustomer: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  isUpdatingUpdateCustomer: Record<string, boolean>;
  setIsUpdatingUpdateCustomer: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const [isUpdatingCreateCustomer, setIsUpdatingCreateCustomer] = useState<Record<string, boolean>>({});
  const [isUpdatingUpdateCustomer, setIsUpdatingUpdateCustomer] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const executeMutation = async ({ actionType, ...payload }: MutationAction) => {
    const customerAdminService = CustomerAdminService();

    const action: Record<ActionType, (payload: any) => Promise<any>> = {
      createCustomer: (params) => customerAdminService.createCustomerAdmin(params.payload),
      updateCustomer: (params) => customerAdminService.updateCustomerAdmin(params.payload),
    };

    const serviceMethod = action[actionType];
    if (!serviceMethod) {
      throw new Error(`Unknown action type: ${actionType}`);
    }
    return serviceMethod(payload);
  };

  const mutation = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType, ...newAttachment }) => {
      setIsUpdatingCreateCustomer({ ...isUpdatingCreateCustomer, [actionType]: true });
      setIsUpdatingUpdateCustomer({ ...isUpdatingUpdateCustomer, [actionType]: true });
    },
    onSuccess: (data: any, variables: any) => {
      console.log('Create Customer data:', data);
      navigate({ to: `/customers` });
      if (variables.actionType === 'createCustomer') {
        enqueueSnackbar('Customer created successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Customer updated successfully', { variant: 'success' });
      }
    },
    onError: (error: any, variables: any) => {
      console.error('Error during mutation:', error);
      if (variables.actionType === 'updateCustomer') {
        enqueueSnackbar('Error updating customer', { variant: 'error' });
      } else {
        enqueueSnackbar('Error creating customer', { variant: 'error' });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['customerAdmin'] });
    },
  });

  return {
    createCustomer: mutation.mutate,
    createCustomerMutation: mutation,
    isUpdatingCreateCustomer,
    setIsUpdatingCreateCustomer,
    updateCustomer: mutation.mutate,
    updateCustomerMutation: mutation,
    isUpdatingUpdateCustomer,
    setIsUpdatingUpdateCustomer
  };
};

export default useCustomerMutation;
