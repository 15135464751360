import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import TicketService from '@/services/ticket.service';
import { enqueueSnackbar } from 'notistack';
import useTicketList from '@/contexts/stores/ticketList.store';

type ActionType = 'addTicket' | 'updateTicketCustomFields' | 'deleteTicket';

interface MutationAction {
  actionType: ActionType;
  [key: string]: any;
}

const useTicketMutationsCustomFields = (): {
  updateTicketCustomFields: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  isUpdatingTicketCustomFields: Record<string, boolean>;
  setIsUpdatingTicketCustomFields: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const { ticketListState, updateTicketList } = useTicketList();
  const [isUpdatingTicketCustomFields, setIsUpdatingTicketCustomFields] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();
  const executeMutation = async ({ actionType, ...payload }: MutationAction) => {
    const ticketService = TicketService();
    const action: { [key in ActionType]?: Function } = {
      updateTicketCustomFields: ticketService.postTicketCustomFields,
      // deleteTicket: ticketService.deleteTicket
    };

    const serviceMethod = action[actionType];
    if (!serviceMethod) {
      throw new Error(`Unknown action type: ${actionType}`);
    }
    return serviceMethod(payload);
  };

  const { mutateAsync: updateTicketCustomFields } = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType }) => {
      setIsUpdatingTicketCustomFields({ ...isUpdatingTicketCustomFields, [actionType]: true });
    },
    onSuccess: (data: any, variables: any) => {
      console.log('Custom Field data:', data);
      if (!data.data.Success) {
        return Promise.reject(new Error('Custom field update failed'));
      }
      console.log('custom fields onSuccess:', data, variables);
      console.log('Mutation successful');
      console.log('variables.data', variables.data);
      // queryClient.invalidateQueries({ queryKey: ['all-tickets'] });
      // if (data.data.Success && variables.data.length > 1 && variables.actionType === 'updateTicketCustomFields') {
      //   console.log('successful update');
      //   enqueueSnackbar('Custom Fields saved successfully', { variant: 'success' });
      // } else if (data.data.Success && variables.actionType === 'updateTicketCustomFields') {
      //   // console.log('successful update');
      //   enqueueSnackbar('Custom Field saved successfully', { variant: 'success' });
      // }
      // if (variables.actionType === 'deleteTicket' && data.ticketId) {
      //     queryClient.removeQueries({ queryKey: ['ticket', data.ticketId] })
      //     enqueueSnackbar(`Ticket #${data.ticketId} deleted successfully`, { variant: 'success' })
      //     queryClient.invalidateQueries({ queryKey: ['archived-tickets'], exact: true });
      // }
    },
    onError: (error: any, variables: any) => {
      console.error('Error during mutation:', error);
      console.warn('ERROR', variables);
      // if (error && variables.data.length > 1 && variables.actionType === 'updateTicketCustomFields') {
      //   enqueueSnackbar('Custom Field updates failed', { variant: 'error' });
      // } else if (error && variables.actionType === 'updateTicketCustomFields') {
      //   enqueueSnackbar('Custom Field update failed', { variant: 'error' });
      // }

      if (variables.actionType === 'deleteTicket' && variables.data.TId) {
        enqueueSnackbar(`Ticket #${variables.data[0].TicketID} delete failed`, {
          variant: 'error',
        });
      }
    },
    // onSettled: ({ actionType }) => {
    //   setIsUpdating({ ...isUpdating, [actionType]: false });
    // },
    onSettled: (variables, context) => {
      console.log('context', context);
      //! need ticket id because stupid backend gives me a whole lotta nothing.
      console.log('variables', variables);
      queryClient.invalidateQueries({
        queryKey: ['ticket-by-id', variables.data]
      });
      queryClient.invalidateQueries({
        queryKey: ['custom-fields', variables.data]
      });
      queryClient.invalidateQueries({
        queryKey: ['ticket-history-by-id', variables.data]
      });
    },
  });

  return { updateTicketCustomFields, isUpdatingTicketCustomFields, setIsUpdatingTicketCustomFields };
};

export default useTicketMutationsCustomFields;
