import * as React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import RolesPermissionsDrawer from '@/app/pages/RolesPermissions/RolesPermissionsDrawer'

export const Route = createFileRoute('/_auth/permissions/$roleId')({
  component: () => <RolesPermissionsDrawer />,
  params: {
    parse: (params) => ({
      roleId: z.number().int().parse(Number(params.roleId)),
    }),
    stringify: ({ roleId }) => ({ roleId: `${roleId}` }),
  },
  validateSearch: (search) => {
    return z
      .object({
        variant: z.enum(['edit', 'new']).default('edit'),
      })
      .parse(search)
  },
})
