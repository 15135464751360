import EditIcon from '@mui/icons-material/Edit';
import { Box, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Typography, Tooltip } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { MRT_ShowHideColumnsButton, MRT_ToggleFullScreenButton, MRT_ToggleFiltersButton, useMaterialReactTable, MRT_RowSelectionState } from 'material-react-table';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from '@tanstack/react-router';
import useColumns from '@/hooks/useColumns';
import { reportTableColumns } from '@/components/Organisms/ReportTable/reportTableColumns';
import useReportTableStore from './useReportTableStore';
import { useDebounce } from '@/hooks/useDebounce';
import { createUserQueryOptions } from '@/services/queries/pingOptions';
import useReports from '@/contexts/stores/reports.store';
import useReport from '@/hooks/fetches/useReports.service';
import { TicketData, TicketExt } from '@/interfaces/dropsource.interfaces';
import { CFTypeIdDetail } from '../TicketDetail/CustomFields.page';
import dayjs from 'dayjs';
import { createCustomerFieldsQueryOptions } from '@/hooks/fetches/useTicket.service';
import { createUserSettingsOptions } from '@/hooks/fetches/useSettings.service';
import { useAuth } from '@/lib/auth';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useState } from 'react';
import exportToCSV from '@/util/exportToCSV.util';

const useReportTable = () => {

  const {
    reportTableState,
    setPagination,
    setSorting,
    setColumnFilters,
    setColumnVisibility,
    setDensity,
    setGlobalFilter,
    setShowColumnFilters,
    setTicketIds,
    setColumnOrder,
    // setRowSelection,
  } = useReportTableStore();


  const theme = useTheme();
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  const user = useQuery(createUserQueryOptions({ ticketid: 0, customerid: 0, limittocustomer: false }))

  const { reportsByCustomerAndId, reportsByFilter } = useReport(reportTableState?.customFilters?.customer?.id, reportTableState?.customFilters.category?.label, reportTableState?.reportFilter?.id);
  const customFieldsByCustomer = useQuery(createCustomerFieldsQueryOptions(reportTableState?.customFilters?.customer?.id))
  const data = reportTableState?.isReportsFiltered ? reportsByFilter?.data || [] : reportsByCustomerAndId?.data || [];
  const refreshQueryKey = ['report', reportTableState?.customFilters?.customer?.id, reportTableState?.customFilters.category?.label]
  const getSettings = useQuery(createUserSettingsOptions(user?.data?.ContextUser?.AgentId))

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})

  // data.forEach((potato: any) => {
  //   console.log(potato)
  //   console.log(typeof (potato))
  // })

  const allColumns = () => {
    let combinedColumns = []
    combinedColumns.push(...reportTableColumns)

    const sorted = customFieldsByCustomer?.data?.sort((a: any, b: any) => {
      const nameA = a.Meta.Name.toLowerCase();
      const nameB = b.Meta.Name.toLowerCase();

      return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });
    });

    sorted?.map((field: any) => {
      const dynamicWidth = Math.max(150, field.Meta.Name.length * 10);

      const variant = () => {
        switch (field.TypeId) {
          case 16: return "range"
          case 17: return "text"
          case 19: return "dropdown"
          case 20: return "text"
          default: return "text"
        }
      }

      // typeId 16 = int
      // typeId 17 = string
      // typeId 19 = Dropdown
      // typeId 20 = boolean

      combinedColumns.push({
        header: field.Meta.Name,
        accessorKey: field.Meta.Cid,
        filterVariant: variant(),
        accessorFn: (row: TicketData) => {
          if (row.CustomFieldValues) {
            const customfield = row.CustomFieldValues?.find((customField: any) => customField.ValueMeta.C.Meta.Cid === field.Meta.Cid)

            if (CFTypeIdDetail(field.TypeId).ClassBinding === 'cfTInteger') {
              return customfield?.Value.MyInt

            } else if (CFTypeIdDetail(field.TypeId).ClassBinding === 'cfTDropdown') {
              return customfield?.Value.SelectedOpt

            } else if (CFTypeIdDetail(field.TypeId).ClassBinding === 'cfTString') {
              return customfield?.Value.Str

            } else if (CFTypeIdDetail(field.TypeId).ClassBinding === 'cfTToggle') {
              return String(customfield?.Value.Checked)

            } else if (CFTypeIdDetail(field.TypeId).ClassBinding === 'cfTDate') {
              return customfield?.Value.TimeVal ? dayjs(customfield?.Value.TimeVal).format('MM/DD/YYYY hh:mm a') : null
            }
          }
          else {
            return null;
          }
        },
        size: dynamicWidth
      })
    })

    if (combinedColumns.length === reportTableColumns.length) {
      return []
    } else return combinedColumns
  }

  const columns = allColumns()

  // const columns = useColumns(reportTableColumns)

  // const { updateTicketTableColumns, getSettings } = useSettings(agentsByCustomer?.data?.ContextUser?.AgentId);


  const Message = (message: string) => {
    return <Typography>{message}</Typography>;
  };

  const handleRefresh = async () => {
    // console.log('refreshing')
    if (reportTableState.isReportsFiltered) {
      const previousData = queryClient.getQueryData(['report', reportTableState?.reportFilter?.id]);
      // console.log(previousData)

      // reportsByCustomerAndId.refetch()
      getSettings.refetch()

      await queryClient.invalidateQueries({ queryKey: ['report', reportTableState?.reportFilter?.id], exact: true }); // Replace 'yourQueryKey' with the actual query key
      const newData = queryClient.getQueryData(['report', reportTableState?.reportFilter?.id]);
      // console.log(newData)
      if (newData) {
        if (JSON.stringify(newData) !== JSON.stringify(previousData)) {
          enqueueSnackbar(Message('New records found!'), { variant: 'success' });
        } else {
          enqueueSnackbar(Message('Table successfully updated'), {
            variant: 'default',
            style: { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText },
          });
        }
        // } else {
        // enqueueSnackbar(Message('No new records found'), { variant: 'error' })
      }
    } else {
      const previousData = queryClient.getQueryData(refreshQueryKey);
      // console.log(previousData)

      reportsByCustomerAndId.refetch()

      await queryClient.invalidateQueries({ queryKey: refreshQueryKey, exact: true }); // Replace 'yourQueryKey' with the actual query key
      const newData = queryClient.getQueryData(refreshQueryKey);
      // console.log(newData)
      if (newData) {
        if (JSON.stringify(newData) !== JSON.stringify(previousData)) {
          enqueueSnackbar(Message('New records found!'), { variant: 'success' });
        } else {
          enqueueSnackbar(Message('Table successfully updated'), {
            variant: 'default',
            style: { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText },
          });
        }
        // } else {
        // enqueueSnackbar(Message('No new records found'), { variant: 'error' })
      }
    }
  };

  const handleExport = () => {
    exportToCSV(reportTable, 'Export Report');
  };

  const reportTable = useMaterialReactTable({
    data,
    columns,
    // enableRowSelection: true,
    enableSorting: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnFilters: true,
    positionGlobalFilter: 'left',
    positionToolbarAlertBanner: 'head-overlay',
    // positionActionsColumn: 'last',

    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onShowColumnFiltersChange: setShowColumnFilters,
    onPaginationChange: setPagination,
    // onRowSelectionChange: setRowSelection,
    // enableTopToolbar: true,
    // enableSelectAll: true,
    // enableColumnOrdering: true,
    // onColumnOrderChange: (newColumnOrder) => {
    //   // saveTicketTableColumnOrder(newColumnOrder);
    //   setColumnOrder(newColumnOrder);
    // },
    enableFilterMatchHighlighting: false,
    muiTableBodyCellProps: {
      sx: {
        p: '4px 4px'
      }
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
        size: 10,
      },
    },
    muiTablePaperProps: {
      sx: {
        p: 2,
        borderRadius: '6px',
        boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px',
        backgroundImage: 'none',
      },
    },
    muiTableBodyProps: {
      sx: {
        border: 'none',
        '& tr:nth-of-type(odd) > td': {
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
    getRowId: (row: any) => row?.TicketExt?.Ticket?.TId,
    //   if (!row?.TicketExt?.Ticket?.TId) {
    //     console.warn('Row is missing TId:', row);
    //     return String(Math.random()); // fallback ID
    //   }
    //   return String(row.TicketExt.Ticket.TId);
    // },
    renderRowActions: ({ row, table }: any) => (
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
        onClick={(event: any) => {
          event.stopPropagation();
        }}
      >
        <Tooltip title="Edit">
          <IconButton
            id="edit"
            size="small"
            onClick={(event: any) => {
              event.stopPropagation();
              navigate({
                to: `/ticket/$ticketId`,
                params: { ticketId: row.original.TicketExt.Ticket.TId },
                search: { variant: 'edit', step: 0 }
              })
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiTableBodyRowProps: ({ row }: any) => ({
      onClick: (event: any) => {
        event.stopPropagation();
        navigate({
          to: `/ticket/$ticketId`,
          params: { ticketId: row.original.TicketExt.Ticket.TId },
          search: { variant: 'edit', step: 0 }
        })
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
        height: '10px',
      },
    }),
    renderToolbarInternalActions: ({ table }: any) => (
      <>
        <IconButton
          onClick={handleExport}
          id={'export'}
        >
          <FileDownloadIcon />
        </IconButton>
        <MRT_ToggleFiltersButton table={reportTable} />
        <IconButton onClick={handleRefresh} id={'refresh'}>
          <RefreshIcon />
        </IconButton>
        <MRT_ShowHideColumnsButton table={reportTable} />
        <MRT_ToggleFullScreenButton table={reportTable} />
      </>
    ),
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      sorting: reportTableState.sorting,
      columnVisibility: reportTableState.columnVisibility,
      columnFilters: reportTableState.columnFilters,
      globalFilter: reportTableState.globalFilter,
      showColumnFilters: reportTableState.showColumnFilters,
      pagination: reportTableState.pagination,
      // columnOrder: reportTableState.columnOrder,
      // rowSelection: {},
      // rowSelection: reportTableState.rowSelection,
    },
    state: {
      showAlertBanner: (reportTableState.isReportsFiltered ? reportsByFilter.isError : reportsByCustomerAndId.isError),
      showSkeletons: (reportTableState.isReportsFiltered ? reportsByFilter.isLoading : reportsByCustomerAndId.isLoading),
      showProgressBars: (reportTableState.isReportsFiltered ? reportsByFilter.isFetching : reportsByCustomerAndId.isFetching),
      sorting: reportTableState.sorting,
      columnVisibility: reportTableState.columnVisibility,
      columnFilters: reportTableState.columnFilters,
      globalFilter: reportTableState.globalFilter,
      showColumnFilters: reportTableState.showColumnFilters,
      pagination: reportTableState.pagination,
      // columnOrder: reportTableState.columnOrder,
      // rowSelection: rowSelection,
      // rowSelection: reportTableState.rowSelection,
    },
  });

  return {
    reportTable,
    refetch: reportsByCustomerAndId.refetch,
  };
}

export default useReportTable