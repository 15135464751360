export type ThemeString = 'DEFAULT' | 'DARK' | 'LIGHT' | 'BLUE' | 'GREEN' | 'INDIGO';

// Usage with const assertion to preserve literal types
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO',
} as const;
