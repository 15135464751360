import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import useFieldGroupMutation from './useFieldGroupsMutation';
import FieldGroupsService from '@/services/fieldGroups.service';

interface IUseFieldGroups {
  fieldGroups: UseQueryResult<any>;
  executeFieldGroupAction: any;
  isUpdating: any;
  setIsUpdating: any;
}

const useFieldGroups = (customerId?: number): IUseFieldGroups => {
  const { executeFieldGroupAction, isUpdating, setIsUpdating } = useFieldGroupMutation();

  const fieldGroupsService = FieldGroupsService();

  const fieldGroups = useQuery<any>({
    queryKey: ['field-groups'],
    queryFn: async () => {
      return new Promise(async (resolve) => {
        await fieldGroupsService
          .getFieldGroups({
            onSuccess: (res: AxiosResponse) => {
              resolve(res.data);
            },
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });

  return {
    fieldGroups,
    executeFieldGroupAction,
    isUpdating,
    setIsUpdating,
  };
};

export default useFieldGroups;

