import {
  DateTimePicker,
  DateTimePickerProps,
  DateTimePickerSlotProps,
  DateTimeValidationError,
  PickerChangeHandlerContext,
  PickerValidDate,
  validateDateTime,
} from '@mui/x-date-pickers'
import { useLocalizationContext } from '@mui/x-date-pickers/internals'
import {
  Control,
  FieldError,
  FieldPath,
  FieldValues,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import { TextFieldProps, useForkRef } from '@mui/material'
import { forwardRef, ReactNode, Ref, RefAttributes } from 'react'
import { useFormError } from '@/contexts/FormErrorProvider'
import { useTransform } from './useTransform'

export const defaultErrorMessages: {
  [v in NonNullable<DateTimeValidationError>]: string
} = {
  disableFuture: 'Date must be in the past',
  maxDate: 'Date is later than the maximum allowed date',
  disablePast: 'Past date is not allowed',
  invalidDate: 'Date is invalid',
  minDate: 'Date is earlier than the minimum allowed date',
  shouldDisableDate: 'Date is not allowed',
  shouldDisableMonth: 'Month is not allowed',
  shouldDisableYear: 'Year is not allowed',
  minTime: 'Time is earlier than the minimum allowed',
  maxTime: 'Time is later than the maximum allowed',
  'shouldDisableTime-hours': 'Specified hour is disabled',
  'shouldDisableTime-minutes': 'Specified minute is disabled',
  'shouldDisableTime-seconds': 'Specified second is disabled',
  minutesStep: 'Invalid minutes step',
}

export function propertyExists<X, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    Object.prototype.hasOwnProperty.call(obj, prop)
  )
}

export function getTimezone<TDate extends PickerValidDate>(
  adapter: ReturnType<typeof useLocalizationContext>,
  value: TDate
): string | null {
  return value == null || !adapter.utils.isValid(value)
    ? null
    : adapter.utils.getTimezone(value)
}

export function readValueAsDate<TDate extends PickerValidDate>(
  adapter: ReturnType<typeof useLocalizationContext>,
  value: string | null | TDate
): TDate | null {
  if (typeof value === 'string') {
    if (value === '') {
      return null
    }
    return adapter.utils.date(value) as TDate
  }
  return value
}

export type DateTimePickerElementProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue extends PickerValidDate = PickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false,
> = Omit<DateTimePickerProps<TValue>, 'value' | 'slotProps'> & {
  name: TName
  required?: boolean
  isDate?: boolean
  parseError?: (error: FieldError) => ReactNode
  rules?: UseControllerProps<TFieldValues, TName>['rules']
  control?: Control<TFieldValues>
  inputProps?: TextFieldProps
  helperText?: TextFieldProps['helperText']
  textReadOnly?: boolean
  slotProps?: Omit<
    DateTimePickerSlotProps<TValue, TEnableAccessibleFieldDOMStructure>,
    'textField'
  >
  overwriteErrorMessages?: typeof defaultErrorMessages
  transform?: {
    input?: (value: PathValue<TFieldValues, TName>) => TValue | null
    output?: (
      value: TValue | null,
      context: PickerChangeHandlerContext<DateTimeValidationError>
    ) => PathValue<TFieldValues, TName>
  }
}

type DateTimePickerElementComponent = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue extends PickerValidDate = PickerValidDate,
>(
  props: DateTimePickerElementProps<TFieldValues, TName, TValue> &
    RefAttributes<HTMLDivElement>
) => JSX.Element

const DateTimePickerElement = forwardRef(function DateTimePickerElement<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue extends PickerValidDate = PickerValidDate,
>(
  props: DateTimePickerElementProps<TFieldValues, TName, TValue>,
  ref: Ref<HTMLDivElement>
) {
  const {
    parseError,
    name,
    required,
    rules = {},
    inputProps,
    control,
    textReadOnly,
    slotProps,
    overwriteErrorMessages,
    inputRef,
    transform,
    ...rest
  } = props

  const adapter = useLocalizationContext()

  const errorMsgFn = useFormError()
  const customErrorFn = parseError || errorMsgFn
  const errorMessages = {
    ...defaultErrorMessages,
    ...overwriteErrorMessages,
  }

  const rulesTmp = {
    ...rules,
    ...(required &&
      !rules.required && {
      required: 'This field is required',
    }),
    validate: {
      internal: (value: TValue | null) => {
        const date = readValueAsDate(adapter, value)
        if (!date) {
          return true
        }
        const internalError = validateDateTime({
          props: {
            shouldDisableDate: rest.shouldDisableDate,
            shouldDisableMonth: rest.shouldDisableMonth,
            shouldDisableYear: rest.shouldDisableYear,
            disablePast: Boolean(rest.disablePast),
            disableFuture: Boolean(rest.disableFuture),
            minDate: rest.minDate,
            maxDate: rest.maxDate,
            disableIgnoringDatePartForTimeValidation:
              rest.disableIgnoringDatePartForTimeValidation,
            maxTime: rest.maxTime,
            minTime: rest.minTime,
            minutesStep: rest.minutesStep,
            shouldDisableTime: rest.shouldDisableTime,
          },

          timezone: rest.timezone ?? getTimezone(adapter, date) ?? 'default',
          value: date,
          adapter,
        })

        return internalError == null || errorMessages[internalError]
      },
      ...rules.validate,
    },
  }

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: rulesTmp,
    control,
    disabled: rest.disabled,
    defaultValue: null as PathValue<TFieldValues, TName>,
  })

  const { value, onChange } = useTransform<TFieldValues, TName, TValue | null>({
    value: field.value,
    onChange: field.onChange,
    transform: {
      input:
        typeof transform?.input === 'function'
          ? transform.input
          : (newValue: TValue | null) =>
            newValue as PathValue<TFieldValues, TName>,
      output:
        typeof transform?.output === 'function'
          ? transform.output
          : (newValue: TValue | null) =>
            newValue as PathValue<TFieldValues, TName>,
    },
  })

  const handleInputRef = useForkRef(field.ref, inputRef)

  return (
    <DateTimePicker
      {...rest}
      {...field}
      value={value}
      ref={ref}
      inputRef={handleInputRef}
      onClose={(...args) => {
        field.onBlur()
        if (rest.onClose) {
          rest.onClose(...args)
        }
      }}
      onChange={(newValue, context) => {
        onChange(newValue, context)
        if (typeof rest.onChange === 'function') {
          rest.onChange(newValue, context)
        }
      }}
      slotProps={{
        ...slotProps,
        textField: {
          ...inputProps,
          required,
          error: !!error,
          helperText: error
            ? typeof customErrorFn === 'function'
              ? customErrorFn(error)
              : error.message
            : inputProps?.helperText || rest.helperText,
          inputProps: {
            readOnly: textReadOnly,
            ...inputProps?.inputProps,
          },
        },
      }}
    />
  )
})
DateTimePickerElement.displayName = 'DateTimePickerElement'
export default DateTimePickerElement as DateTimePickerElementComponent