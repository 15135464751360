import * as React from 'react'
import BasicDrawer from '@/components/Organisms/BasicDrawer/BasicDrawer.component'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import WorkflowRuleDrawer from '@/app/pages/WorkflowRulesDrawer/WorkflowRuleDrawer'
import { createAgentsQueryOptions, createAllWorkflowRulesQueryOptions, createWorkflowRuleQueryOptions } from '@/services/queries/workflowOptions'
import { createStatusesByCustomerOptions, createTicketTypesByCustomerOptions } from '@/services/queries/statusTypeOptions'
import { createCustomerFieldsQueryOptions } from '@/hooks/fetches/useTicket.service'

export const Route = createFileRoute('/_auth/workflow-rules/$workflowruleId')({
  component: () => <WorkflowRuleDrawer />,
  params: {
    parse: (params) => ({
      workflowruleId: z.number().int().parse(Number(params.workflowruleId)),
    }),
    stringify: ({ workflowruleId }) => ({ workflowruleId: `${workflowruleId}` }),
  },
  validateSearch: (search) => {
    return z
      .object({
        variant: z.enum(['edit', 'new']).default('edit'),
        step: z.number().min(0).max(3).default(0), // Based on your steps array length
        completed: z.record(z.string(), z.boolean()).default({}),
        // showSecondaryContact: z.boolean().default(false)
      })
      .parse(search)
  },
  loader: async ({ params, context }) => {
    const options = createAllWorkflowRulesQueryOptions();
    const workflowRule = await context.queryClient.getQueryData(options.queryKey);
    const currentWorkflowRule = workflowRule?.oldSmartRules?.find(
      (rule: any) => rule.SmartRuleId === params.workflowruleId
    ) || workflowRule?.newSmartRules?.find(
      (rule: any) => rule.SmartRuleId === params.workflowruleId
    );
    const customerId = currentWorkflowRule?.Customer

    await Promise.all([
      // context.queryClient.ensureQueryData(createWorkflowRuleQueryOptions({ srid: params.workflowruleId })),
      context.queryClient.ensureQueryData(createTicketTypesByCustomerOptions({ customerId })),
      context.queryClient.ensureQueryData(createStatusesByCustomerOptions({ customerId })),
      context.queryClient.ensureQueryData(createAgentsQueryOptions({ customerId })),
      context.queryClient.ensureQueryData(createCustomerFieldsQueryOptions(customerId))
    ])
  }
})