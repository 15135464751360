import { z } from "zod";
import { CustomFieldValue } from "../WorkflowRules/useWorkflowRuleTable";

export interface OldCondition {
  ConditionId: number;
  Equality: number;
  FieldData: FieldData
  MatchType: number
}

export interface FieldData {
  Fieldtype: number;
  FieldString: number | { 
    Cid?: number;
    Json?: CustomFieldValue
  };
}

export interface OldConditionCustomField {
    Cid: number;
    Json: CustomFieldValue
}

export interface NewCondition {
  condition: string;
  equality: string;
  equalityNumber: number;
  value: string | number | boolean;
  conditionObj: Record<string, any>;
  type: 'baseField' | 'customField';
  allAny: string;
}

export interface OldEmailAction {
  Actiondata: {
    Body: string;
    Recipient: number;
    RecipientList: string[];
    Subject: string;
  }
  Actiontype: 1
}

export interface OldAssigneeAction {
  Actiondata: {
    Assignee: number;
  }
  Actiontype: 3
}

export interface OldStatusAction {
  Actiondata: {
    StatusId: number;
  }
  Actiontype: 2
}

export interface OldActionConfig {
  Actions: OldEmailAction | OldAssigneeAction | OldStatusAction
}

export interface EmailActionConfig {
  type: 'email';
  enabled: boolean;
  config: {
    Recipient: number;
    RecipientList: string[];
    Subject: string;
    Body: string;
  };
}

export interface AssigneeActionConfig {
  type: 'assignee';
  enabled: boolean;
  config: {
    assignee: number;
  };
}

export interface StatusActionConfig {
  type: 'status';
  enabled: boolean;
  config: {
    status: number;
  };
}

export type ActionConfigType = EmailActionConfig | AssigneeActionConfig | StatusActionConfig;

export type ActionConfig = z.infer<typeof actions>

// export type  {
//   type: string;
//   enabled: boolean;
//   config: {
//     assignee?: string;
//     emailTo?: string[];
//     emailSubject?: string;
//     emailBody?: string;
//     status?: string;
//     taskTitle?: string;
//     taskDescription?: string;
//     notificationType?: string;
//     notificationMessage?: string;
//   };
// }

export interface WorkflowRule {
  description: string;
  customer: {
    id: number;
    label: string;
  };
  matchType: 'any' | 'all';
  any_conditions: NewCondition | OldCondition[];
  all_conditions: NewCondition | OldCondition[];
  actions: (OldEmailAction | OldAssigneeAction | OldStatusAction)[];
  status_switch: boolean;
  assignee_switch: boolean;
  email_switch: boolean;
}

export const initialWorkflowRule: WorkflowRule = {
  description: '',
  customer: {
    id: 0,
    label: ''
  },
  matchType: 'any',
  any_conditions: [],
  all_conditions: [],
  actions: [],
  status_switch: false,
  assignee_switch: false,
  email_switch: false,
};

export const actions = z.array(z.object({
  type: z.enum(['status', 'assignee', 'email']),
  assignee_config: z.object({
    Assignee: z.number().optional(),
  }).optional(),
  status_config: z.object({
    StatusId: z.number().optional(),
    statusObject: z.object({
      id: z.number(),
      label: z.string()
    }).optional()
  }).optional(),
  email_config: z.object({
    Recipient: z.number().optional(),
    RecipientList: z.array(z.string()).optional(),
    Body: z.string().optional(),
    Subject: z.string().optional(),
  }).optional(),
}))

const conditions = z.array(z.object({
  condition: z.string(),
  equality: z.string(),
  equalityNumber: z.number(),
  value: z.string(),
  conditionObj: z.record(z.string(), z.any()),
  type: z.string(),
  allAny: z.string()
}))

export const workflowRuleSchema = z.object({
  description: z.string(),
  customer: z.object({
    id: z.number(),
    label: z.string(),
  }),
  matchType: z.enum(['any', 'all']),
  any_conditions: conditions,
  all_conditions: conditions,
  actions: actions,
  assignee_switch: z.boolean(),
  status_switch: z.boolean(),
  email_switch: z.boolean(),
})