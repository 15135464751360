import React, { useMemo, useState } from 'react';
import {
  Typography,
  Box,
  Paper,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Chip,
  InputAdornment,
  TextField,
  styled
} from '@mui/material';
import { useParams, Link } from '@tanstack/react-router';
import { useQuery } from '@tanstack/react-query';
import { useFormContext, useWatch } from 'react-hook-form';
import { createCustomerFieldsQueryOptions } from '@/hooks/fetches/useTicket.service';
import { Search, X, ChevronDown, ChevronUp, Link as LinkIcon } from 'lucide-react';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  '&:before': { display: 'none' },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: 48,
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
  color: theme.palette.primary.contrastText,
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(0.5, 0),
  },
}));

const TokenChip = styled(Chip)(({ theme }) => ({
  height: 24,
  fontFamily: 'monospace',
  fontSize: '0.75rem',
  backgroundColor: theme.palette.grey[100],
  '& .MuiChip-label': {
    color: theme.palette.primary.main,
    padding: '0 8px',
  },
}));

const TokenCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0.75),
  minHeight: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
}));

const Token = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const methods = useFormContext()
  const customerWatch = useWatch({ control: methods.control, name: 'customer' })

  const params = useParams({ from: '/_auth/workflow-rules/$workflowruleId' })

  const customFields = useQuery(createCustomerFieldsQueryOptions(customerWatch?.id))

  const universal = [
    { Token: "$(TicketId)", Desc: "Ticket ID" },
    { Token: "$(Status)", Desc: "The status of the ticket" },
    { Token: "$(Customer)", Desc: "The customer of the ticket" },
    { Token: "$(Title)", Desc: "The title of the ticket" },
    { Token: "$(Assignee)", Desc: "The assignee of the ticket, if the ticket is unassigned, will be replaced with 'UNASSIGNED'" },
    { Token: "$(Priority)", Desc: "The priority of the ticket" },
    { Token: "$(Type)", Desc: "The type of the ticket" },
    { Token: "$(ClientName)", Desc: "The client name" },
    { Token: "$(ClientAddress)", Desc: "The client address" },
    { Token: "$(ClientPhone)", Desc: "The client phone number" },
    { Token: "$(ClientEmail)", Desc: "The client email address" }
  ];


  const cfields = customFields?.data?.map((customField: any) => ({
    Token: `$(FieldCid.${customField.Meta.Cid.toString()})`,
    Desc: (
      <Link
        to={`/custom-fields/${customField.Meta.Cid}`}
        search={{
          variant: 'edit',
        }}
        style={{ textDecoration: 'none' }}
      >
        {customField.Meta.Name}
      </Link>
    ),
  })) || [];

  const allFields = [...universal, ...cfields];

  const filteredFields = useMemo(() => {
    return allFields.filter(field =>
      field.Token.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (typeof field.Desc === 'string'
        ? field.Desc.toLowerCase().includes(searchTerm.toLowerCase())
        : field.Desc.props.children.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [allFields, searchTerm]);

  return (
    <>
      {/* <div>
      <Typography variant="h6" gutterBottom>
        Tokens
      </Typography>
      <Typography variant="body1" paragraph>
        The following tokens will be replaced by the respective information on the ticket to which triggered this smartrule.
        These tokens work in both the subject and the body. These tokens are case-sensitive.
      </Typography>
      <List>
        {universal.map((item, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={item.Token}
              secondary={item.Desc}
            />
          </ListItem>
        ))}
      </List>
      <Typography variant="body1" paragraph>
        For customer-specific fields, the syntax of $(FieldCid.CUSTOMFIELDID) is used, as the names of the customer-specific fields may change.
        The token is still presented even if the customer field is archived.
      </Typography>
      <List>
        {cfields.map((item: any, index: number) => (
          <ListItem key={index}>
            <ListItemText
              primary={item.Token}
              secondary={item.Desc}
            />
          </ListItem>
        ))}
      </List>
    </div> */}
      <Grid size={12}>
        <Box sx={{ maxWidth: 'sm', mx: 'auto', p: 2 }}>
          <StyledAccordion expanded={isOpen} onChange={() => setIsOpen(!isOpen)}>
            <StyledAccordionSummary
              expandIcon={isOpen ? <ChevronUp color="white" size={16} /> : <ChevronDown color="white" size={16} />}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>Available Tokens</Typography>
            </StyledAccordionSummary>
            <AccordionDetails sx={{
              p: 2,
              maxHeight: '300px',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5
            }}>
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                The following tokens will be replaced by the respective information on the ticket that triggered this workflowrule.
                These tokens work in both the subject and the body. These tokens are case-sensitive.
              </Typography>

              <TextField
                fullWidth
                size="small"
                placeholder="Search tokens..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                  '& .MuiInputBase-root': {
                    height: 36,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={14} />
                    </InputAdornment>
                  ),
                  endAdornment: searchTerm && (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => setSearchTerm('')} sx={{ p: 0.5 }}>
                        <X size={14} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Box sx={{
                overflow: 'auto',
                flex: 1,
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: '4px',
                  '&:hover': {
                    background: '#555',
                  },
                },
              }}>
                <Grid container spacing={0.5}>
                  {filteredFields.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <TokenCard elevation={0} variant="outlined">
                        <TokenChip label={item.Token} />
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{
                            flex: 1,
                            lineHeight: 1.2,
                            fontSize: '0.75rem',
                          }}
                        >
                          {item.Desc}
                        </Typography>
                      </TokenCard>
                    </Grid>
                  ))}
                </Grid>

                {filteredFields.length === 0 && (
                  <Box sx={{ textAlign: 'center', py: 2 }}>
                    <Typography variant="caption" color="text.secondary">
                      No tokens found matching your search.
                    </Typography>
                  </Box>
                )}
              </Box>
            </AccordionDetails>
          </StyledAccordion>
        </Box>
      </Grid>

    </>

  );
};

export default Token;
