import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Close, Lightbulb, LightbulbOutlined, Tune } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
  useColorScheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { ColorModeContext } from '@/contexts/globalStateProvider';

export function ThemeConfigurator() {
  // const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  // // const isDarkTheme = useTheme().palette.mode === 'dark';
  // const { mode, setMode } = useColorScheme();
  // // const [mode, setMode] = React.useState<'light' | 'dark'>(isDarkTheme ? 'dark' : 'light');
  // const colorMode = React.useContext(ColorModeContext);

  // const toggleDrawer = useCallback(() => {
  //   setOpen((prev) => !prev);
  // }, []);

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { mode, setMode } = useColorScheme();

  const toggleDrawer = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  // const handleModeChange = useCallback(() => {
  //   setMode(mode === 'light' ? 'dark' : 'light');
  //   colorMode.toggleColorMode() as any;
  // }, [colorMode, mode]);

  // const handleThemeChange = useCallback(
  //   (event: SelectChangeEvent<string>) => {
  //     setThemeName(event.target.value as 'appTheme' | 'shadTheme');
  //   },
  //   [setThemeName],
  // );

  return (
    <React.Fragment key={'right'}>
      <IconButton
        size={'large'}
        color={theme.palette.background.default}
        onClick={toggleDrawer}
        sx={{
          position: 'fixed',
          bottom: 16,
          left: 16,
          zIndex: 100,
          backgroundColor: 'background.paper',
          border: `1px solid`,
        }}
      >
        <Tune fontSize={'large'} color={'inherit'} />
      </IconButton>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={toggleDrawer}
        hideBackdrop={false}
        transitionDuration={500}
        elevation={20}
      >
        <Box sx={{ width: 350 }} role='presentation'>
          <Box sx={{ px: 2, py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant={'h6'}>Theme configurator</Typography>
            <Box>
              <IconButton onClick={toggleDrawer}>
                <Close />
              </IconButton>
            </Box>
          </Box>

          <Stack sx={{ p: 2 }}>

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.default',
                color: 'text.primary',
                borderRadius: 1,
                p: 3,
                minHeight: '56px',
              }}
            >
              <FormControl>
                <FormLabel id="demo-theme-toggle">Theme</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-theme-toggle"
                  name="theme-toggle"
                  row
                  value={mode}
                  onChange={(event) => {
                    setMode(event.target.value as 'system' | 'light' | 'dark')
                  }}
                >
                  <FormControlLabel value="system" control={<Radio />} label="System" />
                  <FormControlLabel value="light" control={<Radio />} label="Light" />
                  <FormControlLabel value="dark" control={<Radio />} label="Dark" />
                </RadioGroup>
              </FormControl>
            </Box>
          </Stack>

          {/* <Stack sx={{ p: 2 }}>
            <Box>
              <Typography variant={'body2'} color={'text.secondary'} textTransform={'uppercase'} mb={2}>
                Theme
              </Typography>

              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Choose theme</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={themeName}
                  label='Choose theme'
                  onChange={handleThemeChange}
                >
                  <MenuItem value={'appTheme'}>AppTheme</MenuItem>
                  <MenuItem value={'shadTheme'}>ShadTheme</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack> */}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}