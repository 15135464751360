// components/LoadingScreen/LoadingScreen.tsx
import { motion } from 'framer-motion'
import { Box, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'


const LoadingContainer = styled(motion.span)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '2rem',
  // backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
  backgroundColor: theme.palette.background.default,
  // backdropFilter: 'blur(8px)', // Blur effect
  zIndex: 9999,
}))

const LoadingDots = styled(Box)({
  display: 'flex',
  gap: '0.5rem',
})

const Dot = styled(motion.span)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  display: 'inline-block',
}))

interface LoadingScreenProps {
  message?: string
}

export function LoadingScreen({ message = 'Loading...' }: LoadingScreenProps) {
  const theme = useTheme()



  // Container animation
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.15,
        when: "beforeChildren",
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.15,
        when: "afterChildren",
      }
    }
  }

  // Dots animation
  const dotsContainerVariants = {
    start: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    end: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  }

  const dotVariants = {
    start: {
      y: "0%",
    },
    end: {
      y: "100%",
    },
  }

  const dotTransition = {
    duration: 0.5,
    yoyo: Infinity,
    ease: "easeInOut",
  }

  return (
    <LoadingContainer
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {/* <Typography
                variant="h5"
                color="primary"
                sx={{
                    fontWeight: 500,
                    letterSpacing: '0.1rem',
                }}
            >
                {message}
            </Typography> */}

      <LoadingDots
        component={motion.div}
        variants={dotsContainerVariants}
        initial="start"
        animate="end"
      >
        {[...Array(3)].map((_, index) => (
          <Dot
            key={index}
            variants={dotVariants}
            transition={dotTransition}
            style={{
              backgroundColor: theme.palette.primary.main,
              boxShadow: `0 0 10px ${theme.palette.primary.main}`,
            }}
          />
        ))}
      </LoadingDots>
    </LoadingContainer>
  )
}