import ConfirmModal from '@/components/Molecules/Modal/ConfirmModal.component';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, CircularProgress, Container, List, ListItem } from '@mui/material';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { Box, Card, Divider, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import { Delete as DeleteIcon } from '@mui/icons-material';
import RestoreIcon from '@mui/icons-material/Restore';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import CheckboxInput from '@/components/Atoms/Checkbox/CheckboxInput.component';
import { CustomerEntity } from '@/interfaces/customer.interface';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import useFieldGroups from '@/hooks/fetches/FieldGroups/useFieldGroups.service';
import { FieldGroupSchema } from '@/interfaces/schemas/fieldGroup.schema';
import { FieldGroupCustomer } from '@/interfaces/fieldGroup.interface';
import { createCustomersQueryOptions } from '@/hooks/fetches/useTicket.service';
import { useQuery } from '@tanstack/react-query';

const FieldGroupDrawer = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isUpdatingArchive, setIsUpdatingArchive] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const params = useParams({ from: '/_auth/field-groups/$fieldGroupId' });
  const { variant } = useSearch({ from: '/_auth/field-groups/$fieldGroupId' });
  const customers = useQuery(createCustomersQueryOptions());
  const { fieldGroups, executeFieldGroupAction } = useFieldGroups();
  const navigate = useNavigate();
  const currentGroup = fieldGroups?.data?.find((fg: any) => fg.FieldGroupId === params?.fieldGroupId);
  const parsedFieldGroup = currentGroup && FieldGroupSchema.parse(currentGroup);

  const methods = useForm<z.infer<typeof FieldGroupSchema>>({
    mode: 'all',
    defaultValues: {
      ArchivedBy: 0,
      Archivedts: 0,
      CustomerList: [],
      Description: '',
      FieldCount: 0,
      FieldGroupId: -1,
      Name: '',
    },
    resolver: zodResolver(FieldGroupSchema),
  });

  const { control, setValue, reset } = methods;
  const customerList = useWatch({ control, name: 'CustomerList' });
  const fieldName = useWatch({ control, name: 'Name' });
  const fieldDescription = useWatch({ control, name: 'Description' });

  const canSend = methods.formState.isDirty && fieldName !== '' && fieldDescription !== '' && customerList.length > 0;

  useEffect(() => {
    setIsOpen(true);
    setIsUpdating(false);
  }, []);

  useEffect(() => {
    if (currentGroup) {
      reset({
        ArchivedBy: parsedFieldGroup?.ArchivedBy,
        Archivedts: parsedFieldGroup?.Archivedts,
        CustomerList: parsedFieldGroup?.CustomerList || [],
        Description: parsedFieldGroup?.Description,
        FieldCount: parsedFieldGroup?.FieldCount,
        FieldGroupId: parsedFieldGroup?.FieldGroupId,
        Name: parsedFieldGroup?.Name,
      });
    }
  }, [currentGroup]);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      navigate({ to: '/field-groups' });
    }, 300); // Match this with MUI's transition duration
  };

  const handleSelectAll = () => {
    const allCustomers = customers?.data?.map((c: CustomerEntity) => ({
      CustomerId: c.CustomerEntId,
      IsDefault: false,
    }));
    setValue('CustomerList', allCustomers, { shouldDirty: true });
  };

  const handleUnSelectAll = () => {
    setValue('CustomerList', [], { shouldDirty: true });
  };

  const formatedIds = customerList?.map((c) => {
    return { customerId: c.CustomerId };
  });

  const handleCreate = (formValues: any) => {
    setIsUpdating(true);
    executeFieldGroupAction({
      actionType: 'upsertFieldGroup',
      payload: {
        CustomerList: formatedIds,
        FieldGroupId: -1,
        archivedts: 0,
        customerCount: customerList?.length,
        Description: formValues.Description,
        fieldCount: 0,
        id: -1,
        Name: formValues.Name,
      },
    });
  };

  const handleUpdate = (formValues: any) => {
    setIsUpdating(true);
    executeFieldGroupAction({
      actionType: 'upsertFieldGroup',
      payload: {
        CustomerList: formatedIds,
        FieldGroupId: formValues?.FieldGroupId,
        archivedts: 0,
        customerCount: customerList?.length,
        Description: formValues?.Description,
        fieldCount: 0,
        id: formValues?.FieldGroupId,
        Name: formValues?.Name,
      },
    });
  };

  const handleSubmit = (formValues: z.infer<typeof FieldGroupSchema>) => {
    if (variant === 'new') {
      handleCreate(formValues);
    } else if (variant === 'edit') {
      handleUpdate(formValues);
    }
  };

  const handleArchive = () => {
    setIsUpdatingArchive(true);
    executeFieldGroupAction({
      actionType: 'archiveFieldGroup',
      payload: {
        fieldGroupId: params?.fieldGroupId,
      },
    });
  };

  const handleRestore = () => {
    setIsUpdatingArchive(true);
    executeFieldGroupAction({
      actionType: 'restoreFieldGroup',
      payload: {
        fieldGroupId: params?.fieldGroupId,
      },
    });
  };

  const handleReset = () => {
    reset({
      ArchivedBy: parsedFieldGroup?.ArchivedBy,
      Archivedts: parsedFieldGroup?.Archivedts,
      CustomerList: parsedFieldGroup?.CustomerList || [],
      Description: parsedFieldGroup?.Description,
      FieldCount: parsedFieldGroup?.FieldCount,
      FieldGroupId: parsedFieldGroup?.FieldGroupId,
      Name: parsedFieldGroup?.Name,
    });
    handleClose();
  };

  const customerCheckBoxList = customers?.data?.map((customer: CustomerEntity) => {
    const isChecked = !!customerList?.some((c: FieldGroupCustomer) => c.CustomerId === customer.CustomerEntId);
    return (
      <ListItem sx={{ p: 0, m: 0 }} key={customer.CustomerEntId}>
        <CheckboxInput
          sxProps={{ p: 0, m: 1 }}
          label={customer.EntName}
          checked={isChecked}
          id={customer.CustomerEntId.toString()}
          onDataChange={(id: string) => {
            const parsedId = parseInt(id, 10);
            let newCustomerList: any[];
            if (customerList?.some((c: FieldGroupCustomer) => c.CustomerId === parsedId)) {
              newCustomerList = customerList.filter((c: FieldGroupCustomer) => c.CustomerId !== parsedId);
            } else {
              const customerToAdd = { CustomerId: parsedId, IsDefault: false };
              newCustomerList = [...customerList, customerToAdd];
            }
            setValue('CustomerList', newCustomerList, { shouldDirty: true });
          }}
        />
      </ListItem>
    );
  });

  return (
    <DrawerLayout
      open={isOpen}
      onClose={handleClose}
      anchor="right"
      keepMounted
      Header={
        <Box>
          <DrawerHeader close={handleClose} title={variant === 'edit' ? 'Edit Field Group' : 'New Field Group'} />
        </Box>
      }>
      {currentGroup?.FieldGroupId === 1 && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Alert sx={{ mt: 2, mb: 2 }} severity="info">
            This field group is locked and cannot be edited.
            <Button variant={'contained'} size={'small'} sx={{ ml: 2 }} onClick={handleClose}>
              Exit
            </Button>
          </Alert>
        </Box>
      )}
      <FormProvider {...methods}>
        <Divider sx={{ mt: 3 }} />

        {customers?.isFetching ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 9999,
            }}>
            <CircularProgress />
          </Box>
        ) : (
          <Container
            maxWidth="md"
            sx={{
              overflowY: 'auto',
              mt: 5,
              width: '75%',
              height: '98%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            component="form"
            onSubmit={methods.handleSubmit(handleSubmit)}>
            <Box
              sx={{
                flex: 1, // Takes up remaining space
                overflowY: 'auto', // Enables scrolling only here
                p: 2,
              }}>
              <Box
                sx={{
                  width: '100%',
                  mx: 'auto',
                  mt: 8,
                  mb: 4,
                }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                  <TextBox id="name" name="Name" label="Group Name" fullWidth required sx={{ flex: 1 }} />
                  <TextBox
                    id="description"
                    name="Description"
                    multiline
                    label="Description"
                    fullWidth
                    sx={{ flex: 1 }}
                  />
                </Box>
              </Box>
              <Card sx={{ p: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h6">Customers</Typography>
                  {!customers?.isFetching && (
                    <Box>
                      <Button size="small" onClick={handleSelectAll} variant="contained">
                        Select All
                      </Button>
                      <Button size="small" sx={{ ml: 2 }} onClick={handleUnSelectAll} variant="outlined">
                        Un-Select All
                      </Button>
                    </Box>
                  )}
                </Box>
                {(customerList?.length === 0 || !customerList) && (
                  <Alert sx={{ mt: 2, mb: 2 }} variant="outlined" color="error" severity="info">
                    Select at least 1 customer
                  </Alert>
                )}
                <List>{customerCheckBoxList}</List>
              </Card>
            </Box>
            <Box>
              <Divider sx={{ mt: 3 }} />
              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  p: 5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '1rem',
                }}>
                {currentGroup?.Archivedts === 0 ? (
                  <LoadingButton
                    loading={isUpdatingArchive}
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => setOpenModal(true)}>
                    Archive
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    loading={isUpdatingArchive}
                    color="error"
                    startIcon={<RestoreIcon />}
                    onClick={() => setOpenModal(true)}>
                    Restore
                  </LoadingButton>
                )}
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button variant="outlined" onClick={handleReset}>
                    Cancel & Close
                  </Button>
                  {openModal && (
                    <ConfirmModal
                      open={openModal}
                      setOpen={setOpenModal}
                      title="Are you sure?"
                      content={
                        currentGroup?.Archivedts === 0
                          ? 'This action will archive this field group.'
                          : 'This action will restore this field group.'
                      }
                      onSuccess={currentGroup?.Archivedts === 0 ? handleArchive : handleRestore}
                      onClose={() => setOpenModal(false)}
                      cancelButtonText="Cancel"
                      confirmButtonText={currentGroup?.Archivedts === 0 ? 'Archive' : 'Restore'}
                    />
                  )}
                  <LoadingButton disabled={!canSend} loading={isUpdating} variant="contained" type="submit">
                    Save
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Container>
        )}
      </FormProvider>
    </DrawerLayout>
  );
};

export default FieldGroupDrawer;
