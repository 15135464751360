import { queryOptions } from '@tanstack/react-query';

import AuthService, { GetAgentsParams } from '../auth.service';

export const createPingQueryOptions = () => {
  const authService = AuthService();
  return queryOptions({
    queryKey: ['ping'],
    queryFn: async () => {
      return new Promise(async (resolve) => {
        const response = await authService.getPing();
        // console.log(response)
        resolve(response);
        return response;
      });
    },
    gcTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 5,
    // staleTime: 1000 * 60 * 5,
    // gcTime: 1000 * 60 * 5,
    // cacheTime: 0,
    // refetchOnWindowFocus: true,
    refetchOnReconnect: false,
    // retry: false
  });
};


export const createUserQueryOptions = ({ ticketid, customerid, limittocustomer, auth }: any) => {
  const authService = AuthService();
  return queryOptions({
    queryKey: ['agentContext', ticketid, customerid, limittocustomer],
    queryFn: async () => {
      const response = await authService.getAgentContext({ customerid, ticketid, limittocustomer });
      // auth.updateUser(response)
      return response;
    },
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    enabled: (!!ticketid && !!customerid && !!limittocustomer) ? false : true,
    refetchOnWindowFocus: false,
  });
};