import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Typography,
} from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { BaseInformationSchema2, defaultValues, createSchema } from '@/interfaces/schemas/baseInformation.schema';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import TicketDetailMap from '@/components/Organisms/TicketDetailMap.component';
import { AccordionDetails, Alert, Card, CardContent, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import useTicketTableStore from '../TicketList/useTicketTableStore';
import CustomFields, { CFTypeIdDetail } from './CustomFields.page';
import { Link, useNavigate, useParams } from '@tanstack/react-router';
import useTicketList from '@/contexts/stores/ticketList.store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  createTicketByIdQueryOptions,
  createTicketCustomFieldsQueryOptions,
  createTicketDuplicateAddressQueryOptions,
  createTicketViewersQueryOptions,
} from '@/hooks/fetches/useTicket.service';
import PrintNoticeDialog from './PrintNoticeDialog.page';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import BaseInformation, { normalizeDate } from './BaseInformation.page';
import Permissions from '@/permissions/Permissions';
import LoadingButton from '@mui/lab/LoadingButton';
import TicketHistory from './TicketHistory.page';
import Attachments from './Attachments.page';
import { enqueueSnackbar } from 'notistack';
import Grid from '@mui/material/Grid2';
import Notes from './Notes.page';
import { useState } from 'react';
import dayjs from 'dayjs';
import AccessDenied from '@/components/Atoms/AccessDenied/AccessDenied.component';
import { useQuery } from '@tanstack/react-query';
import useTicketDelete from '@/hooks/fetches/useTicketDelete.service';
import useTicketMutationsCustomFields from '@/hooks/fetches/useTicketMutationsCustomFields.service';
import useTicketMutationsBaseFields from '@/hooks/fetches/useTicketMutationsBaseFields.service';
import ConfirmModal from '@/components/Molecules/Modal/ConfirmModal.component';

export interface TicketData {
  description?: string;
  type: { id: number; label: string } | null | undefined;
  priority?: { id: number; label: string } | null;
  status?: { StatusID: number; Name: string; Color?: any } | null;
  dueDate?: dayjs.Dayjs | null;
  assignee?: { id: number; label: string } | null;
  clientName?: string;
  clientEmail?: string;
  clientPhone?: string;
  clientAddress?: string;
}

interface CustomFieldValue {
  TicketId: number;
  Cid: number;
  TypeId: number;
  V: {
    MyInt?: number;
    SelectedOpt?: string | number;
    Str?: string;
    Checked?: boolean;
    TimeVal?: string | null;
  };
}

interface Result {
  baseFields: {
    TId: number;
    Title?: string;
    TypeId?: number;
    Priority?: string;
    StatusID?: number;
    Assignee?: number;
    DueDate?: string;
    ClientName?: string;
    ClientEmail?: string;
    ClientPhone?: string;
    ClientAddress?: string;
  };
  customFields: CustomFieldValue[];
}

const TicketDetail = () => {
  const navigate = useNavigate();

  const { ticketListState, updateTicketList } = useTicketList();

  const schema = createSchema(ticketListState.areCustomFieldsPopulated);
  const methods = useForm<BaseInformationSchema2>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues,
  });
  const { isDirty } = methods.formState;
  const { ticketTableState } = useTicketTableStore();
  const { ticketId } = useParams({
    from: '/_auth/ticket/$ticketId',
  });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState<boolean>(false);
  const [navigateTo, setNavigateTo] = useState<string>('');
  const ticketById = useQuery(createTicketByIdQueryOptions(ticketId));
  const ticketViewers = useQuery(createTicketViewersQueryOptions(ticketId));
  const customFields = useQuery(createTicketCustomFieldsQueryOptions(ticketId));
  const duplicateAddress = useQuery(
    createTicketDuplicateAddressQueryOptions(ticketById?.data?.Ticket?.ClientAddress, ticketId),
  );

  const { updateTicketDelete, updateTicketDeleteMutation } = useTicketDelete();
  const { updateTicketBaseFields } = useTicketMutationsBaseFields();
  const { updateTicketCustomFields } = useTicketMutationsCustomFields();

  const handleBackToTicketList = () => {
    if (isDirty && Object.keys(methods.formState.dirtyFields).length > 0) {
      const confirmLeave = confirm('You have unsaved changes, are you sure you want to leave?');
      if (confirmLeave) navigate({ to: '/', search: { drawer: undefined } });
    } else {
      navigate({ to: '/', search: { drawer: undefined } });
    }
  };

  const prepareTicketData = (data: Partial<TicketData>): Result => {
    const result: Result = {
      baseFields: {
        TId: ticketId,
      },
      customFields: [],
    };

    Object.keys(data).forEach((key) => {
      const value = data[key as keyof TicketData];

      if (key === 'description') {
        result.baseFields['Title'] = value as string;
      } else if (key === 'type' && (value as { id: number }).id !== undefined) {
        result.baseFields['TypeId'] = (value as { id: number }).id;
      } else if (key === 'priority' && (value as { label: string }).label) {
        result.baseFields['Priority'] = (value as { label: string }).label.toLowerCase();
      } else if (key === 'status' && (value as { StatusID: number }).StatusID !== undefined) {
        result.baseFields['StatusID'] = (value as { StatusID: number }).StatusID;
      } else if (key === 'assignee' && (value as { id: number }).id !== undefined) {
        result.baseFields['Assignee'] = (value as { id: number }).id;
      } else if (key === 'dueDate' && dayjs.isDayjs(value) && value !== null) {
        console.log('value', value.hour(12).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss[Z]'));
        result.baseFields['DueDate'] = value.hour(12).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss[Z]');
      } else if (key === 'clientName') {
        result.baseFields['ClientName'] = value as string;
      } else if (key === 'clientEmail') {
        result.baseFields['ClientEmail'] = value as string;
      } else if (key === 'clientPhone') {
        result.baseFields['ClientPhone'] = value as string;
      } else if (key === 'clientAddress') {
        result.baseFields['ClientAddress'] = value as string;
      } else {
        const customField = customFields?.data.find((field: any) => field.ValueMeta.C.Meta.Cid.toString() === key);

        if (customField) {
          const customFieldObj = {
            TicketId: ticketId,
            Cid: customField.ValueMeta.C.Meta.Cid,
            TypeId: customField.ValueMeta.C.TypeId,
            V: {},
          };

          if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTInteger') {
            customFieldObj.V = { MyInt: Number(value) };
          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTDropdown') {
            if (value && typeof value !== 'string' && 'label' in value) {
              customFieldObj.V = { SelectedOpt: value.label };
            }
          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTString') {
            customFieldObj.V = { Str: value };
          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTToggle') {
            customFieldObj.V = { Checked: value };
          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTDate') {
            const dateValue = typeof value === 'string' || dayjs.isDayjs(value) ? value : null;

            customFieldObj.V = {
              TimeVal: dateValue ? dayjs(dateValue).format('YYYY-MM-DDTHH:mm') : null,
            };
          }

          result.customFields.push(customFieldObj);
        }
      }
    });

    return result;
  };

  const onSubmit = async (data: any) => {
    const methodValues = methods.getValues();

    const dirtyFields = Object.keys(methods.formState.dirtyFields);
    // const dirtyValues = dirtyFields.reduce((acc, fieldName) => {
    //   const value = methodValues[fieldName as keyof BaseInformationSchema2];
    //   if (fieldName === 'type' && value && typeof value === 'object' && 'id' in value && 'label' in value) {
    //     (acc as any)[fieldName] = {
    //       id: value.id,
    //       label: value.label
    //     };
    //   } else {
    //     (acc as any)[fieldName] = value;
    //   }
    //   return acc;
    // }, {} as Partial<TicketData>);

    const dirtyValues = dirtyFields.reduce((acc, fieldName) => {
      (acc as { [key: string]: any })[fieldName] = methodValues[fieldName as keyof BaseInformationSchema2];
      return acc;
    }, {} as Partial<BaseInformationSchema2>);

    const preparedData = prepareTicketData(dirtyValues);
    if (dirtyFields.length > 0) {
      try {
        setIsSaving(true);
        const promises = [];

        if (Object.keys(preparedData.baseFields).length > 1) {
          promises.push(
            updateTicketBaseFields({
              actionType: 'updateTicketBaseFields',
              data: preparedData.baseFields,
            }),
          );
        }

        if (preparedData.customFields.length > 0) {
          promises.push(
            updateTicketCustomFields({
              actionType: 'updateTicketCustomFields',
              data: preparedData.customFields,
            }),
            // .then(() => {
            //   console.log('invalidating custom fields');
            //!   queryClient.invalidateQueries({
            // !    queryKey: ['custom-fields', ticketId]
            //   });
            // })
          );
        }

        const results = await Promise.allSettled(promises);

        const hasError = results.some((result) => result.status === 'rejected');

        if (!hasError) {
          enqueueSnackbar('Ticket has been updated', { variant: 'success' });
          methods.reset(methodValues);
        } else {
          enqueueSnackbar('Some parts of the ticket update failed', { variant: 'warning' });
        }
      } catch (error) {
        enqueueSnackbar('Ticket update failed', { variant: 'error' });
      } finally {
        setIsSaving(false);
      }
    }
  };

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      const confirmLeave = confirm('Are you sure you want to delete this ticket permanently?');
      if (confirmLeave) {
        const ticketDelete = await updateTicketDeleteMutation.mutateAsync({ actionType: 'deleteTicket', ticketid: ticketId });
        if (typeof ticketDelete?.data === 'string' && ticketDelete?.data?.includes('deleted ticketID')) {
          navigate({ to: '/', search: { drawer: undefined } });
        }
      }
    } finally {
      setIsDeleting(false);
    }
  };

  const findPreviousAndNextTickets = (
    tickets: number[],
    currentTicket: number,
  ): {
    previousId: number | undefined;
    nextId: number | undefined;
    currentIndex: number | undefined;
    totalTickets: number | undefined;
  } => {
    const currentIndex = tickets.indexOf(currentTicket);

    // If ticket not found in array
    if (currentIndex === -1) {
      return {
        previousId: undefined,
        nextId: undefined,
        currentIndex: -1,
        totalTickets: tickets.length,
      };
    }

    const previousId = currentIndex > 0 ? tickets[currentIndex - 1] : undefined;
    const nextId = currentIndex < tickets.length - 1 ? tickets[currentIndex + 1] : undefined;

    return {
      previousId,
      nextId,
      currentIndex: currentIndex + 1,
      totalTickets: tickets.length,
    };
  };

  const { previousId, nextId, currentIndex, totalTickets } = findPreviousAndNextTickets(
    ticketTableState?.ticketIds,
    ticketId,
  );

  return (
    <>
      {ticketById?.data?.Message?.includes('not') && (
        <AccessDenied
          message="You don't have access to this ticket type. Please contact an administrator."
          onClose={() => navigate({ to: '/', search: { drawer: undefined } })}
        />
      )}
      {/* <Grid container display={"flex"} flexDirection={"row"} flexWrap={"wrap"}> */}
      <Grid container size={12}>
        {/* <Grid size={12} sx={{ marginBottom: "16px" }}> */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1, // Higher z-index to ensure it stays on top
            width: '100%',
            backgroundColor: 'background.default', // Add background color to prevent content showing through
            paddingBottom: 2,
          }}>
          <Grid size={12} sx={{ position: 'sticky', top: 0, zIndex: 1, mb: 4 }}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid size={12} container justifyContent="space-between" alignItems="center" sx={{ ml: 2 }}>
                    {ticketById?.isLoading ? (
                      <Skeleton variant="text">
                        <Typography variant="h5" component="h5" sx={{ marginTop: 2 }}>
                          Place holder address
                        </Typography>
                      </Skeleton>
                    ) : (
                      <Typography variant="h5" component="h5" sx={{ marginTop: 2 }}>
                        {ticketById?.data?.Ticket?.ClientAddress || ''}
                      </Typography>
                    )}
                    <Typography variant="body1" component="p" display="flex" alignItems="center">
                      {isOpenConfirmationModal && (
                        <ConfirmModal
                          open={isOpenConfirmationModal}
                          setOpen={setIsOpenConfirmationModal}
                          title="Confirm Close"
                          content={
                            <div>
                              <div style={{ color: 'blue', fontWeight: 'bold', marginBottom: 5 }}>
                                Changes detected on form.
                              </div>
                              <div>
                                You have made changes to this form. Click 'Leave' to close the form and lose your
                                changes. Click 'Cancel' to return to the form.
                              </div>
                            </div>
                          }
                          onSuccess={() => {
                            if (navigateTo === 'previous') {
                              navigate({
                                to: `/ticket/${previousId}`,
                                params: { ticketId: previousId },
                              });
                            } else if (navigateTo === 'next') {
                              navigate({
                                to: `/ticket/${nextId}`,
                                params: { ticketId: nextId },
                              });
                            } else if (navigateTo === 'back') {
                              navigate({ to: '/', search: { drawer: undefined } });
                            }
                          }}
                          onClose={() => setIsOpenConfirmationModal(false)}
                          cancelButtonText="Cancel"
                          confirmButtonText={'Leave'}
                        />
                      )}
                      {currentIndex !== -1 && (
                        <>
                          <Button
                            id="prev-ticket"
                            onClick={() => {
                              if (isDirty) {
                                setIsOpenConfirmationModal(true);
                                setNavigateTo('previous');
                              } else {
                                navigate({
                                  to: `/ticket/${previousId}`,
                                  params: { ticketId: previousId },
                                });
                              }
                            }}
                            disabled={previousId === undefined}
                            size={'small'}>
                            Previous
                          </Button>
                          <Typography sx={{ padding: '8px' }}>
                            {currentIndex} of {totalTickets ?? 0}
                          </Typography>
                          <Button
                            id="next-ticket"
                            onClick={() => {
                              if (isDirty) {
                                setIsOpenConfirmationModal(true);
                                setNavigateTo('next');
                              } else {
                                navigate({
                                  to: `/ticket/${nextId}`,
                                  params: { ticketId: nextId },
                                });
                              }
                            }}
                            disabled={nextId === undefined}
                            size={'small'}>
                            Next
                          </Button>
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    size={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ paddingLeft: '8px' }}>
                    {ticketById?.isLoading ? (
                      <Skeleton variant="text">
                        <Typography variant="body1" component="p">
                          {`Ticket: #999999`} {`Customer- Customer Name here`}
                        </Typography>
                      </Skeleton>
                    ) : (
                      <Typography variant="body1" component="p">
                        {ticketById?.data?.Ticket?.TId && `Ticket: #${ticketById?.data?.Ticket?.TId}`}{' '}
                        {ticketById?.data?.CustomerEntity?.EntName && `- ${ticketById?.data?.CustomerEntity?.EntName}`}
                      </Typography>
                    )}
                    {!isSmallScreen &&
                      (ticketById?.isLoading ? (
                        <Skeleton variant="text">
                          <Typography variant="body1" component="p" align="right" sx={{ paddingRight: '16px' }}>
                            {ticketViewers?.data?.length} Viewing: (
                            {ticketViewers?.data?.map((agent: any) => agent.Name).join(', ')})
                          </Typography>
                        </Skeleton>
                      ) : (
                        <Typography variant="body1" component="p" align="right" sx={{ paddingRight: '16px' }}>
                          {ticketViewers?.data?.length} Viewing: (
                          {ticketViewers?.data?.map((agent: any) => agent.Name).join(', ')})
                        </Typography>
                      ))}
                  </Grid>
                  <Grid
                    size={12}
                    container
                    justifyContent="space-between"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                    alignItems={isSmallScreen ? 'flex-end' : 'center'}
                    sx={{ ml: 2 }}>
                    {ticketById?.isLoading ? (
                      <Skeleton variant="text">
                        <Typography variant="body1" component="p">
                          {`Last Updated 'MM/DD/YY'`} {`by LastUpdatedByName`} {`(Created on MM/DD/YY')`}
                        </Typography>
                      </Skeleton>
                    ) : (
                      <Typography variant="body1" component="p">
                        {ticketById?.data?.Ticket?.LastUpdated &&
                          `Last Updated ${dayjs(ticketById?.data?.Ticket?.LastUpdated).format('MM/DD/YY')}`}{' '}
                        {ticketById?.data?.Ticket?.LastUpdatedByName &&
                          `by ${ticketById?.data?.Ticket?.LastUpdatedByName}`}{' '}
                        {ticketById?.data?.Ticket?.CreateTime &&
                          `(Created on ${dayjs(ticketById?.data?.Ticket?.CreateTime).format('MM/DD/YY')})`}
                      </Typography>
                    )}

                    <Typography>
                      <Permissions target={'ticketDetailDelete'}>
                        <LoadingButton
                          size={'small'}
                          sx={{
                            marginRight: '8px',
                            minWidth: isSmallScreen ? '58px' : '64px',
                          }}
                          id="delete-ticket"
                          color="error"
                          loading={isDeleting}
                          onClick={onDelete}>
                          {isSmallScreen ? 'Delete' : 'Delete Ticket'}
                        </LoadingButton>
                      </Permissions>
                      <Permissions target={'Templates'}>
                        <Button
                          size={'small'}
                          sx={{
                            marginRight: '8px',
                            minWidth: isSmallScreen ? '58px' : '64px',
                          }}
                          variant="outlined"
                          id="print-ticket"
                          onClick={() => updateTicketList({ ticketPrintOpen: true })}>
                          Print
                        </Button>
                      </Permissions>
                      <Button
                        size={'small'}
                        sx={{
                          marginRight: '8px',
                          minWidth: isSmallScreen ? '58px' : '64px',
                        }}
                        variant="outlined"
                        id="map-ticket"
                        onClick={() => updateTicketList({ ticketDetailMapOpen: true })}>
                        {/* //todo Finish the map. I think the data will be best to be pulled from the DOM rather than using state */}
                        Map
                      </Button>
                      <Button
                        size={'small'}
                        sx={{
                          marginRight: '8px',
                          minWidth: isSmallScreen ? '58px' : '64px',
                        }}
                        variant="outlined"
                        id="return-home"
                        onClick={() => {
                          if (isDirty) {
                            setIsOpenConfirmationModal(true);
                            setNavigateTo('back');
                          } else {
                            navigate({ to: '/', search: { drawer: undefined } });
                          }
                        }}>
                        {isSmallScreen ? 'Back' : 'Back To Ticket List'}
                      </Button>
                      <Permissions target={'ticketDetailSave'} disableMode>
                        <LoadingButton
                          variant="contained"
                          size={'small'}
                          sx={{
                            minWidth: isSmallScreen ? '58px' : '64px',
                          }}
                          id="save-ticket"
                          type="submit"
                          loading={isSaving}
                          onClick={methods.handleSubmit(onSubmit)}>
                          Save
                        </LoadingButton>
                      </Permissions>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {duplicateAddress.data && (
            <Grid size={12} width="100%" id="duplicate-address-warning">
              <Alert
                severity="warning"
                sx={{
                  mt: 2,
                  mb: 2,
                }}>
                <Typography variant="body1" component="p">
                  Warning - Tickets with a similar client address:{' '}
                  {duplicateAddress?.data?.map((ticket: any, index: number) => (
                    <>
                      {/* <Grid size={12} key={ticket.TicketID} component="span" style={{ display: 'inline' }}> */}
                      <Link to={`/ticket/${ticket.TicketID}`} style={{ color: 'blue' }}>
                        {ticket.Address}
                      </Link>
                      {/* <a href={`${ticket.TicketID}`} style={{ color: 'blue' }}>{ticket.Address}</a> */}
                      {index < duplicateAddress?.data?.length - 1 ? ', ' : ''}
                      {/* </Grid> */}
                    </>
                  ))}
                </Typography>
              </Alert>
            </Grid>
          )}
        </Box>

        {/* <Grid
          display={"flex"}
          flexDirection={{
            xs: 'column-reverse',
            md: 'row',
          }}
          width={"100%"}
        >
          <Grid
            size={{
              xs: ticketListState.ticketHistoryOpen ? 12 : 12,
              md: ticketListState.ticketHistoryOpen ? 8 : 11.5
            }}
            className="baseInfo"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            sx={{
              marginRight: {
                xs: 0,
                md: 4,
              },
              maxHeight: isSmallScreen ? 'calc(100vh - 262px)' : 'calc(100vh - 250px)',
              overflowX: 'auto',
            }}
              
          > */}
        <Grid
          container
          size={12}
          sx={{
            height: `calc(100vh - ${duplicateAddress.data ? '300px' : '250px'})`, // Adjust height based on warning presence
            overflow: 'auto',
            mt: 2,
            // overflow: 'hidden',
          }}>
          <Grid
            size={{ xs: 12, md: ticketListState.ticketHistoryOpen ? 8 : 11.5 }}
            sx={{
              overflow: 'auto',
              // pr: { md: 2 },
              height: '100%',
            }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Accordion
                  id="base-information"
                  onChange={(e, expanded) =>
                    updateTicketList({
                      accordionsOpen: {
                        ...ticketListState.accordionsOpen,
                        baseFields: expanded,
                      },
                    })
                  }
                  expanded={ticketListState.accordionsOpen.baseFields}
                  sx={{
                    mb: 4,
                    boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;',
                    width: '99%',
                    borderRadius: 1.5,
                    '&.Mui-expanded:first-of-type': { marginTop: 0.5 },
                  }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ ml: 2 }}>
                    <Typography variant="button" component="p" sx={{ fontWeight: 'bold' }} fontSize={14}>
                      Ticket Detail
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 4 }}>
                    <BaseInformation />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  id="custom-fields"
                  onChange={(e, expanded) =>
                    updateTicketList({
                      accordionsOpen: {
                        ...ticketListState.accordionsOpen,
                        customFields: expanded,
                      },
                    })
                  }
                  expanded={ticketListState.accordionsOpen.customFields}
                  sx={{
                    mb: 4,
                    boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;',
                    width: '99%',
                    borderRadius: 1.5,
                    '&.Mui-expanded:first-of-type': { marginTop: 0.5 },
                  }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ ml: 2 }}>
                    <Typography variant="button" component="p" sx={{ fontWeight: 'bold' }} fontSize={14}>
                      Custom Fields (
                      {ticketListState.totalVisibleCustomFields === 0
                        ? 0
                        : ticketListState.totalVisibleCustomFields - 1}
                      )
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 4 }}>
                    <CustomFields />
                  </AccordionDetails>
                </Accordion>
                {ticketListState.ticketPrintOpen && <PrintNoticeDialog />}
              </form>
            </FormProvider>
            <Accordion
              id="attachments"
              onChange={(e, expanded) =>
                updateTicketList({
                  accordionsOpen: {
                    ...ticketListState.accordionsOpen,
                    attachments: expanded,
                  },
                })
              }
              expanded={ticketListState.accordionsOpen.attachments}
              sx={{
                mb: 4,
                boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;',
                width: '99%',
                borderRadius: 1.5,
                '&.Mui-expanded:first-of-type': { marginTop: 0.5 },
              }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ ml: 2 }}>
                <Typography variant="button" component="p" sx={{ fontWeight: 'bold' }} fontSize={14}>
                  Attachments
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 4 }}>
                <Attachments />
              </AccordionDetails>
            </Accordion>
            <Accordion
              id="notes"
              onChange={(e, expanded) =>
                updateTicketList({
                  accordionsOpen: {
                    ...ticketListState.accordionsOpen,
                    notes: expanded,
                  },
                })
              }
              expanded={ticketListState.accordionsOpen.notes}
              sx={{
                mb: 4,
                boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;',
                width: '99%',
                borderRadius: 1.5,
                '&.Mui-expanded:first-of-type': { marginTop: 0.5 },
              }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ ml: 2 }}>
                <Typography variant="button" component="p" sx={{ fontWeight: 'bold' }} fontSize={14}>
                  Notes
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 4 }}>
                <Notes />
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* {ticketListState.ticketHistoryOpen &&
            <Grid size={{ xs: 12, md: 4 }}
              sx={{
                overflowY: 'auto',
                borderRadius: 1.5,
                boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;",
                marginBottom: {
                  xs: 4,
                  md: 0
                }
              }}
            >
              <Paper>
                <Typography variant="body1" component="p">
                  <TicketHistory />
                </Typography>
              </Paper>
            </Grid>
          }
          {!ticketListState.ticketHistoryOpen &&
            <Grid
              sx={{
                marginBottom: {
                  xs: 4,
                  md: 0
                },
                display: {
                  xs: 'flex',
                  md: 'block'
                },
                justifyContent: 'flex-end'
              }}
            >
              <Button
                id='expand-btn'
                variant='outlined'
                onClick={() => updateTicketList({ ticketHistoryOpen: true })}
              >
                <KeyboardDoubleArrowLeftIcon />
              </Button>
            </Grid>
          } */}
          {ticketListState.ticketHistoryOpen ? (
            <Grid
              size={{ xs: 12, md: 4 }}
              sx={{
                height: '100%',
                overflowY: 'auto',
              }}>
              <Paper sx={{ height: '100%', borderRadius: 2 }}>
                <TicketHistory />
              </Paper>
            </Grid>
          ) : (
            <Grid size={{ xs: 12, md: 0.5 }} sx={{ display: 'flex', justifyContent: 'flex-end', height: '25px' }}>
              <Button id="expand-btn" variant="outlined" onClick={() => updateTicketList({ ticketHistoryOpen: true })}>
                <KeyboardDoubleArrowLeftIcon />
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      {ticketListState.ticketDetailMapOpen && <TicketDetailMap />}
    </>
  );
};

export default TicketDetail;
