import React, { ChangeEvent, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';

interface IProps {
  id: string;
  label: string;
  checked: boolean;
  onDataChange: any;
  inputProps?: any;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  hintText?: string;
  sxProps?: any
  indeterminate?: any;
}

const CheckboxInput = ({ onDataChange, ...props }: IProps) => {
  const [checked, setChecked] = useState(true);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onDataChange(props.id, event.target.value);
  };

  return (
    <FormControlLabel
      label={props.label}
      control={<Checkbox sx={props.sxProps} indeterminate={props.indeterminate} checked={props.checked} onChange={handleChange} inputProps={props.inputProps} />}
    />
  );
};

export default CheckboxInput;
