import CustomerDrawer from '@/app/pages/Customers/CustomerDrawer'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

export const Route = createFileRoute('/_auth/customers/$customerId')({
  component: () => <CustomerDrawer />,
  params: {
    parse: (params) => ({
      customerId: z.number().int().parse(Number(params.customerId)),
    }),
    stringify: ({ customerId }) => ({ customerId: `${customerId}` }),
  },
  validateSearch: (search) => {
    return z
      .object({
        variant: z.enum(['edit', 'new']).default('edit'),
        title: z.string().default(''),
        // customerId: z.number().int().default(0),
      })
      .parse(search)
  },
})
