import type { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, MuiDialog, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { Box, Divider, List, ListItem, Tooltip, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import CheckboxInput from '@/components/Atoms/Checkbox/CheckboxInput.component';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import { useFieldArray, useWatch } from 'react-hook-form';
import useReportHeaders from '@/hooks/fetches/ReportHeaders/useReportHeaders.service';
import ConfirmModal from '@/components/Molecules/Modal/ConfirmModal.component';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface IProps extends DialogProps {
  setOpen: (open: boolean) => void;
  data: any;
  methods: any;

  onSuccess?: () => void;
  onClose?: () => void;
  disabled?: boolean;
}

const ReportingGroupHeaderModal = (props: IProps) => {
  const { data, onSuccess, methods, setOpen, onClose, disabled, ...dialogProps } = props;
  const { control, setValue, reset } = methods;

  const [isNewHeader, setIsNewHeader] = useState<boolean>(false);
  const [renameId, setRenameId] = useState<number | null>(null);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openCheckExitModal, setOpenCheckExitModal] = useState<boolean>(false);
  const [indexToBeDeleted, setIndexToBeDeleted] = useState<number | null>(null);
  const [isUnsavedChangesModal, setisUnsavedChangesModal] = useState<boolean>(false);
  const [newHeaderIncludeName, setNewHeaderIncludeName] = useState<boolean>(false);
  const [newHeaderShowAll, setNewHeaderShowAll] = useState<boolean>(false);

  const newHeaderName = useWatch({ control, name: 'newGroupHeader.Name' });
  const nameToBeEdited = useWatch({ control, name: 'newGroupHeader.NameToBeEdited' });

  const originalDataRef = useRef<any>(null);
  const theme = useTheme();
  const { executeReportHeaderAction, setIsUpdating } = useReportHeaders();

  const { append, fields, remove, replace, update } = useFieldArray({
    control,
    name: 'reportingGroupHeaders',
  });

  useEffect(() => {
    setIsUpdating(false);
    setIsNewHeader(false);
    setRenameId(null);

    originalDataRef.current = data;

    if (data) {
      replace(data);
    }
  }, []);

  const handleAddNewGroupHeader = () => {
    append({
      Id: 0,
      Name: newHeaderName,
      IncludeFieldName: newHeaderIncludeName,
      ShowAll: newHeaderShowAll,
      ReportHeaderCount: '',
    });

    setIsDirty(true);
    setIsNewHeader(false);
    setValue('newGroupHeader.Name', '');
    setNewHeaderIncludeName(false);
    setNewHeaderShowAll(false);
  };

  const handlePostNewGroupHeader = (header: any) => {
    executeReportHeaderAction({
      actionType: 'upsertReportHeader',
      payload: {
        Id: 0,
        Name: header.Name,
        IncludeFieldName: header.IncludeFieldName,
        Createdts: 0,
        Updatedts: 0,
        UpdatedBy: '',
        Deletedts: 0,
        DeletedBy: '',
        ShowAll: header.ShowAll,
        UpdatedAt: '0',
      },
    });
  };

  const handleDeleteNewGroupHeader = (header: any) => {
    executeReportHeaderAction({
      actionType: 'upsertReportHeader',
      payload: {
        Id: header.Id,
        Name: header.Name,
        IncludeFieldName: header.IncludeFieldName,
        Createdts: header.Createdts,
        CreatedBy: header.CreatedBy,
        Updatedts: header.Updatedts,
        UpdatedBy: header.UpdatedBy,
        ShowAll: header.ShowAll,
        Deletedts: Math.floor(Date.now() / 1000),
        UpdatedAt: '0',
        ReportHeaderCount: header.ReportHeaderCount,
        ReportingGroupHeaderList: header.ReportingGroupHeaderList,
      },
    });
  };

  const handleSaveGroupHeader = (header: any) => {
    executeReportHeaderAction({
      actionType: 'upsertReportHeader',
      payload: {
        Id: header.Id,
        Name: nameToBeEdited,
        IncludeFieldName: header.IncludeFieldName,
        Createdts: header.Createdts,
        CreatedBy: header.CreatedBy,
        Updatedts: header.Updatedts,
        UpdatedBy: header.UpdatedBy,
        ShowAll: header.ShowAll,
        Deletedts: '0',
        UpdatedAt: '0',
        DeletedBy: '',
        ReportHeaderCount: header.ReportHeaderCount,
        ReportingGroupHeaderList: header.ReportingGroupHeaderList,
      },
    });
  };

  const handleNewName = (i: number) => {
    update(i, {
      ...fields[i],
      Name: nameToBeEdited,
      WasEdited: true,
    });
    setIsDirty(true);
    setRenameId(null);
  };

  const handleSave = () => {
    if (!isDirty) {
      onClose?.();
    } else if (isNewHeader || renameId) {
      setisUnsavedChangesModal(true);
    } else {
      // get current state
      const updatedHeaders = methods.getValues('reportingGroupHeaders');
      // get original state
      const originalHeaders = originalDataRef.current;

      // any newly created headers will have Id = 0
      const newItems = updatedHeaders.filter((h: any) => h.Id === 0);

      // any deleted headers will be in original but not updated
      const removedItems = originalHeaders.filter(
        (orig: any) => !updatedHeaders.some((upd: any) => upd.Id === orig.Id),
      );
      // any edited header will have WasEdited
      const editedItems = updatedHeaders.filter((h: any) => h.WasEdited);

      newItems?.forEach((header: any) => handlePostNewGroupHeader(header));
      removedItems.forEach((header: any) => handleDeleteNewGroupHeader(header));
      editedItems.forEach((header: any) => handleSaveGroupHeader(header));

      onClose?.();
    }
  };

  const handleCheck = () => {
    if (!isDirty) {
      onClose?.();
    } else {
      setOpenCheckExitModal(true);
    }
  };

  return (
    <MuiDialog {...dialogProps}>
      <DialogTitle sx={{ m: 2 }}>Reporting Group Headers</DialogTitle>
      <Divider />
      <DialogContent>
        <List>
          {fields?.map((header: any, i: number) => {
            const validatedId = header.Id === 0 ? header.id : header.Id;
            const nameAndCount =
              header.ReportHeaderCount !== '' ? (
                <Tooltip
                  title={header?.ReportingGroupHeaderList?.split('|')?.map((h: any, i: number) => {
                    return <div key={i}>{h}</div>;
                  })}
                  placement="right">
                  <span>
                    {header.Name}{' '}
                    <span style={{ color: theme.palette.secondary.main }}>{`(${header.ReportHeaderCount})`}</span>
                  </span>
                </Tooltip>
              ) : (
                <div>{header.Name}</div>
              );
            return (
              <ListItem
                sx={{
                  borderBottom: '1px solid #ccc',
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 10,
                }}>
                {renameId === validatedId ? (
                  <TextBox
                    id={`newGroupHeader.NameToBeEdited`}
                    name={`newGroupHeader.NameToBeEdited`}
                    label="Rename Group Header"
                    size="small"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsDirty(true);
                      setValue('newGroupHeader.NameToBeEdited', e.target.value);
                    }}
                  />
                ) : (
                  <Typography>{nameAndCount}</Typography>
                )}
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  {renameId !== validatedId && (
                    <Box>
                      <Button
                        id="renameButton"
                        type="button"
                        onClick={() => {
                          setValue('newGroupHeader.NameToBeEdited', header.Name);
                          setRenameId(validatedId);
                        }}>
                        Rename
                      </Button>
                      <Button
                        id="deleteButton"
                        color="error"
                        onClick={() => {
                          setOpenConfirmDeleteModal(true);
                          setIndexToBeDeleted(i);
                          setIsDirty(true);
                        }}>
                        Delete
                      </Button>
                    </Box>
                  )}
                  {openConfirmDeleteModal && (
                    <ConfirmModal
                      open={openConfirmDeleteModal}
                      setOpen={setOpenConfirmDeleteModal}
                      title="Are you sure?"
                      content={'This action will delete this reporting group header.'}
                      onSuccess={() => {
                        if (indexToBeDeleted != null) {
                          remove(indexToBeDeleted);
                          setIsDirty(true);
                        }
                      }}
                      onClose={() => setOpenConfirmDeleteModal(false)}
                      cancelButtonText="Cancel"
                      confirmButtonText={'Delete'}
                    />
                  )}
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CheckboxInput
                      sxProps={{ p: 0, m: 1 }}
                      id={header.IncludeFieldName}
                      label={'Include Field Names'}
                      checked={header.IncludeFieldName}
                      onDataChange={() => {
                        setIsDirty(true);
                        update(i, {
                          ...fields[i],
                          IncludeFieldName: !header.IncludeFieldName,
                          WasEdited: true,
                        });
                      }}
                    />
                    <Box>
                      <CheckboxInput
                        sxProps={{ p: 0, m: 1 }}
                        id={header.ShowAll}
                        label={'Show All Values'}
                        checked={header.ShowAll}
                        onDataChange={() => {
                          setIsDirty(true);
                          update(i, {
                            ...fields[i],
                            ShowAll: !header.ShowAll,
                            WasEdited: true,
                          });
                        }}
                      />
                      <IconButton
                        id="helpBtn"
                        size="small"
                        sx={{ m: 0, p: 0 }}
                        onClick={() => {
                          window.alert(
                            'When "Show All Values" is checked, the header column will include non-empty values for all custom fields assigned to the group header. When unchecked, will only include the most recent value.',
                          );
                        }}>
                        <Tooltip title="Help" placement="right">
                          <InfoOutlinedIcon />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  </Box>
                  {renameId === validatedId && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Button id="renameButton" size="small" variant="contained" onClick={() => handleNewName(i)}>
                        Save
                      </Button>
                      <Button
                        size="small"
                        id="cancelButton"
                        variant="outlined"
                        color="error"
                        onClick={() => setRenameId(null)}>
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Box>
              </ListItem>
            );
          })}
          {isNewHeader && (
            <ListItem
              sx={{
                borderTop: '1px solid #ccc',
                borderBottom: '1px solid #ccc',
                display: 'flex',
                justifyContent: 'space-even;l',
                gap: 10,
              }}>
              <TextBox
                id="newGroupHeader.Name"
                name="newGroupHeader.Name"
                label="New Group Header Name"
                size="small"
                onChange={() => {
                  setIsDirty(true);
                }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CheckboxInput
                  sxProps={{ p: 0, m: 1 }}
                  id={'newGroupHeader.IncludeName'}
                  label={'Include Field Names'}
                  checked={newHeaderIncludeName}
                  onDataChange={() => {
                    setNewHeaderIncludeName(!newHeaderIncludeName);
                  }}
                />
                <Box>
                  <CheckboxInput
                    sxProps={{ p: 0, m: 1 }}
                    id={'newGroupHeader.ShowAll'}
                    label={'Show All Values'}
                    checked={newHeaderShowAll}
                    onDataChange={() => {
                      setNewHeaderShowAll(!newHeaderShowAll);
                    }}
                  />
                  <IconButton
                    id="helpBtn"
                    size="small"
                    sx={{ m: 0, p: 0 }}
                    onClick={() => {
                      window.alert(
                        'When "Show All Values" is checked, the header column will include non-empty values for all custom fields assigned to the group header. When unchecked, will only include the most recent value.',
                      );
                    }}>
                    <Tooltip title="Help" placement="right">
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Button
                  sx={{ ml: 2 }}
                  variant="outlined"
                  id="cancelButton"
                  size="small"
                  color="error"
                  onClick={() => {
                    setIsNewHeader(false);
                    reset({
                      newGroupHeader: {
                        Name: '',
                        IncludeName: false,
                        ShowAll: false,
                      },
                    });
                  }}>
                  Cancel
                </Button>
                <Button
                  sx={{ ml: 2 }}
                  id="renameButton"
                  size="small"
                  variant="contained"
                  onClick={handleAddNewGroupHeader}>
                  Add
                </Button>
              </Box>
            </ListItem>
          )}
        </List>
        {openCheckExitModal && (
          <ConfirmModal
            open={openCheckExitModal}
            setOpen={setOpenCheckExitModal}
            title="Confirm Close"
            content={
              <div>
                <div style={{ color: 'blue', fontWeight: 'bold', marginBottom: 5 }}>Changes detected on form.</div>
                <div>
                  You have made changes to this form. Click 'Leave' to close the form and lose your changes. Click
                  'Cancel' to return to the form.
                </div>
              </div>
            }
            onSuccess={() => {
              onClose?.();
            }}
            onClose={() => setOpenCheckExitModal(false)}
            cancelButtonText="Cancel"
            confirmButtonText={'Leave'}
          />
        )}
        {isUnsavedChangesModal && (
          <ConfirmModal
            open={isUnsavedChangesModal}
            setOpen={setisUnsavedChangesModal}
            title="Confirm Leave"
            content={'You currently have an unsaved field. If you leave now the field will not be saved. '}
            onSuccess={() => {
              setValue('newGroupHeader.Name', '');
              setNewHeaderIncludeName(false);
              setNewHeaderShowAll(false);
              onClose?.();
            }}
            onClose={() => setisUnsavedChangesModal(false)}
            cancelButtonText="Cancel"
            confirmButtonText={'Leave'}
          />
        )}
      </DialogContent>
      {/* <Divider /> */}
      <DialogActions sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <IconButton
          id="closeModalIcon"
          color="default"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          onClick={handleCheck}>
          <CloseIcon />
        </IconButton>
        <Button
          id="addNewBtn"
          size="small"
          variant="contained"
          onClick={() => {
            setIsNewHeader(true);
          }}
          sx={{ visibility: isNewHeader ? 'hidden' : 'visible' }}>
          + Add New
        </Button>
        <Box>
          <Button id="cancelBtn" color="error" onClick={handleCheck} size="small" variant="outlined">
            Cancel
          </Button>
          <Button
            id="okayBtn"
            disabled={disabled}
            size="small"
            sx={{ ml: 2 }}
            disableElevation
            variant="contained"
            onClick={() => {
              handleSave();
            }}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </MuiDialog>
  );
};

export default ReportingGroupHeaderModal;
