import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { NodeProps, Node } from '@xyflow/react';
import { Input } from './Input';
import { Select } from './Select';
import { NodeWrapper } from './NodeWrapper';
import { z } from 'zod';
import { useFormValidation } from './useFormValidation';
import { EmailConfig } from './Action/EmailConfig';

export const emailConfigSchema = z.object({
  to: z.string().email('Invalid email address'),
  subject: z.string().min(1, 'Subject is required'),
  template: z.string().min(1, 'Template is required'),
  cc: z.array(z.string().email('Invalid CC email')).optional(),
  bcc: z.array(z.string().email('Invalid BCC email')).optional(),
});

export const taskConfigSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().optional(),
  assignee: z.string().min(1, 'Assignee is required'),
  dueDate: z.string().optional(),
  priority: z.enum(['high', 'medium', 'low']),
});

export const statusUpdateConfigSchema = z.object({
  newStatus: z.string().min(1, 'Status is required'),
  reason: z.string().optional(),
  notifyUser: z.boolean().default(false),
});

export const notificationConfigSchema = z.object({
  message: z.string().min(1, 'Message is required'),
  type: z.enum(['success', 'warning', 'info', 'error']),
  channel: z.enum(['email', 'sms', 'push', 'in-app']),
  recipients: z.array(z.string()).min(1, 'At least one recipient is required'),
});

export const actionNodeSchema = z.object({
  label: z.string().min(1, 'Label is required'),
  actionType: z.enum(['email', 'task', 'status', 'notification']),
  enabled: z.boolean(),
  config: z.union([
    emailConfigSchema,
    taskConfigSchema,
    statusUpdateConfigSchema,
    notificationConfigSchema,
  ]),
});

export type ActionNodeData = z.infer<typeof actionNodeSchema>;

const NodeContent = styled.div`
  min-width: 300px;
  padding: 12px;
`;

const EnableToggle = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  cursor: pointer;
`;

const Toggle = styled.input`
  width: 40px;
  height: 20px;
  appearance: none;
  background: ${({ checked }) => checked ? '#4CAF50' : '#ccc'};
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  
  &:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 50%;
    top: 2px;
    left: ${({ checked }) => checked ? '22px' : '2px'};
    transition: left 0.2s;
  }
`;

const actionTypes = [
  { value: 'email', label: 'Send Email' },
  { value: 'task', label: 'Create Task' },
  { value: 'status', label: 'Update Status' },
  { value: 'notification', label: 'Send Notification' },
];

export type ActionNode = Node<
  {
    label: string;
    actionType: string;
    config: Record<string, any>;
    enabled: boolean;
  },
  'action'
>;

const ACTION_TYPES = [
  { value: 'email', label: 'Send Email' },
  { value: 'task', label: 'Create Task' },
  { value: 'status', label: 'Update Status' },
  { value: 'notification', label: 'Send Notification' }
];

export const ActionNode = (props: NodeProps<ActionNode>) => {
  const { data } = props;
  const renderConfigFields = () => {
    switch (data.actionType) {
      case 'email':
        return (
          <>
            <Input
              label="To Address"
              value={data.config.to}
              onChange={(e) => {
                // Handle change through callback
              }}
            />
            <Input
              label="Subject"
              value={data.config.subject}
              onChange={(e) => {
                // Handle change through callback
              }}
            />
          </>
        );
      case 'task':
        return (
          <>
            <Input
              label="Task Title"
              value={data.config.title}
              onChange={(e) => {
                // Handle change through callback
              }}
            />
            <Select
              label="Priority"
              options={[
                { value: 'high', label: 'High' },
                { value: 'medium', label: 'Medium' },
                { value: 'low', label: 'Low' }
              ]}
              value={data.config.priority}
              onChange={(value) => {
                // Handle change through callback
              }}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <NodeWrapper type="action" label={data.label}>
      <NodeContent>
        <Select
          label="Action Type"
          options={ACTION_TYPES}
          value={data.actionType}
          onChange={(value) => {
            // Handle change through callback
          }}
        />
        {renderConfigFields()}
      </NodeContent>
    </NodeWrapper>
  );
};
