// components/SmartStepper.tsx
import { Box, Button, LinearProgress, Step, StepLabel, Stepper, Typography, useTheme } from '@mui/material';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { z } from 'zod';
import { useState, useEffect } from 'react';
import { Route } from '@/routes/_auth/workflow-rules/$workflowruleId';
import { Divider } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useFormContext, useWatch } from 'react-hook-form';

export interface StepperStep {
  label: string;
  key: string;
  component: React.ComponentType<{ stepKey: string }>;
  optional?: boolean;
}

interface CustomStepIconProps {
  icon: React.ReactNode;
  active: boolean;
  completed: boolean;
}

interface SmartStepperProps {
  steps: StepperStep[];
  routePath: string;
  handleComplete: () => void;
}

const stepSearchSchema = z.object({
  step: z.number().default(0),
  completed: z.record(z.string(), z.boolean()).default({}),
});

export function SmartStepper({ steps, routePath, handleComplete }: SmartStepperProps) {
  const navigate = useNavigate({ from: Route.fullPath });
  const { step, completed, variant } = Route.useSearch();
  const theme = useTheme();

  const methods = useFormContext()
  const customerWatch = useWatch({ control: methods.control, name: 'customer' });

  const [stepState, setStepState] = useState({
    step,
    completed: completed || {},
  });

  useEffect(() => {
    setStepState({ step, completed });
  }, [step, completed]);

  const handleNext = () => {
    if (stepState.step === steps.length - 1) {
      handleComplete();
    } else {
      const newStep = Math.min(stepState.step + 1, steps.length - 1);
      const newCompleted = { ...stepState.completed, [steps[stepState.step].key]: true };
      // if (completedSteps() === totalSteps() - 1) {
      //   //     console.log('saved');
      //   //   } 
      // }

      navigate({
        search: {
          step: newStep,
          completed: newCompleted,
          variant: variant,
        },
      });
    }
  };

  const handleBack = () => {
    navigate({
      search: {
        step: Math.max(stepState.step - 1, 0),
        completed: stepState.completed,
        variant: variant,
      },
    });
  };

  const handleStepClick = (step: number) => {
    if (stepState.completed[steps[step].key] || step < stepState.step) {
      navigate({
        search: {
          step: step,
          completed: stepState.completed,
          variant: variant,
        },
      });
    }
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const CustomStepIcon: React.FC<CustomStepIconProps> = ({ icon, active, completed }) => {
    return (
      <>
        {variant !== 'edit' ? (
          completed ? (
            <Box
              id="completed button"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'green',
                width: 26,
                height: 26,
                borderRadius: '50%',
                // color: '#fff',
              }}
            >
              <CheckCircleIcon sx={{ color: 'green' }} />
            </Box>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 24,
                height: 24,
                borderRadius: '50%',
                backgroundColor: active ? theme.palette.primary.light : completed ? '#4caf50' : '#e0e0e0',
                color: '#fff',
              }}
            >
              <Typography>{completed ? <CheckCircleIcon sx={{ color: 'white' }} /> : icon}</Typography>
            </div>
          )
        ) : undefined}
      </>
    );
  };

  const StepComponent = steps[stepState.step].component;

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={stepState.step} step={stepState.step} >
        {steps.map((step, index) => (
          <Step
            key={step.key}
            completed={stepState.completed[step.key]}
            onClick={() => handleStepClick(index)}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <StepLabel optional={step.optional && <Typography variant="caption">Optional</Typography>}>
              {step.label}
              {stepState.step === index ? (
                <>
                  <Divider
                    sx={{
                      width: '100%',
                      height: '2px',
                      mt: 2,
                      // mb: 1,
                      mx: 1,
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                    }}
                  />
                </>
              ) : null}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <LinearProgress sx={{ mt: 4 }} variant="determinate" value={(completedSteps() / steps.length) * 100} />

      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            variant="contained"
            disabled={stepState.step === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={!customerWatch}
          >
            {stepState.step === steps.length - 1 ? 'Save' : 'Next'}
          </Button>
        </Box>
      </Box>

      <StepComponent stepKey={steps[stepState.step].key} />
    </Box>
  );
}