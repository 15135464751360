import { z } from "zod";

export interface NewCondition {
  condition: string;
  equality: string;
  equalityNumber: number;
  value: string | number | boolean;
  conditionObj: Record<string, any>;
  type: 'baseField' | 'customField' | 'customer';
  allAny: string;
}

export interface Condition {
  type: 'baseField' | 'customField' | 'customer';
  value: string[];
  column: string;
  userId: string | null;
  fieldId: number;
  isEqual: string;
  statusValue: string | null;
  conditionObj: Record<string, any>;
}

export interface Filter {
  id: string;
  name: string;
  toggleOn: boolean;
  condition: Condition[];
}

export interface IType {
  name: 'all_conditions',
}

export interface ReportFilter {
  report_filter_customer_filter: {
    id: number;
    name: string;
  } | null;
  report_filter_name: string;
  all_conditions: Condition[];
}

export const initialReportFilter: ReportFilter = {
  report_filter_customer_filter: null,
  report_filter_name: '',
  all_conditions: [],
};

export const ReportFilterSchema = z.object({
  report_filter_customer_filter: z.preprocess(
    (val) => (val === null ? undefined : val),
    z.object({ id: z.number().min(1, { message: 'Required' }), label: z.string().min(1, { message: 'Required' }) }),
  ),
  report_filter_name: z.string().min(1, { message: 'Name is required' }),
  all_conditions: z.array(z.object({
    type: z.string(),
    // You can add more validations here as needed.
    value: z.union([z.number(), z.array(z.string()), z.boolean(), z.string()]),
    column: z.string(),
    userId: z.any().nullable(),
    fieldId: z.number(),
    isEqual: z.string(),
    statusValue: z.any().nullable(),
    conditionObj: z.object({
      TypeId: z.number(),
      Meta: z.object({
        Cid: z.number(),
        Name: z.string(),
        ArchivedTime: z.string(),
      }),
      Type: z.object({
        // Depending on your use-case, you may choose one set of validations
        // here for option labels OR numeric parameters.
        OptLabels: z.array(z.string()).optional(),
        Maximum: z.number().optional(),
        Minimum: z.number().optional(),
        Step: z.number().optional(),
      }),
      // Optionally, include Value and TicketId if needed.
    }),
  })),
});