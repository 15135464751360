import { queryOptions, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import CustomFieldsService from '@/services/customFields.service';
import TicketService from '@/services/ticket.service';
import useCustomFieldMutation from './useCustomFieldMutation';

const useCustomFields = (id?: number) => {
  const { executeCustomFieldAction, isUpdating, setIsUpdating, executeCustomFieldActionMutation } = useCustomFieldMutation();


  const customFieldsService = CustomFieldsService();
  return {
    executeCustomFieldAction,
    isUpdating,
    setIsUpdating,
    executeCustomFieldActionMutation,
  };
};


export default useCustomFields;

export const createFieldByIdQueryOptions = (id: number) =>
  queryOptions<any>({
    queryKey: ['custom-field-by-id', id],
    queryFn: async () => {
      const ticketService = TicketService();
      if (!id) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await ticketService
          .getTicketCustomFieldGroupsById({
            cid: id,
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });
