import * as React from 'react'
import { Outlet, createRootRouteWithContext, Link, ScrollRestoration } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { CircularProgress } from '@mui/material';
import { Suspense, useContext, useEffect, useRef } from 'react';
import ErrorBoundary from '@/components/Organisms/ErrorBoundary/ErrorBoundary.component';
import { QueryClient } from '@tanstack/react-query';
// import NotFound from '@/app/pages/NotFound/NotFound.page';
import { NotFound } from '@/app/pages/NotFound/404Page';
import { setupInterceptors } from '@/services/api.service';
import { AuthContext } from '@/lib/auth';
import { env } from '../config/env';
import { handleLegacyUrl, LegacyUrlMapping } from '@/App';

export interface RouteHandle {
  title?: string; // Displayed in the dashboard layout as a Header
  isNavItem?: boolean;
  name?: string; // Display name for navItems
  hasDividerAfter?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  index?: number;
}

export type IRoutes = {
  auth: AuthContext
  handles?: RouteHandle;
  queryClient: QueryClient
};

interface MyRouterContext {
  sideMenuState: {
    [key: string]: boolean
  }
}

export const Route = createRootRouteWithContext<{ handles: RouteHandle, queryClient: QueryClient, auth: AuthContext, legacyUrlMappings: Record<string, LegacyUrlMapping> }>()({
  beforeLoad: ({ location, context }) => {
    const result = handleLegacyUrl(
      location.pathname,
      location.search as Record<string, string>,
      context
    )
    if (result) throw result
  },
  notFoundComponent: () => <NotFound />,
  component: () => {

    return (
      <Suspense
        fallback={
          <div className="flex size-full items-center justify-center">
            <CircularProgress />
          </div>
        }
      >
        <ErrorBoundary key={""}>
          <ScrollRestoration />
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    )
  },
});
