import { Box, Paper, Typography, Button, useMediaQuery, useTheme } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';

interface AccessDeniedProps {
  message?: string;
  onClose?: () => void;
}

const AccessDenied = ({
  message = "You don't have access to this feature. Please contact an administrator.",
  onClose
}: AccessDeniedProps) => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: isSmallScreen ? 0 : isMediumScreen ? '160px' : '260px',
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 400,
          width: '90%',
          textAlign: 'center',
          borderRadius: 2,
        }}
      >
        <BlockIcon
          color="error"
          sx={{
            fontSize: 64,
            mb: 2
          }}
        />
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: 'error.main'
          }}
        >
          Access Denied
        </Typography>
        <Typography
          variant="body1"
          sx={{ mb: 3 }}
        >
          {message}
        </Typography>
        {onClose && (
          <Button
            variant="contained"
            onClick={onClose}
            sx={{
              mt: 2
            }}
          >
            Go Back
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default AccessDenied; 