import styled from '@emotion/styled';
import React from 'react';

interface SelectOption {
  value: string;
  label: string;
}

interface SelectProps extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  label?: string;
  options: SelectOption[];
  error?: string;
  onChange: (value: string) => void;
}

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
`;

const StyledSelect = styled.select`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #45b7d1;
  }
`;

const Label = styled.label`
  font-size: 12px;
  color: #666;
`;

const ErrorText = styled.span`
  font-size: 12px;
  color: #ff4444;
`;

export const Select: React.FC<SelectProps> = ({
  label,
  options,
  error,
  onChange,
  ...props
}) => {
  return (
    <SelectWrapper>
      {label && <Label>{label}</Label>}
      <StyledSelect
        onChange={(e) => onChange(e.target.value)}
        {...props}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
      {error && <ErrorText>{error}</ErrorText>}
    </SelectWrapper>
  );
};
