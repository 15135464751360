import '@xyflow/react/dist/style.css';
import { Background, Controls, Edge, MiniMap, NodeTypes, OnSelectionChangeParams, ReactFlow } from '@xyflow/react';
import { TriggerNode } from '@/components/Organisms/Node/TriggerNode';
import { ConditionNode } from '@/components/Organisms/Node/ConditionNode';
import { ActionNode } from '@/components/Organisms/Node/ActionNode';
import { useFlowStore } from '@/components/Organisms/Node/useFlowStore';
import { CustomEdge } from '@/components/Organisms/Node/CustomEdge';

const nodeTypes: NodeTypes = {
  trigger: TriggerNode,
  condition: ConditionNode,
  action: ActionNode
};

const edgeTypes = {
  custom: CustomEdge,
};

const WorkflowRuleStep4 = () => {

  const { nodes, edges, onNodesChange, onEdgesChange, onConnect } = useFlowStore();
  const defaultViewport = { x: 400, y: 200, zoom: 1 };
  return (
    <div style={{ width: '70vw', height: '70vh', marginTop: '2rem' }}>

      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView

        defaultViewport={defaultViewport}
        minZoom={0.2}
        maxZoom={4}
        attributionPosition="bottom-left"
        fitViewOptions={{ padding: 0.5, duration: 1000, minZoom: 0.5, maxZoom: 1.5 }}

      >
        <Background />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </div>
  )
}

export default WorkflowRuleStep4