import { queryOptions, useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';
import TicketService from '@/services/ticket.service';
import { AxiosResponse } from 'axios';
import useTicketMutationsTicketListMap from './useTicketMutationsTicketListMap.service';
import useTicketMutationsBaseFields from './useTicketMutationsBaseFields.service';
import useTicketMutationsCustomFields from './useTicketMutationsCustomFields.service';
import useTicketMutationsNotes from './useTicketMutationsNotes.service';
import useTicketMutationsAttachments from './useTicketMutationsAttachments';
import useTicketDelete from './useTicketDelete.service';
import useCreateTicketMutation from './useCreateTicketMutation';
import useAttachmentDelete from './useAttachmentDelete.service';

interface IUseTicket {
  updateTicketListMapTickets: any;
  isUpdatingTicketListMapTickets: any;
  setIsUpdatingTicketListMapTickets: any;
  updateTicketBaseFields: any;
  isUpdatingTicketBaseFields: any;
  setIsUpdatingTicketBaseFields: any;
  updateTicketCustomFields: any;
  isUpdatingTicketCustomFields: any;
  setIsUpdatingTicketCustomFields: any;
  updateTicketNotes: any;
  isUpdatingTicketNotes: any;
  setIsUpdatingTicketNotes: any;
  updateTicketAttachments: any;
  isUpdatingTicketAttachments: any;
  setIsUpdatingTicketAttachments: any;
  updateTicketDelete: any;
  isUpdatingTicketDelete: any;
  setIsUpdatingTicketDelete: any;
  createTicket: any;
  createTicketMutation: any;
  isUpdatingCreateTicket: any;
  setIsUpdatingCreateTicket: any;
  updateAttachmentDelete: any;
  isUpdatingAttachmentDelete: any;
  setIsUpdatingAttachmentDelete: any;
}

const useTicket = (ticketId?: number, customerId?: number, category?: string, address?: string): IUseTicket => {
  const { updateTicketListMapTickets, isUpdatingTicketListMapTickets, setIsUpdatingTicketListMapTickets } =
    useTicketMutationsTicketListMap();
  const { updateTicketBaseFields, isUpdatingTicketBaseFields, setIsUpdatingTicketBaseFields } =
    useTicketMutationsBaseFields();
  const { updateTicketCustomFields, isUpdatingTicketCustomFields, setIsUpdatingTicketCustomFields } =
    useTicketMutationsCustomFields();
  const { updateTicketNotes, isUpdatingTicketNotes, setIsUpdatingTicketNotes } = useTicketMutationsNotes();
  const { updateTicketAttachments, isUpdatingTicketAttachments, setIsUpdatingTicketAttachments } =
    useTicketMutationsAttachments();
  const { updateTicketDelete, isUpdatingTicketDelete, setIsUpdatingTicketDelete } = useTicketDelete();
  const { createTicket, createTicketMutation, isUpdatingCreateTicket, setIsUpdatingCreateTicket } = useCreateTicketMutation()
  const { updateAttachmentDelete, isUpdatingAttachmentDelete, setIsUpdatingAttachmentDelete } = useAttachmentDelete();


  return {

    updateTicketListMapTickets,
    isUpdatingTicketListMapTickets,
    setIsUpdatingTicketListMapTickets,
    updateTicketBaseFields,
    isUpdatingTicketBaseFields,
    setIsUpdatingTicketBaseFields,
    updateTicketCustomFields,
    isUpdatingTicketCustomFields,
    setIsUpdatingTicketCustomFields,
    updateTicketNotes,
    isUpdatingTicketNotes,
    setIsUpdatingTicketNotes,
    updateTicketAttachments,
    isUpdatingTicketAttachments,
    setIsUpdatingTicketAttachments,
    updateTicketDelete,
    isUpdatingTicketDelete,
    setIsUpdatingTicketDelete,
    createTicket,
    createTicketMutation,
    isUpdatingCreateTicket,
    setIsUpdatingCreateTicket,
    updateAttachmentDelete,
    isUpdatingAttachmentDelete,
    setIsUpdatingAttachmentDelete
  };
};

export default useTicket;


export const createTicketByIdQueryOptions = (ticketId: number) => queryOptions<any>({
  queryKey: ['ticket-by-id', ticketId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!ticketId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getTicketById({
          ticketId,
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!ticketId,
  refetchOnWindowFocus: false,
});

export const createTicketViewersQueryOptions = (ticketId: number) => queryOptions<any>({
  queryKey: ['ticket-viewers', ticketId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!ticketId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getTicketViewers({
          ticketId,
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!ticketId,
  refetchOnWindowFocus: false,
});

export const createTicketCustomFieldsQueryOptions = (ticketId: number) => queryOptions<any>({
  queryKey: ['custom-fields', ticketId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!ticketId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getTicketCustomFieldsById({
          ticketId,
          archived: true,
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!ticketId,
  refetchOnWindowFocus: false,
});

export const createTicketDuplicateAddressQueryOptions = (address: string, ticketId: number) => queryOptions<any>({
  queryKey: ['duplicate-address-by-id', address, ticketId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!ticketId || !address) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      const encodedAddress = encodeURIComponent(address);
      await ticketService
        .getTicketDuplicateAddress({
          address: encodedAddress,
          ticketId,
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  refetchOnWindowFocus: false,
  enabled: !!ticketId && !!address,
});

export const createStatusesByCustomerQueryOptions = (customerId?: number) => queryOptions<any>({
  queryKey: ['statuses-by-customer', customerId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!customerId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getStatusesByCustomer({
          customerId: customerId,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!customerId,
  refetchOnWindowFocus: false,
});

export const createTicketTypesByCustomerQueryOptions = (customerId?: number) => queryOptions<any>({
  queryKey: ['ticket-types-by-customer', customerId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!customerId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getTicketTypesByCustomer({
          customerId: customerId,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!customerId,
  refetchOnWindowFocus: false,
});

export const createPrioritiesQueryOptions = () => queryOptions<any>({
  queryKey: ['priorities'],
  queryFn: async () => {
    const ticketService = TicketService();
    return new Promise(async (resolve) => {
      await ticketService
        .getTicketPriorities({
          onSuccess: (res: AxiosResponse) => {
            resolve(res.data);
          },
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  refetchOnWindowFocus: false,
});

export const createAgentsByCustomerQueryOptions = (customerId?: number, ticketId?: number) => queryOptions<any>({
  queryKey: ['agents-by-customer', customerId, ticketId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!customerId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getAgentsByCustomer({
          customerId: customerId,
          ticketId: ticketId,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!customerId,
  refetchOnWindowFocus: false,
});

export const createCustomFieldGroupsQueryOptions = (ticketId: number) => queryOptions<any>({
  queryKey: ['custom-field-groups', ticketId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!ticketId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getTicketCustomFieldGroupsById({
          ticketId,
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!ticketId,
  refetchOnWindowFocus: false,
});

export const createCustomersQueryOptions = () => queryOptions<any>({
  queryKey: ['customers'],
  queryFn: async () => {
    const ticketService = TicketService();
    return new Promise(async (resolve) => {
      await ticketService
        .getCustomers({
          onSuccess: (res: AxiosResponse) => {
            resolve(res.data);
          },
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  refetchOnWindowFocus: false,
});

export const createBaseFieldsQueryOptions = () => queryOptions<any>({
  queryKey: ['base-fields'],
  queryFn: async () => {
    const ticketService = TicketService();
    return new Promise(async (resolve) => {
      await ticketService
        .getBaseFields({
          onSuccess: (res: AxiosResponse) => {
            resolve(res.data);
          },
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  refetchOnWindowFocus: false,
});

export const createCustomerFieldsQueryOptions = (customerId?: number) => queryOptions<any>({
  queryKey: ['custom-fields-by-customer', customerId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!customerId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getCustomFieldsByCustomer({
          customerId,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!customerId,
  refetchOnWindowFocus: false,
});

export const createAttachmentsQueryOptions = (ticketId: number) => queryOptions<any>({
  queryKey: ['attachments-by-id', ticketId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!ticketId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getAttachmentsById({
          ticketId,
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!ticketId,
  refetchOnWindowFocus: false,
});

export const createAttachmentTypesByCustomerQueryOptions = (customerId?: number) => queryOptions<any>({
  queryKey: ['attachment-types-by-customer', customerId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!customerId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getAttachmentTypesByCustomer({
          customerId: customerId,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!customerId,
  refetchOnWindowFocus: false,
});

export const createNotesQueryOptions = (ticketId: number) => queryOptions<any>({
  queryKey: ['notes-by-id', ticketId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!ticketId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getNotesById({
          ticketId,
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!ticketId,
  refetchOnWindowFocus: false,
});

export const createTicketHistoryQueryOptions = (ticketId: number) => queryOptions<any>({
  queryKey: ['ticket-history-by-id', ticketId],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!ticketId) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getTicketHistoryById({
          ticketId,
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!ticketId,
  refetchOnWindowFocus: false,
});

export const createAllTicketsQueryOptions = (customerId?: number, category?: string) => queryOptions<any>({
  queryKey: ['all-tickets', customerId, category],
  queryFn: async () => {
    const ticketService = TicketService();
    if (!customerId || !category) return new Promise((resolve) => resolve(null));
    return new Promise(async (resolve) => {
      await ticketService
        .getTickets({
          customerId: customerId,
          customFields: false,
          category: category,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  enabled: !!customerId && !!category,
  refetchOnWindowFocus: false,
});

export const createStatusesQueryOptions = () => queryOptions<any>({
  queryKey: ['statuses'],
  queryFn: async () => {
    const ticketService = TicketService();
    return new Promise(async (resolve) => {
      await ticketService
        .getStatuses({
          onSuccess: (res: AxiosResponse) => {
            resolve(res.data);
          },
        })
        .then((res) => {
          resolve(res?.data);
        });
    });
  },
  staleTime: 1000 * 60 * 5, // 5 minutes
  refetchInterval: 1000 * 60 * 5, // 5 minutes
  refetchOnWindowFocus: false,
});
