// src/queries/templateQueries.ts
import { useQuery, useMutation, useQueryClient, queryOptions } from '@tanstack/react-query';
import NotificationService, { UpdateTemplateParams } from '../notification.service';

export const templateKeys = {
  all: ['templates'] as const,
  list: (customerid: number) => [...templateKeys.all, 'list', customerid] as const,
  customers: () => [...templateKeys.all, 'customers'] as const,
  mergeFields: (customerid: number) => [...templateKeys.all, 'mergeFields', customerid] as const,
};

export const createTemplateListOptions = (customerid: number) => {
  const notificationService = NotificationService();
  return queryOptions({
    queryKey: templateKeys.list(customerid),
    queryFn: async () => {
      if (!customerid) return null;
      const response = await notificationService.getTemplates({ customerid });
      return response;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerid,
    refetchOnWindowFocus: false
  });
};

export const createMergeFieldMapOptions = (customerid: number) => {
  const notificationService = NotificationService();
  return queryOptions({
    queryKey: templateKeys.mergeFields(customerid),
    queryFn: async () => {
      if (!customerid) return null;
      const response = await notificationService.getMergeFieldMap({ customerid });
      console.log('response : ', response);
      return response;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerid,
    refetchOnWindowFocus: false
  });
};

export const useTemplateOperations = () => {
  const notificationService = NotificationService();
  const queryClient = useQueryClient();

  const createTemplateMutation = useMutation({
    mutationFn: (params: {
      templateFile: File,
      customerId: number,
      templateName: string
    }) => notificationService.postNewTemplate({
      template: params.templateFile,
      customerId: params.customerId,
      templateName: params.templateName
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    },
  });

  const updateTemplateMutation = useMutation({
    mutationFn: (params: UpdateTemplateParams) =>
      notificationService.updateTemplate(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    },
  });

  const deleteTemplateMutation = useMutation({
    mutationFn: (templateId: number) =>
      notificationService.deleteTemplate({ templateId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    },
  });

  return {
    createTemplate: createTemplateMutation,
    updateTemplate: updateTemplateMutation,
    deleteTemplate: deleteTemplateMutation,
  };
};
