import React, { createContext, lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from './ThemeContext';
import DayjsProvider from './DayJSProvider';
// import createTheme from '@/constants/theme';
import { createTheme, ThemeProvider as MuiThemeProvider, useColorScheme } from '@mui/material/styles';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import useIndexedDB from '../services/database/useIndexedDB';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { Close } from '@mui/icons-material';
import { custom } from 'zod';
import { customSerializer } from '@/services/database/customSerializer';
import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/queryClient';
import { CssBaseline, useMediaQuery, } from '@mui/material';
import { getThemeByName } from '@/constants/themes/theme';
import { ThemeConfigurator } from '@/constants/themes/ThemeConfigurator.component';
import { createLightTheme } from '@/constants/themes';
import { createDarkTheme } from '@/constants/themes';
import variants from '@/constants/themes/variants.constant';
import { THEMES } from '@/constants/themes/themes.constant';
import components from '@/constants/themes/components.constant';
import typography from '@/constants/themes/typography.constant';
import shadows from '@/constants/themes/shadows.constant';
import breakpoints from '@/constants/themes/breakpoints.constant';

export const ColorModeContext = createContext({ toggleColorMode: () => { } });

const GlobalStateProvider = (props: any) => {
  const IndexedDBAdapter = useIndexedDB();
  const { mode } = useColorScheme();

  let themeConfig = mode === 'light' ? variants.find((theme) => theme.name === THEMES.INDIGO) : variants.find((theme) => theme.name === THEMES.DARK);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = createTheme({
    // Enable CSS variables
    // cssVariables: true,
    spacing: 4,
    breakpoints: breakpoints,
    // @ts-ignore
    components: components,
    typography: typography,
    shadows: shadows,
    palette: themeConfig.palette,
    // Enable both color schemes
    colorSchemes: {
      light: true,
      dark: true,
    },
  }, {
    // Other theme configurations
    components: components,
    typography: typography,
    shadows: shadows,
    breakpoints: breakpoints
  });

  const ReactQueryDevtoolsProduction = lazy(async () =>
    import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
      default: d.ReactQueryDevtools,
    })),
  );

  const asyncStoragePersister = createAsyncStoragePersister({
    storage: IndexedDBAdapter,
    key: 'dropSource',
    throttleTime: 1000,
    serialize: JSON.stringify,
    deserialize: JSON.parse,
    // serialize: customSerializer.serialize,
    // deserialize: customSerializer.deserialize,
  });


  return (
    // <ColorModeContext.Provider value={colorMode}>
    <MuiThemeProvider theme={theme} defaultMode={prefersDarkMode ? 'dark' : 'light'}>
      <CssBaseline />
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: asyncStoragePersister, buster: 'dropSource2' }}>
        <DayjsProvider>
          {props.children}
          <ThemeConfigurator />
          <Suspense fallback={null}>
            {/* <ReactQueryDevtoolsProduction /> */}
          </Suspense>
          <ReactQueryDevtools initialIsOpen={false} />
        </DayjsProvider>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          maxSnack={3}
          hideIconVariant={true}
          action={(key: any) => (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                closeSnackbar(key);
              }}
              id={'notification'}
            >
              <Close />
            </IconButton>
          )}
          autoHideDuration={5000}
        />
      </PersistQueryClientProvider>
    </MuiThemeProvider>
    // </ColorModeContext.Provider>
  );
};

export default GlobalStateProvider;
