import Reports from '@/app/pages/Reports/Reports.page'
import { createUserSettingsOptions } from '@/hooks/fetches/useSettings.service';
import DashboardLayout from '@/layouts/DashboardLayout'
import { createUserQueryOptions } from '@/services/queries/pingOptions';
import { createFileRoute, Outlet } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/reports')({
  component: () => <ReportsLayout />,
  loader: async ({ context }) => {
    const user = await context?.queryClient?.fetchQuery(createUserQueryOptions({ ticketid: 0, customerid: 0, limittocustomer: false, auth: context?.auth }))
    await Promise.all([
      context?.queryClient?.ensureQueryData(createUserSettingsOptions(user?.ContextUser?.AgentId)),
    ])
  },
})

const ReportsLayout = () => {
  return (
    <DashboardLayout title="Reports">
      <Reports />
      <Outlet />
    </DashboardLayout>
  )
}
