import { Control, useController } from 'react-hook-form';
import { Autocomplete, TextField, Chip } from '@mui/material';

export interface EmailOption {
  id: string;
  label: string;
}

// schema.ts
import { z } from 'zod';

export const emailSchema = z.object({
  emails: z.array(
    z.object({
      id: z.string(),
      label: z.string().email('Invalid email format')
    })
  )
});

export type EmailFormData = z.infer<typeof emailSchema>;

interface EmailAutocompleteProps {
  control: Control<any>;
  name: string;
  options: EmailOption[];
  label?: string;
}

export const EmailAutocomplete = ({
  control,
  name,
  options,
  label = 'Emails'
}: EmailAutocompleteProps) => {

  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name,
    control
  });

  const handleChange = (_: any, newValue: (string | EmailOption)[]) => {
    const formattedValues = newValue.map(value =>
      typeof value === 'string'
        ? { id: crypto.randomUUID(), label: value }
        : value
    );
    onChange(formattedValues);
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      value={value}
      options={options}
      onChange={handleChange}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.label;
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.label}
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={error?.message}
          placeholder="Enter email addresses"
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(options, params) => {
        const filtered = options.filter((option) =>
          option.label.toLowerCase().includes(params.inputValue.toLowerCase())
        );

        if (params.inputValue !== '' && filtered.length === 0) {
          filtered.push({
            id: crypto.randomUUID(),
            label: params.inputValue
          });
        }

        return filtered;
      }}
    />
  );
};
