import ConfirmModal from '@/components/Molecules/Modal/ConfirmModal.component';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Container, List, ListItem } from '@mui/material';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { Box, Card, Divider, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import { Delete as DeleteIcon } from '@mui/icons-material';

import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import CheckboxInput from '@/components/Atoms/Checkbox/CheckboxInput.component';
import useTicketTypes from '@/hooks/fetches/TicketTypes/useTicketTypes.service';
import { CustomerEntity } from '@/interfaces/customer.interface';
import { z } from 'zod';
import { TicketTypeSchema } from '@/interfaces/schemas/ticketType.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { TicketType, TicketTypeCustomer } from '@/interfaces/ticketType.interface';
import { useQuery } from '@tanstack/react-query';
import { createCustomerOptionsQuery } from '@/services/queries/customerOptions';

const TicketTypeDrawer = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { variant } = useSearch({ from: '/_auth/type-editor/$typeId' });
  const params = useParams({ from: '/_auth/type-editor/$typeId' });
  const navigate = useNavigate();
  const customers = useQuery(createCustomerOptionsQuery());
  const { ticketTypesByAllCustomers, executeTicketTypeAction } = useTicketTypes(params?.typeId);
  const currentType = ticketTypesByAllCustomers?.data?.find((tt: TicketType) => tt.TypeID === params.typeId);

  const parsedTicketType = currentType ? TicketTypeSchema.parse(currentType) : null;

  const methods = useForm<z.infer<typeof TicketTypeSchema>>({
    mode: 'all',
    defaultValues: parsedTicketType || {
      TypeName: '',
      TypeDescription: '',
      TypeID: 0,
      Customer: [],
    },
    resolver: zodResolver(TicketTypeSchema),
  });

  const { control, setValue, reset } = methods;
  const customerList = useWatch({ control, name: 'Customer' });
  const getIdsOnly = customerList?.map((c: TicketTypeCustomer) => c.CustomerID);

  useEffect(() => {
    setIsOpen(true);
    setIsDeleting(false);
    setIsUpdating(false);
  }, []);

  useEffect(() => {
    if (currentType) {
      reset({
        TypeName: currentType.TypeName,
        TypeDescription: currentType.TypeDescription,
        TypeID: currentType.TypeID,
        Customer: currentType.Customer || [],
      });
    }
  }, [currentType, reset]);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      navigate({ to: '/type-editor' });
    }, 300); // Match this with MUI's transition duration
  };

  const handleSelectAll = () => {
    const allCustomers = customers?.data?.map((c: CustomerEntity) => ({
      CustomerID: c.CustomerEntId,
      CustomerName: c.EntName,
    }));
    setValue('Customer', allCustomers, { shouldDirty: true });
  };

  const handleUnSelectAll = () => {
    setValue('Customer', [], { shouldDirty: true });
  };

  const handleReset = () => {
    reset({
      TypeName: currentType?.TypeName || '',
      TypeDescription: currentType?.TypeDescription === '' ? '' : currentType?.TypeDescription || '',
      Customer: currentType?.Customer,
    });
    handleClose();
  };

  const handleCreate = (formValues: z.infer<typeof TicketTypeSchema>) => {
    executeTicketTypeAction({
      actionType: 'createTicketType',
      payload: {
        Name: formValues.TypeName,
        Description: formValues.TypeDescription,
        TypeId: 0,
        CustomerList: getIdsOnly,
      },
    });
  };

  const handleUpdate = (formValues: z.infer<typeof TicketTypeSchema>) => {
    executeTicketTypeAction({
      actionType: 'updateTicketType',
      payload: {
        Name: formValues.TypeName,
        Description: formValues.TypeDescription,
        TypeId: formValues.TypeID,
        CustomerList: getIdsOnly,
      },
    });
  };

  const handleSubmit = (formValues: z.infer<typeof TicketTypeSchema>) => {
    setIsUpdating(true);
    if (variant === 'edit') {
      handleUpdate(formValues);
    } else if (variant === 'new') {
      handleCreate(formValues);
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
    executeTicketTypeAction({
      actionType: 'deleteTicketType',
      payload: {
        TypeID: parsedTicketType?.TypeID,
      },
    });
  };

  const customerCheckBoxList = customers?.data?.map((customer: CustomerEntity) => {
    const isChecked = customerList?.some((c: TicketTypeCustomer) => c.CustomerID === customer.CustomerEntId);
    return (
      <ListItem sx={{ p: 0, m: 0 }} key={customer.CustomerEntId}>
        <CheckboxInput
          sxProps={{ p: 0, m: 1 }}
          label={customer.EntName}
          checked={isChecked}
          id={customer.CustomerEntId.toString()}
          onDataChange={(id: string) => {
            const parsedId = parseInt(id, 10);
            let newCustomerList: TicketTypeCustomer[];
            if (customerList?.some((c: TicketTypeCustomer) => c.CustomerID === parsedId)) {
              newCustomerList = customerList.filter((c: TicketTypeCustomer) => c.CustomerID !== parsedId);
            } else {
              const customerToAdd = { CustomerID: parsedId, CustomerName: customer.EntName };
              newCustomerList = [...customerList, customerToAdd];
            }
            setValue('Customer', newCustomerList, { shouldDirty: true });
          }}
        />
      </ListItem>
    );
  });

  return (
    <DrawerLayout
      open={isOpen}
      onClose={handleClose}
      anchor="right"
      keepMounted
      Header={
        <Box sx={{ display: 'flex' }}>
          <DrawerHeader close={handleClose} title={variant === 'edit' ? 'Edit Type' : 'New Type'} />
        </Box>
      }>
      <FormProvider {...methods}>
        <Divider sx={{ mt: 3 }} />
        {ticketTypesByAllCustomers?.isFetching || customers?.isFetching ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 9999,
            }}>
            <CircularProgress />
          </Box>
        ) : (
          <Container
            maxWidth="md"
            sx={{
              width: '75%',
              overflowY: 'auto',
              mt: 5,
              height: '98%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            component="form"
            onSubmit={methods.handleSubmit(handleSubmit)}>
            <Box>
              <Box
                sx={{
                  width: '100%',
                  mx: 'auto',
                  mt: 8,
                  mb: 4,
                }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                  <TextBox id="name" name="TypeName" label="Name" fullWidth required sx={{ flex: 1 }} />
                  <TextBox
                    id="description"
                    name="TypeDescription"
                    multiline
                    label="Description"
                    fullWidth
                    sx={{ flex: 1 }}
                  />
                </Box>
              </Box>
              <Card sx={{ p: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h6">Customers</Typography>
                  {!ticketTypesByAllCustomers?.isFetching && !customers?.isFetching && (
                    <Box>
                      <Button size="small" onClick={handleSelectAll} variant="contained">
                        Select All
                      </Button>
                      <Button size="small" sx={{ ml: 2 }} onClick={handleUnSelectAll} variant="outlined">
                        Un-Select All
                      </Button>
                    </Box>
                  )}
                </Box>
                <List>{customerCheckBoxList}</List>
              </Card>
            </Box>
            <Box>
              <Divider />
              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  p: 5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '1rem',
                }}>
                <LoadingButton
                  loading={isDeleting}
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => setOpenModal(true)}>
                  Delete
                </LoadingButton>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button variant="outlined" onClick={handleReset}>
                    Cancel & Close
                  </Button>
                  {openModal && (
                    <ConfirmModal
                      open={openModal}
                      setOpen={setOpenModal}
                      title="Are you sure?"
                      content="This action will permanently delete this ticket type."
                      onSuccess={handleDelete}
                      onClose={() => setOpenModal(false)}
                      cancelButtonText="Cancel"
                      confirmButtonText="Delete"
                    />
                  )}
                  <LoadingButton loading={isUpdating} variant="contained" type="submit">
                    Save
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Container>
        )}
      </FormProvider>
    </DrawerLayout>
  );
};

export default TicketTypeDrawer;
