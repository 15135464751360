import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { MRT_ColumnFiltersState, MRT_DensityState, MRT_PaginationState, MRT_SortingState, MRT_TableState, MRT_VisibilityState } from 'material-react-table';
import { queryClient } from '@/lib/queryClient';
import { createUserQueryOptions } from '@/services/queries/pingOptions';
import { createUserSettingsOptions } from '@/hooks/fetches/useSettings.service';

interface ICustomFilters {
  customer: {
    id: number,
    label: string
  } | undefined,
  category: {
    id: number,
    label: 'Active' | 'Closed' | 'All' | 'Assigned' | undefined
  } | undefined,
}

// Define the ITicketTableStore interface
interface ITicketTableStore {
  showDrawer: boolean;
  pagination: MRT_PaginationState;
  sorting: MRT_SortingState;
  columnFilters: MRT_ColumnFiltersState;
  columnVisibility: MRT_VisibilityState;
  density: MRT_DensityState;
  globalFilter: string | undefined;
  showColumnFilters: boolean;
  customFilters: ICustomFilters
  ticketIds: number[]
  isFilterVisible: boolean
  columnOrder: string[]
}

// Define the Zustand store interface
interface TicketTableStore {
  ticketTableState: ITicketTableStore;
  updateTicketTable: (newState: Partial<ITicketTableStore>) => void;
  setPagination: (updater: MRT_PaginationState | ((prev: MRT_PaginationState) => MRT_PaginationState)) => void;
  setSorting: (updater: MRT_SortingState | ((prev: MRT_SortingState) => MRT_SortingState)) => void;
  setColumnFilters: (updater: MRT_ColumnFiltersState | ((prev: MRT_ColumnFiltersState) => MRT_ColumnFiltersState)) => void;
  setColumnVisibility: (updater: MRT_VisibilityState | ((prev: MRT_VisibilityState) => MRT_VisibilityState)) => void;
  setDensity: (updater: MRT_DensityState | ((prev: MRT_DensityState) => MRT_DensityState)) => void;
  setGlobalFilter: (updater: string | undefined | ((prev: string | undefined) => string | undefined)) => void;
  setShowColumnFilters: (updater: boolean | ((prev: boolean) => boolean)) => void;
  setCustomFilters: (updater: ICustomFilters | ((prev: ICustomFilters) => ICustomFilters)) => void;
  setTicketIds: (updater: number[] | ((prev: number[]) => number[])) => void;
  setIsFilterVisible: (updater: boolean | ((prev: boolean) => boolean)) => void
  setColumnOrder: (updater: string[] | ((prev: string[]) => string[])) => void;
  reset: () => void
}

const defaultColumnOrder = [
  "TId",
  "Status",
  "Type",
  "conditionalColumn",
  "Priority",
  "Title",
  "Assignee",
  "CustomerName",
  "RaisedBy",
  "DueDate",
  "Created",
  "ClientName",
  "ClientEmail",
  "ClientPhone",
  "ClientAddress",
]


export const getTableColumnSortingState = async (): Promise<any> => {

  try {
    const agentByContext = await queryClient.fetchQuery(createUserQueryOptions({ ticketid: 0, customerid: 0, limittocustomer: false }));

    const getSettings = await queryClient.fetchQuery(createUserSettingsOptions(agentByContext?.ContextUser?.AgentId));

    const columnSorting = getSettings?.data?.Data?.find(
      (entry: any) => entry.Identifier === 'ticketTableColumnSorting'
    )?.ColumnOrder;

    return columnSorting || defaultColumnOrder
  }
  catch (error) {
    console.log(error)
    return defaultColumnOrder
  }
}

const initialTableState: ITicketTableStore = {
  showDrawer: false,
  // Updated page size to 25.
  pagination: { pageIndex: 0, pageSize: 25 },
  sorting: [{ id: 'TId', desc: true }],
  columnFilters: [],
  columnVisibility: { conditionalColumn: false },
  density: 'compact',
  globalFilter: undefined,
  showColumnFilters: false,
  customFilters: { customer: undefined, category: undefined },
  ticketIds: [],
  isFilterVisible: true,
  columnOrder: defaultColumnOrder
}

// Create the Zustand store with persistence
const useTicketTableStore = create<TicketTableStore>()(
  persist(
    (set) => ({
      ticketTableState: initialTableState,
      updateTicketTable: (newState: Partial<ITicketTableStore>) =>
        set((state) => ({
          ticketTableState: {
            ...state.ticketTableState,
            ...newState,
          },
        })),
      setPagination: (updater) =>
        set((state) => ({
          ticketTableState: {
            ...state.ticketTableState,
            pagination: typeof updater === 'function' ? updater(state.ticketTableState.pagination) : updater,
          }
        })),


      setSorting: (updater) =>
        set((state) => ({
          ticketTableState: {
            ...state.ticketTableState,
            sorting: typeof updater === 'function' ? updater(state.ticketTableState.sorting) : updater,
          }
        })),

      setColumnFilters: (updater) =>
        set((state) => {
          const newFilters = typeof updater === 'function'
            ? updater(state.ticketTableState.columnFilters)
            : updater;

          // Ensure unique IDs by keeping only the latest filter for each ID
          const uniqueFilters = newFilters.reduce((acc, current) => {
            const index = acc.findIndex(filter => filter.id === current.id);
            if (index >= 0) {
              acc[index] = current; // Replace existing filter
            } else {
              acc.push(current); // Add new filter
            }
            return acc;
          }, [] as typeof newFilters);

          return {
            ticketTableState: {
              ...state.ticketTableState,
              columnFilters: uniqueFilters,
            }
          };
        }),

      setColumnVisibility: (updater) =>
        set((state) => ({
          ticketTableState: {
            ...state.ticketTableState,
            columnVisibility: typeof updater === 'function' ? updater(state.ticketTableState.columnVisibility) : updater,
          }
        })),

      setDensity: (updater) =>
        set((state) => ({
          ticketTableState: {
            ...state.ticketTableState,
            density: typeof updater === 'function' ? updater(state.ticketTableState.density) : updater,
          }
        })),
      setGlobalFilter: (updater) =>
        set((state) => ({
          ticketTableState: {
            ...state.ticketTableState,
            globalFilter: typeof updater === 'function' ? updater(state.ticketTableState.globalFilter) : updater,
          }
        })),
      setShowColumnFilters: (updater) =>
        set((state) => ({
          ticketTableState: {
            ...state.ticketTableState,
            showColumnFilters: typeof updater === 'function' ? updater(state.ticketTableState.showColumnFilters) : updater,
          }
        })),
      setCustomFilters: (updater) =>
        set((state) => ({
          ticketTableState: {
            ...state.ticketTableState,
            customFilters: typeof updater === 'function' ? updater(state.ticketTableState.customFilters) : updater,
          }
        })),
      setTicketIds: (updater) =>
        set((state) => ({
          ticketTableState: {
            ...state.ticketTableState,
            ticketIds: typeof updater === 'function' ? updater(state.ticketTableState.ticketIds) : updater,
          }
        })),
      setIsFilterVisible: (updater) =>
        set((state) => ({
          ticketTableState: {
            ...state.ticketTableState,
            isFilterVisible: typeof updater === 'function' ? updater(state.ticketTableState.isFilterVisible) : updater,
          }
        })),
      setColumnOrder: (updater) =>
        set((state) => ({
          ticketTableState: {
            ...state.ticketTableState,
            columnOrder: typeof updater === 'function' ? updater(state.ticketTableState.columnOrder) : updater,
          }
        })),
      reset: () => set({ ticketTableState: initialTableState }),
    }),
    {
      name: 'ticket-table-storage',
      partialize: (state) => ({
        ticketTableState: {
          pagination: state.ticketTableState.pagination,
          sorting: state.ticketTableState.sorting,
          columnFilters: state.ticketTableState.columnFilters,
          columnVisibility: state.ticketTableState.columnVisibility,
          density: state.ticketTableState.density,
          globalFilter: state.ticketTableState.globalFilter,
          showColumnFilters: state.ticketTableState.showColumnFilters,
          customFilters: state.ticketTableState.customFilters,
          ticketIds: state.ticketTableState.ticketIds,
          columnOrder: state.ticketTableState.columnOrder,
          isFilterVisible: state.ticketTableState.isFilterVisible
        },
      }),
    }
  )
);

export default useTicketTableStore;