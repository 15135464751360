import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useTicket from '@/hooks/fetches/useTicket.service';
import { Autocomplete, CardContent, CircularProgress, InputLabel, ListSubheader, MenuItem, Select, Theme, Typography, useMediaQuery } from '@mui/material';
import Grid from "@mui/material/Grid2"
import { Controller, FormProvider, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form';
import { AutoComplete, Box, Button, Card, IconButton, Paper, Stack, TextField, } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import useTicketList from '@/contexts/stores/ticketList.store';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import useReportTableStore from './useReportTableStore';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import { createStatusesByCustomerOptions, createTicketTypesByCustomerOptions } from '@/services/queries/statusTypeOptions';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { custom } from 'zod';
import { queryClient } from '@/lib/queryClient';
import { createUserQueryOptions } from '@/services/queries/pingOptions';
import useSettings, { createUserSettingsOptions } from '@/hooks/fetches/useSettings.service';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useReportTable from './useReportTable';
// import { defaultValues, ReportFilterSchema } from '@/interfaces/schemas/report.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from '@/lib/auth';
import { getReportByFilter } from '@/hooks/fetches/useReports.service';
// import { Filter } from 'lucide-react';
import { reportTableColumns } from '@/components/Organisms/ReportTable/reportTableColumns';
import { Condition, Filter, ReportFilter, initialReportFilter, IType, NewCondition, Customer, ReportFilterSchema } from './interface';
import CheckboxElement from '@/components/Molecules/Checkbox/CheckboxElement.component';
import { CFTypeIdDetail } from '../TicketDetail/CustomFields.page';
import { casesArray, IcfTDateCasesArray, IcfTDropdownCasesArray, IcfTIntegerCasesArray, IcfTStringCasesArray, IcfTToggleCasesArray, turnEqualityValueToNumber } from '@/constants/filter/filter.constant';
import { Plus, X } from 'lucide-react'
import { createCustomerFieldsQueryOptions, createCustomersQueryOptions, createTicketCustomFieldsQueryOptions } from '@/hooks/fetches/useTicket.service';
import { createBaseFieldsQueryOptions } from '@/hooks/fetches/useTicket.service';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
interface CreateTicketFormSchema {
  customer: { id: number; label: string } | null;
  priority: { id: number; label: string } | null;
  type: { id: number; label: string } | null;
  status: { id: number; label: string, color: number } | null;
  description: string
}

const ReportFiltersDrawer = () => {

  const navigate = useNavigate();
  // const { drawer } = useSearch({ from: '/_auth/reports' });

  // console.log('drawer', drawer)

  const methods = useForm<ReportFilter>({
    mode: 'all', // Enables all validation modes
    defaultValues: initialReportFilter, // Use the default values
    resolver: zodResolver(ReportFilterSchema),
    shouldUnregister: false
  });
  // console.log('methods', methods.getValues())
  // const methods = useForm<ReportFilterSchema>({
  //   mode: 'all',
  //   defaultValues,
  // });
  const { watch, control, setValue, reset } = methods

  const customerWatch = useWatch({ control, name: 'report_filter_customer_filter' })

  const baseFields = useQuery(createBaseFieldsQueryOptions())
  const customFieldsByCustomer = useQuery(createCustomerFieldsQueryOptions(customerWatch?.id))
  const customers = useQuery(createCustomersQueryOptions())
  const ticketTypes = useQuery(createTicketTypesByCustomerOptions({ customerId: customerWatch?.id }))

  // console.log('customFieldsByCustomer', customFieldsByCustomer?.data)

  // const { ticketListState, updateTicketList, } = useTicketList();
  const { reportTableState, setIsReportsFiltered, setReportFilter, setCustomFilters } = useReportTableStore()
  const { reportTable } = useReportTable();

  const [isOpen, setIsOpen] = useState(false)
  const [reportFilters, setReportFilters] = useState<any[]>([]);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { variant, step } = useSearch({ from: '/_auth/reports/$reportFilter' })
  const { reportFilter } = useParams({ from: '/_auth/reports/$reportFilter' });
  // const { user } = useAuth()
  const user = useQuery(createUserQueryOptions({ ticketid: 0, customerid: 0, limittocustomer: false }))
  const getSettings = useSuspenseQuery(createUserSettingsOptions(user?.data?.ContextUser?.AgentId))
  const { updateSettings } = useSettings(user?.data?.ContextUser?.AgentId);
  const oldCustomerValueRef = useRef<any>(null);

  // console.log('variant', variant)

  const { fields: allConditionsFields, append: allConditionsAppend, remove: allConditionsRemove, } = useFieldArray({
    control: control,
    name: 'all_conditions',
    shouldUnregister: false
  });

  // console.log('allConditionsFields:', allConditionsFields)

  const handleAddAllCondition = () => {
    allConditionsAppend({
      type: '',
      value: [''],
      column: '',
      userId: null,
      fieldId: 0,
      isEqual: '',
      statusValue: null,
    })
  };
  // const ticketTypesByCustomer = useQuery(createTicketTypesByCustomerOptions({
  //   customerId: customerWatch?.id
  // }));

  // const statusesByCustomer = useQuery(createStatusesByCustomerOptions({
  //   customerId: customerWatch?.id
  // }))

  // const getSettings = queryClient.ensureQueryData(createUserSettingsOptions(agentByContext?.ContextUser?.AgentId));
  // const agentByContext = useSuspenseQuery(createUserQueryOptions({ ticketid: 0, customerid: 0, limittocustomer: false, auth:  }));

  // console.log('getSettings', getSettings?.data?.data?.Data)

  // const getReportFilters = async (): Promise<any> => {

  //   try {


  //     const reportFilters = getSettings?.data?.Data?.filter((entry: any) => entry.id)

  //     return reportFilters || []
  //   }
  //   catch (error) {
  //     console.log(error)
  //     return []
  //   }
  // }

  useEffect(() => {
    // const fetchFilters = async () => {
    // const filters = await getReportFilters();
    // setReportFilters(filters);
    // };

    // fetchFilters();

    setIsOpen(true)
  }, [])

  const handleClose = () => {
    // Wait for animation to complete before navigating
    setTimeout(() => {
      navigate({ to: '/reports', search: { drawer: undefined } });
    }, 300) // Match this with MUI's transition duration
  }

  const handleCloseDrawer = () => {
    handleClose()
    navigate({ to: '/reports', search: { drawer: undefined } });
  };

  const toCamelCase = (str: string) =>
    str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      )
      .replace(/\s+/g, '');

  const handleSubmit = async (formValues: any) => {
    // console.log('hello world submit')
    console.log('formValues', formValues);
    // console.log('getSettings?.data?.data?.Data', getSettings?.data?.data?.Data)
    // console.log('reportFilter', reportFilter)
    const preparedValue = (condition: Condition) => {
      switch (condition.conditionObj.TypeId) {
        case 19:
          if (condition.conditionObj.Meta.Cid === 9000) {
            const matchingObject = ticketTypes?.data.find((_: any, index: number) => index === condition.value);
            return matchingObject?.Name
          } else {
            return condition.conditionObj.Type.OptLabels.find((_: any, index: any) => {
              return index === condition.value
            })
          }
        case 20:
          return `${condition.value}`
        case 22:
          if (condition.isEqual === 'Is Between') {
            const dateArray = condition.value.map((value: any) => {
              return dayjs(value).format('YYYY-MM-DD')
            })
            return dateArray
          }
          return dayjs(condition.value).format('YYYY-MM-DD')
        default:
          return condition.value
      }
    }

    const existingFilter = getSettings?.data?.Data.find((filter: any) => filter.id === reportFilter)

    if (existingFilter) {
      const updatedConditions = [
        {
          type: 'customer',
          value: [formValues.report_filter_customer_filter.label],
          column: 'Select a Customer',
          userId: null,
          fieldId: formValues.report_filter_customer_filter.id,
          isEqual: 'isEqual', // Default for customer
          statusValue: null,
        },
        ...formValues.all_conditions.map((condition: Condition) => {

          const newValue = preparedValue(condition)
          console.log('newValue', newValue)
          return (
            {
              type: condition.type,
              value: Array.isArray(newValue) ? newValue : [newValue],
              column: condition.column,
              userId: condition.userId,
              fieldId: condition.conditionObj.Meta.Cid,
              isEqual: toCamelCase(condition.isEqual),
              statusValue: condition.statusValue,
            }
          )
        }),
      ];

      const updatedFilter = {
        ...existingFilter,
        name: formValues.report_filter_name,
        condition: updatedConditions,
      };

      const updatedFilters = getSettings?.data?.Data.map((filter: any) => {
        if (filter.id === reportFilter) {
          return updatedFilter;
        }
        return filter;
      });

      const updatedSettings = {
        ...getSettings?.data,
        Data: updatedFilters,
      };

      console.log('updatedSettings:', updatedSettings);

      try {
        await updateSettings({
          actionType: "updateReportFilters",
          data: updatedSettings,
        });
      } catch (error) {
        console.error("Report Filter save failed", error);
      }

      // const response = updateUserSettingsMutation.mutate({
      //   actionType: 'updateUserSettings',
      //   payload: {
      //     Data: updatedSettings,
      //   },
      // });

      // if (response) {
      //   enqueueSnackbar('Report Filter Updated', { variant: 'success' });
      //   handleCloseDrawer()
      // }
    } else {
      const newFilter = {
        id: crypto.randomUUID(),
        name: formValues.report_filter_name,
        toggleOn: true,
        condition: [
          {
            type: 'customer',
            value: [formValues.report_filter_customer_filter.label],
            column: 'Select a Customer',
            userId: null,
            fieldId: formValues.report_filter_customer_filter.id,
            isEqual: 'isEqual', // Default for customer
            statusValue: null,
          },
          ...formValues.all_conditions.map((condition: Condition) => {

            const newValue = preparedValue(condition)

            return (
              {
                type: condition.type,
                value: Array.isArray(newValue) ? newValue : [newValue],
                column: condition.column,
                userId: condition.userId,
                fieldId: condition.conditionObj.Meta.Cid,
                isEqual: toCamelCase(condition.isEqual),
                statusValue: condition.statusValue,
              }
            )
          }),
        ],
      };

      const updatedFilters = [...getSettings?.data?.Data, newFilter];

      const updatedSettings = {
        ...getSettings?.data,
        Data: updatedFilters,
      };

      console.log('updatedSettings with new filter:', updatedSettings);

      try {
        await updateSettings({
          actionType: "updateReportFilters",
          data: updatedSettings,
        });
      } catch (error) {
        console.error("New Report Filter save failed", error);
      }
    }

    reset({
      report_filter_customer_filter: null,
      report_filter_name: '',
      all_conditions: []
    });
  }

  const handleApplyFilter = async (filter: any) => {
    console.log('handleApplyFilter', filter)

    const customer = filter?.condition.find((filter: any) => filter.type === 'customer')

    setCustomFilters({ customer: { id: customer.fieldId, label: customer.value[0] }, category: { id: 3, label: 'All' } });
    setReportFilter(filter);
    setIsReportsFiltered(true);
    reportTable.setColumnFilters(() => [
      {
        id: 'CustomerName',
        value: customer.value[0] ? customer.value[0] : '',
      },
    ]);
    handleClose()
  }

  const getColor = (colorValue: number) => {
    const color = `#${(colorValue >>> 8).toString(16).padStart(6, '0')}`;
    return color;
  };

  const getBrightness = (color: any) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const getTextColor = (backgroundColor: any) => {
    return getBrightness(backgroundColor) > 186 ? 'black' : 'white';
  };

  // const handleConditionOnChange = (item: any, index: number, selectedValue: string) => {
  // let conditionObject;

  // const baseField = baseFields?.data.find((item: any) => item.Meta.Name === selectedValue)
  // if (baseField) {
  //   conditionObject = baseField;
  //   setValue(`any_conditions.${index}.type`, "baseField");

  // } else {
  //   const customField = customFieldsByCustomer?.data.find((item: any) => item.Meta.Name === selectedValue);
  //   if (customField) {
  //     conditionObject = customField;
  //     setValue(`any_conditions.${index}.type`, "customField");
  //   }
  // }
  // // console.log(conditionObject)
  // setValue(`any_conditions.${index}.conditionObj`, conditionObject);
  // //! sanitize
  // setValue(`any_conditions.${index}.value`, "");

  //   const conditionObject = baseFields?.data.find((item: any) => item.Meta.Name === selectedValue)
  //     || customFieldsByCustomer?.data.find((item: any) => item.Meta.Name === selectedValue);

  //   if (conditionObject) {
  //     setValue(`any_conditions.${index}`, {
  //       ...watch(`any_conditions.${index}`),
  //       condition: selectedValue,
  //       type: baseFields?.data.includes(conditionObject) ? 'baseField' : 'customField',
  //       conditionObj: conditionObject,
  //       value: "",
  //     }, { shouldValidate: true });
  //   }
  // };

  const handleConditionOnChange = useCallback((item: any, index: number, selectedValue: any, type: IType) => {
    const conditionObject = baseFields?.data.find((item: any) => item.Meta.Name === selectedValue)
      || customFieldsByCustomer?.data.find((item: any) => item.Meta.Name === selectedValue);

    if (conditionObject) {
      const updates = {
        [`all_conditions.${index}.column`]: selectedValue,
        [`all_conditions.${index}.type`]: baseFields?.data.includes(conditionObject) ? "baseField" : "customField",
        [`all_conditions.${index}.conditionObj`]: conditionObject,
        [`all_conditions.${index}.value`]: conditionObject.TypeId === 22 ? null : ""
      };

      Object.entries(updates).forEach(([key, value]) => {
        setValue(key, value, { shouldValidate: true });
      });
    }
  }, [baseFields?.data, customFieldsByCustomer?.data, setValue]);

  const handleEqualityOnChange = (item: any, index: number, selectedValue: any, type: IType) => {
    // console.log('selectedValue', selectedValue)
    if (selectedValue === "Is Set" || selectedValue === "Is Not Set" || selectedValue === "Today") {
      setValue(`all_conditions.${index}.value`, [""]);
    }
    setValue(`all_conditions.${index}.isEqual`, selectedValue);
  }

  const handleDeleteFilter = async (filter: Filter) => {
    const deleteConfirm = confirm(`Are you sure you want to delete "${filter.name}" filter?`)
    if (deleteConfirm) {

      const updatedFilters = getSettings?.data?.Data.filter(
        (existingFilter: Filter) => existingFilter.id !== filter.id
      );

      const updatedSettings = {
        ...getSettings?.data,
        Data: updatedFilters,
      };

      try {
        await updateSettings({
          actionType: "deleteReportFilter",
          data: updatedSettings,
        });
      } catch (error) {
        console.error("Report Filter delete failed", error);
      }

    }
  }

  const ValueController = React.memo(
    ({ name, condition, index, children, rules, type }: any) => (
      <Controller
        name={name}
        control={control}
        rules={{ required: 'Value is required', ...rules }}
        render={({ field }) => children(field, index)}
      />
    )
  );

  const renderDropdownType = (condition: any, index: number, type: IType) => (
    <>
      <ValueController
        name={`all_conditions.${index}.value`}
        type={type}
        condition={condition}
        index={index}
      >
        {(field: any, index: number) => (
          <>
            <InputLabel id="condition">Value</InputLabel>
            <Select
              size="small"
              sx={{ width: '100%' }}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              {...field}
            >
              {condition?.Type?.OptLabels?.map((option: any, index: number) => {
                return (
                  <MenuItem key={index} value={index}>{option}</MenuItem>
                )
              })
              }
            </Select>
          </>
        )}
      </ValueController>
    </>
  );

  const renderDateType = (
    condition: any,
    index: number,
    type: IType,
    isBetween: boolean
  ) => {
    return (
      <>
        {isBetween ? (
          <>
            <ValueController
              name={`all_conditions.${index}.value.0`}
              type={type}
              condition={condition}
              index={index}
            >
              {(field: any) => (
                <>
                  <InputLabel id="value">Value</InputLabel>
                  {condition?.Type?.IncludeTime === true ? (
                    <DateTimePicker
                      {...field}
                      slotProps={{
                        textField: {
                          size: 'small',
                        },
                      }}
                    />
                  ) : (
                    <DatePicker
                      {...field}
                      slotProps={{
                        textField: {
                          size: 'small',
                        },
                      }}
                    />
                  )}
                </>
              )}
            </ValueController>
            <ValueController
              name={`all_conditions.${index}.value.1`}
              type={type}
              condition={condition}
              index={index}
            >
              {(field: any) => (
                <>
                  <InputLabel id="value">Value</InputLabel>
                  {condition?.Type?.IncludeTime === true ? (
                    <DateTimePicker
                      {...field}
                      slotProps={{
                        textField: {
                          size: 'small',
                        },
                      }}
                    />
                  ) : (
                    <DatePicker
                      {...field}
                      slotProps={{
                        textField: {
                          size: 'small',
                        },
                      }}
                    />
                  )}
                </>
              )}
            </ValueController>
          </>
        ) : (
          <ValueController
            name={`all_conditions.${index}.value.0`}
            type={type}
            condition={condition}
            index={index}
          >
            {(field: any) => (
              <>
                <InputLabel id="value">Value</InputLabel>
                {condition?.Type?.IncludeTime === true ? (
                  <DateTimePicker
                    {...field}
                    slotProps={{
                      textField: {
                        size: 'small',
                      },
                    }}
                  />
                ) : (
                  <DatePicker
                    {...field}
                    slotProps={{
                      textField: {
                        size: 'small',
                      },
                    }}
                  />
                )}
              </>
            )}
          </ValueController>
        )}
      </>
    )
  };

  const renderStringType = (
    condition: any,
    index: number,
    type: IType,
    isBetween: boolean
  ) => {
    return (
      <>
        {isBetween ? (
          <>
            {/* First text field */}
            <ValueController
              name={`all_conditions.${index}.value.0`}
              type={type}
              condition={condition}
              index={index}
            >
              {(field: any) => (
                <>
                  <InputLabel id="value">Value</InputLabel>
                  <TextField
                    placeholder="Type a Value"
                    size="small"
                    sx={{ width: '100%' }}
                    {...field}
                  />
                </>
              )}
            </ValueController>

            {/* Second text field */}
            <ValueController
              name={`all_conditions.${index}.value.1`}
              type={type}
              condition={condition}
              index={index}
            >
              {(field: any) => (
                <>
                  <InputLabel id="value">Value</InputLabel>
                  <TextField
                    placeholder="Type a Value"
                    size="small"
                    sx={{ width: '100%' }}
                    {...field}
                  />
                </>
              )}
            </ValueController>
          </>
        ) : (
          <ValueController
            name={`all_conditions.${index}.value.0`}
            type={type}
            condition={condition}
            index={index}
          >
            {(field: any) => (
              <>
                <InputLabel id="value">Value</InputLabel>
                <TextField
                  placeholder="Type a Value"
                  size="small"
                  sx={{ width: '100%' }}
                  {...field}
                />
              </>
            )}
          </ValueController>
        )}
      </>
    )
  };

  const renderIntegerType = (condition: any, index: number, type: IType, isBetween: boolean) => {
    return (
      <>
        {isBetween ? (
          <>
            <ValueController
              name={`all_conditions.${index}.value.0`}
              type={type}
              condition={condition}
              index={index}
            >
              {(field: any, index: number) => (
                <>
                  <InputLabel id="value">Value</InputLabel>
                  <TextField
                    type="number"
                    placeholder="Type a Value"
                    size="small"
                    sx={{ width: '100%' }}
                    inputProps={{
                      min: condition?.Type.Minimum,
                      max: condition?.Type.Maximum,
                      step: condition?.Type.Step
                    }}
                    {...field}
                  />
                </>
              )}
            </ValueController>
            <ValueController
              name={`all_conditions.${index}.value.1`}
              type={type}
              condition={condition}
              index={index}
            >
              {(field: any, index: number) => (
                <>
                  <InputLabel id="value">Value</InputLabel>
                  <TextField
                    type="number"
                    placeholder="Type a Value"
                    size="small"
                    sx={{ width: '100%' }}
                    inputProps={{
                      min: condition?.Type.Minimum,
                      max: condition?.Type.Maximum,
                      step: condition?.Type.Step
                    }}
                    {...field}
                  />
                </>
              )}
            </ValueController>
          </>
        ) : (
          <ValueController
            name={`all_conditions.${index}.value.0`}
            type={type}
            condition={condition}
            index={index}
          >
            {(field: any, index: number) => (
              <>
                <InputLabel id="value">Value</InputLabel>
                <TextField
                  type="number"
                  placeholder="Type a Value"
                  size="small"
                  sx={{ width: '100%' }}
                  inputProps={{
                    min: condition?.Type.Minimum,
                    max: condition?.Type.Maximum,
                    step: condition?.Type.Step
                  }}
                  {...field}
                />
              </>
            )}
          </ValueController>
        )}
      </>
    );
  };

  const renderToggleType = (condition: any, index: number, type: IType) => (
    <>
      <InputLabel id="value">Value</InputLabel>
      <CheckboxElement
        name={`all_conditions.${index}.value`}
        control={control}
        size="small"
        // label="Value"
        required
        // fullWidth
        onChange={(value: any) => {
          console.log(value)
          setValue(`all_conditions.${index}.value`, value.target.checked)
        }}
      />
    </>
  );

  const renderEqualityOptions = (condition: any, index: number) => {
    // console.log('equality options:', condition)
    if (condition && condition?.TypeId && CFTypeIdDetail(condition?.TypeId).ClassBinding) {
      switch (CFTypeIdDetail(condition.TypeId).ClassBinding) {
        case 'cfTDropdown': return IcfTDropdownCasesArray;
        case 'cfTDate': return IcfTDateCasesArray;
        case 'cfTString': return IcfTStringCasesArray;
        case 'cfTInteger': return IcfTIntegerCasesArray;
        case 'cfTToggle': return IcfTToggleCasesArray;
        default: return []
      }
    }
  };

  const renderDynamicField = React.useCallback((selectedObject: any, index: number, type: IType) => {
    if (!selectedObject?.TypeId) return null;

    const isBetween = watch(`all_conditions.${index}.isEqual`) === 'Is Between';
    if (selectedObject?.Meta?.Cid === 9000) {
      selectedObject = {
        ...selectedObject,
        Type: {
          ...selectedObject.Type,
          OptLabels: ticketTypes?.data?.map((item: any) => item.Name),
        },
      }
    }

    const binding = CFTypeIdDetail(selectedObject.TypeId).ClassBinding;
    const fieldMap = {
      'cfTDropdown': () => renderDropdownType(selectedObject, index, type),
      'cfTDate': () => renderDateType(selectedObject, index, type, isBetween),
      'cfTString': () => renderStringType(selectedObject, index, type, isBetween),
      'cfTInteger': () => renderIntegerType(selectedObject, index, type, isBetween),
      'cfTToggle': () => renderToggleType(selectedObject, index, type)
    };

    return fieldMap[binding as keyof typeof fieldMap]?.() ?? <div>default</div>;
  }, []);

  const ReportFilterHome = () => {
    // console.log('getSettings?.data', getSettings?.data)
    return (
      <Grid
        size={{ xs: 12, md: 12 }}
        display="flex"
        flexDirection="column"
        rowGap={4}
        paddingX={2}
        paddingY={1}
        sx={{ mt: 4 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            id="sendit"
            // type="submit"
            variant="contained"
            onClick={(event: any) => {
              event.stopPropagation();
              {
                // !isFetching &&
                navigate({
                  to: '/reports/$reportFilter',
                  params: { reportFilter: '0' },
                  search: {
                    variant: 'new',
                    step: 1,
                  },
                });
              }
            }}
          >
            Create New Filter
          </Button>
        </Box>
        <Grid>
          <Typography variant="h5" mb={4}>Select Existing Filters...</Typography>
          {getSettings?.data?.Data
            ?.filter((filter: any) => filter.id)
            ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
            ?.map((filter: any, index: number) => (
              <Card sx={{ padding: 4, marginBottom: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={index}>
                <Grid display='flex' flexDirection='row' alignItems='center'>
                  <IconButton
                    id="edit"
                    size="small"
                    color='error'
                    sx={{ mr: 2 }}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      handleDeleteFilter(filter)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    id="edit"
                    size="small"
                    color='primary'
                    sx={{ mr: 2 }}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      {
                        // !isFetching &&
                        navigate({
                          to: '/reports/$reportFilter',
                          params: { reportFilter: filter.id },
                          search: {
                            variant: 'edit',
                            step: 1,
                          },
                        });
                      }
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <Typography variant="h5" key={index}>{filter.name}</Typography>
                </Grid>
                <Button id='sendit' onClick={() => handleApplyFilter(filter)} variant='contained'>Apply Filter</Button>
              </Card>
            ))}
        </Grid>
      </Grid>
    )
  }

  const ConditionRow = React.memo(({ key, item, index, type }: { key: number; item: Condition; index: number; type: IType }) => {
    if (item.column === "Select a Customer") return
    const { watch, control } = useFormContext<Filter>();
    return (
      <Paper elevation={0} sx={{ p: 2, mb: index === allConditionsFields.length - 1 ? 0 : 4, border: '1px solid #e0e0e0', width: '95%' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid size={6}>
            <Controller
              key={index}
              name={`all_conditions.${index}.column`}
              control={control}
              rules={{ required: 'Condition is required' }}
              render={({ field }) => (
                <>
                  <InputLabel id="condition">Condition</InputLabel>
                  <Select
                    {...field}
                    size="small"
                    onChange={(e) => {
                      field.onChange(e); // default RHF onChange
                      handleConditionOnChange(item, index, e.target.value, type); // custom handler
                    }}
                    // placeholder={"Select a Condition"}
                    style={{ width: '100%' }}
                    endAdornment={
                      (baseFields.isPending || customFieldsByCustomer.isPending) ? (
                        <CircularProgress
                          size={20}
                          sx={{
                            position: 'absolute',
                            right: 25,
                            color: 'grey.500'
                          }}
                        />
                      ) : null
                    }
                  >
                    <ListSubheader>Base Fields</ListSubheader>
                    {baseFields?.data && baseFields?.data
                      .sort((a: any, b: any) => a.Meta.Name.localeCompare(b.Meta.Name))
                      .map((item2: any) => {
                        return (
                          <MenuItem value={item2.Meta.Name}>{item2.Meta.Name}</MenuItem>
                        )
                      })}
                    <ListSubheader>Custom Fields</ListSubheader>
                    {customFieldsByCustomer?.data && customFieldsByCustomer?.data.map((item2: any) => {
                      return (
                        <MenuItem value={item2.Meta.Name}>{item2.Meta.Name}</MenuItem>
                      )
                    })}
                  </Select>
                </>
              )}
            />
          </Grid>
          <Grid size={5}>
            {watch(`all_conditions.${index}.column`) && (
              <Controller
                name={`all_conditions.${index}.isEqual`}
                control={control}
                rules={{ required: 'Equality is required' }}
                render={({ field }) => (
                  <>
                    <InputLabel id="condition">Equality</InputLabel>
                    <Select
                      {...field}
                      size="small"
                      onChange={(e) => {
                        field.onChange(e); // default RHF onChange
                        handleEqualityOnChange(item, index, e.target.value, type); // custom handler
                      }}
                      // placeholder={"Equals..."}
                      style={{ width: '100%' }}
                    >
                      {renderEqualityOptions(watch(`all_conditions.${index}.conditionObj`), index)?.map((item: any) => {
                        return (
                          <MenuItem value={item.name}>{item.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </>
                )}
              />
            )}

          </Grid>
          <Grid size={1}>
            <IconButton id="delete-condition" size="small" color="error" onClick={() => {
              // if (type?.name === 'any_conditions') {
              //   anyConditionsRemove(index)
              // } else {
              allConditionsRemove(index)
              // }
            }}>
              <X size={20} />
            </IconButton>
          </Grid>
          <Grid size={12}>
            <Box sx={{ width: '100%' }}>

              {watch(`all_conditions.${index}.column`) !== "" &&
                watch(`all_conditions.${index}.isEqual`) !== "Is Set" &&
                watch(`all_conditions.${index}.isEqual`) !== "Is Not Set" &&
                watch(`all_conditions.${index}.isEqual`) !== "Today" &&
                (
                  renderDynamicField(watch(`all_conditions.${index}.conditionObj`), index, type)
                )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    )
  });

  const EditFilter = useMemo(() => {
    console.log('EditFilter ran:')
    // const filter = getSettings?.data?.Data.find((filter: Filter) => filter.id === reportFilter)
    // console.log('reportFilter:', reportFilter)
    // const [filterName, setFilterName] = React.useState(filter?.name || '');

    // console.log('filter:', filter)

    let combinedColumns = []
    combinedColumns.push(...reportTableColumns)

    const sorted = customFieldsByCustomer?.data?.sort((a: any, b: any) => {
      const nameA = a.Meta.Name.toLowerCase();
      const nameB = b.Meta.Name.toLowerCase();

      return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });
    });

    // console.log('sorted', sorted)

    const sortedColumns = sorted?.map((field: any) => {
      return {
        header: field.Meta.Name,
        accessorKey: field.Meta.Cid
        //might need to add typeId and stuff to this
      }
    })

    // console.log('sortedColumns', sortedColumns)

    if (sortedColumns) {
      combinedColumns?.push(...sortedColumns)
    }

    // console.log('combinedColumns', combinedColumns)

    // const handleSave = () => {
    //   const newFilter = { ...filter, name: filterName };
    //   // onSave(newFilter);
    // };

    return (
      <Box>
        <TextBox
          name="report_filter_name"
          control={control}
          label="Name"
          sx={{ mt: 2 }}
          fullWidth
          required
          size='small'
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Card sx={{ width: '99%', mt: 4, mb: 4 }}>
            <CardContent>
              <AutoComplete
                id="report_filter_customer_filter"
                options={
                  customers.isPending
                    ? []
                    : customers?.data?.map((customer: any) => ({
                      id: customer.CustomerEntId,
                      label: customer.EntName,
                    })) || []
                }
                control={control}
                loading={customers.isPending}
                autocompleteProps={{
                  id: 'report_filter_customer_filter',
                  loadingText: 'Loading Customers...',
                  size: 'small',
                  onFocus: (event) => {
                    oldCustomerValueRef.current = methods.getValues('report_filter_customer_filter')
                  },
                  onChange: (event, value) => {
                    event.preventDefault();
                    if (oldCustomerValueRef.current !== null) {
                      const customerChange = confirm('Are you sure you want to change the customer? Doing may affect the conditions you have already selected.');
                      if (customerChange) {
                        reset({
                          ...methods.getValues(),
                          all_conditions: []
                        });
                      } else {
                        reset({
                          ...methods.getValues(),
                          report_filter_customer_filter: oldCustomerValueRef.current
                        });
                      }
                    } else {
                      reset({
                        ...methods.getValues(),
                        report_filter_customer_filter: value
                      });
                    }
                  }
                  // getOptionLabel: (option) => {
                  //   console.log('option', option)
                  //   return option?.value?.[0] || '';
                  // }, // Show the label for each option
                  // isOptionEqualToValue: (option, value) => option.fieldId === value?.id
                }}
                multiple={false}
                label="Customer"
              />
            </CardContent>
          </Card>
          {
            allConditionsFields.length > 0 &&
            <Card sx={{ width: '99%' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', "&:last-child": { pb: 2 } }}>
                {allConditionsFields?.map((item: Condition, index: number) => (
                  <ConditionRow key={index} item={item} index={index} type={{ name: 'all_conditions' }} />
                ))}
              </CardContent>
            </Card>
          }
        </Box>
        <Button
          id='add-condition-btn-all'
          variant="outlined"
          startIcon={<Plus size={20} />}
          sx={{ mt: 4 }}
          onClick={handleAddAllCondition}
          disabled={!customerWatch}
        >
          Add Condition
        </Button>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }} display={'flex'}>
          <Button
            id='back'
            variant='outlined'
            sx={{ mr: 4 }}
            // size='small'
            onClick={() => {
              navigate({
                to: '/reports/$reportFilter',
                params: { reportFilter: 'all' },
                search: {
                  variant: 'reportFilters',
                  step: 0
                }
              })
              reset({
                report_filter_customer_filter: null,
                report_filter_name: '',
                all_conditions: []
              });
            }}
          >
            Back
          </Button>
          <Button
            id='back'
            variant='contained'
            // size='small'
            type='submit'
            onClick={() => {
              navigate({
                to: '/reports/$reportFilter',
                params: { reportFilter: 'all' },
                search: {
                  variant: 'reportFilters',
                  step: 0
                }
              })
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  }, [reportTableColumns, customFieldsByCustomer?.data, customers?.data, allConditionsFields, customerWatch?.id]);

  // useEffect(() => {
  //   const sub = watch((value) => {
  //     console.log(methods.trigger())
  //     console.log(value);
  //     console.log({ isDirty })
  //     console.log({ isValid })
  //   });

  //   return () => sub.unsubscribe();
  // }, [watch]);

  useEffect(() => {
    // console.log('useEffect ran:')
    const filter = getSettings?.data?.Data?.find((filter: Filter) => filter.id === reportFilter)
    const customer = customerWatch ? customerWatch : filter?.condition.find((filter: Condition) => filter.type === 'customer')

    if (filter) {
      // Reset form values only when the filter is available
      // console.log('filter', filter)
      const newCustomer = customerWatch ? customerWatch : {
        id: customer?.fieldId,
        label: customer?.value[0]
      }

      const mapCondition = (condition: Condition) => {
        // console.log('condition:', condition)

        const findBooleanValue = (condition: any) => {
          if (condition.value[0] === "true") {
            return true
          } else {
            return false
          }
        }

        const findDropdownValue = (baseFieldObj: any, condition: any) => {
          if (baseFieldObj.Meta.Cid === 9000) {
            return ticketTypes?.data?.findIndex((item: any) => item.Name === condition.value[0])
          } else {
            const newIndex = baseFieldObj.Type?.OptLabels.findIndex((item: any) => item === condition.value[0])
            return newIndex
          }
        }

        const convertDateValue = (condition: any) => {
          if (condition.isEqual === "isBetween") {
            const rawDate = condition.value.map((item: any) => dayjs(item));
            return rawDate
          } else {
            const rawDate = condition.value[0];
            const parsedDate = dayjs(rawDate);

            return parsedDate.isValid() ? parsedDate : null;
          }
        }

        if (condition.type === 'baseField') {
          const baseFieldObj = baseFields?.data?.find((item: any) => item.Meta.Cid === condition.fieldId)

          const equality = casesArray.find((entry) => entry.value.toLowerCase() === condition.isEqual.toLowerCase());

          const dateItem = IcfTDateCasesArray.find(
            (entry) => entry.value.toLowerCase() === equality?.value?.toLowerCase()
          );

          const modifiedEquality = baseFieldObj?.TypeId === 22
            ? (dateItem?.name ?? condition.isEqual)
            : (equality?.name ?? condition.isEqual);

          const newValue = () => {
            switch (baseFieldObj?.TypeId) {
              case 19:
                return findDropdownValue(baseFieldObj, condition)
              case 22:
                return convertDateValue(condition)
              default:
                return condition?.value
            }
          }

          return {
            ...condition,
            value: newValue(),
            isEqual: modifiedEquality,
            conditionObj: baseFieldObj || [],
          }
        }

        if (condition.type === 'customField') {
          const customFieldObj = customFieldsByCustomer?.data?.find((item: any) => item.Meta.Cid === condition.fieldId)

          const newValue = (customFieldObj?.TypeId === 20) ? findBooleanValue(condition) : condition?.value

          const equality = casesArray.find((entry) => entry.value.toLowerCase() === condition.isEqual.toLowerCase());

          const dateItem = IcfTDateCasesArray.find(
            (entry) => entry.value.toLowerCase() === equality?.name?.toLowerCase()
          );

          const modifiedEquality = customFieldObj?.TypeId === 22
            ? (dateItem?.name ?? condition.isEqual)
            : (equality?.name ?? condition.isEqual);

          return {
            ...condition,
            value: newValue,
            isEqual: modifiedEquality,
            conditionObj: customFieldObj || [],
          }
        }
      }

      const allConditions = filter?.condition
        .filter((condition: Condition) => condition.type !== "customer")
        .map((condition: Condition) => mapCondition(condition));

      // console.log('ALL CONDITIONS', allConditions)
      oldCustomerValueRef.current = newCustomer
      reset({
        report_filter_customer_filter: newCustomer,
        report_filter_name: filter?.name,
        all_conditions: allConditions
      });
    } else if (variant === 'new') {
      console.log('do nothing')
    } else {
      reset({
        report_filter_customer_filter: null,
        report_filter_name: '',
        all_conditions: []
      });
    }
  }, [reportFilter, baseFields?.data, customFieldsByCustomer?.data, getSettings?.data]);

  useEffect(() => {
    if (variant === 'new') {
      reset({
        report_filter_customer_filter: null,
        report_filter_name: '',
        all_conditions: []
      });
    }
  }, [variant, reset]);

  return (
    <DrawerLayout
      open={isOpen}
      onClose={handleClose}
      anchor="right"
      keepMounted
      width={'35%'}
      Header={
        <DrawerHeader
          close={handleClose}
          title={'Report Filters'}
        />
      }
    >
      <Grid
        container
        style={{
          height: '100%',
          width: '100%',
        }}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent={'space-between'}
        flexWrap="nowrap"
        padding={4}
        overflow="auto"
      >
        <Grid size={{ xs: 12 }} marginTop={!isDesktop ? 2 : 0} height={'100%'} sx={{ overflow: 'auto', paddingBottom: '90px' }}>
          <FormProvider {...methods} >
            <form className="d-flex flex-column row-gap-4" onSubmit={methods.handleSubmit(handleSubmit)}>
              {/* <ReportFilterHome />
              <EditFilter /> */}
              {variant === 'reportFilters' && <ReportFilterHome />}
              {(variant === 'edit' || variant === 'new') && EditFilter}
            </form>
          </FormProvider>
        </Grid>
      </Grid >
    </DrawerLayout >
  )
}

export default ReportFiltersDrawer