import { z } from 'zod';

// break this up so there's some nested objects for each api call
export const CustomFieldSchema = z.object({
  api: z.object({
    name: z.string().min(1, { message: 'Name field is required' }),
    isRequired: z.string(),
    archivedts: z.string(),
    childCount: z.string(),
    childList: z.string(),
    cid: z.string(),
    customerCount: z.string(),
    customerIdList: z.string(),
    customerList: z.string(),
    fieldGroupId: z.string(),
    fieldGroupName: z.string().optional(),
    parentField: z.string(),
    reportHeaderCount: z.string(),
    reportHeaderId: z.string(),
    reportingGroupHeaderList: z.string(),
    reportingGroupHeaderName: z.string(),
    ticketCount: z.string(),
    type: z.string(),
  }),
  metaData: z.object({
    DefaultValue: z.boolean().optional(),
    IncludeTime: z.boolean().optional(),
    Maximum: z.number().optional(),
    Minimum: z.number().optional(),
    Step: z.number().optional(),
    OptLabels: z.array(z.string()).optional(),
    SingleOptLabel: z.string().optional(),
    ParentField: z.string(),
    ParentFieldId: z.number(),
    ParentFieldType: z.string().optional(),
    ParentFieldValue: z.string(),
    FieldGroupId: z.number(),
    ArchivedTime: z.string(),
    Archivedts: z.number(),
    CustomerForTypes: z.any().optional(),
    CustomerIdForTypes: z.number(),
    TicketTypes: z.array(z.any()),
    TypeList: z
      .array(
        z.object({
          CustomerID: z.number(),
          TypeList: z
            .array(
              z.object({
                TypeName: z.string(),
                TypeID: z.number(),
              }),
            )
            .nullable(),
        }),
      )
      .nullable(),
  }),
  newGroupHeader: z
    .object({
      Name: z.string(),
      IncludeFieldName: z.boolean(),
      ShowAll: z.boolean(),
      NameToBeEdited: z.string(),
    })
    .optional(),
  reportingGroupHeaders: z.any(),
});

export const customField = {
  api: {
    name: '',
    isRequired: '',
    archivedts: '',
    childCount: '',
    childList: '',
    cid: '',
    customerCount: '',
    customerIdList: '',
    customerList: '',
    fieldGroupId: '',
    fieldGroupName: '',
    parentField: '',
    reportHeaderCount: '',
    reportHeaderId: '',
    reportingGroupHeaderList: '',
    reportingGroupHeaderName: '',
    ticketCount: '',
    type: '',
  },
  metaData: {
    IncludeTime: false,
    DefaultValue: false,
    Maximum: 0,
    Minimum: 0,
    Step: 0,
    OptLabels: [],
    SingleOptLabel: '',
    ParentField: '',
    ParentFieldId: 0,
    ParentFieldValue: '',
    ParentFieldType: '',
    FieldGroupId: 0,
    ArchivedTime: '',
    Archivedts: 0,
    CustomerForTypes: '',
    CustomerIdForTypes: 0,
    TicketTypes: [],
    TypeList: [],
  },
};

export type TypeCustomerList = {
  CustomerID: number;
  TypeIDList: number[];
};

export type IncomingTypeCustomerList = {
  CustomerID: number;
  TypeIDList: {
    TypeID: number;
    TypeName: string;
  };
};

export interface CustomField {
  archivedts: string;
  childCount: string;
  childList: string;
  cid: string;
  customerCount: string;
  customerIdList: string;
  customerList: string;
  fieldGroupId: string;
  isRequired: string;
  name: string;
  parentField: string;
  reportHeaderCount: string;
  reportHeaderId: string;
  reportingGroupHeaderList: string;
  reportingGroupHeaderName: string;
  ticketCount: string;
  type: string;
}

export interface ReportHeader {
  CreatedBy: string;
  Createdts: number;
  DeletedBy: string;
  Deletedts: number;
  Id: number;
  IncludeFieldName: false;
  Name: string;
  ReportHeaderCount: string;
  ReportingGroupHeaderList: string;
  ShowAll: boolean;
  UpdatedAt: number;
  UpdatedBy: string;
  Updatedts: number;
}

export type CheckedTypes = {
  TypeID: number;
  TypeName: string;
};
