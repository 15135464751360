import StatusService from '@/services/statuses.service';
import { Mutation, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import type { UseMutationResult } from '@tanstack/react-query';

type ActionType = 'createStatus' | 'deleteStatus' | 'updateStatus' | 'sortStatusOrder';

interface MutationAction {
  actionType: ActionType;
  payload: any;
}

const useStatusMutation = (): {
  executeStatusAction: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  isUpdating: Record<string, boolean>;
  setIsUpdating: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const [isUpdating, setIsUpdating] = useState<Record<string, boolean>>({});

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const statusService = StatusService();

  const actionMap: { [key in ActionType]: Function } = {
    deleteStatus: statusService.deleteStatusById,
    createStatus: statusService.createStatus,
    updateStatus: statusService.updateStatus,
    sortStatusOrder: statusService.sortStatusOrder,
  };

  const executeMutation = async ({ actionType, payload }: MutationAction) => {
    const serviceMethod = actionMap[actionType];
    if (!serviceMethod) {
      enqueueSnackbar('Error processing your request', { variant: 'error' });
    } else {
      return serviceMethod(payload);
    }
  };

  const { mutate: executeStatusAction } = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType }) => {
      setIsUpdating((prev) => ({ ...prev, [actionType]: true }));
    },

    onSuccess: (data: any, { actionType }) => {
      const messages: { [key in ActionType]: string } = {
        deleteStatus: 'Status successfully deleted',
        createStatus: 'Status successfully created',
        updateStatus: 'Status successfully updated',
        sortStatusOrder: 'Status order successfully saved',
      };

      const successMessage = messages[actionType] || 'Operation successful';
      const errorMessage = `Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()}`;

      enqueueSnackbar(successMessage, { variant: 'success' });
      navigate({ to: '/status-editor' });
    },
    onError: (error: any, { actionType }) => {
      const errorMessage = `Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(); // Invalidate all queries
    },
  });

  return { executeStatusAction, isUpdating, setIsUpdating };
};

export default useStatusMutation;
