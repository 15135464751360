import { apiService } from './api.service';
import { BASE_UAPI_URL } from '@/constants/commonStrings.constant';
import { AxiosRequestConfig } from 'axios';

const TicketTypeService = () => {
  const getTicketTypes = async ({ onSuccess, onError }: { onSuccess?: Function; onError?: Function }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/tickettype',
      onSuccess,
      onError,
    });
  };

  const getCustomerTicketTypeById = async ({
    customerId,
    onSuccess,
    onError,
  }: {
    customerId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/tickettype/customers?ttid=' + customerId,
      onSuccess,
      onError,
    });
  };

  const getTicketTypesByAllCustomers = async ({
    onSuccess,
    onError,
  }: {
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/tickettype/typesbyallcustomers',
      onSuccess,
      onError,
    });

  }




  const createTicketType = async (params: any, config?: Partial<AxiosRequestConfig>) => {
    const requestId = `postCreateStatus_${Date.now()}`;
    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/tickettype/addtype?ttid=0',
      data: params,
      ...config,
      id: requestId,
    });
    return response;
  };

  const updateTicketType = async (params: any, config?: Partial<AxiosRequestConfig>) => {
    const requestId = `postCreateStatus_${Date.now()}`;
    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/tickettype/addtype?ttid=' + params?.TypeId,
      data: params,
      ...config,
      id: requestId,
    });
    return response;
  };

  const deleteTicketType = async (params: any, config?: Partial<AxiosRequestConfig>) => {
    const requestId = `postCreateStatus_${Date.now()}`;
    const response = await apiService({
      method: 'delete',
      url: BASE_UAPI_URL + '/tickettype/addtype?ttid=' + params?.TypeID,
      data: params,
      ...config,
      id: requestId,
    });
    return response;
  };
  
  return {
    getTicketTypes,
    getCustomerTicketTypeById,
    deleteTicketType,
    createTicketType,
    updateTicketType,
    getTicketTypesByAllCustomers
  };
};

export default TicketTypeService;
