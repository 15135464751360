import React from 'react';
import styled from '@emotion/styled';
import { Handle, Position } from '@xyflow/react';

interface NodeWrapperProps {
  children: React.ReactNode;
  type: 'trigger' | 'condition' | 'action';
  label: string;
  isSelected?: boolean;
}

const StyledNode = styled.div<{ nodeType: string; isSelected?: boolean }>`
  padding: 16px;
  border-radius: 8px;
  background: white;
  border: 2px solid ${({ nodeType }) => getNodeColor(nodeType)};
  box-shadow: ${({ isSelected }) =>
    isSelected ? '0 0 0 2px #1a192b' : '0 1px 4px rgba(0,0,0,0.16)'};
  
  &:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
`;

const Header = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
  color: #1a192b;
`;

const getNodeColor = (type: string) => {
  switch (type) {
    case 'trigger':
      return '#ff6b6b';
    case 'condition':
      return '#4ecdc4';
    case 'action':
      return '#45b7d1';
    default:
      return '#ddd';
  }
};

export const NodeWrapper: React.FC<NodeWrapperProps> = ({
  children,
  type,
  label,
  isSelected
}) => {
  return (
    <StyledNode nodeType={type} isSelected={isSelected}>
      {type !== 'trigger' && (
        <Handle
          type="target"
          position={Position.Top}
          style={{ background: '#555' }}
        />
      )}

      <Header>{label}</Header>
      {children}

      {type !== 'action' && (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ background: '#555' }}
        />
      )}
    </StyledNode>
  );
};
