import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
// import { FormDataTypeProps } from '../../../interfaces/formBuilder.interface';
// import DynamicIcon from '../lib/DynamicIcon';

interface IProps {
  onDataChange: any;
  id: string;
  label: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  hintText?: string;
  type?: string;
  min?: number;
  max?: number;
  step?: number;
  endAdornment?: React.ReactNode;
}

const NumberFieldInput = ({ onDataChange, ...props }: IProps) => {
  const [value, setValue] = useState(props.value || '');
  const theme = useTheme();

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onDataChange(event.target.value);
  };

  return (
    <FormControl required={props.required} size="small" fullWidth disabled={props.disabled} variant="outlined">
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <OutlinedInput
        endAdornment={props.endAdornment}
        id={props.id}
        type="number"
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          max: props.max,
          min: props.min,
          step: props.step,
        }}
        label={props.label}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        value={value}
        fullWidth
        sx={{
          bgcolor: theme.palette.background.paper,
        }}
        autoComplete="off"
        onChange={handleChange}
      />
      {props.hintText && <FormHelperText>{props.hintText}</FormHelperText>}
    </FormControl>
  );
};

export default NumberFieldInput;
