import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import GlobalStateProvider from './contexts/globalStateProvider';

function render() {
  const container = document.getElementById('root');
  const root = ReactDOM.createRoot(container!);
  // enableMockServer().then(() =>
  return root.render(
    <StrictMode>
      <GlobalStateProvider>
        <App />
      </GlobalStateProvider>
    </StrictMode>,
  )
  // )
  // createRoot(document.getElementById('root')!).render(
  //   <StrictMode>
  //     <GlobalStateProvider>
  //       <App />
  //     </GlobalStateProvider>
  //   </StrictMode>,
  // );
}
render();
