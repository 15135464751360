import { z } from 'zod';
import { BASE_API_URL, BASE_UAPI_URL } from '../constants/commonStrings.constant';
import { apiService } from './api.service';
import { AxiosRequestConfig } from 'axios';
import createQueryParams from '@/util/queryParams';
import { env } from '@/config/env';

export const postCreateTicketParams = z.object({
  priorityId: z.number(),
  typeId: z.number(),
  customer: z.number().int().positive(),
  ticketId: z.number(),
  Title: z.string(),
});
export type PostCreateTicketParams = {
  priorityId: number;
  typeId: number;
  customer: number;
  ticketId: number;
  Title: string;
};

const TicketService = () => {
  const getTickets = async ({
    customerId,
    customFields = false,
    category,
    onSuccess,
    onError,
  }: {
    customerId: number;
    customFields?: boolean;
    category: string;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/tickets',
      params: {
        fields: customFields,
        customer: customerId,
        category: category,
      },
      onSuccess,
      onError,
    });
  };

  const getTicketById = async ({
    ticketId,
    onSuccess,
    onError,
  }: {
    ticketId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/ticketext',
      params: {
        ticketid: ticketId,
      },
      onSuccess,
      onError,
    });
  };

  const getTicketViewers = async ({
    ticketId,
    onSuccess,
    onError,
  }: {
    ticketId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/viewers',
      params: {
        ticketid: ticketId,
        json: true,
      },
      onSuccess,
      onError,
    });
  };

  const getTicketTypesByCustomer = async ({
    customerId,
    onSuccess,
    onError,
  }: {
    customerId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/tickettype/typesbycustomer',
      params: {
        cid: customerId,
      },
      onSuccess,
      onError,
    });
  };

  const getTicketPriorities = async ({ onSuccess, onError }: { onSuccess?: Function; onError?: Function }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/priorities',
      onSuccess,
      onError,
    });
  };

  const getTicketDuplicateAddress = async ({
    address,
    ticketId,
    onSuccess,
    onError,
  }: {
    address: string;
    ticketId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/ticket/duplicateaddress',
      params: {
        address: address,
        ticketid: ticketId,
      },
      onSuccess,
      onError,
    });
  };

  const getTicketCustomFieldsById = async ({
    ticketId,
    archived,
    onSuccess,
    onError,
  }: {
    ticketId: number;
    archived: boolean;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/customfields?ticketid=' + ticketId + '&archived=' + archived,
      // params: {
      //   ticketid: ticketId,
      // },
      onSuccess,
      onError,
    });
  };

  const getTicketCustomFieldGroupsById = async ({
    ticketId,
    cid,
    onSuccess,
    onError,
  }: {
    ticketId?: number;
    cid?: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/customfields',
      params: {
        groupsummary: ticketId,
        cid: cid?.toString()
      },
      onSuccess,
      onError,
    });
  };

  const getCustomers = async ({ onSuccess, onError }: { onSuccess?: Function; onError?: Function }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/customers',
      onSuccess,
      onError,
    });
  };

  const getBaseFields = async ({ onSuccess, onError }: { onSuccess?: Function; onError?: Function }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/tickets/basefields',
      onSuccess,
      onError,
    });
  };

  const getStatuses = async ({ onSuccess, onError }: { onSuccess?: Function; onError?: Function }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/statuses',
      onSuccess,
      onError,
    });
  };

  const getStatusesByCustomer = async ({
    customerId,
    onSuccess,
    onError,
  }: {
    customerId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/statuses/customer',
      params: {
        customerid: customerId,
      },
      onSuccess,
      onError,
    });
  };

  // const getTicketFillerDataById = async ({
  //   ticketId,
  //   onSuccess,
  //   onError
  // }: {
  //   ticketId: number,
  //   onSuccess?: Function,
  //   onError?: Function
  // }) => {
  //   //! This is not a UAPI endpoint
  //   return apiService({
  //     method: "get",
  //     url: `/www/api-tokens.proc.json?TicketId=` + ticketId,
  //     onSuccess,
  //     onError,
  //   });
  // }

  // const getAgents = async ({
  //   onSuccess,
  //   onError
  // }: {
  //   onSuccess?: Function,
  //   onError?: Function
  // }) => {
  //   return apiService({
  //     method: "get",
  //     url: BASE_UAPI_URL + "/agentnames",
  //     onSuccess,
  //     onError,
  //   });
  // }

  const getAgentsByCustomer = async ({
    customerId,
    ticketId = 0,
    limitToCustomer = true,
    onSuccess,
    onError,
  }: {
    customerId: number;
    ticketId?: number;
    limitToCustomer?: boolean;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/agents',
      params: {
        customerid: customerId,
        ticketid: ticketId,
        limittocustomer: true,
      },
      onSuccess,
      onError,
    });
  };

  const getCustomFieldsByCustomer = async ({
    customerId,
    onSuccess,
    onError,
  }: {
    customerId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/customfields',
      params: {
        full: true,
        customerid: customerId,
      },
      onSuccess,
      onError,
    });
  };

  const getAttachmentsById = async ({
    ticketId,
    onSuccess,
    onError,
  }: {
    ticketId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/attachments',
      params: {
        ticketId: ticketId,
      },
      onSuccess,
      onError,
    });
  };

  const getAttachmentTypesByCustomer = async ({
    customerId,
    onSuccess,
    onError,
  }: {
    customerId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/getattachmenttypes',
      params: {
        cid: customerId,
      },
      onSuccess,
      onError,
    });
  };

  const getNotesById = async ({
    ticketId,
    onSuccess,
    onError,
  }: {
    ticketId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/note/full',
      params: {
        ticketid: ticketId,
      },
      onSuccess,
      onError,
    });
  };

  const getTicketHistoryById = async ({
    ticketId,
    onSuccess,
    onError,
  }: {
    ticketId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/tickethistory',
      params: {
        ticketid: ticketId,
      },
      onSuccess,
      onError,
    });
  };

  const postDuplicateAddress = async ({
    data,
    onSuccess,
    onError,
  }: {
    data: any;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/ticket/duplicateaddress',
      onSuccess,
      onError,
      data,
    });
  };

  const postTicketMapUpdates = async ({
    data,
    onSuccess,
    onError,
  }: {
    data: any;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/ticket/ticketmapupdate',
      onSuccess,
      onError,
      data,
    });
  };

  const postTicketBaseFields = async ({
    data,
    onSuccess,
    onError,
  }: {
    data: any;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/ticket',
      onSuccess,
      onError,
      data,
    });
  };

  const postTicketCustomFields = async ({
    data,
    onSuccess,
    onError,
  }: {
    data: any;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/customfields/values',
      onSuccess,
      onError,
      data,
    });
  };

  const postNotes = async ({ data, onSuccess, onError }: { data: any; onSuccess?: Function; onError?: Function }) => {
    // console.log('data: ', data);
    return apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/note',
      onSuccess,
      onError,
      data,
    });
  };

  const postCreateTicket = async (
    params: PostCreateTicketParams,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {
    // const validateRequestParams = postCreateTicketParams.parse(params);
    // const search = createQueryParams(params);
    const requestId = `postCreateTicket_${Date.now()}`;

    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL + `/ticket`,
      data: params,
      ...config,
      id: requestId,
    });

    console.log('createTicket response: ', response);

    return response.data;
  };

  const killSession = async ({ onSuccess, onError }: { onSuccess?: Function; onError?: Function }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/killsession',
      onSuccess,
      onError,
    });
  };

  const deleteTicketById = async ({
    ticketid,
    onSuccess,
    onError,
  }: {
    ticketid: any;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    console.log('deleteTicketById ticketid: ', ticketid);
    return apiService({
      method: 'delete',
      url: BASE_UAPI_URL + '/ticket?ticketid=' + ticketid,
      onSuccess,
      onError,
    });
  };

  return {
    getTickets,
    getTicketById,
    getTicketViewers,
    getTicketTypesByCustomer,
    getTicketPriorities,
    getTicketDuplicateAddress,
    getTicketCustomFieldsById,
    getTicketCustomFieldGroupsById,
    getCustomers,
    getBaseFields,
    getStatuses,
    getStatusesByCustomer,
    // getTicketFillerDataById,
    // getAgents,
    getAgentsByCustomer,
    getCustomFieldsByCustomer,
    getAttachmentsById,
    getAttachmentTypesByCustomer,
    getNotesById,
    getTicketHistoryById,
    postDuplicateAddress,
    postTicketMapUpdates,
    postTicketBaseFields,
    postTicketCustomFields,
    postNotes,
    postCreateTicket,
    killSession,
    deleteTicketById,
  };
};

export default TicketService;
