import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, FormControl, Autocomplete, TextField, CircularProgress, Accordion, AccordionDetails, Typography, Checkbox, FormControlLabel, AccordionSummary } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactDOM from "react-dom/client";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
// import useTheme from "../hooks/useTheme";
// import createTheme from "../constants/theme/index";
import axios from 'axios';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import useTicketList from '@/contexts/stores/ticketList.store';
import { useParams } from '@tanstack/react-router';
import useTemplate from '@/hooks/fetches/useTemplate.service';
import { AutoComplete } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { useFormContext, useWatch } from 'react-hook-form';
import { toCamelCase } from '@/util/toCamelCase.util';
import { createBaseFieldsQueryOptions, createTicketByIdQueryOptions, createAttachmentsQueryOptions } from '@/hooks/fetches/useTicket.service';
import { useQuery } from '@tanstack/react-query';

interface Template {
  // AgentName: string;
  // CreatedAt: string;
  // CreatedBy: string | null;
  // CustomerId: number;
  // CustomerName: string;
  // DeletedAt: string | null;
  // DeletedBy: string | null;
  // Description: string;
  // FileName: string;
  // TemplateId: number;
  // TemplateName: string;
  // UpdatedAt: string | null;
  // UpdatedBy: string | null;
  // Url: string;
  id: number;
  label: string;
}

interface Note {
  Nid: number;
  Body: string;
  Filesinfo: FileInfo[];
  CreateBy: string;
  CreateTime: string;
  TimeZoneOffset: string;
  TimeZone: string;
  AttachmentTypeId: number;
}

interface FileInfo {
  Fid: number;
  Nid: number;
  Filename: string;
  Filesize: number;
  URL: string;
  UpdatedAt: string;
  UpdatedBy: number;
  DeletedAt: string;
  DeletedBy: number;
  UserName: string;
}

const PrintNoticeDialog = (props: any) => {
  const {
    customerID
  } = props

  const url = document.getElementById("siteUapiUrl")?.innerHTML ?? '';

  const [selectedTemplate, setSelectedTemplate] = useState<Template | undefined>(undefined);
  // const [open, setOpen] = useState(false);
  // const { theme } = useTheme();
  // const [templateList, setTemplateList] = useState<Template[]>([]);
  const [loading, setLoading] = useState(false);
  const [domData, setDomData] = useState<any>();
  // const [notes, setNotes] = useState<Note[]>([]);
  const [checkedFiles, setCheckedFiles] = useState<{ [key: number]: boolean }>({});
  const [mainChecked, setMainChecked] = useState(true);
  const [mainIndeterminate, setMainIndeterminate] = useState(false);
  const { ticketListState, updateTicketList } = useTicketList();
  // const urlParams = new URLSearchParams(window.location.search);
  // const tid = urlParams.get("TicketId");

  const { ticketId } = useParams({
    from: '/_auth/ticket/$ticketId',
  })
  const methods = useFormContext();
  const templateIdWatch = useWatch({ control: methods.control, name: 'template' });

  const attachmentsById = useQuery(createAttachmentsQueryOptions(ticketId))
  const ticketById = useQuery(createTicketByIdQueryOptions(ticketId))
  const baseFields = useQuery(createBaseFieldsQueryOptions())

  const { templatesByCustomer, customFieldsByTemplateId, downloadOrPrintTemplate } = useTemplate(templateIdWatch?.id, ticketListState?.selectedCustomer?.id); //todo fix this variable so I get the cids

  // console.log('customFieldsByTemplateId.data:', customFieldsByTemplateId.data)

  // const getTemplates = async () => {
  //   const response = await axios
  //     .get(
  //       url + `/gettemplate?templateid=0&customerid=${customerID}`
  //       // authHdr()
  //     )
  //     .then(function (response) {
  //       setTemplateList(response.data)
  //     })
  //     .catch(function (error) {
  //       console.error("getting templates not working");
  //       return error;
  //     });
  //   return response
  // }

  // const getCidList = async (templateId: number) => {
  //   // const fakeResponse = {

  //   const response = await axios
  //     .get(
  //       url + `/getcustomfieldsbytemplateid?templateid=${templateId}`
  //       // authHdr()
  //     )
  //     .then(function (response) {
  //       return response.data
  //       // setTemplateList(response.data)
  //     })
  //     .catch(function (error) {
  //       console.error("getting cid's not working");
  //       return error;
  //     });
  //   // return response
  //   //   // data: [41, 59, 9000, 9001, 9002, 9003, 9004, 9005, 9006, 9007, 9008, 9009, 9010, 9011, 9012]
  //   //   data: [
  //   //     185, 38, 20, 41, 337, 59, 18, 60, 19, // From the first provided HTML content
  //   //     40, 72, 115, 105, 108, 110, 116, 117, 118, 119, // From the second provided HTML content
  //   //     253, 254, 44, 264, 255, 256, 257, 265, 97, 96, 77, 52, 53, 55, 54, 94, 93, 95, 10, 9, 56, 295, 231, // From the third provided HTML content
  //   //     30, 31, // From the "GPS/GIS Services" section
  //   //     61, 62, 64, 65, // From the "Hourly" section
  //   //     350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380, 381, 382, // From the "Material" section
  //   //     120, 51, 125, // From the "Removals and Re-Entries" section
  //   //     25, 2, 223, 49, 23, 24, 29, 6, 224, 225, // From the "Splicing Units" section
  //   //     81, 147, 46, 48, 212, 42, 57, 226, 227, 349, // From the "Underground Units" section
  //   //     9000, 9001, 9002, 9003, 9004, 9005, 9006, 9007, 9008, 9009, 9010, 9011, 9012
  //   //   ]
  //   // }

  //   // setCidList(fakeResponse.data);
  //   // grabDOMData(response.CustomFieldIds);

  //   // return fakeResponse;
  // }

  // const getNotes = async () => {

  //   const formData = new FormData();
  //   // formData.append("TicketId", tid || ""); //this was the ticketId from the url
  //   formData.append("Request", "get");

  //   const response = await axios
  //     .post("api-notefileupload.proc.json", formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     })
  //     .then(function (response) {
  //       setNotes(response.data || []);
  //       return notes;
  //     })
  //     .catch(function (error) {
  //       console.error("Error fetching notes:", error);
  //       return error;
  //     });

  //   return response;
  // }

  const postPopulatedTemplate = async (data: any, AttachmentIds: any, templateId: number | undefined) => {

    // const combinedData = {
    //   TemplateId: templateId,
    //   AttachmentIds,
    //   ...data
    // };

    // console.log('combinedData', combinedData);

    try {
      const response = await downloadOrPrintTemplate({
        actionType: 'downloadOrPrintTemplate',
        data: data,
        attachmentIds: AttachmentIds,
        templateId: templateId
      });

      console.log('response', response);

      // getTemplates();
      return response.data;
    } catch (error) {
      console.error("Error merging to template:", error);
      throw error;
    }
  };

  const grabDOMData = async (cids: any[]) => {
    const result: { [key: number]: string } = {};

    console.log('templateIdWatch.id:', templateIdWatch?.id)

    console.log('customFieldsByTemplateId?.data?.CustomFieldIds:', customFieldsByTemplateId?.data?.CustomFieldIds)
    customFieldsByTemplateId?.data?.CustomFieldIds && customFieldsByTemplateId?.data?.CustomFieldIds.forEach((cid: number) => {
      let value = '';
      switch (cid) {
        case 9000:
          value = (document.querySelector('[name="type"]') as HTMLInputElement)?.value || '';
          // console.log('typeValue:', typeValue);
          // const typeSelectElement = document.querySelector('[name="type"]') as HTMLSelectElement;
          // console.log('typeSelectElement:', typeSelectElement);
          // if (typeSelectElement) {
          //   const selectedOption = typeSelectElement.options[typeSelectElement.selectedIndex];
          //   value = selectedOption ? selectedOption.text : '';
          // }
          break;
        case 9001:
          value = (document.querySelector('[name="priority"]') as HTMLInputElement)?.value || '';
          console.log('priorityValue:', value);
          // const prioritySelectElement = document.querySelector('[data-field="priority"] select') as HTMLSelectElement;
          // if (prioritySelectElement) {
          //   const selectedOption = prioritySelectElement.options[prioritySelectElement.selectedIndex];
          //   value = selectedOption ? selectedOption.text.toUpperCase() : '';
          // }
          break;
        case 9002:
          value = (document.querySelector('[name="status"]') as HTMLInputElement)?.value || '';
          console.log('statusValue:', value);
          // const statusSelectElement = document.querySelector('[data-field="status"] select') as HTMLSelectElement;
          // if (statusSelectElement) {
          //   const selectedOption = statusSelectElement.options[statusSelectElement.selectedIndex];
          //   value = selectedOption ? selectedOption.text : '';
          // }
          break;
        case 9003:
          value = (document.querySelector('[name="dueDate"]') as HTMLInputElement)?.value || '';
          console.log('dueDateValue:', value);
          // const dueDateInput = document.querySelector('[data-field="due-date"] input') as HTMLInputElement;
          // if (dueDateInput) {
          //   const dueDateValue = dueDateInput.value;
          //   if (dueDateValue) {
          //     value = dayjs(dueDateValue).format('MM/DD/YYYY')
          //   }
          // }
          break;
        case 9004:
          value = (document.querySelector('[name="description"]') as HTMLInputElement)?.value || '';
          console.log('descriptionValue:', value);
          break;
        case 9005:
          value = (document.querySelector('[name="clientName"]') as HTMLInputElement)?.value || '';
          console.log('clientNameValue:', value);
          // value = (document.querySelector('[data-field="client-name"] input') as HTMLInputElement)?.value || '';
          break;
        case 9006:
          value = (document.querySelector('[name="clientEmail"]') as HTMLInputElement)?.value || '';
          console.log('clientEmailValue:', value);
          // value = (document.querySelector('[data-field="client-email"] input') as HTMLInputElement)?.value || '';
          break;
        case 9007:
          value = (document.querySelector('[name="clientPhone"]') as HTMLInputElement)?.value || '';
          console.log('clientPhoneValue:', value);
          // value = (document.querySelector('[data-field="client-phone"] input') as HTMLInputElement)?.value || '';
          break;
        case 9008:
          value = (document.querySelector('[name="clientAddress"]') as HTMLInputElement)?.value || '';
          console.log('clientAddressValue:', value);
          // value = (document.querySelector('[data-field="client-address"] input') as HTMLInputElement)?.value || '';
          break;
        case 9009: //this doesn't exist
          value = (document.querySelector('[name="closedDate"]') as HTMLInputElement)?.value || '';
          console.log('closedDateValue:', value);
          // value = (document.querySelector('[data-field="closed-date"] input') as HTMLInputElement)?.value || '';
          break;
        case 9010:
          value = (document.querySelector('[name="assignee"]') as HTMLInputElement)?.value || '';
          console.log('assigneeValue:', value);
          // const tokenNameElement = document.querySelector('widget[warg-name="TicketAssignee"] .tokenname');
          // if (tokenNameElement) {
          //   value = tokenNameElement.textContent || '';
          // }
          break;
        case 9011:
          value = ticketById.data?.Raised?.Name
          console.log('raisedByValue:', value)
          // const raisedByElement = document.querySelector('[data-field="raised-by"]');
          // if (raisedByElement) {
          //   value = raisedByElement.textContent?.replace(' By: ', '').trim() || '';
          // }
          break;
        case 9012:
          value = dayjs(ticketById.data?.Ticket.CreateTime).format('MM/DD/YY')
          console.log('createdValue:', value);
          // const createdElement = document.querySelector('[data-field="created"]');
          // if (createdElement) {
          //   value = createdElement.textContent?.replace('Created: ', '').trim() || '';
          // }
          break;
        default:
          // const widget = document.querySelector(`input[id="${cid}"]`)?.value;
          value = (document.querySelector(`[id="${cid}"]`) as HTMLInputElement)?.value || '';
          console.log('CFValue:', value)
          // if (widget) {
          //   value = widget.value;
          //   const valueElement = widget.querySelector('.cfield-input') as HTMLInputElement;
          //   if (valueElement) {
          //     value = valueElement.value;

          //     if (value === 'Select...') {
          //       value = '';
          //     }

          //     const parsedDate = new Date(value);
          //     if (!isNaN(parsedDate.getTime())) {
          //       value = dayjs(parsedDate).format('MM/DD/YYYY');
          //     }
          //   }
          // }
          break;
      }

      // if (value !== '') {
      result[cid] = value;
      // }
    });

    console.log('result:', result)
    setDomData(result);

    return result;
  };

  const getVirtualDOMData = () => {
    const watchedValues = methods.watch();

    return customFieldsByTemplateId?.data.CustomFieldIds?.reduce((acc: any, cid: number) => {
      let formattedValue;

      if (cid >= 9000) {
        const field = baseFields?.data?.find((field: any) => field.Meta.Cid === cid);
        if (field) {
          const camelCaseValue = toCamelCase(field.Meta.Name);

          const value = watchedValues[camelCaseValue];

          formattedValue = dayjs(value).isValid()
            ? dayjs(value).format('MM/DD/YYYY')
            : value;
        }
      }

      if (!formattedValue) {
        const rawValue = watchedValues[cid];

        if (dayjs(rawValue).isValid()) {
          formattedValue = dayjs(rawValue).format('MM/DD/YYYY');
        } else if (typeof rawValue === 'object' && rawValue !== null) {
          formattedValue = rawValue.label || rawValue.Name || JSON.stringify(rawValue);
        } else {
          formattedValue = rawValue;
        }
      }

      acc[cid] = formattedValue;
      return acc;
    }, {} as { [key: number]: any });
  }

  // const handleOpen = () => {
  //   // setOpen(true);
  //   // getNotes();
  // }

  const handleClose = () => {
    // setOpen(false);
    updateTicketList({ ticketPrintOpen: false });
    setDomData(null);
    setSelectedTemplate(undefined);
  }

  const getCheckedKeys = (obj: { [key: number]: boolean }): number[] => {
    return Object.keys(obj)
      .filter(key => obj[parseInt(key, 10)] === true)
      .map(key => parseInt(key, 10));
  };

  const handleDownload = async () => {
    setLoading(true);

    const checkedAttachments = getCheckedKeys(checkedFiles);
    const data = getVirtualDOMData();

    try {
      const byteStream = await postPopulatedTemplate(data, checkedAttachments, templateIdWatch?.id);
      const blob = new Blob([byteStream], { type: 'application/pdf' });
      saveAs(blob, `${templateIdWatch?.label} #${ticketId}.pdf`);
    } catch (error) {
      console.error('Error downloading template:', error);
    }

    setLoading(false);
  };

  const handlePrint = async () => {
    setLoading(true);
    const checkedAttachments = getCheckedKeys(checkedFiles);
    const data = getVirtualDOMData();

    try {
      const byteStream = await postPopulatedTemplate(
        data,
        checkedAttachments,
        templateIdWatch?.id
      );

      const blob = new Blob([byteStream], { type: 'application/pdf' });
      const printUrl = URL.createObjectURL(blob);

      // Create a hidden iframe
      const printIframe = document.createElement('iframe');
      printIframe.style.position = 'fixed';
      printIframe.style.right = '0';
      printIframe.style.bottom = '0';
      printIframe.style.width = '0';
      printIframe.style.height = '0';
      printIframe.style.border = 'none';
      printIframe.src = printUrl;

      document.body.appendChild(printIframe);

      printIframe.onload = () => {
        if (printIframe.contentWindow) {
          printIframe.contentWindow.focus();
          printIframe.contentWindow.print();
        }
      };

      const handlePrintCompletion = () => {
        document.body.removeChild(printIframe);
        URL.revokeObjectURL(printUrl);
      };

      // Add an event listener for when the print dialog is closed
      window.addEventListener('afterprint', handlePrintCompletion);

      if (!printIframe) {
        alert('Failed to open print window. Please try again.');
      }
    } catch (error) {
      console.error('Error printing template:', error);
    }

    setLoading(false);
  };

  // const handleOnChange = async (value: any) => {
  //   console.log('value:', value)
  //   setSelectedTemplate(value)
  //   console.log('selectedTemplate:', selectedTemplate)
  //   // console.log('customFieldsByTemplateId?.data:', customFieldsByTemplateId?.data)
  //   grabDOMData(customFieldsByTemplateId?.data?.CustomFieldIds);
  //   // getCidList(value.TemplateId)
  // }

  const handleFileCheckboxChange = (fid: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedFiles((prev) => ({
      ...prev,
      [fid]: event.target.checked,
    }));
  };

  const handleMainCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setMainChecked(checked);
    setMainIndeterminate(false);
    setCheckedFiles((prev) =>
      Object.keys(prev).reduce((acc, key) => {
        acc[parseInt(key)] = checked;
        return acc;
      }, {} as { [key: number]: boolean })
    );
  };

  useEffect(() => {
    const allChecked = Object.values(checkedFiles).every(Boolean);
    const noneChecked = Object.values(checkedFiles).every((value) => !value);

    setMainChecked(allChecked);
    setMainIndeterminate(!allChecked && !noneChecked);
  }, [checkedFiles]);

  useEffect(() => {
    if (attachmentsById.data) {
      const initialCheckedFiles: { [key: number]: boolean } = {};

      attachmentsById.data.forEach((attachment: any) => {
        // note.Filesinfo?.forEach((file: any) => {
        if (attachment?.Fid) {
          initialCheckedFiles[attachment.Fid] = true;
        }
        // });
      });

      // console.log('initialCheckedFiles:', initialCheckedFiles);

      setCheckedFiles(initialCheckedFiles);
    }
  }, [attachmentsById.data]);

  // useEffect(() => {
  //   const printNoticeButton = document.getElementById("print-notice");

  //   if (printNoticeButton) {
  //     printNoticeButton.addEventListener("click", handleOpen);
  //   }

  //   // getTemplates();
  //   // getNotes();
  // }, []);

  return (
    // <MuiThemeProvider theme={createTheme(theme)}>
    <Dialog open={ticketListState.ticketPrintOpen} onClose={handleClose} fullWidth maxWidth="xs">
      {loading && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: 10
        }}>
          <CircularProgress />
        </div>
      )}
      <DialogTitle>
        Print Notice
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <FormControl fullWidth sx={{ pt: 2 }}>
          <AutoComplete
            id="template"
            options={
              templatesByCustomer?.isPending
                ? []
                : templatesByCustomer?.data?.map((data: any) => ({
                  id: data.TemplateId,
                  label: data.TemplateName,
                })) || []
            }
            loading={templatesByCustomer?.isPending}
            autocompleteProps={{
              id: 'template',
              loadingText: 'Loading Templates...',
              size: 'small',
              sx: {
                marginBottom: 4
              }
            }}
            multiple={false}
            label="Select Template..."
          />
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={mainChecked}
              indeterminate={mainIndeterminate}
              onChange={handleMainCheckboxChange}
            />
          }
          label="Include Attachments"
          sx={{ display: 'flex', alignItems: 'center' }}
          className='top-pnd-checkbox'
        />
        <Accordion className='pnd-accordion'
          sx={{
            marginBottom: 4,
            marginTop: 0.5,
            boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;",
            width: "99%",
            borderRadius: 1.5,
            '&.Mui-expanded:first-of-type': { marginTop: 0.5 },
            '&::before': { display: 'none' },
            '&.Mui-expanded': { marginTop: '0px', },
          }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ '::before': { display: 'none' } }}>
            <Typography>Choose...</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
            {attachmentsById?.data?.length > 0 ? (
              attachmentsById?.data?.map((note: any, index: any) =>
                // (Array.isArray(note.Filesinfo) && note.Filesinfo.length > 0) && (
                //   note.Filesinfo.map((file: any, index: any) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={checkedFiles[note.Fid] || false}
                      // checked={true}
                      onChange={handleFileCheckboxChange(note.Fid)}
                    />
                  }
                  label={note.Filename}
                  sx={{ display: 'flex', alignItems: 'center' }}
                  className='pnd-checkbox'
                />
                //   ))
                // )
              )
            ) : (
              <Typography>No Attachments available</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">Close</Button>
        <Button onClick={handleDownload}
          // disabled={!domData}
          variant="contained">Download</Button>
        <Button onClick={handlePrint}
          // disabled={!domData}
          variant="contained">Print</Button>
      </DialogActions>
    </Dialog>
    // </MuiThemeProvider>
  );
};

export default PrintNoticeDialog;
