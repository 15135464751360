import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
  Modal,
  Box,
  IconButton,
  Drawer,
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { MapContainer, TileLayer, LayersControl, useMap, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import L from 'leaflet';
import 'leaflet.locatecontrol';
import 'leaflet.markercluster';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import 'leaflet-lasso';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SnackbarProvider, closeSnackbar, useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import createTheme from '@/constants/theme/index';
import useTheme from '@/hooks/useTheme';
import useTicketList from '@/contexts/stores/ticketList.store';
import '@/styles/map.css';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';

import MarkerClusterGroup from 'react-leaflet-cluster'; // Import marker cluster group
import useTicketMutationsTicketListMap from '@/hooks/fetches/useTicketMutationsTicketListMap.service';
import { createAgentsByCustomerQueryOptions, createAllTicketsQueryOptions, createStatusesByCustomerQueryOptions } from '@/hooks/fetches/useTicket.service';

interface Markers {
  [ticketId: string]: L.Marker;
}

interface LassoFinishedEvent extends L.LeafletEvent {
  latLngs?: L.LatLng[];
  layers?: L.Layer[];
  potato?: string;
}

interface MapMarkersProps {
  tableData: any[];
  setMarkers: (markers: Markers) => void;
  // markerClusterGroupRef: React.MutableRefObject<L.MarkerClusterGroup | null>;
}

interface Agent {
  AgentId: number;
  Name: string;
  RoleName: string;
  Email: string;
}

interface Status {
  StatusId: number;
  Name: string;
  Flags: number;
  Color: number;
}

interface TicketLayer extends L.Layer {
  ticketId?: number;
  assignee?: string;
  status?: string;
}

interface CustomMarker extends L.Marker {
  ticketId?: string;
  status?: string;
  assignee?: string;
}

interface LayerChangeHandlerProps {
  onLayerChange: (name: string) => void;
}

// const MapMarkers = ({ tableData, setMarkers, markerClusterGroupRef }: MapMarkersProps): JSX.Element | null => {
//   const map = useMap();
//   const customIcon = (color: any, criticalPriority: boolean) => {
//     const borderStyle = criticalPriority ? 'stroke-width: 2; stroke: red;' : '';

//     return L.icon({
//       iconUrl: `data:image/svg+xml;charset=UTF-8,
//         <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'>
//           <!-- Outer Border with padding -->
//           <path d='M13 1C8.13 1 4 5.13 4 10c0 5.25 9 15 9 15s9-9.75 9-15c0-4.87-4.13-9-9-9z' style='${borderStyle}' fill='none'/>
//           <!-- Marker -->
//           <path fill='${encodeURIComponent(color)}' d='M13 3C9.13 3 6 6.13 6 10c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S11.62 7.5 13 7.5 15.5 8.62 15.5 10 14.38 12.5 13 12.5z'/>
//         </svg>`,
//       iconSize: [41, 41],
//       iconAnchor: [20.5, 41],
//       popupAnchor: [1, -41],
//     });
//   };

//   useEffect(() => {
//     const markerMap: Markers = {};
//     const bounds = L.latLngBounds([]);

//     const markerClusterGroup = L.markerClusterGroup();
//     markerClusterGroupRef.current = markerClusterGroup;

//     tableData.forEach((data: any) => {
//       const date = data.original.TicketExt.Ticket.DueDate;
//       const ticketId = data.original.TicketExt.Ticket.TId;
//       const latitude = data.original.TicketExt.Latitude;
//       const longitude = data.original.TicketExt.Longitude;
//       const address = data.original.TicketExt.Ticket.ClientAddress;
//       const markerColor = '#' + data.original.TicketExt.Status.Color.toString(16).padStart(8, '0');
//       const criticalPriority = data.original.TicketExt.Ticket.Priority == 'critical' ? true : false;
//       const status = data.original.TicketExt.Status.Name;
//       const assignee = data.original.TicketExt.Assignee.Name;

//       let formattedDate = '';
//       let dateStyle = {};

//       if (date.trim().toLowerCase() !== '0001-01-01t00:00:00z') {
//         const currentDate = dayjs();
//         const dueDate = dayjs(date);

//         formattedDate = dueDate.format('MM/DD/YY');

//         if (dueDate.isBefore(currentDate)) {
//           dateStyle = 'red';
//         }
//       }

//       if (latitude != null && longitude != null && latitude !== 0 && longitude !== 0) {
//         const marker = L.marker([latitude, longitude], { icon: customIcon(markerColor, criticalPriority) });

//         (marker as any).ticketId = ticketId;
//         (marker as any).status = status;
//         (marker as any).assignee = assignee;

//         const popupContent = `
//           <div>
//             <p><b>Ticket Id:</b> <a href="ticket.html?TicketId=${ticketId}">${ticketId}</a></p>
//             <p><b>Address:</b> ${address}</p>
//             <p><b>Due Date:</b> <span style="color: ${dateStyle || 'black'};">${formattedDate}</span></p>
//             <p><b>Assigned To:</b> ${assignee}</p>
//             <p><b>Status:</b> ${status}</p>
//           </div>`;
//         marker.bindPopup(popupContent);

//         markerMap[ticketId] = marker;
//         bounds.extend(marker.getLatLng());

//         markerClusterGroup.addLayer(marker);
//       }
//     });

//     map.addLayer(markerClusterGroup);

//     if (bounds.isValid()) {
//       map.fitBounds(bounds, { padding: [50, 50] });
//     }
//     if (!bounds.isValid()) {
//       alert('No coordinates found');
//       map.setView([40.235077, -99.121838], 5);
//     }

//     setMarkers(markerMap);

//     return () => {
//       map.removeLayer(markerClusterGroup);
//     };
//   }, [tableData, map, setMarkers, markerClusterGroupRef]);

//   return null;
// };

// const MapMarkers = ({ tableData }: MapMarkersProps): JSX.Element | null => {
//   const bounds = L.latLngBounds([]);
//   const map = useMap();

//   const intToRGB = (colorInt: number) => {
//     const b = (colorInt >> 16) & 255;
//     const g = (colorInt >> 8) & 255;
//     const r = colorInt & 255;
//     return `rgb(${r}, ${g}, ${b})`;
//   };

//   const customIcon = (color: string, criticalPriority: boolean) => {
//     const borderStyle = criticalPriority ? 'stroke-width: 2; stroke: red;' : '';
//     return L.icon({
//       iconUrl: `data:image/svg+xml;charset=UTF-8,
//         <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'>
//           <!-- Outer Border -->
//           <path d='M13 1C8.13 1 4 5.13 4 10c0 5.25 9 15 9 15s9-9.75 9-15c0-4.87-4.13-9-9-9z' style='${borderStyle}' fill='none'/>
//           <!-- Marker -->
//           <path fill='${encodeURIComponent(color)}' d='M13 3C9.13 3 6 6.13 6 10c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S11.62 7.5 13 7.5 15.5 8.62 15.5 10 14.38 12.5 13 12.5z'/>
//         </svg>`,
//       iconSize: [41, 41],
//       iconAnchor: [20.5, 41],
//       popupAnchor: [1, -41],
//     });
//   };

//   // Add clustering with JSX-based markers
//   return (
//     <>

//       <MarkerClusterGroup chunkedLoading>
//         {tableData.map((data) => {
//           const {
//             TId: ticketId,
//             Latitude: latitude,
//             Longitude: longitude,
//             ClientAddress: address,
//             DueDate: dueDate,
//             Priority: priority,
//             Status: { Color: markerColor, Name: status },
//             Assignee: { Name: assignee },
//           } = data.original.TicketExt;

//           const isCriticalPriority = priority === 'critical';
//           const formattedDate = dueDate && dayjs(dueDate).isValid()
//             ? dayjs(dueDate).format('MM/DD/YY')
//             : '';
//           const dateStyle = dayjs(dueDate).isBefore(dayjs()) ? { color: 'red' } : {};

//           if (latitude && longitude && latitude !== 0 && longitude !== 0) {
//             bounds.extend([latitude, longitude]);

//             return (
//               <Marker
//                 key={ticketId}
//                 position={[latitude, longitude]}
//                 icon={customIcon(`#${markerColor.toString(16).padStart(6, '0')}`, isCriticalPriority)}
//               >
//                 <Popup>
//                   <div className="marker-popup">
//                     <p>
//                       <b>Ticket Id:</b>{' '}
//                       <Link
//                         to={`/ticket/${ticketId}`}
//                         params={{ ticketId }}
//                       >
//                         {ticketId}
//                       </Link>
//                     </p>
//                     <p><b>Address:</b> {address}</p>
//                     <p>
//                       <b>Due Date:</b>{' '}
//                       <span style={dateStyle}>{formattedDate}</span>
//                     </p>
//                     <p><b>Assigned To:</b> {assignee}</p>
//                     <p><b>Status:</b> {status}</p>
//                   </div>
//                 </Popup>
//               </Marker>
//             );
//           }

//           return null;
//         })}
//       </MarkerClusterGroup>
//       {bounds.isValid() && map.fitBounds(bounds, { padding: [50, 50] })}
//     </>
//   );
// };



const MapMarkers = ({ tableData, setMarkers }: MapMarkersProps): JSX.Element | null => {
  const map = useMap(); // Get access to the map instance
  const markerRefs = useRef<Record<string, L.Marker>>({}); // Store refs for each marker
  const { updateTicketList } = useTicketList();

  const customIcon = (color: string, criticalPriority: boolean) => {
    const borderStyle = criticalPriority ? 'stroke-width: 2; stroke: red;' : '';
    return L.icon({
      iconUrl: `data:image/svg+xml;charset=UTF-8,
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'>
          <path d='M13 1C8.13 1 4 5.13 4 10c0 5.25 9 15 9 15s9-9.75 9-15c0-4.87-4.13-9-9-9z' style='${borderStyle}' fill='none'/>
          <path fill='${encodeURIComponent(color)}' d='M13 3C9.13 3 6 6.13 6 10c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S11.62 7.5 13 7.5 15.5 8.62 15.5 10 14.38 12.5 13 12.5z'/>
        </svg>`,
      iconSize: [41, 41],
      iconAnchor: [20.5, 41],
      popupAnchor: [1, -41],
    });
  };

  useEffect(() => {
    const markerMap: Record<string, L.Marker> = {};
    const bounds = L.latLngBounds([]);

    tableData.forEach((data) => {
      const {
        Latitude: latitude,
        Longitude: longitude,
        Status: { Color: markerColor, Name: status },
        Assignee: { Name: assignee },
      } = data.original.TicketExt;

      const {
        TId: ticketId,
        ClientAddress: address,
        DueDate: dueDate,
        Priority: priority,
      } = data.original.TicketExt.Ticket;

      if (latitude && longitude && latitude !== 0 && longitude !== 0) {
        const isCriticalPriority = priority === 'critical';
        const formattedDate = dueDate && dayjs(dueDate).isValid() && dueDate !== '0001-01-01T00:00:00Z'
          ? dayjs(dueDate).format('MM/DD/YY')
          : '';
        const dateStyle = dayjs(dueDate).isBefore(dayjs()) ? { color: 'red' } : {};

        // Create the marker
        const marker = L.marker([latitude, longitude], {
          icon: customIcon(`#${markerColor.toString(16).padStart(6, '0')}`, isCriticalPriority),
        });

        (marker as any).ticketId = ticketId;
        (marker as any).status = status;
        (marker as any).assignee = assignee;

        // Bind the popup to the marker
        // const popupContent = `
        //   <div class="marker-popup123">
        //     <p><b>Ticket Id:</b> ${ticketId}</p>
        //     <p><b>Address:</b> ${address}</p>
        //     <p><b>Due Date:</b> <span style="color: ${dateStyle.color || 'black'};">${formattedDate}</span></p>
        //     <p><b>Assigned To:</b> ${assignee}</p>
        //     <p><b>Status:</b> ${status}</p>
        //   </div>
        // `;
        // marker.bindPopup(popupContent);

        // Add marker to markerMap
        markerMap[ticketId] = marker;
        bounds.extend(marker.getLatLng());
      }
    });

    // Update markerMap state
    setMarkers(markerMap);

    // Adjust map bounds
    if (bounds.isValid()) {
      map.fitBounds(bounds, { padding: [50, 50] });
    } else {
      map.setView([40.235077, -99.121838], 5); // Default fallback center
    }
  }, [tableData, map, setMarkers]);

  const markers = tableData.map((data) => {
    const {
      Latitude: latitude,
      Longitude: longitude,
      Status: { Color: markerColor, Name: status },
      Assignee: { Name: assignee },
    } = data.original.TicketExt;

    const {
      TId: ticketId,
      ClientAddress: address,
      DueDate: dueDate,
      Priority: priority,
    } = data.original.TicketExt.Ticket;

    if (latitude && longitude && latitude !== 0 && longitude !== 0) {
      const isCriticalPriority = priority === 'critical';
      const formattedDate = dueDate && dayjs(dueDate).isValid() && dueDate !== '0001-01-01T00:00:00Z'
        ? dayjs(dueDate).format('MM/DD/YY')
        : '';
      const dateStyle = dayjs(dueDate).isBefore(dayjs()) ? { color: 'red' } : {};

      return (
        <Marker
          key={ticketId}
          position={[latitude, longitude]}
          icon={customIcon(`#${markerColor.toString(16).padStart(6, '0')}`, isCriticalPriority)}
          ref={(ref) => {
            if (ref) {

              const marker = ref as CustomMarker;
              marker.ticketId = ticketId;
              marker.status = status;
              marker.assignee = assignee;
              markerRefs.current[ticketId] = marker;
            } // Store ref in markerRefs
          }}
        >
          <Popup>
            <div className="marker-popup">
              <p>
                <b>Ticket Id:</b>{' '}
                <Link
                  to={`/ticket/${ticketId}`}
                  params={{ ticketId }}
                  onClick={(e) => {
                    updateTicketList({ ticketListMapOpen: false });
                    e.defaultPrevented || null;
                  }}
                >
                  {ticketId}
                </Link>
              </p>
              <p><b>Address:</b> {address}</p>
              <p>
                <b>Due Date:</b>{' '}
                <span style={dateStyle}>{formattedDate}</span>
              </p>
              <p><b>Assigned To:</b> {assignee}</p>
              <p><b>Status:</b> {status}</p>
            </div>
          </Popup>
        </Marker>
      );
    }

    return null;
  });

  return (
    <MarkerClusterGroup chunkedLoading>
      {markers}
    </MarkerClusterGroup>
  );
};


const TicketListMap = ({ table }: any) => {
  const [open, setOpen] = useState(false);
  const mapRef = useRef<L.Map>(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const [markers, setMarkers] = useState<Markers>({});
  const [searchInput, setSearchInput] = useState('');
  const markerClusterGroupRef = useRef<L.MarkerClusterGroup | null>(null);
  const { BaseLayer } = LayersControl;
  const [mapDrawerOpen, setMapDrawerOpen] = useState<any>(false);
  const drawerAnchorRef = useRef(null);
  const modalRef = useRef(null);
  const [lassoedMarkers, setLassoedMarkers] = useState<any[]>([]);
  const lassoPolygonRef = useRef<L.Polygon | null>(null);
  const [lassoPolygonExists, setLassoPolygonExists] = useState(false);
  const lassoControlRef = useRef<L.Control.Lasso | null>(null);
  const locateControlRef = useRef<L.Control.Locate | null>(null);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<Status | null>(null);
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth >= 1024);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>('');
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<any>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { theme } = useTheme();
  const savedLayer = localStorage.getItem('selectedLayer') || 'Google Hybrid';
  const [selectedLayer, setSelectedLayer] = useState(savedLayer);
  const { ticketListState, updateTicketList } = useTicketList();
  const queryClient = useQueryClient();

  const allTickets = useQuery(createAllTicketsQueryOptions(ticketListState?.selectedCustomer?.id, ticketListState?.selectedCategory?.label))
  const agentsByCustomer = useQuery(createAgentsByCustomerQueryOptions(ticketListState?.selectedCustomer?.id))
  const statusesByCustomer = useQuery(createStatusesByCustomerQueryOptions(ticketListState?.selectedCustomer?.id))

  const { updateTicketListMapTickets } = useTicketMutationsTicketListMap();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    updateTicketList({ ticketListMapOpen: false });
    locateControlRef.current = null;
    lassoControlRef.current = null;
    lassoPolygonRef.current = null;
    setLassoPolygonExists(false);
    setLassoedMarkers([]);
    setMapDrawerOpen(false);
    setSelectedAgent(null);
    setSelectedStatus(null);
    setMapDrawerOpen(false);
  };

  const handleDrawerOpen = () => {
    if (!mapDrawerOpen) {
      setMapDrawerOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setMapDrawerOpen(false);
  };

  const handleDrawerSave = async () => {
    const oldTableData = tableData;
    const oldAllTickets = queryClient.getQueryData([
      'all-tickets',
      ticketListState?.selectedCustomer?.id,
      ticketListState?.selectedCategory?.label,
    ]);

    if (selectedAgent || selectedStatus) {
      // await postAgentandStatusData()
      const statusId = selectedStatus ? selectedStatus.StatusId : null;
      const assigneeId = selectedAgent ? selectedAgent.AgentId : null;
      const requestBody = lassoedMarkers.map((marker) => ({
        TicketID: marker.ticketId,
        Assignee: assigneeId,
        Status: statusId,
      }));

      try {
        const updatedTicketsForTable = handleOptimisiticTicketUpdate(
          tableData,
          requestBody,
          selectedStatus,
          selectedAgent,
          true, // Indicates that `tableData` tickets have `.original`
        );
        setTableData(updatedTicketsForTable);

        queryClient.setQueryData(
          ['all-tickets', ticketListState?.selectedCustomer?.id, ticketListState?.selectedCategory?.label],
          (oldTickets: any) => {
            return handleOptimisiticTicketUpdate(
              oldTickets,
              requestBody,
              selectedStatus,
              selectedAgent,
              false, // Indicates that `oldTickets` do not have `.original`
            );
          },
        );

        await updateTicketListMapTickets({ actionType: 'updateTicketListMap', data: requestBody });
      } catch (error: any) {
        setTableData(oldTableData);
        queryClient.setQueryData(
          ['all-tickets', ticketListState?.selectedCustomer?.id, ticketListState?.selectedCategory?.label],
          oldAllTickets,
        );
      }
    }
    removeLasso();
  };

  const handleOptimisiticTicketUpdate = (
    tickets: any[],
    requestBody: any[],
    selectedStatus: any,
    selectedAgent: any,
    isOriginalNested: boolean,
  ) => {
    return tickets.map((ticket: any) => {
      const ticketExt = isOriginalNested ? ticket.original.TicketExt : ticket.TicketExt;

      const updatedTicket = requestBody.find((update: any) => update.TicketID === ticketExt.Ticket.TId);

      if (updatedTicket) {
        return {
          ...ticket,
          ...(isOriginalNested && {
            original: {
              TicketExt: {
                ...ticket.original.TicketExt,
                Ticket: {
                  ...ticket.original.TicketExt.Ticket,
                  StatusId: updatedTicket.Status,
                  Assignee: updatedTicket.Assignee,
                },
                Status: {
                  ...ticket.original.TicketExt.Status,
                  StatusId: updatedTicket.Status,
                  Name: selectedStatus ? selectedStatus.Name : ticket.original.TicketExt.Status.Name,
                  Color: selectedStatus ? selectedStatus.Color : ticket.original.TicketExt.Status.Color,
                },
                Assignee: {
                  ...ticket.original.TicketExt.Assignee,
                  AgentId: updatedTicket.Assignee,
                  Name: selectedAgent ? selectedAgent.Name : ticket.original.TicketExt.Assignee.Name,
                },
              },
            },
          }),
          ...(!isOriginalNested && {
            // For the non-original case
            TicketExt: {
              ...ticketExt,
              Ticket: {
                ...ticketExt.Ticket,
                StatusId: updatedTicket.Status,
                Assignee: updatedTicket.Assignee,
              },
              Status: {
                ...ticketExt.Status,
                StatusId: updatedTicket.Status,
                Name: selectedStatus ? selectedStatus.Name : ticketExt.Status.Name,
                Color: selectedStatus ? selectedStatus.Color : ticketExt.Status.Color,
              },
              Assignee: {
                ...ticketExt.Assignee,
                AgentId: updatedTicket.Assignee,
                Name: selectedAgent ? selectedAgent.Name : ticketExt.Assignee.Name,
              },
            },
          }),
        };
      }
      return ticket;
    });
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const removeLasso = () => {
    if (lassoPolygonRef.current && mapRef.current) {
      mapRef.current.removeLayer(lassoPolygonRef.current);
      lassoPolygonRef.current = null;
    }
    setLassoPolygonExists(false);
    if (mapDrawerOpen) {
      setMapDrawerOpen(false);
    }
  };

  const searchByTicketId = (ticketId: string) => {
    setSelectedAgent(null);
    setSelectedStatus(null);
    const marker = markers[ticketId];
    if (marker) {
      const map = mapRef.current;
      if (map) {
        map.flyTo(marker.getLatLng(), 20, {
          animate: true,
          duration: 1,
        });
        setTimeout(() => {
          marker.openPopup();
          if (markerClusterGroupRef.current) {
            const cluster = markerClusterGroupRef.current.getVisibleParent(marker);
            if (cluster && cluster instanceof L.MarkerCluster && cluster.getChildCount() > 1) {
              markerClusterGroupRef.current.zoomToShowLayer(marker, () => {
                marker.openPopup();
              });
            }
          }
          setMapDrawerOpen(true);
          setLassoedMarkers([marker]);
          setLassoPolygonExists(true);
        }, 1200);
      } else {
        console.error('Map is not fully initialized.');
      }
    } else {
      alert('Ticket ID not found on map.');
    }
  };

  const addLocateControl = (map: L.Map, locateControlRef: React.MutableRefObject<L.Control.Locate | null>) => {
    if (!locateControlRef.current) {
      const locateControl = L.control.locate().addTo(map);
      locateControlRef.current = locateControl;
    }
  };

  const addLassoControl = (map: L.Map, lassoControlRef: React.MutableRefObject<L.Control.Lasso | null>) => {
    if (!lassoControlRef.current) {
      const lassoControl = L.control.lasso({ position: 'topleft' }).addTo(map);
      lassoControlRef.current = lassoControl;
    }
  };

  const handleLassoFinished = (event: LassoFinishedEvent) => {
    const validLayers = event.layers?.filter((layer: TicketLayer) => layer.ticketId !== undefined);

    if (validLayers && validLayers.length > 0) {
      setLassoedMarkers(validLayers);
    } else {
      setLassoedMarkers([]);
    }

    if (lassoPolygonRef.current && mapRef) {
      removeLasso();
    }

    if (event.latLngs && mapRef.current) {
      const polygon = L.polygon(event.latLngs, {
        color: 'rgb(16, 132, 255)',
        fillColor: 'rgba(16, 132, 255, 0.6)',
        fillOpacity: 0.2,
      }).addTo(mapRef.current);

      lassoPolygonRef.current = polygon;
      setLassoPolygonExists(true);
    }

    handleDrawerOpen();
  };

  const getColor = (colorValue: number) => {
    const color = `#${(colorValue >>> 8).toString(16).padStart(6, '0')}`;
    return color;
  };

  const getBrightness = (color: any) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const getTextColor = (backgroundColor: any) => {
    return getBrightness(backgroundColor) > 186 ? 'black' : 'white';
  };

  const loadingSpinner = () => {
    return (
      <Box sx={{ display: 'flex', zIndex: 2000, position: 'absolute', top: '50%', left: '50%' }}>
        <CircularProgress sx={{ color: 'rgb(255, 193, 7)' }} />
      </Box>
      // <Spinner style={{ zIndex: 2000, width: '3rem', height: '3rem' }} className="spinner-border text-warning" animation="border" role="status">
      //   <span className="visually-hidden">Loading...</span>
      // </Spinner>
    );
  };

  const LayerChangeHandler = ({ onLayerChange }: LayerChangeHandlerProps): null => {
    const map = useMap();

    useEffect(() => {
      const handleLayerChange = (e: any) => {
        if (e.name) {
          onLayerChange(e.name);
        }
      };

      map.on('baselayerchange', handleLayerChange);

      return () => {
        map.off('baselayerchange', handleLayerChange);
      };
    }, [map, onLayerChange]);

    return null;
  };

  const handleLayerChange = (name: any) => {
    setSelectedLayer(name); // Update the state with the new layer name
  };

  const getTableData = () => {
    const tableRows = table.getFilteredRowModel().rows;
    if (tableRows !== undefined) {
      setTableData(tableRows);
    }
    setSelectedAgent(null);
    setSelectedStatus(null);
    setMapDrawerOpen(false);
    setLassoedMarkers([]);
    // setLoading(false)
  };

  const postAgentandStatusData = async () => {
    if (lassoedMarkers.length === 0) {
      return;
    }

    const statusId = selectedStatus ? selectedStatus.StatusId : null;
    const assigneeId = selectedAgent ? selectedAgent.AgentId : null;
    const requestBody = lassoedMarkers.map((marker) => ({
      TicketID: marker.ticketId,
      Assignee: assigneeId,
      Status: statusId,
    }));

    try {
      const response = await axios.post(`www/uapi/ticket/ticketmapupdate`, requestBody);

      enqueueSnackbar('Sucessful Save');
      setSnackBarMessage('Sucessful Save');
      setShowSnackBar(true);
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackBarMessage('Unsuccessful Save');
      setShowSnackBar(true);
      setSnackbarSeverity('error');
    }
  };

  const MemoTicketListMap = useMemo(() => {
    return (
      <MapContainer
        center={[40.235077, -99.121838]}
        zoom={5}
        ref={mapRef}
        style={{ height: '100%', width: '100%' }}
        maxZoom={20}
      >
        <Snackbar
          open={showSnackBar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={5000}
          onClose={() => setShowSnackBar(false)}
        >
          <Alert variant="filled" severity={snackbarSeverity}>
            {snackBarMessage}
          </Alert>
        </Snackbar>

        <Box
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            margin: '12px 72px 0 0',
          }}
        >
          <input
            type="number"
            id="map-search-input"
            placeholder="Search Ticket ID"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                searchByTicketId(searchInput);
              }
            }}
            onFocus={() => mapRef.current?.dragging.disable()}
            onBlur={() => mapRef.current?.dragging.enable()}
            inputMode="numeric" // show numpad on mobile
            style={{
              marginRight: '8px',
              background: 'white',
              border: '1px solid #ced4da',
              color: 'black',
              fontSize: '16px',
              height: '33px',
            }}
          />
          <Button
            variant="contained"
            onClick={() => searchByTicketId(searchInput)}
          // className="mdc-button"
          >
            Search
          </Button>
        </Box>
        <LayersControl position="topright">
          <BaseLayer checked={selectedLayer === 'Google Hybrid'} name="Google Hybrid">
            <TileLayer url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" maxZoom={20} />
          </BaseLayer>
          <BaseLayer checked={selectedLayer === 'Google Satellite'} name="Google Satellite">
            <TileLayer url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" maxZoom={20} />
          </BaseLayer>
          <BaseLayer checked={selectedLayer === 'OpenStreetMap'} name="OpenStreetMap">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={20} />
          </BaseLayer>
        </LayersControl>
        <LayerChangeHandler onLayerChange={handleLayerChange} />
        {open && (
          // <MapMarkers
          //   tableData={tableData}
          //   setMarkers={setMarkers}
          //   markerClusterGroupRef={markerClusterGroupRef}
          // />
          <MapMarkers tableData={tableData} setMarkers={setMarkers} />

        )}
      </MapContainer>
    )
  }, [tableData, selectedLayer, ticketListState.ticketListMapOpen, searchInput])

  useEffect(() => {
    getTableData();
  }, [table]);

  useEffect(() => {
    if (ticketListState.ticketListMapOpen) {
      handleOpen();
    }
  }, [ticketListState.ticketListMapOpen]);

  useEffect(() => {
    localStorage.setItem('selectedLayer', selectedLayer);
  }, [selectedLayer]);

  useEffect(() => {
    const leafletLeftElements = Array.from(document.getElementsByClassName('leaflet-left'));

    leafletLeftElements.forEach((element: Element) => {
      if (mapDrawerOpen && isDesktop) {
        (element as HTMLElement).style.left = '340px';
      } else {
        (element as HTMLElement).style.left = '';
      }
    });
  }, [mapDrawerOpen, isDesktop]);

  useEffect(() => {
    if (modalRef.current) {
      drawerAnchorRef.current = modalRef.current;
    }
  }, [open]);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      addLocateControl(map, locateControlRef);
      addLassoControl(map, lassoControlRef);

      map.on('lasso.finished', (event: LassoFinishedEvent) => handleLassoFinished(event));
    }
  }, [markers]);

  useEffect(() => {
    const mapButton = document.getElementById('map-tickets-btn');
    const customerDropdown = document.getElementById('customer-dropdown');

    const handleDataChange = () => {
      if (mapButton) {
        const interval = setInterval(() => {
          // const workflowRulesData = ($("#workflowRules") as any).bootstrapTable("getData");
          // if (Array.isArray(workflowRulesData)) {
          //   mapButton.removeAttribute('disabled');
          //   mapButton.addEventListener("click", handleOpen);
          //   clearInterval(interval);
          // }
        }, 500);
      }
    };
    handleDataChange();

    const handleDropdownChange = () => {
      mapButton?.setAttribute('disabled', 'true');
      handleDataChange();
    };

    if (customerDropdown) {
      customerDropdown.addEventListener('change', handleDropdownChange);
    }

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    // $("#workflowRules").on('post-body.bs.table', getTableData);
    getTableData();

    return () => {
      if (customerDropdown) {
        customerDropdown.removeEventListener('change', handleDropdownChange);
      }
    };
  }, []);

  return (
    <>
      <MuiThemeProvider theme={createTheme(theme)}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            id="modal-container"
            ref={modalRef}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
              height: '90%',
              bgcolor: 'background.paper',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* {loading && loadingSpinner()} */}
            <Tooltip title="Close" placement="bottom">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 10,
                  color: 'black',
                  zIndex: '1000',
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  width: '48px',
                  height: '48px',
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                  border: '2px solid rgba(0, 0, 0, 0.2)',
                  backgroundClip: 'padding-box',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            {lassoPolygonExists && (
              <Tooltip title="Open Drawer" placement="bottom">
                <IconButton
                  aria-label="drawer-open"
                  onClick={handleDrawerOpen}
                  sx={{
                    position: 'absolute',
                    right: 10,
                    top: 126,
                    color: 'black',
                    zIndex: '1000',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    width: '48px',
                    height: '48px',
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                    border: '2px solid rgba(0, 0, 0, 0.2)',
                    backgroundClip: 'padding-box',
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>
            )}
            {lassoPolygonExists && (
              <Tooltip title="Clear Lasso" placement="bottom">
                <IconButton
                  aria-label="drawer-open"
                  onClick={removeLasso}
                  sx={{
                    position: 'absolute',
                    // left: 10,
                    marginLeft: '10px',
                    top: 172,
                    color: 'black',
                    zIndex: '1000',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    width: '34px',
                    height: '34px',
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                    border: '2px solid rgba(0, 0, 0, 0.2)',
                    backgroundClip: 'padding-box',
                  }}
                  className={'leaflet-left'}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            <Box sx={{ flex: 1, position: 'relative' }}>
              {MemoTicketListMap}
            </Box>
            <Drawer
              anchor={isDesktop ? 'left' : 'bottom'}
              open={mapDrawerOpen}
              keepMounted
              container={modalRef.current}
              onClose={handleDrawerClose}
              PaperProps={{
                sx: {
                  height: isDesktop ? '100%' : '35%',
                  pointerEvents: 'auto',
                  width: isDesktop ? '340px' : '100%',
                },
              }}
              slotProps={{
                backdrop: { style: { backgroundColor: 'transparent' } },
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  padding: '16px',
                  zIndex: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }}
                role="presentation"
              >
                <Box sx={{ marginBottom: '16px' }}>
                  <Autocomplete
                    options={agentsByCustomer?.data?.VisibleAgents}
                    getOptionLabel={(option) => option.Name}
                    value={selectedAgent}
                    onChange={(event, newValue) => setSelectedAgent(newValue)}
                    renderInput={(params) => <TextField {...params} label="Assigned To" />}
                    style={{ width: '95%' }}
                    disabled={agentsByCustomer?.data?.VisibleAgents.length === 0}
                    size="small"
                  />
                  <Autocomplete
                    options={statusesByCustomer?.data}
                    getOptionLabel={(option) => option.Name}
                    value={selectedStatus}
                    onChange={(event, newValue) => setSelectedStatus(newValue)}
                    renderInput={(params) => {
                      const backgroundColor = selectedStatus ? getColor(selectedStatus.Color) : 'white';
                      const textColor = selectedStatus ? getTextColor(backgroundColor) : 'black';

                      return (
                        <TextField
                          {...params}
                          label="Status"
                          sx={{
                            '& .MuiOutlinedInput-input': {
                              // other styles can go here
                              backgroundColor: `${backgroundColor} !important`,
                              color: `${textColor} !important`,
                              borderRadius: '4px',
                            },
                          }}
                        // InputProps={{
                        //   style: {
                        //     backgroundColor: `${backgroundColor}`,
                        //     color: `${textColor}`,
                        //   }
                        // }}
                        />
                      );
                    }}
                    sx={{
                      width: '95%',
                      marginTop: '8px',
                    }}
                    disabled={statusesByCustomer?.data?.length === 0}
                    size="small"
                  />
                </Box>
                {lassoedMarkers &&
                  lassoedMarkers.map((marker, index) => (
                    <Accordion sx={{ marginTop: '8px', width: '95%' }} key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Box>Ticket Id: {marker.ticketId}</Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          <Box>
                            Ticket Id:{' '}
                            <Link
                              to={`/ticket/${marker.ticketId}`}
                              params={marker.ticketId}
                              onClick={(e) => {
                                updateTicketList({ ticketListMapOpen: false });
                                e.defaultPrevented || null;
                              }}
                            >
                              {marker.ticketId}
                            </Link>
                          </Box>
                          <Box>Assigned To: {marker.assignee}</Box>
                          <Box>Status: {marker.status}</Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                <Box
                  sx={{
                    marginTop: '10px',
                    paddingBottom: '10px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '8px',
                  }}
                >
                  <Button variant="outlined" onClick={handleDrawerClose} className="mdc-button--outlined">
                    Close
                  </Button>
                  <Button variant="contained" onClick={handleDrawerSave} disabled={loading} className="mdc-button">
                    Save
                  </Button>
                </Box>
              </Box>
            </Drawer>
          </Box>
        </Modal>
      </MuiThemeProvider>
    </>
  );
};

// @ts-ignore
// window.R_TicketListMap = {
//   init: (selector: string) => {
//     const domContainer = document.querySelector(`#${selector}`);
//     if (domContainer) {
//       const root = ReactDOM.createRoot(domContainer!);
//       root.render(<TicketListMap />);
//     }
//   },
// };

export default TicketListMap;
