import { useState, useCallback } from 'react';
import { ZodSchema } from 'zod';

export const useFormValidation = <T extends object>(schema: ZodSchema<T>) => {
  const [errors, setErrors] = useState<Partial<Record<keyof T, string>>>({});

  const validate = useCallback((data: T) => {
    const result = schema.safeParse(data);
    if (!result.success) {
      const formattedErrors: Partial<Record<keyof T, string>> = {};
      result.error.errors.forEach((error) => {
        const path = error.path[0] as keyof T;
        formattedErrors[path] = error.message;
      });
      setErrors(formattedErrors);
      return false;
    }
    setErrors({});
    return true;
  }, [schema]);

  return { errors, validate };
};
