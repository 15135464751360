import * as React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import FieldGroupDrawer from '@/app/pages/FieldGroups/FieldGroupDrawer'

export const Route = createFileRoute('/_auth/field-groups/$fieldGroupId')({
  component: () => <FieldGroupDrawer />,
  params: {
    parse: (params) => ({
      fieldGroupId: z.number().int().parse(Number(params.fieldGroupId)),
    }),
    stringify: ({ fieldGroupId }) => ({ fieldGroupId: `${fieldGroupId}` }),
  },
  validateSearch: (search) => {
    return z
      .object({
        variant: z.enum(['edit', 'new']).default('edit'),
        // step: z.number().min(0).max(1).default(0),
        // Based on your steps array length
        // showSecondaryContact: z.boolean().default(false)
      })
      .parse(search)
  },
})
