import { apiService } from './api.service';
import { BASE_UAPI_URL } from '@/constants/commonStrings.constant';
import { AxiosRequestConfig } from 'axios';

const CustomFieldService = () => {
  const archiveRestoreCustomField = async (params: any) => {
    console.log({ params });
    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL + `/customfields?${params.action}=` + params?.Cid,
      data: {},
    });
    return response;
  };

  const postCustomField = async (params: any, config?: Partial<AxiosRequestConfig>): Promise<any> => {
    const requestId = `postCreateStatus_${Date.now()}`;
    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/customfields/tickettypelist',
      data: params,
      ...config,
      id: requestId,
    });
    return response;
  };

  const postCustomFieldTypes = async (params: any, config?: Partial<AxiosRequestConfig>): Promise<any> => {
    const requestId = `postCreateStatus_${Date.now()}`;
    console.log({ params });
    const { Cid, CustomerID, data } = params;
    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL + `/customfields/tickettypelist?customerid=${CustomerID}&cfieldid=${Cid}`,
      data: data,
      ...config,
      id: requestId,
    });
    return response;
  };

  return {
    postCustomField,
    archiveRestoreCustomField,
    postCustomFieldTypes,
  };
};

export default CustomFieldService;
