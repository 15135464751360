import TicketDetail from '@/app/pages/TicketDetail/TicketDetail.page'
import { createCustomFieldGroupsQueryOptions, createStatusesByCustomerQueryOptions, createTicketByIdQueryOptions, createTicketCustomFieldsQueryOptions } from '@/hooks/fetches/useTicket.service'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

export const Route = createFileRoute('/_auth/ticket/$ticketId')({
  component: () => <TicketDetailLayout />,
  params: {
    parse: (params) => ({
      ticketId: z.number().int().parse(Number(params.ticketId)),
    }),
    stringify: ({ ticketId }) => ({ ticketId: `${ticketId}` }),
  },
  // beforeLoad: async ({ params, context }) => {
  //   const { ticketId } = params;
  //   context.queryClient.prefetchQuery(createTicketByIdQueryOptions(ticketId));
  //   context.queryClient.prefetchQuery(createStatusesByCustomerQueryOptions(ticketId));
  //   context.queryClient.prefetchQuery(createTicketCustomFieldsQueryOptions(ticketId));
  //   context.queryClient.prefetchQuery(createCustomFieldGroupsQueryOptions(ticketId));
  // },
})

const TicketDetailLayout = () => {
  return (
    <DashboardLayout>
      <TicketDetail />
    </DashboardLayout>
  )
}
