
import { TextEditor } from "mui-tiptap-editor";
import { Control, Controller } from "react-hook-form";

interface IEmailEditor {
  name: string;
  control: Control;
  label?: string;
  initialContent?: string;
  placeHolder?: string;
}

const EmailEditor = (props: IEmailEditor) => {

  const { initialContent, control, placeHolder, name, label } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={initialContent}
      render={({ field }: any) => (
        <TextEditor
          {...field}
          id={name}
          disableTabs
          content={initialContent ?? ''}
          placeholder={placeHolder}
          toolbar={[
            "ai",
            'align',
            'bold',
            'bulletList',
            'color',
            'heading',
            'history',
            'italic',
            'link',
            'orderedList',
            'strike',
            'underline',
            'table',
          ]}
          label={label ?? ""}
        />
      )}
    />
  );
}

export default EmailEditor