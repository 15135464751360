import { BASE_UAPI_URL } from '@/constants/commonStrings.constant';
import { apiService } from './api.service';
import { AxiosRequestConfig } from 'axios';
import { z } from 'zod';

export type PostLinkedCustomers = {
  CustomerId: number;
  CustomerName: string;
};

export type PostCreateStatusParams = {
  Status: {
    Name: string;
    Color: number;
    Flags: number;
    StatusID: number;
  };
  CustomerList: number[] | [];
};

export type UpdateStatusParams = {
  Status: {
    Name: string;
    Color: number;
    Flags: number;
    StatusID: number;
  };
  CustomerList: number[] | [];
};

export type UpdateSortStatusParams = {
  customerId:number ;
  getStatusIds: number[] | [];
}

export const postCreateStatusParams = z.object({
  Status: z.object({
    Name: z.string().min(1, { message: 'This field is required.' }),
    Color: z.number().nonnegative({ message: 'This field is required.' }),
    Flags: z.number(),
    StatusID: z.number(),
  }),
  CustomerList: z.array(z.number()),
});

const StatusService = () => {
  const getStatuses = async ({ onSuccess, onError }: { onSuccess?: Function; onError?: Function }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/statuses',
      onSuccess,
      onError,
    });
  };

  const getStatusesByCustomer = async ({
    customerId,
    onSuccess,
    onError,
  }: {
    customerId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/statuses/byuser',
      params: {
        customerid: customerId,
      },
      onSuccess,
      onError,
    });
  };

  const getSortedStatuses = async ({
    customerId,
    onSuccess,
    onError,
  }: {
    customerId: number;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/statuses/customer?customerid='+customerId,
      onSuccess,
      onError,
    });
  };

  const createStatus = async (
    params: PostCreateStatusParams,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<any> => {
    const requestId = `postCreateStatus_${Date.now()}`;
    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL + `/statuses/admin`,
      data: params,
      ...config,
      id: requestId,
    });
    return response;
  };

  const deleteStatusById = async (statusid: number) => {
    return apiService({
      method: 'delete',
      url: BASE_UAPI_URL + '/statuses/admin?statusid=' + statusid,
    });
  };

  const updateStatus = async (params: UpdateStatusParams, config?: Partial<AxiosRequestConfig>): Promise<any> => {
    const requestId = `postCreateStatus_${Date.now()}`;
    const response = await apiService({
      method: 'put',
      url: BASE_UAPI_URL + `/statuses/admin?statusid=${params?.Status?.StatusID}`,
      data: params,
      ...config,
      id: requestId,
    });
    return response;
  };

  const sortStatusOrder = async (params: UpdateSortStatusParams, config?: Partial<AxiosRequestConfig>): Promise<any> => {
    
    const requestId = `postCreateStatus_${Date.now()}`;
    const {customerId, getStatusIds} = params
    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL + `/statussort?customerid=`+customerId,
      data: getStatusIds,
      ...config,
      id: requestId,
    });
    console.log(response)
    return response;
  };

  return {
    getSortedStatuses,
    updateStatus,
    deleteStatusById,
    getStatuses,
    getStatusesByCustomer,
    createStatus,
    sortStatusOrder
  };
};

export default StatusService;
