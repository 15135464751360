import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import TicketService from '@/services/ticket.service';
import { enqueueSnackbar } from 'notistack';
import useTicketList from '@/contexts/stores/ticketList.store';

type ActionType = 'addTicket' | 'updateTicket' | 'deleteTicket' | 'updateTicketListMap';

interface MutationAction {
  actionType: ActionType;
  [key: string]: any;
}

const useTicketMutationsTicketListMap = (): {
  updateTicketListMapTickets: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  isUpdatingTicketListMapTickets: Record<string, boolean>;
  setIsUpdatingTicketListMapTickets: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const { ticketListState, updateTicketList } = useTicketList();
  const [isUpdatingTicketListMapTickets, setIsUpdatingTicketListMapTickets] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();
  const executeMutation = async ({ actionType, ...payload }: MutationAction) => {
    const ticketService = TicketService();
    const action: { [key in ActionType]?: Function } = {
      updateTicketListMap: ticketService.postTicketMapUpdates,
      // updateTicket: ticketService.updateTicket,
      // deleteTicket: ticketService.deleteTicket
    };

    const serviceMethod = action[actionType];
    if (!serviceMethod) {
      throw new Error(`Unknown action type: ${actionType}`);
    }
    return serviceMethod(payload);
  };

  const { mutateAsync: updateTicketListMapTickets } = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType }) => {
      setIsUpdatingTicketListMapTickets({ ...isUpdatingTicketListMapTickets, [actionType]: true });
    },
    onSuccess: (data: any, variables: any) => {
      console.log('onSuccess:', data, variables);
      console.log('Mutation successful');
      console.log('variables.data', variables.data);
      queryClient.invalidateQueries({ queryKey: ['all-tickets'] });
      if (
        variables.data[0].TicketID &&
        variables.data.length === 1 &&
        (variables.actionType === 'updateTicket' || variables.actionType === 'updateTicketListMap')
      ) {
        console.log('successful update');
        enqueueSnackbar('Ticket saved successfully', { variant: 'success' });
      } else if (variables.data.length > 1 && variables.actionType === 'updateTicketListMap') {
        console.log('successful update');
        enqueueSnackbar('Tickets saved successfully', { variant: 'success' });
      }
      // if (variables.actionType === 'deleteTicket' && data.ticketId) {
      //     queryClient.removeQueries({ queryKey: ['ticket', data.ticketId] })
      //     enqueueSnackbar(`Ticket #${data.ticketId} deleted successfully`, { variant: 'success' })
      //     queryClient.invalidateQueries({ queryKey: ['archived-tickets'], exact: true });
      // }
    },
    onError: (error: any, variables: any) => {
      console.error('Error during mutation:', error);
      console.warn('ERROR', variables);
      if (
        variables.data[0].TicketID &&
        variables.data.length === 1 &&
        (variables.actionType === 'updateTicket' || variables.actionType === 'updateTicketListMap')
      ) {
        enqueueSnackbar('Ticket update failed', { variant: 'error' });
      } else if (variables.data.length > 1 && variables.actionType === 'updateTicketListMap') {
        enqueueSnackbar('Ticket updates failed', { variant: 'error' });
      }

      if (variables.actionType === 'deleteTicket' && variables.data[0].TicketID) {
        enqueueSnackbar(`Ticket #${variables.data[0].TicketID} delete failed`, {
          variant: 'error',
        });
      }
    },
    // onSettled: ({ actionType }) => {
    //   setIsUpdating({ ...isUpdating, [actionType]: false });
    // },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['all-tickets'] });
    },
  });

  return { updateTicketListMapTickets, isUpdatingTicketListMapTickets, setIsUpdatingTicketListMapTickets };
};

export default useTicketMutationsTicketListMap;
