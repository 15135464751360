import type { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, MuiDialog } from '@utilisourcepackagelibdev/utilisourcepackagelib';


interface IProps extends DialogProps {
  setOpen: any;
  title?: any;
  content?: any;
  hideCloseIcon?: boolean;
  hideOkayBtn?: boolean;
  hideCancelBtn?: boolean;
  onSuccess?: any;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onClose?: any;
  disabled?: any;
}

const ConfirmModal = (props: IProps) => {
  const {
    title,
    content,
    hideCloseIcon,
    hideCancelBtn,
    hideOkayBtn,
    setOpen,
    cancelButtonText,
    confirmButtonText,
    onClose,
    disabled,
    ...dialogProps
  } = props;

  const handleSuccessClick = () => {
    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  return (
    <MuiDialog {...dialogProps}>
      {title && <DialogTitle sx={{ m: 4 }}>{title}</DialogTitle>}
      <DialogContent>{content || null}</DialogContent>
      <DialogActions sx={{ m: 2 }}>
        {!hideCloseIcon && (
          <IconButton
            id="closeModalIcon"
            color="default"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
        {!hideCancelBtn && (
          <Button
            id="cancelBtn"
            onClick={onClose}
            size="small"
            variant="contained"
          >
            {cancelButtonText || 'Cancel'}
          </Button>
        )}
        {!hideOkayBtn && (
          <Button
            id="okayBtn"
            disabled={disabled}
            size="small"
            color="error"
            disableElevation
            variant="outlined"
            onClick={() => {
              handleSuccessClick();
              onClose?.();
            }}
          >
            {confirmButtonText || 'Okay'}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
};

export default ConfirmModal;
