import { BASE_UAPI_URL } from '../constants/commonStrings.constant';
import { apiService } from './api.service';

const ReportService = () => {
  const getFilteredReport = async ({
    filterId,
    onSuccess,
    onError,
  }: {
    filterId: string;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/tickets?fields=true&filter=' + filterId + '&report=true',
      onSuccess,
      onError,
    });
  };

  const getReports = async ({
    customerId,
    customFields = true,
    category,
    onSuccess,
    onError,
  }: {
    customerId: number;
    customFields?: boolean;
    category: string;
    onSuccess?: Function;
    onError?: Function;
  }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/tickets',
      params: {
        fields: customFields,
        customer: customerId,
        category: category,
      },
      onSuccess,
      onError,
    });
  };

  return {
    getFilteredReport,
    getReports
  };
};

export default ReportService;
