import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import FieldGroupsService from '@/services/fieldGroups.service';

type ActionType = 'upsertFieldGroup' | 'restoreFieldGroup' | 'archiveFieldGroup';

interface MutationAction {
  actionType: ActionType;
  payload: any;
}

const useFieldGroupMutation = (): {
  executeFieldGroupAction: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  isUpdating: Record<string, boolean>;
  setIsUpdating: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const [isUpdating, setIsUpdating] = useState<Record<string, boolean>>({});

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const fieldGroupService = FieldGroupsService();

  const actionMap: { [key in ActionType]: Function } = {
    upsertFieldGroup: fieldGroupService.upsertFieldGroup,
    restoreFieldGroup: fieldGroupService.restoreFieldGroup,
    archiveFieldGroup: fieldGroupService.archiveFieldGroup,
  };

  const executeMutation = async ({ actionType, payload }: MutationAction) => {
    const serviceMethod = actionMap[actionType];
    if (!serviceMethod) {
      enqueueSnackbar('Error processing your request', { variant: 'error' });
    } else {
      return serviceMethod(payload);
    }
  };

  const { mutate: executeFieldGroupAction } = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType }) => {
      setIsUpdating((prev) => ({ ...prev, [actionType]: true }));
    },

    onSuccess: (data: any, { actionType }) => {
      const messages: { [key in ActionType]: string } = {
        archiveFieldGroup: 'Field group successfully archived',
        upsertFieldGroup: 'Field group successfully saved',
        restoreFieldGroup: 'Field group successfully restored',
      };

      const successMessage = messages[actionType] || 'Operation successful';

      enqueueSnackbar(successMessage, { variant: 'success' });
      navigate({ to: '/field-groups' });
    },
    onError: (error: any, { actionType }) => {
      const errorMessage = `Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });

  return { executeFieldGroupAction, isUpdating, setIsUpdating };
};

export default useFieldGroupMutation;
