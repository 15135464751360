import TicketTypeService from '@/services/ticketType.service';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import useTicketTypeMutation from './useTicketTypeMutation';

const useTicketTypes = (customerId?: number) => {
  const { executeTicketTypeAction, isUpdating, setIsUpdating } = useTicketTypeMutation();

  const ticketTypesService = TicketTypeService();

  const ticketTypes = useQuery<any>({
    queryKey: ['ticketTypes'],
    queryFn: async () => {
      return new Promise(async (resolve) => {
        await ticketTypesService
          .getTicketTypes({
            onSuccess: (res: AxiosResponse) => {
              resolve(res.data);
            },
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });

  const ticketTypeByCustomer = useQuery<any>({
    queryKey: ['ticket-type-by-customer', customerId],
    queryFn: async () => {
      if (customerId) {
        return new Promise(async (resolve) => {
          await ticketTypesService.getCustomerTicketTypeById({ customerId: customerId }).then((res) => {
            resolve(res?.data);
          });
        });
      }
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    enabled: Boolean(customerId), // this will prevent the query from running until customerId is defined
  });

  const ticketTypesByAllCustomers = useQuery<any>({
    queryKey: ['ticket-types-by-all-customers'],
    queryFn: async () => {
      return new Promise(async (resolve) => {
        await ticketTypesService
          .getTicketTypesByAllCustomers({
            onSuccess: (res: AxiosResponse) => {
              resolve(res.data);
            },
          })
          .then((res) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  })

  return {
    ticketTypes,
    ticketTypeByCustomer,
    executeTicketTypeAction,
    isUpdating,
    setIsUpdating,
    ticketTypesByAllCustomers
  };
};

export default useTicketTypes;
