import { useNavigate } from '@tanstack/react-router';
import React, { useState, useEffect } from 'react';
import { useSearch, useParams } from '@tanstack/react-router';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  useTheme,
  Theme,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import { DrawerLayout } from '@/layouts/DrawerLayout';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import ConfirmModal from '@/components/Molecules/Modal/ConfirmModal.component';
import RestoreIcon from '@mui/icons-material/Restore';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { createCustomFieldsQueryOptions } from '@/services/queries/customFieldOptions';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { AutoComplete, Card, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import CheckboxInput from '@/components/Atoms/Checkbox/CheckboxInput.component';
import { z } from 'zod';
import {
  CheckedTypes,
  CustomField,
  customField,
  CustomFieldSchema,
  ReportHeader,
  TypeCustomerList,
} from '@/interfaces/schemas/customField.schema';
import useCustomFields, { createFieldByIdQueryOptions } from '@/hooks/fetches/CustomFields/useCustomFields.service';
import RadioButton from '@/components/RadioButton/RadioButton.component';
import { zodResolver } from '@hookform/resolvers/zod';
import CustomValueConfirmationModal from './CustomValueConfirmationModal';
import ReportingGroupHeaderModal from './ReportingGroupHeaderModal';
import useFieldGroups from '@/hooks/fetches/FieldGroups/useFieldGroups.service';
import { createCustomerOptionsQuery } from '@/services/queries/customerOptions';
import { CustomerEntity } from '@/interfaces/customer.interface';
import useReportHeaders from '@/hooks/fetches/ReportHeaders/useReportHeaders.service';
import EditIcon from '@mui/icons-material/Edit';
import EditDropdownModal from './EditDropdownModal';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useTicketTypes from '@/hooks/fetches/TicketTypes/useTicketTypes.service';
import { FieldGroup, FieldGroupCustomer } from '@/interfaces/fieldGroup.interface';
import { TicketType, TicketTypeCustomer } from '@/interfaces/ticketType.interface';

const CustomFieldsAdminDrawer = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isResoringArchiving, setIsResoringArchiving] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openCustomValueModal, setOpenCustomValueModal] = useState<boolean>(false);
  const [openReportingGroupHeaderModal, setOpenReportingGroupHeaderModal] = useState<boolean>(false);
  const [openDropdownModal, setOpenDropdownModal] = useState<boolean>(false);
  const [isShowSavedText, setIsShowSavedText] = useState<boolean>(false);
  const [isOpenExitComfirmationModal, setIsOpenExitComfirmationModal] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [ticketTypeCustList, setTicketTypeCustList] = useState<TypeCustomerList[]>([]);
  const [isSendingType, setIsSendingType] = useState<boolean>(false);
  const [isShowError, setIsShowError] = useState<boolean>(false);

  // integerData is defined up here because it might get added to via CustomValueConfirmationModal
  const [integerData, setIntegerData] = useState<string[]>([
    '*any value',
    'Has value',
    'Has no value',
    '-set custom value-',
  ]);
  const customFieldTypeData = ['Date', 'Dropdown', 'File', 'Integer value', 'String input', 'Toggle'];
  const navigate = useNavigate();
  const params = useParams({ from: '/_auth/custom-fields/$customFieldId' });
  const { variant } = useSearch({ from: '/_auth/custom-fields/$customFieldId' });
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const theme = useTheme();

  // api calls
  const allCustomFields = useQuery(createCustomFieldsQueryOptions());
  const fieldById = useSuspenseQuery(createFieldByIdQueryOptions(params.customFieldId));
  const { executeCustomFieldAction, executeCustomFieldActionMutation } = useCustomFields();
  const { reportHeaders } = useReportHeaders();
  const { fieldGroups } = useFieldGroups();
  const customers = useQuery(createCustomerOptionsQuery());
  const { ticketTypesByAllCustomers } = useTicketTypes();

  // extracting & renaming  data
  const currentCustomField = allCustomFields?.data?.data?.find(
    (g: CustomField) => Number(g.cid) === params.customFieldId,
  );
  const metaData = fieldById?.data;
  const sortedData = allCustomFields?.data?.data?.sort((a: CustomField, b: CustomField) =>
    a.name.localeCompare(b.name),
  );

  const sortedReportingGroupHeaders = reportHeaders?.data?.sort((a: ReportHeader, b: ReportHeader) =>
    a.Name.localeCompare(b.Name),
  );

  const allTicketTypes = ticketTypesByAllCustomers?.data;
  // set default values as empty until the data is loaded and form values are reset

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const methods = useForm<z.infer<typeof CustomFieldSchema>>({
    mode: 'all',
    defaultValues: customField,
    resolver: zodResolver(CustomFieldSchema),
    shouldUnregister: false,
  });

  const {
    control,
    setValue,
    reset,
    watch,
    formState: { errors, dirtyFields },
    setError,
  } = methods;

  useEffect(() => {
    if (currentCustomField && metaData) {
      reset(
        {
          api: {
            name: currentCustomField?.name,
            isRequired: currentCustomField?.isRequired,
            archivedts: currentCustomField?.archivedts,
            childCount: currentCustomField?.childCount,
            childList: currentCustomField?.childList,
            cid: currentCustomField?.cid,
            customerCount: currentCustomField?.customerCount,
            customerIdList: currentCustomField?.customerIdList,
            customerList: currentCustomField?.customerList,
            fieldGroupId: currentCustomField?.fieldGroupId,
            fieldGroupName: fieldGroups?.data?.find(
              (g: FieldGroup) => g.FieldGroupId === Number(currentCustomField.fieldGroupId),
            )?.Name,
            parentField: currentCustomField?.parentField,
            reportHeaderCount: currentCustomField?.reportHeaderCount,
            reportHeaderId: currentCustomField?.reportHeaderId,
            reportingGroupHeaderList: currentCustomField?.reportingGroupHeaderList,
            reportingGroupHeaderName: currentCustomField?.reportingGroupHeaderName,
            ticketCount: currentCustomField?.ticketCount,
            type: currentCustomField?.type,
          },
          metaData: {
            IncludeTime: metaData?.Type?.IncludeTime,
            DefaultValue: metaData?.Type?.DefaultValue,
            Maximum: metaData?.Type?.Maximum,
            Minimum: metaData?.Type?.Minimum,
            Step: metaData?.Type?.Step,
            OptLabels: metaData?.Type?.OptLabels,
            SingleOptLabel: metaData?.Type?.OptLabels?.[0] || '-empty-',
            ArchivedTime: metaData?.CustomField?.Meta?.ArchivedTime,
            Archivedts: metaData?.CustomField?.Meta?.Archivedts,
            ParentField: metaData?.CustomField?.Meta?.Settings?.ParentField,
            ParentFieldId: metaData?.CustomField?.Meta?.Settings?.ParentFieldId,
            ParentFieldValue: metaData?.CustomField?.Meta?.Settings?.ParentFieldValue,
            FieldGroupId: metaData?.CustomField?.Meta?.Settings?.FieldGroupId,
            CustomerForTypes: Number(currentCustomField?.customerCount) === 1 ? currentCustomField?.customerList : '',
            CustomerIdForTypes:
              Number(currentCustomField?.customerCount) === 1 ? Number(currentCustomField?.customerIdList) : 0,
            TicketTypes: [],
            TypeList: metaData?.TypeList || [],
          },
          reportingGroupHeaders: sortedReportingGroupHeaders,
        },
        { keepDirty: false },
      );
    }
  }, [fieldById.data, currentCustomField]);

  // variables from react hook form
  const name = watch('api.name');
  const type = watch('api.type');
  const isRequired = watch('api.isRequired');
  const includeTime = watch('metaData.IncludeTime');
  const defaultValue = watch('metaData.DefaultValue');
  const parentFieldId = watch('metaData.ParentFieldId');
  const parentField = watch('metaData.ParentField');
  const parentFieldValue = watch('metaData.ParentFieldValue');
  const customerList = watch('api.customerList');
  const customerIdList = watch('api.customerIdList');
  const minValue = watch('metaData.Minimum');
  const maxValue = watch('metaData.Maximum');
  const optLabels = watch('metaData.OptLabels');
  const customerForTypes = watch('metaData.CustomerForTypes');
  const customerIdForTypes = watch('metaData.CustomerIdForTypes');
  const typeList = watch('metaData.TypeList');
  const checkedTicketTypes = watch('metaData.TicketTypes');
  console.log({ checkedTicketTypes })
  // as of right now, the custom field name and type are the only mandaroty fields
  const canSend = type !== '' && name !== '';


  const ticketTypesByCustomerId = allTicketTypes?.filter((type: TicketType) =>
    type?.Customer?.some((c: TicketTypeCustomer) => c.CustomerID === customerIdForTypes),
  );

  const parentDataType = sortedData?.find((d: CustomField) => Number(d.cid) === parentFieldId)?.type;

  const tempCustomerIdList = customerIdList === '' ? [] : customerIdList?.split('|');

  const customersForDropDown = customers?.data?.filter((customer: CustomerEntity) =>
    tempCustomerIdList?.includes(customer.CustomerEntId.toString()),
  );

  const getFieldGroupData = () => {
    return fieldGroups?.data?.filter((group: FieldGroup) =>
      group?.CustomerList?.some((customer: FieldGroupCustomer) =>
        tempCustomerIdList?.includes(String(customer.CustomerId)),
      ),
    );
  };

  const fieldGroupData = customerIdList === '' ? [{ Name: 'No Groups Available' }] : getFieldGroupData();

  //will only process data for 1 customer at a time.
  const handleTypeCustomerData = () => {
    setIsDirty(true);
    if (customerIdForTypes === 0) return;
    // Check if the customer already exists in the list
    const existingCustomerIndex = ticketTypeCustList.findIndex(
      (pair: TypeCustomerList) => pair.CustomerID === customerIdForTypes,
    );
    // if there is already data for the customer, update
    if (existingCustomerIndex !== -1) {
      // Customer exists - update their TypeIDList
      const updatedTicketTypeCustList = ticketTypeCustList.map((pair: TypeCustomerList) => {
        if (pair.CustomerID === customerIdForTypes) {
          // Update the TypeIDList with the currently selected ticket types
          const updatedTypeIDList = checkedTicketTypes.map((t: any) => t.TypeID);
          return { ...pair, TypeIDList: updatedTypeIDList };
        }
        return pair;
      });
      setTicketTypeCustList(updatedTicketTypeCustList);
    } else {
      // Customer does not exist - add a new entry
      const ticketTypeIds = checkedTicketTypes.map((t: any) => t.TypeID);
      const dataToBeAdded = { CustomerID: customerIdForTypes, TypeIDList: ticketTypeIds };

      setTicketTypeCustList([...ticketTypeCustList, dataToBeAdded]);
    }
  };

  // initially have the data from api be the single source of truth.
  // any types that aren't null, and are associated with the current list of customers.
  useEffect(() => {
    // set the useState slice to automatically store the data from api call

    const data = typeList?.filter((pair) => pair.TypeList !== null);
    const restructuredData = data?.map((datum: any) => {
      let types = datum.TypeList?.map((t: any) => t.TypeID);
      return { CustomerID: datum.CustomerID, TypeIDList: types };
    });
    const filteredData = restructuredData?.filter((pair: any) =>
      tempCustomerIdList.some((id) => Number(id) === pair.CustomerID),
    );
    setTicketTypeCustList([...(filteredData ?? [])]);

    // if there's only 1 customer, automatically set the checkedTypes
    if (tempCustomerIdList.length === 1) {
      const type = allTicketTypes?.filter((type: TicketType) =>
        data?.[0]?.TypeList?.some((t: any) => t.TypeID === type.TypeID),
      );
      setValue('metaData.TicketTypes', type ?? []);
    }
  }, [typeList]);

  useEffect(() => {
    if (maxValue && minValue && maxValue < minValue) {
      setError('metaData.Maximum', {
        type: 'manual',
        message: 'Maximum must be greater than Minimum',
      });
    }
  }, [minValue, maxValue, setError]);

  // typeId is for the payload.
  console.log(currentCustomField)

  const getTypeId = () => {
    const typeData = {
      Date: 22,

      'String input': 17,
      'Integer value': 16,
      'File upload': 18,
      Dropdown: 19,
      Toggle: 20,
      ArcGIS: 21,
    };
    return typeData[type as keyof typeof typeData];
  };

  // a portion of the payload is based on the custom field type and mapped out here
  const getTypeData = (formValues: any) => {
    switch (getTypeId()) {
      case 22:
        return { IncludeTime: formValues.metaData.IncludeTime };
      case 16:
        return {
          Maximum: formValues.metaData.Maximum,
          Minimum: formValues.metaData.Maximum,
          Step: formValues.metaData.Step,
        };
      case 17:
        return {};
      case 18:
        return {};
      case 19:
        return { OptLabels: optLabels };
      case 20:
        return { DefaultValue: formValues.metaData.DefaultValue };
      case 21:
        return 0;
      default:
        return null;
    }
  };

  // data used for ParentFieldValue dropdown (conditional based on type)
  const dropdownData = (parentDataType: string) => {
    switch (parentDataType) {
      case 'Toggle':
        return ['*any value', 'Checked', 'Unchecked'];
      case 'String input':
        return ['*any value', 'Has value', 'Has no value'];
      case 'Integer value':
        return integerData;
      case 'File':
        return ['*any value', 'Has value', 'Has no value'];
      case 'Dropdown':
        return ['*any value', 'Complete', 'Need Help'];
      case 'Date':
        return ['*any value', 'Has value', 'Has no value'];
      default:
        return [];
    }
  };

  const handleClose = () => {
    setIsDirty(false);

    setIsOpenExitComfirmationModal(false);
    setIsOpen(false);
    setTimeout(() => {
      navigate({ to: '/custom-fields' });
    }, 300);
  };

  const handleSubmit: SubmitHandler<any> = (formValues: any) => {
    setIsUpdating(true);
    if (variant === 'edit') {
      executeCustomFieldAction({
        actionType: 'upsertCustomField',
        payload: {
          CustomerIdList: tempCustomerIdList?.map((num: string) => Number(num)),
          Meta: {
            ArchivedTime: formValues.metaData.ArchivedTime,
            Archivedts: formValues.metaData.Archivedts,
            Cid: Number(currentCustomField?.cid),
            Name: formValues.api.name,
            Settings: {
              FieldGroupId: formValues.metaData.FieldGroupId,
              IsRequired: formValues.api.isRequired === 'true',
              ParentFieldId: formValues.metaData.ParentFieldId,
              ParentFieldValue: formValues.metaData.ParentFieldValue,
              ReportHeaderId: Number(formValues.api.reportHeaderId),
            },
          },
          Type: getTypeData(formValues),
          TypeId: getTypeId(),
          TicketTypeCustList: ticketTypeCustList,
        },
      });
    } else {
      executeCustomFieldAction({
        actionType: 'upsertCustomField',
        payload: {
          CustomerIdList: tempCustomerIdList?.map((num: any) => Number(num)),
          Meta: {
            Cid: -1,
            Name: formValues.api.name,
            Settings: {
              FieldGroupId: formValues.metaData.FieldGroupId,
              IsRequired: formValues.api.isRequired === 'true',
              ParentFieldId: formValues.metaData.ParentFieldId,
              ParentFieldValue: formValues.metaData.ParentFieldValue,
              ReportHeaderId: Number(formValues.api.reportHeaderId),
            },
          },
          Type: getTypeData(formValues),
          TypeId: getTypeId(),
          TicketTypeCustList: ticketTypeCustList,
        },
      });
    }
  };

  const handleArchiveRestore = () => {
    const action = metaData?.CustomField?.Meta?.ArchivedTime === '0001-01-01T00:00:00Z' ? 'archive' : 'restore';
    executeCustomFieldAction({
      actionType: `${action}CustomField`,
      payload: { Cid: Number(currentCustomField?.cid), action: `${action}` },
    });
  };

  const handleSubmitTypes = async () => {
    setIsDirty(true);
    setIsSendingType(true);
    handleTypeCustomerData();

    // if the types were the only field that was changed, then set isDirty to false.
    if (
      Object.keys(dirtyFields).length === 1 &&
      dirtyFields.metaData &&
      Object.keys(dirtyFields.metaData).length === 1 &&
      dirtyFields.metaData.CustomerForTypes
    ) {
      setIsDirty(false);
    }

    executeCustomFieldActionMutation.mutate({
      actionType: 'updateTypes',
      payload: {
        Cid: metaData ? metaData?.CustomField?.Meta?.Cid : -1,
        CustomerID: customerIdForTypes,
        data: checkedTicketTypes?.map((type: any) => type.TypeID),
      }
    }, {
      onSuccess: () => {
        setIsSendingType(false);
        setIsShowSavedText(true);
        setTimeout(() => {
          setIsShowSavedText(false);
        }, 1500);
      },
      onError: (error) => {
        console.log('error', error)
        setIsSendingType(false);
        setIsShowError(true);
        setTimeout(() => {


          setIsShowError(false);
        }, 1500);
      }
    });
  };
  // components

  const customerCheckBoxList = customers?.data?.map((customer: CustomerEntity) => {
    const isChecked = !!tempCustomerIdList?.some((c: string) => Number(c) === customer.CustomerEntId);
    return (
      <ListItem sx={{ p: 0, m: 0, pl: 2 }} key={customer.CustomerEntId}>
        <CheckboxInput
          sxProps={{ p: 0, m: 1 }}
          label={customer.EntName}
          checked={isChecked}
          id={customer.EntName}
          onDataChange={() => {
            setIsDirty(true);

            let tempNameList = customerList === '' ? [] : customerList?.split('|');
            let newTempNameList = [];
            let tempIdList = customerIdList === '' ? [] : customerIdList?.split('|');
            let newTempIdList = [];
            if (tempNameList?.some((c: string) => c === customer.EntName)) {
              newTempNameList = tempNameList?.filter((c: string) => c !== customer.EntName);
              newTempIdList = tempIdList?.filter((c: string) => Number(c) !== customer.CustomerEntId);
            } else {
              newTempNameList = [...tempNameList, customer.EntName];
              newTempIdList = [...tempIdList, customer.CustomerEntId];
            }
            // tempList = customerList
            setValue('metaData.CustomerForTypes', newTempNameList?.length === 1 ? newTempNameList?.[0] : '');
            setValue('metaData.CustomerIdForTypes', newTempIdList?.length === 1 ? Number(newTempIdList?.[0]) : 0);
            setValue('api.customerList', newTempNameList.join('|'));
            setValue('api.customerIdList', newTempIdList.join('|'));
            if (newTempNameList?.length === 0 && newTempIdList.length === 0) {
              setValue('api.fieldGroupName', '');
            }
          }}
        />
      </ListItem>
    );
  });

  // logic for indeterminate checkbox
  const totalTicketTypes = ticketTypesByCustomerId?.length || 0;
  const checkedTicketTypesCount = checkedTicketTypes?.length || 0;
  const checkAll = checkedTicketTypesCount === totalTicketTypes && totalTicketTypes > 0;
  const indeterminate = checkedTicketTypesCount > 0 && checkedTicketTypesCount < totalTicketTypes;

  const ticketTypeCheckbox = () => {
    if (ticketTypesByCustomerId?.length === 0) {
      return (
        <Typography>{`No ticket types available for ${customerForTypes?.label === undefined ? customerForTypes : customerForTypes.label}`}</Typography>
      );
    } else {
      return (
        <List sx={{ display: 'flex', flexDirection: 'column', pl: 2 }}>
          <CheckboxInput
            label="All"
            indeterminate={indeterminate}
            sxProps={{ p: 1, m: 1 }}
            checked={checkAll}
            id="checkAll"
            onDataChange={() => {
              setIsDirty(true);
              if (checkAll) {
                setValue('metaData.TicketTypes', []);
              } else {
                setValue('metaData.TicketTypes', ticketTypesByCustomerId || []);
              }
            }}
          />
          {ticketTypesByCustomerId?.map((type: any) => {
            const isChecked = checkedTicketTypes?.some((t: CheckedTypes) => t?.TypeID === type?.TypeID);
            return (
              <CheckboxInput
                key={type.TypeID}
                label={type.TypeName}
                sxProps={{ p: 1, m: 1 }}
                checked={isChecked}
                id={type.TypeID}
                onDataChange={() => {
                  setIsDirty(true);

                  if (isChecked) {
                    const filteredTypes =
                      checkedTicketTypes?.filter((t: CheckedTypes) => t.TypeID !== type.TypeID) || [];
                    setValue('metaData.TicketTypes', filteredTypes);
                  } else {
                    const addedTypes = [...(checkedTicketTypes || []), type];
                    setValue('metaData.TicketTypes', addedTypes);
                  }
                }}
              />
            );
          })}

          {isShowSavedText ? (
            <Chip label="Success" color="success" />
          ) : (
            <LoadingButton loading={isSendingType} variant="contained" onClick={handleSubmitTypes}>
              {checkedTicketTypes.length !== 0
                ? `Update Types for ${customerForTypes?.label === undefined ? customerForTypes : customerForTypes.label}`
                : `Add Types for ${customerForTypes?.label === undefined ? customerForTypes : customerForTypes.label}`}
            </LoadingButton>
          )}

          {isShowError && <Chip label="Error processing your request" color="error" />}
        </List>
      );
    }
  };

  return (
    <DrawerLayout
      open={isOpen}
      onClose={() => (isDirty ? setIsOpenExitComfirmationModal(true) : handleClose())}
      anchor="right"
      keepMounted
      width={'75%'}
      Header={
        <DrawerHeader
          close={() => (isDirty ? setIsOpenExitComfirmationModal(true) : handleClose())}
          title={variant === 'edit' ? 'Edit Custom Field' : 'New Custom Field'}
        />
      }>
      <FormProvider {...methods}>
        {fieldById?.isLoading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 9999,
            }}>
            <CircularProgress />
          </Box>
        ) : (
          // @ts-ignore
          //use sparingly
          <Container
            maxWidth="xl"
            component="form"
            onSubmit={methods.handleSubmit(handleSubmit)}
            sx={{
              overflowY: 'auto',
              mt: 5,
              height: '98%',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Divider sx={{ mb: 2 }} />
            {metaData?.CustomField?.Meta?.ArchivedTime !== '0001-01-01T00:00:00Z' && metaData && (
              <Chip
                // sx={{ position: 'absolute', right: '10%', top: '19px' }}
                sx={{ m: 2, fontWeight: 'bold' }}
                label={`This field was archived on ${new Date(metaData?.CustomField?.Meta?.ArchivedTime)?.toDateString()}`}
                color="error"
              />
            )}
            <Grid
              size={{ xs: 12 }}
              marginTop={!isDesktop ? 2 : 0}
              height={'100%'}
              padding={1}
              sx={{ overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 3 }}>
              {variant === 'edit' && (
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>Number of tickets using field: {currentCustomField?.ticketCount}</Typography>
                  <Typography>cid: {currentCustomField?.cid}</Typography>
                </Box>
              )}
              <Grid sx={{ display: 'flex', mt: 2, gap: 4, flexDirection: isDesktop ? 'row' : 'column' }}>
                <TextBox
                  id="api.name"
                  name="api.name"
                  multiline
                  label="Custom Field Name*"
                  fullWidth
                  onChange={() => setIsDirty(true)}
                  sx={{ flex: 1 }}
                />
                <Grid sx={{ flex: 1 }}>
                  <AutoComplete
                    id="api.type"
                    control={control}
                    options={customFieldTypeData.map((type: string) => ({
                      label: type,
                    }))}
                    // loading
                    label="Custom Field Type*"
                    multiple={false}
                    autocompleteProps={{
                      id: 'type',
                      onChange: (_, selectedOption) => {
                        setValue('api.type', selectedOption?.label ?? '');
                        setIsDirty(true);
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <CheckboxInput
                label="Required to Close Ticket"
                sxProps={{ p: 1, m: 1 }}
                checked={isRequired === 'true'}
                id="api.isRequired"
                onDataChange={() => {
                  setValue('api.isRequired', isRequired === 'true' ? 'false' : 'true');
                  setIsDirty(true);
                }}
              />
              <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {type === 'Date' && (
                  <CheckboxInput
                    label="Include Time"
                    sxProps={{ p: 1, m: 1 }}
                    checked={includeTime === undefined ? false : includeTime}
                    id="dateCheckbox"
                    onDataChange={() => {
                      setValue('metaData.IncludeTime', !includeTime, { shouldDirty: true, shouldValidate: true });
                      setIsDirty(true);
                    }}
                  />
                )}
                {type === 'Toggle' && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <Typography>Default Value:</Typography>
                    <RadioButton
                      id="metaData.DefaultValue"
                      label="Unchecked"
                      name="myRadioGroup"
                      checked={defaultValue === false}
                      value={false}
                      onDataChange={() => {
                        setValue('metaData.DefaultValue', false);
                        setIsDirty(true);
                      }}
                    />
                    <RadioButton
                      id="metaData.DefaultValue"
                      label="Checked"
                      name="myRadioGroup"
                      checked={defaultValue === true}
                      value={true}
                      onDataChange={() => {
                        setValue('metaData.DefaultValue', true);
                        setIsDirty(true);
                      }}
                    />
                  </Box>
                )}
                {type === 'Integer value' && (
                  <Grid sx={{ display: 'flex', gap: 4, width: '90%', m: 4 }}>
                    <TextBox
                      size="small"
                      control={control}
                      type="number"
                      id="metaData.Minimum"
                      name="metaData.Minimum"
                      label="Min"
                      onChange={() => setIsDirty(true)}
                      sx={{ flex: 1 }}
                    />
                    <TextBox
                      size="small"
                      control={control}
                      type="number"
                      id="metaData.Maximum"
                      name="metaData.Maximum"
                      label="Max"
                      onChange={() => setIsDirty(true)}
                      sx={{ flex: 1 }}
                    />
                    <TextBox
                      size="small"
                      type="number"
                      id="metaData.Step"
                      name="metaData.Step"
                      label="Step"
                      sx={{ flex: 1 }}
                      onChange={() => setIsDirty(true)}
                    />
                  </Grid>
                )}
                {type === 'Dropdown' && (
                  <Grid sx={{ width: '90%', mt: 4, mb: 4 }}>
                    <Grid sx={{ width: '100%', display: 'flex', gap: 4 }}>
                      <Grid sx={{ flex: 1 }}>
                        <AutoComplete
                          id="metaData.SingleOptLabel"
                          control={methods.control}
                          options={optLabels ? optLabels : []}
                          label="Dropdown Items"
                          multiple={false}
                          autocompleteProps={{
                            size: 'small',
                            id: 'metaData.SingleOptLabel',
                            onChange: (_, selectedOption) => {
                              // do we need to do anything here?
                              setIsDirty(true);
                            },
                          }}
                        />
                      </Grid>
                      <Tooltip title="Edit">
                        <Button
                          sx={{ height: '40px' }}
                          onClick={() => {
                            setOpenDropdownModal(true);
                            setIsDirty(true);
                          }}>
                          <EditIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Typography>{`Item Count: ${optLabels ? optLabels?.length : 0}`}</Typography>
                  </Grid>
                )}
                {openDropdownModal && (
                  <EditDropdownModal
                    open={openDropdownModal}
                    setOpen={setOpenDropdownModal}
                    onClose={() => setOpenDropdownModal(false)}
                    methods={methods}
                    data={optLabels}
                  />
                )}
              </Grid>
              {variant === 'edit' && <Typography>Number of child fields: {currentCustomField?.childCount}</Typography>}
              <Grid sx={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column', gap: 4, mt: 2 }}>
                <Grid sx={{ flex: 1 }}>
                  <AutoComplete
                    id="metaData.ParentField"
                    control={methods.control}
                    options={sortedData?.map((field: CustomField) => ({
                      label: field?.name,
                      id: field?.cid,
                      parentType: field?.type,
                    }))}
                    label="Parent Field (Optional)"
                    multiple={false}
                    autocompleteProps={{
                      id: 'metaData.ParentField',
                      onChange: (_, selectedOption) => {
                        setIsDirty(true);
                        setValue('metaData.ParentField', selectedOption?.label ?? '');
                        setValue('metaData.ParentFieldId', Number(selectedOption?.id) ?? 0);
                        setValue('metaData.ParentFieldValue', dropdownData(parentDataType)[0] ?? '');
                        if (!selectedOption) {
                          setValue('metaData.ParentFieldValue', '');
                        }
                      },
                    }}
                  />
                  {parentDataType && <Typography sx={{ color: 'grey' }}>{parentDataType}</Typography>}
                </Grid>
                <Grid sx={{ flex: 1 }}>
                  <AutoComplete
                    id="metaData.ParentFieldValue"
                    control={methods.control}
                    options={dropdownData(parentDataType)?.map((type: string) => ({
                      label: type,
                    }))}
                    label="Display When Parent Value"
                    disabled={parentField === ''}
                    multiple={false}
                    autocompleteProps={{
                      value: dropdownData(parentDataType)?.find((opt: any) => opt.label === parentFieldValue) || null,
                      id: 'ParentFieldValue',
                      onChange: (_, selectedOption) => {
                        setValue('metaData.ParentFieldValue', selectedOption?.label ?? '');
                        if (selectedOption?.label === '-set custom value-') {
                          setOpenCustomValueModal(true);
                        }
                        setIsDirty(true);
                      },
                    }}
                  />
                  {openCustomValueModal && (
                    <CustomValueConfirmationModal
                      open={openCustomValueModal}
                      setOpen={setOpenCustomValueModal}
                      onClose={() => setOpenCustomValueModal(false)}
                      methods={methods}
                      setIntegerData={setIntegerData}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: 4 }}>
                <Box sx={{ display: 'flex', gap: 4 }}>
                  <Grid sx={{ width: '49%' }}>
                    <AutoComplete
                      id="api.reportingGroupHeaderName"
                      control={methods.control}
                      options={sortedReportingGroupHeaders?.map((group: any) => ({
                        label: group?.Name,
                        Id: group?.Id,
                      }))}
                      // loading
                      label="Reporting Group Header (Optional)"
                      multiple={false}
                      autocompleteProps={{
                        id: 'api.reportingGroupHeaderName',
                        onChange: (_, selectedOption) => {
                          setValue('api.reportingGroupHeaderName', selectedOption?.label);
                          setValue('api.reportHeaderId', `${selectedOption?.Id}`);
                          setIsDirty(true);
                        },
                        // loadingText: '',
                      }}
                    />
                  </Grid>
                  <Button
                    onClick={() => {
                      window.alert(
                        'The values of custom fields sharing the same Report Group Header will be grouped in a single report column whose name is specified here.',
                      );
                    }}>
                    <InfoOutlinedIcon />
                  </Button>
                  <Button
                    sx={{ ml: 4 }}
                    onClick={() => {
                      setOpenReportingGroupHeaderModal(true);
                      setIsDirty(true);
                    }}>
                    Manage
                  </Button>
                  {openReportingGroupHeaderModal && (
                    <ReportingGroupHeaderModal
                      data={sortedReportingGroupHeaders}
                      open={openReportingGroupHeaderModal}
                      setOpen={setOpenReportingGroupHeaderModal}
                      onClose={() => setOpenReportingGroupHeaderModal(false)}
                      methods={methods}
                    />
                  )}
                </Box>
                <Box sx={{ display: 'flex', gap: 4, mb: 5 }}>
                  <Grid sx={{ width: '49%' }}>
                    <AutoComplete
                      id="api.fieldGroupName"
                      control={methods.control}
                      options={fieldGroupData?.map((group: FieldGroup) => ({
                        label: group?.Name,
                        id: group?.FieldGroupId,
                      }))}
                      label="Group Name (Optional)"
                      multiple={false}
                      autocompleteProps={{
                        id: 'api.fieldGroupName',
                        onChange: (_, selectedOption) => {
                          setValue('api.fieldGroupName', selectedOption?.label);
                          setValue('metaData.FieldGroupId', selectedOption?.id);
                          setIsDirty(true);
                        },
                      }}
                    />
                  </Grid>
                  <Button
                    onClick={() => {
                      window.alert(
                        'Groups in this list are those that are defined for the selected customers. In addition a general default group is automatically assigned.',
                      );
                    }}>
                    <InfoOutlinedIcon />
                  </Button>
                </Box>
              </Grid>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: isDesktop ? 'row' : 'column' }}>
                <Card sx={{ width: isDesktop ? '49%' : '100%', mb: 5 }}>
                  <Typography sx={{ p: 2, fontWeight: 'bold' }}>Customers: </Typography>
                  <List sx={{ pl: 4 }}>{customerCheckBoxList}</List>
                </Card>
                <Box sx={{ width: isDesktop ? '49%' : '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', gap: 2, mb: 5 }}>
                    <Typography>Assigned to Ticket Types for:</Typography>
                    <Grid sx={{ flex: 1 }}>
                      <AutoComplete
                        id="metaData.CustomerForTypes"
                        control={methods.control}
                        options={customersForDropDown?.map((group: CustomerEntity) => ({
                          label: group?.EntName,
                          id: group?.CustomerEntId,
                        }))}
                        label="Choose Customer"
                        multiple={false}
                        autocompleteProps={{
                          id: 'metaData.CustomerForTypes',
                          onChange: (_, selectedOption) => {
                            setValue('metaData.CustomerIdForTypes', selectedOption?.id);
                            const custId = selectedOption?.id;
                            setIsDirty(true);

                            // if the datal already exists in ticketTypeCustList use/update that.
                            const currentData = ticketTypeCustList.find((p: any) => p.CustomerID === custId);

                            // get the types based on the typeIDs
                            const currentTypes = allTicketTypes?.filter((t: TicketType) =>
                              currentData?.TypeIDList?.some((id: number) => id === t.TypeID),
                            );

                            if (currentTypes.length > 0) {
                              setValue('metaData.TicketTypes', currentTypes);

                              //if there's no data based on the original customerID list,
                              // then check if it's available in the meta payload.
                            } else if (
                              metaData?.TypeList?.find((t: any) => t.CustomerID === custId)?.TypeList?.length > 0
                            ) {
                              const typesFromMeta = metaData?.TypeList?.find(
                                (t: any) => t.CustomerID === custId,
                              )?.TypeList;

                              const currentTypesFromMeta = allTicketTypes?.filter((t: TicketType) =>
                                typesFromMeta?.some((type: any) => type.TypeID === t.TypeID),
                              );
                              setValue('metaData.TicketTypes', currentTypesFromMeta);

                              // if there's no data then just re-set to an empry array.
                            } else {
                              setValue('metaData.TicketTypes', []);
                            }
                          },
                        }}
                      />
                    </Grid>
                  </Box>
                  <Card>
                    {customerForTypes === '' || !customerForTypes ? (
                      <Typography sx={{ p: 2 }}>Please select at least 1 customer to see associated types</Typography>
                    ) : (
                      ticketTypeCheckbox()
                    )}
                  </Card>
                </Box>
              </Box>
            </Grid>
            <Divider />
            <Box
              sx={{
                position: 'sticky',
                bottom: 0,
                p: 5,
                display: 'flex',
                justifyContent: 'space-between',
                gap: '1rem',
              }}>
              {metaData?.CustomField?.Meta?.ArchivedTime === '0001-01-01T00:00:00Z' || !metaData ? (
                <LoadingButton
                  loading={isResoringArchiving}
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setIsResoringArchiving(true);
                    setOpenModal(true);
                  }}>
                  Archive
                </LoadingButton>
              ) : (
                <LoadingButton
                  loading={isResoringArchiving}
                  color="error"
                  startIcon={<RestoreIcon />}
                  onClick={() => {
                    setIsResoringArchiving(true);
                    setOpenModal(true);
                  }}>
                  Restore
                </LoadingButton>
              )}
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    isDirty ? setIsOpenExitComfirmationModal(true) : handleClose();
                  }}>
                  Cancel & Close
                </Button>
                {isOpenExitComfirmationModal && (
                  <ConfirmModal
                    open={isOpenExitComfirmationModal}
                    setOpen={setIsOpenExitComfirmationModal}
                    title="Confirm Close"
                    content={
                      <div>
                        <div style={{ color: 'blue', fontWeight: 'bold', marginBottom: 5 }}>
                          Changes detected on form.
                        </div>
                        <div>
                          You have made changes to this form. Click 'Leave' to close the form and lose your changes.
                          Click 'Cancel' to return to the form.
                        </div>
                      </div>
                    }
                    onSuccess={() => handleClose()}
                    onClose={() => setIsOpenExitComfirmationModal(false)}
                    cancelButtonText="Cancel"
                    confirmButtonText={'Leave'}
                  />
                )}
                {openModal && (
                  <ConfirmModal
                    open={openModal}
                    setOpen={setOpenModal}
                    title={metaData?.CustomField?.Meta?.ArchivedTime === '0001-01-01T00:00:00Z'
                      ? 'Confirm Archive'
                      : 'Confirm Restore'}
                    content={
                      metaData?.CustomField?.Meta?.ArchivedTime === '0001-01-01T00:00:00Z'
                        ? (
                          <div>
                            <Typography sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>Confirm Archive of custom field {currentCustomField?.Name}</Typography>
                            <List>
                              <ListItem>
                                <Typography>- Count of tickets referencing this custom field: {currentCustomField?.ticketCount}</Typography>
                              </ListItem>
                              <ListItem>
                                <Typography>- Count of customers referencing this custom field: {currentCustomField?.customerCount}</Typography>
                              </ListItem>
                            </List>
                            <Typography>Click 'Continue' to archive this custom field or 'Cancel' to return to the form.</Typography>
                          </div>
                        )
                        : 'This action will restore this custom field.'
                    }
                    onSuccess={handleArchiveRestore}
                    onClose={() => {
                      setIsResoringArchiving(false);
                      setOpenModal(false);
                    }}
                    cancelButtonText="Cancel"
                    confirmButtonText={
                      metaData?.CustomField?.Meta?.ArchivedTime === '0001-01-01T00:00:00Z' ? 'Archive' : 'Restore'
                    }
                  />
                )}
                <LoadingButton disabled={!canSend} loading={isUpdating} variant="contained" type="submit">
                  Save
                </LoadingButton>
              </Box>
            </Box>
          </Container>
        )}
      </FormProvider>
    </DrawerLayout>
  );
};

export default CustomFieldsAdminDrawer;
