import { z } from 'zod';

export const StatusEntitySchema = z.object({
  StatusID: z.number(), 
  Name: z.string(),
  Color: z.number(),
  Flags: z.number(),
});

export const FormValuesSchema = z.object({
  statuses: z.array(StatusEntitySchema),
  customer: z
    .object({
      id: z.number(),
      label: z.string(),
    })
    .nullable(),
});
