import { BASE_UAPI_URL } from "@/constants/commonStrings.constant";
import { apiService } from "./api.service";
import { AxiosRequestConfig } from "axios";
import createQueryParams from "@/util/queryParams";

export type GetTemplatesParams = {
  /**
   * @type integer, int32
   */
  customerid: number;
};

export type GetMergeFieldMapParams = {
  /**
   * @type integer, int32
   */
  customerid: number;
};

export type PostNewTemplateParams = {
  template: File;
  customerId: number;
  templateName: string;
};

export type DownloadTemplateParams = {
  templateId: number;
};

export type DeleteTemplateParams = {
  templateId: number;
};

export type UpdateTemplateParams = {
  template?: File;
  customerId?: string;
  templateName?: string;
  templateId?: number | null;
  description?: string;
};

const NotificationService = () => {
  const getTemplates = async (
    params: GetTemplatesParams,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<unknown> => {

    const { customerid } = params;
    const search = createQueryParams(params);
    const requestId = `getTemplates_${Date.now()}`;

    const response = await apiService({
      method: 'get',
      url: BASE_UAPI_URL +  `/gettemplate?templateid=0&customerid=${customerid}`,
      ...config,
      id: requestId,
    });

    return response.data;
  };

  const getMergeFieldMap = async (
    params: GetMergeFieldMapParams,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<unknown> => {

    const { customerid } = params;
    const search = createQueryParams(params);
    const requestId = `getMergeFieldMap_${Date.now()}`;

    const response = await apiService({
      method: 'get',
      url: BASE_UAPI_URL +  `/getmergefieldmap${search}`,
      ...config,
      id: requestId,
    });

    return response.data;
  };

  const postNewTemplate = async (
    params: PostNewTemplateParams,
    config?: Partial<AxiosRequestConfig>
  ): Promise<unknown> => {
    const { template, customerId, templateName } = params;
    const formData = new FormData();

    formData.append('template', template);
    formData.append('CustomerId', customerId.toString());
    formData.append('TemplateName', templateName);
    formData.append('Description', '');

    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL +  `/createtemplate`,
      ...config,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const downloadTemplate = async (
    params: DownloadTemplateParams,
    config?: Partial<AxiosRequestConfig>
  ): Promise<unknown> => {
    const { templateId } = params;
    const response = await apiService({
      method: 'get',
      url: BASE_UAPI_URL +  `/downloadtemplate?templateid=${templateId}`,
      ...config,
      responseType: 'arraybuffer',
    });

    //! old logic - 
    // Extract the filename from the Content-Disposition header
    const disposition = response.headers['content-disposition'];

    // Split the disposition to find the filename
    let filename = "template.docx"; // default filename
    if (disposition && disposition.indexOf('filename=') !== -1) {
      const filenamePart = disposition.split('filename=')[1];
      filename = filenamePart.trim().replace(/['"]/g, '');
    }

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    // saveAs(blob, filename);

    return response.data;
  };

  const deleteTemplate = async (
    params: DeleteTemplateParams,
    config?: Partial<AxiosRequestConfig>
  ): Promise<unknown> => {
    const { templateId } = params;
    const response = await apiService({
      method: 'delete',
      url: BASE_UAPI_URL +  `/deletetemplate?templateid=${templateId}`,
      ...config,
    });
    return response.data;
  };

  const updateTemplate = async (
    params: UpdateTemplateParams,
    config?: Partial<AxiosRequestConfig>
  ): Promise<any> => {
    const { template, customerId, templateName, templateId, description } = params;

    const formData = new FormData();
  
    if (params.template) {
      formData.append('template', params.template);
    }
    if (params.customerId) {
      formData.append('CustomerId', params.customerId);
    }
    if (params.templateName) {
      formData.append('TemplateName', params.templateName);
    }
    if (params.description) {
      formData.append('Description', params.description);
    }
    if (params.templateId) {
      formData.append('TemplateId', params.templateId.toString());
    }

    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL +  `/updatetemplate`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    });
    return response.data;
  };



  return {
    getTemplates,
    getMergeFieldMap,
    postNewTemplate,
    downloadTemplate,
    deleteTemplate,
    updateTemplate
  }

};

export default NotificationService;
