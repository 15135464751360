import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from '@tanstack/react-router';
import CustomFieldsService from '@/services/customFields.service';

type ActionType = 'upsertCustomField' | 'archiveCustomField' | 'restoreCustomField' | 'updateTypes';

interface MutationAction {
  actionType: ActionType;
  payload: any;
}

const useCustomFieldMutation = (): {
  executeCustomFieldAction: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  isUpdating: Record<string, boolean>;
  setIsUpdating: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  executeCustomFieldActionMutation: UseMutationResult<any, unknown, MutationAction, unknown>;

} => {
  const [isUpdating, setIsUpdating] = useState<Record<string, boolean>>({});
  const params = useParams({ from: '/_auth/custom-fields/$customFieldId' });

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const customFieldsService = CustomFieldsService();

  const actionMap: { [key in ActionType]: Function } = {
    upsertCustomField: customFieldsService.postCustomField,
    archiveCustomField: customFieldsService.archiveRestoreCustomField,
    restoreCustomField: customFieldsService.archiveRestoreCustomField,
    updateTypes: customFieldsService.postCustomFieldTypes,
  };


  const executeMutation = async ({ actionType, payload }: MutationAction) => {
    const serviceMethod = actionMap[actionType];
    if (!serviceMethod) {
      enqueueSnackbar('Error processing your request', { variant: 'error' });
    } else {
      return serviceMethod(payload);
    }
  };

  const mutation = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType, payload }) => {
      setIsUpdating((prev) => ({ ...prev, [actionType]: true }));
    },



    onSuccess: (data: any, { actionType }) => {
      const messages: { [key in ActionType]: string } = {
        upsertCustomField: 'Field group successfully saved',
        archiveCustomField: 'Field group successfully archived',
        restoreCustomField: 'Field group successfully restored',
        updateTypes: 'Typessuccessfully updated',
      };


      const successMessage = messages[actionType] || 'Operation successful';
      if (actionType !== 'updateTypes') {
        enqueueSnackbar(successMessage, { variant: 'success' });
        enqueueSnackbar(successMessage, { variant: 'success' });
        navigate({ to: '/custom-fields' });
      }


    },
    onError: (error: any, { actionType }) => {
      const errorMessage = `Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['custom-field-by-id', params.customFieldId] });
    },


  });

  return { executeCustomFieldAction: mutation.mutate, isUpdating, setIsUpdating, executeCustomFieldActionMutation: mutation };
};



export default useCustomFieldMutation;

