import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import { FormProvider, SubmitHandler, useForm, useFormContext, useWatch } from 'react-hook-form';
import { Button, Box, Container, Theme, useMediaQuery, Typography, Alert, Snackbar, IconButton, Tooltip, DialogContent, DialogTitle, Dialog, DialogActions } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { SmartStepper } from '../WorkflowRules/Stepper';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { createAgentsQueryOptions, createAllWorkflowRulesQueryOptions } from '@/services/queries/workflowOptions';
import { createMergeFieldMapOptions } from '@/services/queries/notificationOptions';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import { LoadingButton } from '@mui/lab';
import { DeleteIcon } from 'lucide-react';
import ConfirmModal from '@/components/Molecules/Modal/ConfirmModal.component';
import { ContentCopy } from '@mui/icons-material';

const templateSchema = z.object({
  title: z.string().min(1, { message: 'Title is required' }),
  description: z.string().optional(),
});

const initialTemplate = {
  title: '',
  description: '',
}

type Template = z.infer<typeof templateSchema>;

const TemplateDrawer = () => {
  const navigate = useNavigate();
  const params = useParams({ from: '/_auth/templates/$templateId' });
  const { variant, customerId, title } = useSearch({ from: '/_auth/templates/$templateId' })
  const [activeStep, setActiveStep] = useState(0);

  const mergeFieldMap = useQuery(createMergeFieldMapOptions(customerId))

  const methods = useForm<Template>({
    mode: 'all',
    defaultValues: initialTemplate,
    // shouldUnregister: false,
    resolver: zodResolver(templateSchema)
  });

  // Query workflow rule data based on ID

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));


  const [isOpen, setIsOpen] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [openTemplateFieldsDialog, setOpenTemplateFieldsDialog] = useState(false);

  // Open drawer when component mounts
  useEffect(() => {
    setIsOpen(true)
  }, [])

  const handleClose = () => {
    setIsOpen(false)
    // Wait for animation to complete before navigating
    setTimeout(() => {
      navigate({ to: '/templates' })
    }, 300) // Match this with MUI's transition duration
  }

  const handleCloseDrawer = () => {
    handleClose()
    setActiveStep(0);
    // methods.reset(editCustomerValues2);
    navigate({ to: '/templates' });
  };

  const handleSubmit: SubmitHandler<any> = (formValues: any) => {
    console.log('formValues : ', formValues);

    if (variant === 'edit') {
    } else {
    }
    return undefined
  }

  const handleComplete = () => {
    console.log('handleComplete')
    console.log(methods.getValues())
  }

  const handleDelete = () => {
    console.log('handleDelete')
    setIsDeleting(true);
    // executeTicketTypeAction({
    //   actionType: 'deleteTicketType',
    //   payload: {
    //     TypeID: parsedTicketType?.TypeID,
    //   },
    // });
  };

  useEffect(() => {
    if (variant === 'edit') {
      methods.setValue('title', title)
    }
  }, [variant, title])

  const TemplateFieldsDialog = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleClose = () => {
      setOpenTemplateFieldsDialog(false);
    };


    const handleCopy = (text: any) => {
      navigator.clipboard.writeText(text).then(() => {
        setSnackbarOpen(true);
      });
    };

    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };

    return (
      <>
        <Dialog open={openTemplateFieldsDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Merge Fields</DialogTitle>
          <DialogContent>
            <h4 style={{ marginBottom: '8px' }}>
              Below are the list of merge fields available to use inside your merge document.
            </h4>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: '32px', rowGap: '8px', alignItems: 'center' }}>
              <div style={{ fontWeight: 'bold', textAlign: 'left', textDecoration: 'underline' }}>Base/Custom Field Name</div>
              <div style={{ fontWeight: 'bold', textAlign: 'left', textDecoration: 'underline' }}>Merge Field Name</div>
              {mergeFieldMap.data?.map((field, index) => (
                <React.Fragment key={index}>
                  <div style={{ textAlign: 'left' }}>{field.CustomFieldName}:</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '8px' }}>{field.MergeFieldName}</span>
                    <Tooltip title="Copy to clipboard">
                      <IconButton size="small" onClick={() => handleCopy(field.MergeFieldName)}>
                        <ContentCopy style={{ fontSize: '14px' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='outlined'>
              Close
            </Button>
          </DialogActions>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message="Copied to clipboard"
          />
        </Dialog>
      </>
    )
  }


  return (
    <DrawerLayout
      open={isOpen}
      onClose={handleCloseDrawer}
      anchor="right"
      keepMounted
      width={'50%'}
      Header={
        <DrawerHeader
          close={handleCloseDrawer}
          title={variant === 'edit' ? 'Edit Template' : 'New Template'}
        />
      }
    >
      <Grid
        container
        style={{
          height: '100%',
          width: '100%',
        }}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent={'space-between'}
        flexWrap="nowrap"
        padding={4}
        overflow="auto"
      >
        <FormProvider {...methods}>
          {/* @ts-ignore */}
          <Container maxWidth='xl' component='form' onSubmit={methods.handleSubmit(handleSubmit)} sx={{
            width: '75%',
            overflowY: 'auto',
            mt: 5,
            height: '98%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            mb: 4,
          }}>
            <Box sx={{ mb: 4 }}>
              {variant === 'new' && (
                <>
                  <Box sx={{ mb: 4 }}>

                    <Alert severity='info'>

                      <Typography variant='subtitle1'>
                        A template consists of a Microsoft Word mail merge document. The extension of the document must be .docx and the file must be saved as a Word document not a Word template format. To see a list of available fields {" "}
                        <a onClick={() => setOpenTemplateFieldsDialog(true)} style={{ cursor: 'pointer', textDecoration: 'underline' }}><b>click here.</b></a>
                      </Typography>
                    </Alert>
                  </Box>

                </>

              )}
              <TextBox
                name='title'
                fullWidth
                label='Template Title'
                sx={{ mb: 4 }}
              />
              {variant === 'new' && (
                <Box>
                  <Typography variant='h6'>
                    Upload Template File
                  </Typography>
                  <input
                    accept=".docx"
                    id="upload-template"
                    type="file"
                  // style={{ display: 'none' }}
                  // ref={}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                borderTop: '1px solid',
                borderColor: 'grey.300',
                p: 5,
                display: 'flex',
                justifyContent: 'space-between',
                gap: '1rem'
              }}>
              <LoadingButton
                loading={isDeleting}
                color="error"
                startIcon={<DeleteIcon />}
                size='small'
                onClick={() => setOpenModal(true)}>
                Delete
              </LoadingButton>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button variant="outlined" onClick={handleCloseDrawer} size='small'>
                  Cancel & Close
                </Button>
                {openModal && (
                  <ConfirmModal
                    open={openModal}
                    setOpen={setOpenModal}
                    title="Are you sure?"
                    content="This action will permanently delete this template."
                    onSuccess={handleDelete}
                    onClose={() => setOpenModal(false)}
                    cancelButtonText="Cancel"
                    confirmButtonText="Delete"
                  />
                )}
                <LoadingButton loading={isUpdating} variant="contained" type="submit" size='small'>
                  Save
                </LoadingButton>
              </Box>
            </Box>
            <TemplateFieldsDialog />
          </Container>

        </FormProvider>
      </Grid>
    </DrawerLayout >
  )
}

export default TemplateDrawer