export const getColor = (colorValue: number) => {
  const color = `#${(colorValue >>> 8).toString(16).padStart(6, '0')}`;
  return color;
};

export const hexToNumericalColorValue = (hex: string): number => {
  const cleanHex = hex.replace('#', '');
  if (cleanHex.length !== 6) {
    console.warn('hexToColorValue: Invalid hex length. Expected 6 characters.', cleanHex);
    return 0;
  }
  const parsed = parseInt(cleanHex, 16);
  if (isNaN(parsed)) {
    console.warn('hexToColorValue: Parsed value is NaN.', cleanHex);
    return 0;
  }
  return (parsed << 8) >>> 0;
};

