import CustomFieldsAdminDrawer from '@/app/pages/CustomFields/CustomFieldsAdminDrawer';
import { createFieldByIdQueryOptions } from '@/hooks/fetches/CustomFields/useCustomFields.service';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

export const Route = createFileRoute('/_auth/custom-fields/$customFieldId')({
  component: () => <CustomFieldsAdminDrawer />,
  params: {
    parse: (params) => ({
      customFieldId: z.number().int().parse(Number(params.customFieldId)),
    }),
    stringify: ({ customFieldId }) => ({ customFieldId: `${customFieldId}` }),
  },
  loader: async ({ params, context }) => {
    await Promise.all([
      context.queryClient.ensureQueryData(createFieldByIdQueryOptions(params.customFieldId))
    ]);
  },
  validateSearch: (search) => {
    return z
      .object({
        variant: z.enum(['edit', 'new']).default('edit'),
        // showSecondaryContact: z.boolean().default(false)
      })
      .parse(search);
  },
});
