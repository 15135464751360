import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { NodeProps, Node, Position } from '@xyflow/react';
import { NodeWrapper } from './NodeWrapper';
import { Input } from './Input';
import { z } from 'zod';
import { useFormValidation } from './useFormValidation';
import { TagInput } from './TagInput';
import WorkflowRuleStep1 from '@/app/pages/WorkflowRulesDrawer/WorkflowRuleStep1';
import WorkflowRuleStep2 from '@/app/pages/WorkflowRulesDrawer/WorkflowRuleStep2';

export const triggerNodeSchema = z.object({
  label: z.string().min(1, 'Label is required'),
  customerId: z.string().min(1, 'Customer ID is required')
    .regex(/^CUST-\d{3,}$/, 'Customer ID must be in format CUST-XXX'),
  customerName: z.string().min(2, 'Customer name must be at least 2 characters'),
  customerEmail: z.string().email('Invalid email format').optional(),
  tags: z.array(z.string()).optional(),
});

export type TriggerNodeData = z.infer<typeof triggerNodeSchema>;

const NodeContent = styled.div`
  min-width: 300px;
`;

const ValidationMessage = styled.div<{ isError?: boolean }>`
  margin-top: 8px;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  background-color: ${({ isError }) => isError ? '#ffebee' : '#e8f5e9'};
  color: ${({ isError }) => isError ? '#c62828' : '#2e7d32'};
`;

export type TriggerNode = Node<{
  label: string;
  customerId: string;
  customerName: string;
  customerEmail: string;
  tags: string[];
}, 'trigger'>;

export const TriggerNode = (props: NodeProps<TriggerNode>) => {
  const { label, customerId, customerName, customerEmail, tags } = props.data;
  const { errors, validate } = useFormValidation(triggerNodeSchema);

  const handleChange = useCallback((field: keyof TriggerNodeData, value: any) => {
    // const newData = {
    //   ...props.data,
    //   [field]: value
    // };

    // onChange?.(newData);
    // const isValid = validate(newData);
    // onValidated?.(isValid);
    console.log('hello')
  }, [props.data, validate]);

  return (
    <NodeWrapper type="trigger" label={label}>
      <NodeContent>
        <WorkflowRuleStep1 />
        <WorkflowRuleStep2 />
        {/* <Input
          label="Label"
          value={label}
          onChange={(e) => handleChange('label', e.target.value)}
          error={errors.label}
        />

        <Input
          label="Customer ID"
          value={customerId}
          placeholder="CUST-XXX"
          onChange={(e) => handleChange('customerId', e.target.value)}
          error={errors.customerId}
        />

        <Input
          label="Customer Name"
          value={customerName}
          onChange={(e) => handleChange('customerName', e.target.value)}
          error={errors.customerName}
        />

        <Input
          label="Customer Email"
          type="email"
          value={customerEmail || ''}
          onChange={(e) => handleChange('customerEmail', e.target.value)}
          error={errors.customerEmail}
        />

        <TagInput
          label="Tags"
          tags={tags || []}
          onChange={(tags) => handleChange('tags', tags)}
        />

        {Object.keys(errors).length > 0 && (
          <ValidationMessage isError>
            Please fix the validation errors to proceed
          </ValidationMessage>
        )} */}
      </NodeContent>
    </NodeWrapper>
  );
};
