import useSettingsMutation from './useSettingsMutation.service';
import { queryOptions, useQuery } from '@tanstack/react-query';
import SettingsService from '@/services/settings.service';

interface IUseSettings {
  // getSettings: any;
  updateSettings: any;
  isUpdatingSettings: any;
  setIsUpdatingSettings: any;
}

const useSettings = (agentId?: number): IUseSettings => {
  const { updateSettings, isUpdatingSettings, setIsUpdatingSettings } = useSettingsMutation();

  const settingsService = SettingsService();

  // const getSettings = useQuery<any>({
  //   queryKey: ['settings', agentId],
  //   queryFn: async () => {
  //     if (!agentId) return new Promise((resolve) => resolve(null));
  //     return new Promise(async (resolve) => {
  //       await settingsService
  //         .getSettingsByAgentId({
  //           agentId,
  //         })
  //         .then((res) => {
  //           resolve(res?.data);
  //         });
  //     });
  //   },
  //   staleTime: 1000 * 60 * 5, // 5 minutes
  //   refetchInterval: 1000 * 60, // 1 Minute
  //   enabled: !!agentId,
  //   refetchOnWindowFocus: false,
  // });

  return {
    // getSettings,
    updateSettings,
    isUpdatingSettings,
    setIsUpdatingSettings,
  };
};

export default useSettings;

export interface ISettings {
  agentId: number;
}

export const createUserSettingsOptions = (agentId: number) => {
  const settingsService = SettingsService();
  return queryOptions({
    queryKey: ['agentContext', agentId],
    queryFn: async () => {
      const response = await settingsService.getSettingsByAgentId({ agentId });
      return response.data;
    },
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    enabled: (!!agentId),
    refetchOnWindowFocus: false,
  });
};