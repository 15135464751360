import { defaultValues } from '@/interfaces/schemas/ticketListFilter.schema'
import { CircularProgress, Container, FormControl, IconButton, InputLabel, ListSubheader, MenuItem, Paper, Select, TextField } from '@mui/material'
import Grid from "@mui/material/Grid2"
import { Box, Button, Divider, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib'
import { Plus, X } from 'lucide-react'
import React, { useCallback, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { CFTypeIdDetail } from '../WorkflowRules/useWorkflowRuleTable'
import { IcfTDateCasesArray, IcfTDropdownCasesArray, IcfTIntegerCasesArray, IcfTStringCasesArray, IcfTToggleCasesArray, turnEqualityValueToDisplayValue, turnEqualityValueToNumber, turnNumberToEqualityValue, turnOldNumberToEqualityValue } from '@/constants/filter/filter.constant'
import CheckboxElement from '@/components/Molecules/Checkbox/CheckboxElement.component'
import { NewCondition, WorkflowRule } from './interface'
import { Condition } from '@/components/Organisms/BasicDrawer/BasicDrawer.component'
// import { Condition, WorkflowRule } from './WorkflowRuleDrawer'
import { createBaseFieldsQueryOptions, createCustomerFieldsQueryOptions } from '@/hooks/fetches/useTicket.service';
import { createTicketTypesByCustomerOptions } from '@/services/queries/statusTypeOptions'
import { useQuery } from '@tanstack/react-query'

interface IType {
  name: 'any_conditions' | 'all_conditions',
}

const WorkflowRuleStep2 = () => {

  const methods = useFormContext()
  const { watch, control, setValue } = methods

  const customerWatch = useWatch({ control, name: 'customer' })

  const baseFields = useQuery(createBaseFieldsQueryOptions())
  const ticketTypesByCustomer = useQuery(createTicketTypesByCustomerOptions(customerWatch?.id))
  const customFieldsByCustomer = useQuery(createCustomerFieldsQueryOptions(customerWatch?.id))

  const { fields: allConditionsFields, append: allConditionsAppend, remove: allConditionsRemove, } = useFieldArray({
    control: control,
    name: 'all_conditions',
    shouldUnregister: false
  });

  const { fields: anyConditionsFields, append: anyConditionsAppend, remove: anyConditionsRemove, } = useFieldArray({
    control: control,
    name: 'any_conditions',
    shouldUnregister: false
  });

  const handleAddAnyCondition = () => {
    anyConditionsAppend({
      condition: '',
      equality: '',
      equalityNumber: 0,
      value: '',
      conditionObj: {},
      type: '',
      allAny: ''
    });
  };

  const handleAddAllCondition = () => {
    allConditionsAppend({
      condition: '',
      equality: '',
      equalityNumber: 0,
      value: '',
      conditionObj: {},
      type: '',
      allAny: ''
    });
  };

  // const handleConditionOnChange = (item: any, index: number, selectedValue: string) => {
  // let conditionObject;

  // const baseField = baseFields?.data.find((item: any) => item.Meta.Name === selectedValue)
  // if (baseField) {
  //   conditionObject = baseField;
  //   setValue(`any_conditions.${index}.type`, "baseField");

  // } else {
  //   const customField = customFieldsByCustomer?.data.find((item: any) => item.Meta.Name === selectedValue);
  //   if (customField) {
  //     conditionObject = customField;
  //     setValue(`any_conditions.${index}.type`, "customField");
  //   }
  // }
  // // console.log(conditionObject)
  // setValue(`any_conditions.${index}.conditionObj`, conditionObject);
  // //! sanitize
  // setValue(`any_conditions.${index}.value`, "");

  //   const conditionObject = baseFields?.data.find((item: any) => item.Meta.Name === selectedValue)
  //     || customFieldsByCustomer?.data.find((item: any) => item.Meta.Name === selectedValue);

  //   if (conditionObject) {
  //     setValue(`any_conditions.${index}`, {
  //       ...watch(`any_conditions.${index}`),
  //       condition: selectedValue,
  //       type: baseFields?.data.includes(conditionObject) ? 'baseField' : 'customField',
  //       conditionObj: conditionObject,
  //       value: "",
  //     }, { shouldValidate: true });
  //   }
  // };

  const handleConditionOnChange = useCallback((item: any, index: number, selectedValue: any, type: IType) => {
    const conditionObject = baseFields?.data.find((item: any) => item.Meta.Name === selectedValue)
      || customFieldsByCustomer?.data.find((item: any) => item.Meta.Name === selectedValue);

    if (conditionObject) {
      const updates = {
        [`${type.name}.${index}.condition`]: selectedValue,
        [`${type.name}.${index}.type`]: baseFields?.data.includes(conditionObject) ? "baseField" : "customField",
        [`${type.name}.${index}.conditionObj`]: conditionObject,
        [`${type.name}.${index}.value`]: ""
      };

      Object.entries(updates).forEach(([key, value]) => {
        setValue(key, value, { shouldValidate: true });
      });
    }
  }, [baseFields?.data, customFieldsByCustomer?.data, ticketTypesByCustomer?.data, setValue]);

  const handleEqualityOnChange = (item: any, index: number, selectedValue: any, type: IType) => {
    // console.log(selectedValue)
    if (selectedValue === "Is Set" || selectedValue === "Is Not Set" || selectedValue === "Today") {
      setValue(`${type.name}.${index}.value`, "");
    }
    setValue(`${type.name}.${index}.equalityNumber`, turnEqualityValueToNumber(selectedValue));
  }

  const ValueController = React.memo(({ condition, index, children, rules, type }: any) => (
    <Controller
      name={`${type.name}.${index}.value`}
      control={control}
      rules={{ required: 'Value is required', ...rules }}
      render={({ field }) => children(field, index)}
    />
  ));


  const renderDropdownType = (condition: any, index: number, type: IType,) => (
    <>
      <ValueController
        type={type}
        condition={condition}
        index={index}
      >
        {(field: any, index: number) => (
          <>
            <InputLabel id="condition">Value</InputLabel>
            <Select
              size="small"
              sx={{ width: '100%' }}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              {...field}
            >
              {condition?.Type.OptLabels.map((option: any, index: number) => {
                return (
                  <MenuItem
                    key={index}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                )
              })
              }
            </Select>
          </>
        )}
      </ValueController>
    </>

  );

  const renderDateType = (condition: any, index: number, type: IType) => (
    <ValueController
      type={type}
      condition={condition}
      index={index}
    >
      {(field: any, index: number) => (
        <>
          {condition?.Type.IncludeTime === true ? <DateTime time={true} {...field} /> : <DateTime time={false} {...field} />}
        </>
      )}
    </ValueController>
  );

  const renderStringType = (condition: any, index: number, type: IType) => (
    <ValueController
      type={type}
      condition={condition}
      index={index}
    >
      {(field: any, index: number) => (
        <>
          <InputLabel id="value">Value</InputLabel>
          <TextField placeholder="Type a Value" size="small" sx={{ width: '100%' }} {...field}></TextField>
        </>
      )}
    </ValueController>
  )

  const renderIntegerType = (condition: any, index: number, type: IType) => (
    <ValueController
      type={type}
      condition={condition}
      index={index}
    >
      {(field: any, index: number) => (

        <>
          <InputLabel id="value">Value</InputLabel>
          <TextField type="number" placeholder="Type a Value" size="small" sx={{ width: '100%' }} max={condition?.Type.Maximum} min={condition?.Type.Minimum} step={condition?.Type.Step} {...field}></TextField>
        </>
      )}
    </ValueController>
  );

  const renderToggleType = (condition: any, index: number, type: IType) => (
    <>
      <InputLabel id="value">Value</InputLabel>
      <CheckboxElement
        name={`${type.name}.${index}.value`}
        control={control}
        size="small"
        // label="Value"
        required
        // fullWidth
        onChange={(value: any) => {
          console.log(value)
          setValue(`${type.name}.${index}.value`, value.target.checked)
        }}
      />
    </>
  );

  const renderEqualityOptions = (condition: any, index: number) => {
    console.log(condition)
    if (condition && condition?.TypeId && CFTypeIdDetail(condition?.TypeId).ClassBinding) {
      switch (CFTypeIdDetail(condition.TypeId).ClassBinding) {
        case 'cfTDropdown': return IcfTDropdownCasesArray;
        case 'cfTDate': return IcfTDateCasesArray;
        case 'cfTString': return IcfTStringCasesArray;
        case 'cfTInteger': return IcfTIntegerCasesArray;
        case 'cfTToggle': return IcfTToggleCasesArray;
        default: return []
      }
    }
  };

  const renderDynamicField = React.useCallback((selectedObject: any, index: number, type: IType) => {
    if (!selectedObject?.TypeId) return null;

    const binding = CFTypeIdDetail(selectedObject.TypeId).ClassBinding;
    const fieldMap = {
      'cfTDropdown': () => renderDropdownType(selectedObject, index, type),
      'cfTDate': () => renderDateType(selectedObject, index, type),
      'cfTString': () => renderStringType(selectedObject, index, type),
      'cfTInteger': () => renderIntegerType(selectedObject, index, type),
      'cfTToggle': () => renderToggleType(selectedObject, index, type)
    };

    // return fieldMap[binding]?.() ?? <div>default</div>;
    return fieldMap[binding as keyof typeof fieldMap]?.() ?? <div>default</div>;
  }, []);

  const ConditionRow = React.memo(({ key, item, index, type }: { key: number; item: NewCondition; index: number; type: IType }) => {
    const { watch, control } = useFormContext<WorkflowRule>();
    return (
      <Paper elevation={0} sx={{ p: 2, mb: 4, border: '1px solid #e0e0e0', width: '90%' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid size={6}>
            <Controller
              key={key}
              name={`${type.name}.${index}.condition`}
              control={control}
              rules={{ required: 'Condition is required' }}
              render={({ field }) => (
                <>
                  <InputLabel id="condition">Condition</InputLabel>
                  <Select
                    {...field}
                    size="small"
                    onChange={(e) => {
                      field.onChange(e); // default RHF onChange
                      handleConditionOnChange(item, index, e.target.value, type); // custom handler
                    }}
                    placeholder={"Select a Condition"}
                    style={{ width: '100%' }}
                    endAdornment={
                      (baseFields.isPending || customFieldsByCustomer.isPending) ? (
                        <CircularProgress
                          size={20}
                          sx={{
                            position: 'absolute',
                            right: 25,
                            color: 'grey.500'
                          }}
                        />
                      ) : null
                    }
                  >
                    <ListSubheader>Base Fields</ListSubheader>
                    {baseFields?.data && baseFields?.data.map((item2: any) => {
                      return (
                        <MenuItem value={item2.Meta.Name}>{item2.Meta.Name}</MenuItem>
                      )
                    })}
                    <ListSubheader>Custom Fields</ListSubheader>
                    {customFieldsByCustomer?.data && customFieldsByCustomer?.data.map((item2: any) => {
                      return (
                        <MenuItem value={item2.Meta.Name}>{item2.Meta.Name}</MenuItem>
                      )
                    })}
                  </Select>
                </>
              )}
            />
          </Grid>
          <Grid size={5}>
            {watch(`${type.name}.${index}.condition`) && (
              <Controller
                name={`${type.name}.${index}.equality`}
                control={control}
                rules={{ required: 'Equality is required' }}
                render={({ field }) => (
                  <>
                    <InputLabel id="condition">Equality</InputLabel>
                    <Select
                      {...field}
                      size="small"
                      onChange={(e) => {
                        field.onChange(e); // default RHF onChange
                        handleEqualityOnChange(item, index, e.target.value, type); // custom handler
                      }}
                      placeholder={"Equals..."}
                      style={{ width: '100%' }}
                    >
                      {renderEqualityOptions(watch(`${type.name}.${index}.conditionObj`), index)?.map((item: any) => {
                        return (
                          <MenuItem value={item.name}>{item.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </>
                )}
              />
            )}

          </Grid>
          <Grid size={1}>
            <IconButton size="small" color="error" onClick={() => {
              if (type?.name === 'any_conditions') {
                anyConditionsRemove(index)
              } else {
                allConditionsRemove(index)
              }
            }}>
              <X size={20} />
            </IconButton>
          </Grid>
          <Grid size={12}>
            <Box sx={{ width: '100%' }}>

              {watch(`${type.name}.${index}.condition`) !== "" && watch(`${type.name}.${index}.equality`) !== "Is Set" && watch(`${type.name}.${index}.equality`) !== "Is Not Set" && watch(`${type.name}.${index}.equality`) !== "Today" && (
                renderDynamicField(watch(`${type.name}.${index}.conditionObj`), index, type)
              )}
            </Box>
          </Grid>

        </Grid>
      </Paper>
    )
  });

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant='button' color="text.primary" sx={{ mt: 4 }}>
        Select Conditions
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Grid container>

        <Grid size={{ xs: 12, md: 6 }}>

          {/* {match Any} */}
          <Box sx={{ height: '100%' }}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Match Any Conditions
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Any conditions in this column must be true (OR)
              </Typography>
            </Box>

            <Box >
              {anyConditionsFields.map((item, index) => (
                <ConditionRow key={index} item={item} index={index} type={{ name: 'any_conditions' }} />
              ))}
            </Box>
            <Button
              id='add-condition-btn-any'
              variant="outlined"
              startIcon={<Plus size={20} />}
              onClick={handleAddAnyCondition}
              sx={{ mt: 2 }}
            >
              Add Match Any Condition
            </Button>
            <Button
              id='add-condition-btn-any'
              variant="outlined"
              startIcon={<Plus size={20} />}
              type='submit'
              sx={{ mt: 2 }}
            >
              submit
            </Button>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          {/* {match All} */}
          <Box sx={{ height: '100%' }}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Match All Conditions
              </Typography>
              <Typography variant="body2" color="text.secondary">
                All conditions in this column must be true (AND)
              </Typography>
            </Box>

            <Box >
              {allConditionsFields.map((item, index) => (
                <ConditionRow key={index} item={item} index={index} type={{ name: 'all_conditions' }} />
              ))}
            </Box>

            <Button
              id='add-condition-btn-all'
              variant="outlined"
              startIcon={<Plus size={20} />}
              sx={{ mt: 2 }}
              onClick={handleAddAllCondition}
            >
              Add Match All Condition
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default WorkflowRuleStep2