import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Box, Card, List, ListItem, Orientation, Tooltip, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { GripVertical } from 'lucide-react';
import { getColor } from '@/util/getColor.utils';
import { SimpleStatus } from '@/interfaces/status.interface';

interface DraggableListProps {
  initialStatuses: SimpleStatus[] | [];
  onChange?: (newStatuses: any) => void;
}

// the preview that a user will see as they're dragging the list item
const ListItemPreview = ({ status, index }: { status: any; index: number }) => {
  const theme = useTheme()
  return (
    <ListItem
      key={status.StatusId}
      sx={{
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: 2,
        cursor: 'grab',
        mb: 1,
        width: 250,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      <Box
        sx={{
          width: '1rem',
          height: '1rem',
          backgroundColor: getColor(status?.Color),
          borderRadius: '2px',
          mr: 1,
        }}
      />
      <Box>{status.Name}</Box>
      {index + 1}
    </ListItem>
  );
};

const DraggableList = ({ initialStatuses, onChange }: DraggableListProps) => {
  const [statuses, setStatuses] = useState<SimpleStatus[]>([]);
  useEffect(() => {
    if (Array.isArray(initialStatuses)) {
      setStatuses(initialStatuses);
    }
  }, [initialStatuses]);

  // drop indicator component
  const edgeToOrientationMap: Record<Edge, 'horizontal' | 'vertical'> = {
    top: 'horizontal',
    bottom: 'horizontal',
    left: 'vertical',
    right: 'vertical',
  };
  const strokeSize = 2;
  const terminalSize = 8;

  const DropIndicator = ({ edge, gap }: { edge: Edge; gap: string }) => {
    const lineOffset = `calc(-0.5 * (${gap} + ${strokeSize}px))`;
    const isHorizontal = edgeToOrientationMap[edge] === 'horizontal';

    return (
      <Box
        sx={{
          position: 'absolute',
          zIndex: 10,
          backgroundColor: 'blue',
          ...(isHorizontal
            ? { height: `${strokeSize}px`, width: '100%' }
            : { width: `${strokeSize}px`, height: '100%' }),
          ...(edge === 'top' && { top: lineOffset, left: 0, right: 0 }),
          ...(edge === 'bottom' && { bottom: lineOffset, left: 0, right: 0 }),
          ...(edge === 'left' && { left: lineOffset, top: 0, bottom: 0 }),
          ...(edge === 'right' && { right: lineOffset, top: 0, bottom: 0 }),
          '&::before': {
            content: '""',
            position: 'absolute',
            backgroundColor: 'blue',
            borderRadius: '50%',
            ...(isHorizontal
              ? { height: `${terminalSize}px`, width: `${terminalSize}px`, top: '-4px', left: '-4px' }
              : { height: `${terminalSize}px`, width: `${terminalSize}px`, left: '-4px', top: '-4px' }),
          },
        }}
      />
    );
  };

  // set the status in the correct spot int the array 
  const moveStatus = useCallback((sourceId: any, targetId: any) => {
    setStatuses((prev: SimpleStatus[]) => {
      const sourceIndex = prev.findIndex((s: SimpleStatus) => s.StatusID === sourceId);
      const targetIndex = prev.findIndex((s: SimpleStatus) => s.StatusID === targetId);
      if (sourceIndex === -1 || targetIndex === -1) return prev;

      const updated = [...prev];
      const [movedItem] = updated.splice(sourceIndex, 1);
      updated.splice(targetIndex, 0, movedItem);

      if (onChange) {
        onChange(updated);
      }

      return updated;
    });
  }, []);

  const DraggableListItem = ({ status, index }: { status: any; index: any }) => {
    const theme = useTheme()
    const ref = useRef<HTMLLIElement | null>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [preview, setPreview] = useState<any>();
    const [aboutToDrop, setAboutToDrop] = useState<Edge | null>(null);

    useEffect(() => {
      const element = ref?.current;
      if (element) {
        return combine(
          draggable({
            element,
            getInitialData() {
              return { ...status };
            },
            onDragStart() {
              setIsDragging(true);
            },
            onDrop() {
              setIsDragging(false);
              setPreview(null);
            },
            onGenerateDragPreview({ nativeSetDragImage }) {
              setCustomNativeDragPreview({
                nativeSetDragImage,
                render({ container }) {
                  setPreview(container);
                },
              });
            },
          }),
          dropTargetForElements({
            element,
            getData({ input }) {
              const data = { ...status };
              return attachClosestEdge(data, {
                element,
                input,
                allowedEdges: ['top', 'bottom'],
              });
            },
            getIsSticky() {
              return true;
            },
            onDragEnter({ self }) {
              const closestEdge = extractClosestEdge(self?.data);
              setAboutToDrop(closestEdge);
            },
            onDrag({ self }) {
              const closestEdge = extractClosestEdge(self.data);
              setAboutToDrop(closestEdge);
            },
            onDragLeave() {
              setAboutToDrop(null);
            },
            onDrop({ source, self }) {
              setAboutToDrop(null);
              if (source.data.StatusID !== self.data.StatusID) {
                moveStatus(source?.data?.StatusID, self.data.StatusID);
              }
            },
          }),
        );
      }
    }, [status]);
    return (
      <ListItem
        ref={ref}
        sx={{
          border: isDragging ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.main}`,
          borderRadius: '8px',
          padding: 2,
          cursor: isDragging ? 'grabbing' : 'pointer',
          backgroundColor: isDragging ? theme.palette.primary.main : theme.palette.background.paper,
          position: 'relative',
        }}>
        <Box
          sx={{
            opacity: isDragging ? 0.3 : 1,
            display: 'flex',
            alignItems: 'center',
          }}>
          <GripVertical />
        </Box>
        <Box
          sx={{
            opacity: isDragging ? 0.3 : 1,
            width: '1rem',
            height: '1rem',
            backgroundColor: getColor(status?.Color),
            borderRadius: '2px',
            ml: 2,
          }}
        />
        <Box sx={{ opacity: isDragging ? 0.3 : 1, ml: 5 }}>{status.Name}</Box>
        <Box
          sx={{
            opacity: isDragging ? 0.3 : 1,
            position: 'absolute',
            right: '16px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}>
          <Tooltip title={'id: ' + status.StatusID} placement="right">
            {index + 1}
          </Tooltip>
        </Box>
        {preview && createPortal(<ListItemPreview status={status} index={index} />, preview)}
        {aboutToDrop && <DropIndicator edge={aboutToDrop} gap="8px" />}
      </ListItem>
    );
  };

  // return the actual list
  return (
    <Card sx={{ p: 2, height: '75%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <List
        sx={{
          p: 2,
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          listStyle: 'none',
          maxHeight: '100%',
          overflow: 'auto',
        }}>
        {statuses?.map((status: SimpleStatus, index: number) => (
          <DraggableListItem index={index} status={status} key={index} />
        ))}
      </List>
    </Card>
  );
};

export default DraggableList;
