import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext, useWatch } from 'react-hook-form';
import { Button, Box, Container, Theme, useMediaQuery, Typography, Alert, Snackbar, IconButton, Tooltip, DialogContent, Dialog, DialogActions, Checkbox, TextField, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { SmartStepper } from '../WorkflowRules/Stepper';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import { LoadingButton } from '@mui/lab';
import { Copy, Trash2Icon, Plus } from 'lucide-react';
import ConfirmModal from '@/components/Molecules/Modal/ConfirmModal.component';
import { ContentCopy } from '@mui/icons-material';
import { createCustomerAdminQuery } from '@/services/queries/customerOptions';
import { AutoComplete } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import DateTimePickerElement from '@/components/Organisms/Date/DateTimePicker.component';
import TimePickerElement from '@/components/Organisms/Date/TimePicker.component';
import utc from 'dayjs/plugin/utc';
import useCustomerMutation from '@/hooks/fetches/useCustomerMutations';
import { createRolesQueryOptions } from '@/hooks/fetches/usePermission.service';

dayjs.extend(utc);

// type Customer = z.infer<typeof customerSchema>;

const RolesPermissionsDrawer = () => {
  const navigate = useNavigate();
  const params = useParams({ from: '/_auth/permissions/$roleId' });
  const { variant } = useSearch({ from: '/_auth/permissions/$roleId' })
  const [activeStep, setActiveStep] = useState(0);

  const methods = useForm({
    mode: 'all',
    // defaultValues: initialCustomer,
    // shouldUnregister: false,
    // resolver: zodResolver(customerSchema)
  });

  const rolesPermissions = useQuery(createRolesQueryOptions())


  const currentRole = rolesPermissions?.data?.find((role: any) => role?.roleId === params.roleId)
  console.log('currentRole : ', currentRole)

  // Query workflow rule data based on ID

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));


  const [isOpen, setIsOpen] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  // Open drawer when component mounts
  useEffect(() => {
    setIsOpen(true)
  }, [])

  const handleClose = () => {
    setIsOpen(false)
    // Wait for animation to complete before navigating
    setTimeout(() => {
      navigate({ to: '/permissions' })
    }, 300) // Match this with MUI's transition duration
  }

  const handleCloseDrawer = () => {
    handleClose()
    setActiveStep(0);
    // methods.reset(editCustomerValues2);
    navigate({ to: '/permissions' });
  };

  const handleSubmit: SubmitHandler<any> = (formValues: any) => {
    console.log('formValues : ', formValues);

    if (variant === 'edit') {

    } else {

    }
    return undefined
  }

  const handleComplete = () => {
    console.log('handleComplete')
    console.log(methods.getValues())
  }

  const handleDelete = () => {
    console.log('handleDelete')
    setIsDeleting(true);
  };

  useEffect(() => {
    if (variant === 'edit') {

    }
  }, [variant])


  return (
    <DrawerLayout
      open={isOpen}
      onClose={handleCloseDrawer}
      anchor="right"
      keepMounted
      width={'80%'}
      Header={
        <DrawerHeader
          close={handleCloseDrawer}
          title={variant === 'edit' ? 'Edit Role & Permissions' : 'New Role & Permissions'}
        />
      }
    >
      <FormProvider {...methods}>
        {/* @ts-ignore */}
        <Container maxWidth='xl' component='form' onSubmit={methods.handleSubmit(handleSubmit)} sx={{
          overflowY: 'auto',
          mt: 5,
          height: '98%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          mb: 4,
        }}>

          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: (theme: Theme) => theme.palette.background.paper,
              borderTop: '1px solid',
              borderColor: (theme: Theme) => theme.palette.divider,
              p: 5,
              display: 'flex',
              justifyContent: 'space-between',
              gap: '1rem'
            }}>
            <LoadingButton
              loading={isDeleting}
              color="error"
              startIcon={<Trash2Icon />}
              size='small'
              onClick={() => setOpenModal(true)}>
              Delete
            </LoadingButton>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button variant="outlined" onClick={handleCloseDrawer} size='small'>
                Cancel & Close
              </Button>
              {openModal && (
                <ConfirmModal
                  open={openModal}
                  setOpen={setOpenModal}
                  title="Are you sure?"
                  content="This action will permanently delete this customer."
                  onSuccess={handleDelete}
                  onClose={() => setOpenModal(false)}
                  cancelButtonText="Cancel"
                  confirmButtonText="Delete"
                />
              )}
              <LoadingButton loading={isUpdating} variant="contained" type="submit" size='small'>
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Container>

      </FormProvider>
    </DrawerLayout >
  )
}

export default RolesPermissionsDrawer