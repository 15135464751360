import { mkConfig, generateCsv, download } from 'export-to-csv';

const exportToCSV = (table: any, title?: string) => {

  const flattenObject = (obj: any): Record<string, any> => {
    const visibleColumns = table.getVisibleLeafColumns();
    console.log(visibleColumns)
    const result: Record<string, any> = {};
  
    visibleColumns.forEach((column: any) => {
      console.log(column)
      // Use the column's accessor function to get the correct value
      const value = column.accessorFn?.(obj) ?? obj[column.columnDef.header];
      if (value !== undefined) {
        result[column.columnDef.header] = value;
      }
    });
  
    return result;
  };

  // Utility function to clean data
  const cleanData = (data: any) => {
    return data.map((item: any) => {
      return Object.keys(item).reduce((acc, key) => {
        if (item[key] !== undefined && item[key] !== null) {
          // @ts-ignore
          acc[key] = item[key];
        }
        return acc;
      }, {});
    });
  };

  const rowData = table.getRowModel().rows.map((row: any) => flattenObject(row.original));
  const cleanedData = cleanData(rowData);
  console.log(cleanedData);

  if (cleanedData.length === 0) {
    console.error('No data to export');
    return;
  }

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    showTitle: true,
    title: title ? title : 'Export',
    useTextFile: false,
    useBom: true,
  });

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(cleanedData);
    console.log(csv);
    download(csvConfig)(csv);
  };

  return handleExportData();
};

export default exportToCSV;
