import { z } from 'zod';

export const reportSchema = z.object({
  customer_filter: z.preprocess(
    (val) => (val === null ? undefined : val),
    z.object({
      id: z.number().min(1, { message: 'Required' }),
      label: z.string().min(1, { message: 'Required' }),
    }),
  ),
  category_filter: z.preprocess(
    (val) => (val === null ? undefined : val),
    z.object({
      id: z.number().min(0, { message: 'Required' }),
      label: z.string().min(1, { message: 'Required' }),
    }),
  ),
});

export type ReportFilterSchema = {
  customer_filter: { id: number; label: string } | null;
  category_filter: { id: number; label: string } | null;
};

export const defaultValues: ReportFilterSchema = {
  customer_filter: null,
  category_filter: null,
};
