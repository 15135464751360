import type { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, IconButton, MuiDialog, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import RadioButton from '@/components/RadioButton/RadioButton.component';
import { useState } from 'react';

interface IProps extends DialogProps {
  setOpen: (open: boolean) => void;
  methods: any;
  hideCloseIcon?: boolean;
  hideOkayBtn?: boolean;
  hideCancelBtn?: boolean;
  onSuccess?: () => void;
  onClose?: () => void;
  disabled?: boolean;
  setIntegerData: React.Dispatch<any>;
}

const CustomValueConfirmationModal = (props: IProps) => {
  const {
    setIntegerData,
    onSuccess,
    methods,
    hideCloseIcon,
    hideCancelBtn,
    hideOkayBtn,
    setOpen,
    onClose,
    disabled,
    ...dialogProps
  } = props;

  const [comparison, setComparison] = useState<string>('');
  const [numericalValue, setNumericalValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleAddCustomValue = () => {
    const parsedValue = Number(numericalValue);

    // Validation
    if (numericalValue.trim() === '') {
      setError('Valid number is required.');
      return;
    }

    if (isNaN(parsedValue)) {
      setError('Please enter a valid number.');
      return;
    }

    if (parsedValue < 0 || parsedValue > 1000) {
      setError('Number must be between 0 and 1000.');
      return;
    }

    if (comparison) {
      const newOption = `${comparison} ${parsedValue}`;
      setIntegerData((prev: any) => [...prev, newOption]);
      methods.setValue('metaData.ParentFieldValue', newOption);
      onSuccess?.();
      onClose?.();
    } else {
      setError('Please select a comparison option.');
    }
  };

  const handleNumericalValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Allow only digits and optionally a decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      setNumericalValue(value);
      if (error) setError(''); // Clear error on valid input
    }
  };

  return (
    <MuiDialog {...dialogProps}>
      <DialogTitle sx={{ m: 2 }}>Add Custom Value</DialogTitle>
      <Divider />
      <DialogContent>
        <Card sx={{ p: 2, display: 'flex', gap: 5 }}>
          <Grid>
            <Typography>Enter Value</Typography>
            <TextBox
              id="numericalValue"
              name="numericalValue"
              value={numericalValue}
              onChange={handleNumericalValueChange}
              label="0 to 1000"
              fullWidth
              sx={{ mt: 3 }}
              error={Boolean(error)}
              helperText={error}
            />
          </Grid>
          <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>Display When Ticket Value Is</Typography>
            <RadioButton
              id="greaterThan"
              label="Greater Than"
              name="customValueRadioBtn"
              checked={comparison === 'Greater Than'}
              value={false}
              onDataChange={() => setComparison('Greater Than')}
              sxProps={{ p: 0, mt: 1, pl: 3 }}
            />
            <RadioButton
              id="lessThan"
              label="Less Than"
              name="customValueRadioBtn"
              checked={comparison === 'Less Than'}
              value={false}
              onDataChange={() => setComparison('Less Than')}
              sxProps={{ p: 0, mt: 1, pl: 3 }}
            />
            <RadioButton
              id="equals"
              label="Equals"
              name="customValueRadioBtn"
              checked={comparison === 'Equals'}
              value={false}
              onDataChange={() => setComparison('Equals')}
              sxProps={{ p: 0, mt: 1, pl: 3 }}
            />
          </Grid>
        </Card>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ m: 2 }}>
        {!hideCloseIcon && (
          <IconButton
            id="closeModalIcon"
            color="default"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
        {!hideCancelBtn && (
          <Button id="cancelBtn" color="error" onClick={onClose} size="small" variant="outlined">
            Cancel
          </Button>
        )}
        {!hideOkayBtn && (
          <Button
            id="okayBtn"
            disabled={disabled}
            size="small"
            disableElevation
            variant="contained"
            onClick={handleAddCustomValue}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
};

export default CustomValueConfirmationModal;
