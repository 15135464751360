/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as AuthImport } from './routes/_auth'
import { Route as AuthIndexImport } from './routes/_auth/index'
import { Route as AuthUserProfileImport } from './routes/_auth/user-profile'
import { Route as AuthReportsImport } from './routes/_auth/reports'
import { Route as AuthWorkflowRulesWorkflowruleIdImport } from './routes/_auth/workflow-rules/$workflowruleId'
import { Route as AuthTypeEditorTypeIdImport } from './routes/_auth/type-editor/$typeId'
import { Route as AuthTicketTicketIdImport } from './routes/_auth/ticket/$ticketId'
import { Route as AuthTemplatesTemplateIdImport } from './routes/_auth/templates/$templateId'
import { Route as AuthStatusEditorSortOrderImport } from './routes/_auth/status-editor/sort-order'
import { Route as AuthStatusEditorStatusIdImport } from './routes/_auth/status-editor/$statusId'
import { Route as AuthReportsReportFilterImport } from './routes/_auth/reports/$reportFilter'
import { Route as AuthPermissionsRoleIdImport } from './routes/_auth/permissions/$roleId'
import { Route as AuthFieldGroupsFieldGroupIdImport } from './routes/_auth/field-groups/$fieldGroupId'
import { Route as AuthCustomersCustomerIdImport } from './routes/_auth/customers/$customerId'
import { Route as AuthCustomFieldsCustomFieldIdImport } from './routes/_auth/custom-fields/$customFieldId'

// Create Virtual Routes

const AuthWorkflowRulesLazyImport = createFileRoute('/_auth/workflow-rules')()
const AuthTypeEditorLazyImport = createFileRoute('/_auth/type-editor')()
const AuthTemplatesLazyImport = createFileRoute('/_auth/templates')()
const AuthStatusEditorLazyImport = createFileRoute('/_auth/status-editor')()
const AuthReleaseNotesLazyImport = createFileRoute('/_auth/release-notes')()
const AuthPermissionsLazyImport = createFileRoute('/_auth/permissions')()
const AuthFieldGroupsLazyImport = createFileRoute('/_auth/field-groups')()
const AuthCustomersLazyImport = createFileRoute('/_auth/customers')()
const AuthCustomFieldsLazyImport = createFileRoute('/_auth/custom-fields')()
const AuthBatchUpdatesLazyImport = createFileRoute('/_auth/batch-updates')()
const AuthAgentsLazyImport = createFileRoute('/_auth/agents')()

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthWorkflowRulesLazyRoute = AuthWorkflowRulesLazyImport.update({
  id: '/workflow-rules',
  path: '/workflow-rules',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/workflow-rules.lazy').then((d) => d.Route),
)

const AuthTypeEditorLazyRoute = AuthTypeEditorLazyImport.update({
  id: '/type-editor',
  path: '/type-editor',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/type-editor.lazy').then((d) => d.Route),
)

const AuthTemplatesLazyRoute = AuthTemplatesLazyImport.update({
  id: '/templates',
  path: '/templates',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/templates.lazy').then((d) => d.Route),
)

const AuthStatusEditorLazyRoute = AuthStatusEditorLazyImport.update({
  id: '/status-editor',
  path: '/status-editor',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/status-editor.lazy').then((d) => d.Route),
)

const AuthReleaseNotesLazyRoute = AuthReleaseNotesLazyImport.update({
  id: '/release-notes',
  path: '/release-notes',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/release-notes.lazy').then((d) => d.Route),
)

const AuthPermissionsLazyRoute = AuthPermissionsLazyImport.update({
  id: '/permissions',
  path: '/permissions',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/permissions.lazy').then((d) => d.Route),
)

const AuthFieldGroupsLazyRoute = AuthFieldGroupsLazyImport.update({
  id: '/field-groups',
  path: '/field-groups',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/field-groups.lazy').then((d) => d.Route),
)

const AuthCustomersLazyRoute = AuthCustomersLazyImport.update({
  id: '/customers',
  path: '/customers',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/customers.lazy').then((d) => d.Route),
)

const AuthCustomFieldsLazyRoute = AuthCustomFieldsLazyImport.update({
  id: '/custom-fields',
  path: '/custom-fields',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/custom-fields.lazy').then((d) => d.Route),
)

const AuthBatchUpdatesLazyRoute = AuthBatchUpdatesLazyImport.update({
  id: '/batch-updates',
  path: '/batch-updates',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/batch-updates.lazy').then((d) => d.Route),
)

const AuthAgentsLazyRoute = AuthAgentsLazyImport.update({
  id: '/agents',
  path: '/agents',
  getParentRoute: () => AuthRoute,
} as any).lazy(() => import('./routes/_auth/agents.lazy').then((d) => d.Route))

const AuthUserProfileRoute = AuthUserProfileImport.update({
  id: '/user-profile',
  path: '/user-profile',
  getParentRoute: () => AuthRoute,
} as any)

const AuthReportsRoute = AuthReportsImport.update({
  id: '/reports',
  path: '/reports',
  getParentRoute: () => AuthRoute,
} as any)

const AuthWorkflowRulesWorkflowruleIdRoute =
  AuthWorkflowRulesWorkflowruleIdImport.update({
    id: '/$workflowruleId',
    path: '/$workflowruleId',
    getParentRoute: () => AuthWorkflowRulesLazyRoute,
  } as any)

const AuthTypeEditorTypeIdRoute = AuthTypeEditorTypeIdImport.update({
  id: '/$typeId',
  path: '/$typeId',
  getParentRoute: () => AuthTypeEditorLazyRoute,
} as any)

const AuthTicketTicketIdRoute = AuthTicketTicketIdImport.update({
  id: '/ticket/$ticketId',
  path: '/ticket/$ticketId',
  getParentRoute: () => AuthRoute,
} as any)

const AuthTemplatesTemplateIdRoute = AuthTemplatesTemplateIdImport.update({
  id: '/$templateId',
  path: '/$templateId',
  getParentRoute: () => AuthTemplatesLazyRoute,
} as any)

const AuthStatusEditorSortOrderRoute = AuthStatusEditorSortOrderImport.update({
  id: '/sort-order',
  path: '/sort-order',
  getParentRoute: () => AuthStatusEditorLazyRoute,
} as any)

const AuthStatusEditorStatusIdRoute = AuthStatusEditorStatusIdImport.update({
  id: '/$statusId',
  path: '/$statusId',
  getParentRoute: () => AuthStatusEditorLazyRoute,
} as any)

const AuthReportsReportFilterRoute = AuthReportsReportFilterImport.update({
  id: '/$reportFilter',
  path: '/$reportFilter',
  getParentRoute: () => AuthReportsRoute,
} as any)

const AuthPermissionsRoleIdRoute = AuthPermissionsRoleIdImport.update({
  id: '/$roleId',
  path: '/$roleId',
  getParentRoute: () => AuthPermissionsLazyRoute,
} as any)

const AuthFieldGroupsFieldGroupIdRoute =
  AuthFieldGroupsFieldGroupIdImport.update({
    id: '/$fieldGroupId',
    path: '/$fieldGroupId',
    getParentRoute: () => AuthFieldGroupsLazyRoute,
  } as any)

const AuthCustomersCustomerIdRoute = AuthCustomersCustomerIdImport.update({
  id: '/$customerId',
  path: '/$customerId',
  getParentRoute: () => AuthCustomersLazyRoute,
} as any)

const AuthCustomFieldsCustomFieldIdRoute =
  AuthCustomFieldsCustomFieldIdImport.update({
    id: '/$customFieldId',
    path: '/$customFieldId',
    getParentRoute: () => AuthCustomFieldsLazyRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_auth/reports': {
      id: '/_auth/reports'
      path: '/reports'
      fullPath: '/reports'
      preLoaderRoute: typeof AuthReportsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/user-profile': {
      id: '/_auth/user-profile'
      path: '/user-profile'
      fullPath: '/user-profile'
      preLoaderRoute: typeof AuthUserProfileImport
      parentRoute: typeof AuthImport
    }
    '/_auth/agents': {
      id: '/_auth/agents'
      path: '/agents'
      fullPath: '/agents'
      preLoaderRoute: typeof AuthAgentsLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/batch-updates': {
      id: '/_auth/batch-updates'
      path: '/batch-updates'
      fullPath: '/batch-updates'
      preLoaderRoute: typeof AuthBatchUpdatesLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/custom-fields': {
      id: '/_auth/custom-fields'
      path: '/custom-fields'
      fullPath: '/custom-fields'
      preLoaderRoute: typeof AuthCustomFieldsLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/customers': {
      id: '/_auth/customers'
      path: '/customers'
      fullPath: '/customers'
      preLoaderRoute: typeof AuthCustomersLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/field-groups': {
      id: '/_auth/field-groups'
      path: '/field-groups'
      fullPath: '/field-groups'
      preLoaderRoute: typeof AuthFieldGroupsLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/permissions': {
      id: '/_auth/permissions'
      path: '/permissions'
      fullPath: '/permissions'
      preLoaderRoute: typeof AuthPermissionsLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/release-notes': {
      id: '/_auth/release-notes'
      path: '/release-notes'
      fullPath: '/release-notes'
      preLoaderRoute: typeof AuthReleaseNotesLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/status-editor': {
      id: '/_auth/status-editor'
      path: '/status-editor'
      fullPath: '/status-editor'
      preLoaderRoute: typeof AuthStatusEditorLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/templates': {
      id: '/_auth/templates'
      path: '/templates'
      fullPath: '/templates'
      preLoaderRoute: typeof AuthTemplatesLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/type-editor': {
      id: '/_auth/type-editor'
      path: '/type-editor'
      fullPath: '/type-editor'
      preLoaderRoute: typeof AuthTypeEditorLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/workflow-rules': {
      id: '/_auth/workflow-rules'
      path: '/workflow-rules'
      fullPath: '/workflow-rules'
      preLoaderRoute: typeof AuthWorkflowRulesLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/': {
      id: '/_auth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/custom-fields/$customFieldId': {
      id: '/_auth/custom-fields/$customFieldId'
      path: '/$customFieldId'
      fullPath: '/custom-fields/$customFieldId'
      preLoaderRoute: typeof AuthCustomFieldsCustomFieldIdImport
      parentRoute: typeof AuthCustomFieldsLazyImport
    }
    '/_auth/customers/$customerId': {
      id: '/_auth/customers/$customerId'
      path: '/$customerId'
      fullPath: '/customers/$customerId'
      preLoaderRoute: typeof AuthCustomersCustomerIdImport
      parentRoute: typeof AuthCustomersLazyImport
    }
    '/_auth/field-groups/$fieldGroupId': {
      id: '/_auth/field-groups/$fieldGroupId'
      path: '/$fieldGroupId'
      fullPath: '/field-groups/$fieldGroupId'
      preLoaderRoute: typeof AuthFieldGroupsFieldGroupIdImport
      parentRoute: typeof AuthFieldGroupsLazyImport
    }
    '/_auth/permissions/$roleId': {
      id: '/_auth/permissions/$roleId'
      path: '/$roleId'
      fullPath: '/permissions/$roleId'
      preLoaderRoute: typeof AuthPermissionsRoleIdImport
      parentRoute: typeof AuthPermissionsLazyImport
    }
    '/_auth/reports/$reportFilter': {
      id: '/_auth/reports/$reportFilter'
      path: '/$reportFilter'
      fullPath: '/reports/$reportFilter'
      preLoaderRoute: typeof AuthReportsReportFilterImport
      parentRoute: typeof AuthReportsImport
    }
    '/_auth/status-editor/$statusId': {
      id: '/_auth/status-editor/$statusId'
      path: '/$statusId'
      fullPath: '/status-editor/$statusId'
      preLoaderRoute: typeof AuthStatusEditorStatusIdImport
      parentRoute: typeof AuthStatusEditorLazyImport
    }
    '/_auth/status-editor/sort-order': {
      id: '/_auth/status-editor/sort-order'
      path: '/sort-order'
      fullPath: '/status-editor/sort-order'
      preLoaderRoute: typeof AuthStatusEditorSortOrderImport
      parentRoute: typeof AuthStatusEditorLazyImport
    }
    '/_auth/templates/$templateId': {
      id: '/_auth/templates/$templateId'
      path: '/$templateId'
      fullPath: '/templates/$templateId'
      preLoaderRoute: typeof AuthTemplatesTemplateIdImport
      parentRoute: typeof AuthTemplatesLazyImport
    }
    '/_auth/ticket/$ticketId': {
      id: '/_auth/ticket/$ticketId'
      path: '/ticket/$ticketId'
      fullPath: '/ticket/$ticketId'
      preLoaderRoute: typeof AuthTicketTicketIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/type-editor/$typeId': {
      id: '/_auth/type-editor/$typeId'
      path: '/$typeId'
      fullPath: '/type-editor/$typeId'
      preLoaderRoute: typeof AuthTypeEditorTypeIdImport
      parentRoute: typeof AuthTypeEditorLazyImport
    }
    '/_auth/workflow-rules/$workflowruleId': {
      id: '/_auth/workflow-rules/$workflowruleId'
      path: '/$workflowruleId'
      fullPath: '/workflow-rules/$workflowruleId'
      preLoaderRoute: typeof AuthWorkflowRulesWorkflowruleIdImport
      parentRoute: typeof AuthWorkflowRulesLazyImport
    }
  }
}

// Create and export the route tree

interface AuthReportsRouteChildren {
  AuthReportsReportFilterRoute: typeof AuthReportsReportFilterRoute
}

const AuthReportsRouteChildren: AuthReportsRouteChildren = {
  AuthReportsReportFilterRoute: AuthReportsReportFilterRoute,
}

const AuthReportsRouteWithChildren = AuthReportsRoute._addFileChildren(
  AuthReportsRouteChildren,
)

interface AuthCustomFieldsLazyRouteChildren {
  AuthCustomFieldsCustomFieldIdRoute: typeof AuthCustomFieldsCustomFieldIdRoute
}

const AuthCustomFieldsLazyRouteChildren: AuthCustomFieldsLazyRouteChildren = {
  AuthCustomFieldsCustomFieldIdRoute: AuthCustomFieldsCustomFieldIdRoute,
}

const AuthCustomFieldsLazyRouteWithChildren =
  AuthCustomFieldsLazyRoute._addFileChildren(AuthCustomFieldsLazyRouteChildren)

interface AuthCustomersLazyRouteChildren {
  AuthCustomersCustomerIdRoute: typeof AuthCustomersCustomerIdRoute
}

const AuthCustomersLazyRouteChildren: AuthCustomersLazyRouteChildren = {
  AuthCustomersCustomerIdRoute: AuthCustomersCustomerIdRoute,
}

const AuthCustomersLazyRouteWithChildren =
  AuthCustomersLazyRoute._addFileChildren(AuthCustomersLazyRouteChildren)

interface AuthFieldGroupsLazyRouteChildren {
  AuthFieldGroupsFieldGroupIdRoute: typeof AuthFieldGroupsFieldGroupIdRoute
}

const AuthFieldGroupsLazyRouteChildren: AuthFieldGroupsLazyRouteChildren = {
  AuthFieldGroupsFieldGroupIdRoute: AuthFieldGroupsFieldGroupIdRoute,
}

const AuthFieldGroupsLazyRouteWithChildren =
  AuthFieldGroupsLazyRoute._addFileChildren(AuthFieldGroupsLazyRouteChildren)

interface AuthPermissionsLazyRouteChildren {
  AuthPermissionsRoleIdRoute: typeof AuthPermissionsRoleIdRoute
}

const AuthPermissionsLazyRouteChildren: AuthPermissionsLazyRouteChildren = {
  AuthPermissionsRoleIdRoute: AuthPermissionsRoleIdRoute,
}

const AuthPermissionsLazyRouteWithChildren =
  AuthPermissionsLazyRoute._addFileChildren(AuthPermissionsLazyRouteChildren)

interface AuthStatusEditorLazyRouteChildren {
  AuthStatusEditorStatusIdRoute: typeof AuthStatusEditorStatusIdRoute
  AuthStatusEditorSortOrderRoute: typeof AuthStatusEditorSortOrderRoute
}

const AuthStatusEditorLazyRouteChildren: AuthStatusEditorLazyRouteChildren = {
  AuthStatusEditorStatusIdRoute: AuthStatusEditorStatusIdRoute,
  AuthStatusEditorSortOrderRoute: AuthStatusEditorSortOrderRoute,
}

const AuthStatusEditorLazyRouteWithChildren =
  AuthStatusEditorLazyRoute._addFileChildren(AuthStatusEditorLazyRouteChildren)

interface AuthTemplatesLazyRouteChildren {
  AuthTemplatesTemplateIdRoute: typeof AuthTemplatesTemplateIdRoute
}

const AuthTemplatesLazyRouteChildren: AuthTemplatesLazyRouteChildren = {
  AuthTemplatesTemplateIdRoute: AuthTemplatesTemplateIdRoute,
}

const AuthTemplatesLazyRouteWithChildren =
  AuthTemplatesLazyRoute._addFileChildren(AuthTemplatesLazyRouteChildren)

interface AuthTypeEditorLazyRouteChildren {
  AuthTypeEditorTypeIdRoute: typeof AuthTypeEditorTypeIdRoute
}

const AuthTypeEditorLazyRouteChildren: AuthTypeEditorLazyRouteChildren = {
  AuthTypeEditorTypeIdRoute: AuthTypeEditorTypeIdRoute,
}

const AuthTypeEditorLazyRouteWithChildren =
  AuthTypeEditorLazyRoute._addFileChildren(AuthTypeEditorLazyRouteChildren)

interface AuthWorkflowRulesLazyRouteChildren {
  AuthWorkflowRulesWorkflowruleIdRoute: typeof AuthWorkflowRulesWorkflowruleIdRoute
}

const AuthWorkflowRulesLazyRouteChildren: AuthWorkflowRulesLazyRouteChildren = {
  AuthWorkflowRulesWorkflowruleIdRoute: AuthWorkflowRulesWorkflowruleIdRoute,
}

const AuthWorkflowRulesLazyRouteWithChildren =
  AuthWorkflowRulesLazyRoute._addFileChildren(
    AuthWorkflowRulesLazyRouteChildren,
  )

interface AuthRouteChildren {
  AuthReportsRoute: typeof AuthReportsRouteWithChildren
  AuthUserProfileRoute: typeof AuthUserProfileRoute
  AuthAgentsLazyRoute: typeof AuthAgentsLazyRoute
  AuthBatchUpdatesLazyRoute: typeof AuthBatchUpdatesLazyRoute
  AuthCustomFieldsLazyRoute: typeof AuthCustomFieldsLazyRouteWithChildren
  AuthCustomersLazyRoute: typeof AuthCustomersLazyRouteWithChildren
  AuthFieldGroupsLazyRoute: typeof AuthFieldGroupsLazyRouteWithChildren
  AuthPermissionsLazyRoute: typeof AuthPermissionsLazyRouteWithChildren
  AuthReleaseNotesLazyRoute: typeof AuthReleaseNotesLazyRoute
  AuthStatusEditorLazyRoute: typeof AuthStatusEditorLazyRouteWithChildren
  AuthTemplatesLazyRoute: typeof AuthTemplatesLazyRouteWithChildren
  AuthTypeEditorLazyRoute: typeof AuthTypeEditorLazyRouteWithChildren
  AuthWorkflowRulesLazyRoute: typeof AuthWorkflowRulesLazyRouteWithChildren
  AuthIndexRoute: typeof AuthIndexRoute
  AuthTicketTicketIdRoute: typeof AuthTicketTicketIdRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthReportsRoute: AuthReportsRouteWithChildren,
  AuthUserProfileRoute: AuthUserProfileRoute,
  AuthAgentsLazyRoute: AuthAgentsLazyRoute,
  AuthBatchUpdatesLazyRoute: AuthBatchUpdatesLazyRoute,
  AuthCustomFieldsLazyRoute: AuthCustomFieldsLazyRouteWithChildren,
  AuthCustomersLazyRoute: AuthCustomersLazyRouteWithChildren,
  AuthFieldGroupsLazyRoute: AuthFieldGroupsLazyRouteWithChildren,
  AuthPermissionsLazyRoute: AuthPermissionsLazyRouteWithChildren,
  AuthReleaseNotesLazyRoute: AuthReleaseNotesLazyRoute,
  AuthStatusEditorLazyRoute: AuthStatusEditorLazyRouteWithChildren,
  AuthTemplatesLazyRoute: AuthTemplatesLazyRouteWithChildren,
  AuthTypeEditorLazyRoute: AuthTypeEditorLazyRouteWithChildren,
  AuthWorkflowRulesLazyRoute: AuthWorkflowRulesLazyRouteWithChildren,
  AuthIndexRoute: AuthIndexRoute,
  AuthTicketTicketIdRoute: AuthTicketTicketIdRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthRouteWithChildren
  '/login': typeof LoginRoute
  '/reports': typeof AuthReportsRouteWithChildren
  '/user-profile': typeof AuthUserProfileRoute
  '/agents': typeof AuthAgentsLazyRoute
  '/batch-updates': typeof AuthBatchUpdatesLazyRoute
  '/custom-fields': typeof AuthCustomFieldsLazyRouteWithChildren
  '/customers': typeof AuthCustomersLazyRouteWithChildren
  '/field-groups': typeof AuthFieldGroupsLazyRouteWithChildren
  '/permissions': typeof AuthPermissionsLazyRouteWithChildren
  '/release-notes': typeof AuthReleaseNotesLazyRoute
  '/status-editor': typeof AuthStatusEditorLazyRouteWithChildren
  '/templates': typeof AuthTemplatesLazyRouteWithChildren
  '/type-editor': typeof AuthTypeEditorLazyRouteWithChildren
  '/workflow-rules': typeof AuthWorkflowRulesLazyRouteWithChildren
  '/': typeof AuthIndexRoute
  '/custom-fields/$customFieldId': typeof AuthCustomFieldsCustomFieldIdRoute
  '/customers/$customerId': typeof AuthCustomersCustomerIdRoute
  '/field-groups/$fieldGroupId': typeof AuthFieldGroupsFieldGroupIdRoute
  '/permissions/$roleId': typeof AuthPermissionsRoleIdRoute
  '/reports/$reportFilter': typeof AuthReportsReportFilterRoute
  '/status-editor/$statusId': typeof AuthStatusEditorStatusIdRoute
  '/status-editor/sort-order': typeof AuthStatusEditorSortOrderRoute
  '/templates/$templateId': typeof AuthTemplatesTemplateIdRoute
  '/ticket/$ticketId': typeof AuthTicketTicketIdRoute
  '/type-editor/$typeId': typeof AuthTypeEditorTypeIdRoute
  '/workflow-rules/$workflowruleId': typeof AuthWorkflowRulesWorkflowruleIdRoute
}

export interface FileRoutesByTo {
  '/login': typeof LoginRoute
  '/reports': typeof AuthReportsRouteWithChildren
  '/user-profile': typeof AuthUserProfileRoute
  '/agents': typeof AuthAgentsLazyRoute
  '/batch-updates': typeof AuthBatchUpdatesLazyRoute
  '/custom-fields': typeof AuthCustomFieldsLazyRouteWithChildren
  '/customers': typeof AuthCustomersLazyRouteWithChildren
  '/field-groups': typeof AuthFieldGroupsLazyRouteWithChildren
  '/permissions': typeof AuthPermissionsLazyRouteWithChildren
  '/release-notes': typeof AuthReleaseNotesLazyRoute
  '/status-editor': typeof AuthStatusEditorLazyRouteWithChildren
  '/templates': typeof AuthTemplatesLazyRouteWithChildren
  '/type-editor': typeof AuthTypeEditorLazyRouteWithChildren
  '/workflow-rules': typeof AuthWorkflowRulesLazyRouteWithChildren
  '/': typeof AuthIndexRoute
  '/custom-fields/$customFieldId': typeof AuthCustomFieldsCustomFieldIdRoute
  '/customers/$customerId': typeof AuthCustomersCustomerIdRoute
  '/field-groups/$fieldGroupId': typeof AuthFieldGroupsFieldGroupIdRoute
  '/permissions/$roleId': typeof AuthPermissionsRoleIdRoute
  '/reports/$reportFilter': typeof AuthReportsReportFilterRoute
  '/status-editor/$statusId': typeof AuthStatusEditorStatusIdRoute
  '/status-editor/sort-order': typeof AuthStatusEditorSortOrderRoute
  '/templates/$templateId': typeof AuthTemplatesTemplateIdRoute
  '/ticket/$ticketId': typeof AuthTicketTicketIdRoute
  '/type-editor/$typeId': typeof AuthTypeEditorTypeIdRoute
  '/workflow-rules/$workflowruleId': typeof AuthWorkflowRulesWorkflowruleIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/login': typeof LoginRoute
  '/_auth/reports': typeof AuthReportsRouteWithChildren
  '/_auth/user-profile': typeof AuthUserProfileRoute
  '/_auth/agents': typeof AuthAgentsLazyRoute
  '/_auth/batch-updates': typeof AuthBatchUpdatesLazyRoute
  '/_auth/custom-fields': typeof AuthCustomFieldsLazyRouteWithChildren
  '/_auth/customers': typeof AuthCustomersLazyRouteWithChildren
  '/_auth/field-groups': typeof AuthFieldGroupsLazyRouteWithChildren
  '/_auth/permissions': typeof AuthPermissionsLazyRouteWithChildren
  '/_auth/release-notes': typeof AuthReleaseNotesLazyRoute
  '/_auth/status-editor': typeof AuthStatusEditorLazyRouteWithChildren
  '/_auth/templates': typeof AuthTemplatesLazyRouteWithChildren
  '/_auth/type-editor': typeof AuthTypeEditorLazyRouteWithChildren
  '/_auth/workflow-rules': typeof AuthWorkflowRulesLazyRouteWithChildren
  '/_auth/': typeof AuthIndexRoute
  '/_auth/custom-fields/$customFieldId': typeof AuthCustomFieldsCustomFieldIdRoute
  '/_auth/customers/$customerId': typeof AuthCustomersCustomerIdRoute
  '/_auth/field-groups/$fieldGroupId': typeof AuthFieldGroupsFieldGroupIdRoute
  '/_auth/permissions/$roleId': typeof AuthPermissionsRoleIdRoute
  '/_auth/reports/$reportFilter': typeof AuthReportsReportFilterRoute
  '/_auth/status-editor/$statusId': typeof AuthStatusEditorStatusIdRoute
  '/_auth/status-editor/sort-order': typeof AuthStatusEditorSortOrderRoute
  '/_auth/templates/$templateId': typeof AuthTemplatesTemplateIdRoute
  '/_auth/ticket/$ticketId': typeof AuthTicketTicketIdRoute
  '/_auth/type-editor/$typeId': typeof AuthTypeEditorTypeIdRoute
  '/_auth/workflow-rules/$workflowruleId': typeof AuthWorkflowRulesWorkflowruleIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/login'
    | '/reports'
    | '/user-profile'
    | '/agents'
    | '/batch-updates'
    | '/custom-fields'
    | '/customers'
    | '/field-groups'
    | '/permissions'
    | '/release-notes'
    | '/status-editor'
    | '/templates'
    | '/type-editor'
    | '/workflow-rules'
    | '/'
    | '/custom-fields/$customFieldId'
    | '/customers/$customerId'
    | '/field-groups/$fieldGroupId'
    | '/permissions/$roleId'
    | '/reports/$reportFilter'
    | '/status-editor/$statusId'
    | '/status-editor/sort-order'
    | '/templates/$templateId'
    | '/ticket/$ticketId'
    | '/type-editor/$typeId'
    | '/workflow-rules/$workflowruleId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/login'
    | '/reports'
    | '/user-profile'
    | '/agents'
    | '/batch-updates'
    | '/custom-fields'
    | '/customers'
    | '/field-groups'
    | '/permissions'
    | '/release-notes'
    | '/status-editor'
    | '/templates'
    | '/type-editor'
    | '/workflow-rules'
    | '/'
    | '/custom-fields/$customFieldId'
    | '/customers/$customerId'
    | '/field-groups/$fieldGroupId'
    | '/permissions/$roleId'
    | '/reports/$reportFilter'
    | '/status-editor/$statusId'
    | '/status-editor/sort-order'
    | '/templates/$templateId'
    | '/ticket/$ticketId'
    | '/type-editor/$typeId'
    | '/workflow-rules/$workflowruleId'
  id:
    | '__root__'
    | '/_auth'
    | '/login'
    | '/_auth/reports'
    | '/_auth/user-profile'
    | '/_auth/agents'
    | '/_auth/batch-updates'
    | '/_auth/custom-fields'
    | '/_auth/customers'
    | '/_auth/field-groups'
    | '/_auth/permissions'
    | '/_auth/release-notes'
    | '/_auth/status-editor'
    | '/_auth/templates'
    | '/_auth/type-editor'
    | '/_auth/workflow-rules'
    | '/_auth/'
    | '/_auth/custom-fields/$customFieldId'
    | '/_auth/customers/$customerId'
    | '/_auth/field-groups/$fieldGroupId'
    | '/_auth/permissions/$roleId'
    | '/_auth/reports/$reportFilter'
    | '/_auth/status-editor/$statusId'
    | '/_auth/status-editor/sort-order'
    | '/_auth/templates/$templateId'
    | '/_auth/ticket/$ticketId'
    | '/_auth/type-editor/$typeId'
    | '/_auth/workflow-rules/$workflowruleId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/login"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/reports",
        "/_auth/user-profile",
        "/_auth/agents",
        "/_auth/batch-updates",
        "/_auth/custom-fields",
        "/_auth/customers",
        "/_auth/field-groups",
        "/_auth/permissions",
        "/_auth/release-notes",
        "/_auth/status-editor",
        "/_auth/templates",
        "/_auth/type-editor",
        "/_auth/workflow-rules",
        "/_auth/",
        "/_auth/ticket/$ticketId"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_auth/reports": {
      "filePath": "_auth/reports.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/reports/$reportFilter"
      ]
    },
    "/_auth/user-profile": {
      "filePath": "_auth/user-profile.tsx",
      "parent": "/_auth"
    },
    "/_auth/agents": {
      "filePath": "_auth/agents.lazy.tsx",
      "parent": "/_auth"
    },
    "/_auth/batch-updates": {
      "filePath": "_auth/batch-updates.lazy.tsx",
      "parent": "/_auth"
    },
    "/_auth/custom-fields": {
      "filePath": "_auth/custom-fields.lazy.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/custom-fields/$customFieldId"
      ]
    },
    "/_auth/customers": {
      "filePath": "_auth/customers.lazy.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/customers/$customerId"
      ]
    },
    "/_auth/field-groups": {
      "filePath": "_auth/field-groups.lazy.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/field-groups/$fieldGroupId"
      ]
    },
    "/_auth/permissions": {
      "filePath": "_auth/permissions.lazy.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/permissions/$roleId"
      ]
    },
    "/_auth/release-notes": {
      "filePath": "_auth/release-notes.lazy.tsx",
      "parent": "/_auth"
    },
    "/_auth/status-editor": {
      "filePath": "_auth/status-editor.lazy.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/status-editor/$statusId",
        "/_auth/status-editor/sort-order"
      ]
    },
    "/_auth/templates": {
      "filePath": "_auth/templates.lazy.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/templates/$templateId"
      ]
    },
    "/_auth/type-editor": {
      "filePath": "_auth/type-editor.lazy.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/type-editor/$typeId"
      ]
    },
    "/_auth/workflow-rules": {
      "filePath": "_auth/workflow-rules.lazy.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/workflow-rules/$workflowruleId"
      ]
    },
    "/_auth/": {
      "filePath": "_auth/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/custom-fields/$customFieldId": {
      "filePath": "_auth/custom-fields/$customFieldId.tsx",
      "parent": "/_auth/custom-fields"
    },
    "/_auth/customers/$customerId": {
      "filePath": "_auth/customers/$customerId.tsx",
      "parent": "/_auth/customers"
    },
    "/_auth/field-groups/$fieldGroupId": {
      "filePath": "_auth/field-groups/$fieldGroupId.tsx",
      "parent": "/_auth/field-groups"
    },
    "/_auth/permissions/$roleId": {
      "filePath": "_auth/permissions/$roleId.tsx",
      "parent": "/_auth/permissions"
    },
    "/_auth/reports/$reportFilter": {
      "filePath": "_auth/reports/$reportFilter.tsx",
      "parent": "/_auth/reports"
    },
    "/_auth/status-editor/$statusId": {
      "filePath": "_auth/status-editor/$statusId.tsx",
      "parent": "/_auth/status-editor"
    },
    "/_auth/status-editor/sort-order": {
      "filePath": "_auth/status-editor/sort-order.tsx",
      "parent": "/_auth/status-editor"
    },
    "/_auth/templates/$templateId": {
      "filePath": "_auth/templates/$templateId.tsx",
      "parent": "/_auth/templates"
    },
    "/_auth/ticket/$ticketId": {
      "filePath": "_auth/ticket/$ticketId.tsx",
      "parent": "/_auth"
    },
    "/_auth/type-editor/$typeId": {
      "filePath": "_auth/type-editor/$typeId.tsx",
      "parent": "/_auth/type-editor"
    },
    "/_auth/workflow-rules/$workflowruleId": {
      "filePath": "_auth/workflow-rules/$workflowruleId.tsx",
      "parent": "/_auth/workflow-rules"
    }
  }
}
ROUTE_MANIFEST_END */
