import StatusService from '@/services/statuses.service';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import useStatusMutation from './useStatusMutation';

interface IUseStatus {
  // statuses: UseQueryResult<any>;
  statusesByCustomer: UseQueryResult<any>;
  executeStatusAction: any;
  isUpdating: any;
  setIsUpdating: any;
  sortedStatusesByCustomer: any;
}

const useStatus = (customerId?: number): IUseStatus => {
  const { executeStatusAction, isUpdating, setIsUpdating } = useStatusMutation();

  const statusService = StatusService();

  // const statuses = useQuery<any>({
  //   queryKey: ['statuses'],
  //   queryFn: async () => {
  //     return new Promise(async (resolve) => {
  //       await statusService
  //         .getStatuses({
  //           onSuccess: (res: AxiosResponse) => {
  //             resolve(res.data);
  //           },
  //         })
  //         .then((res) => {
  //           resolve(res?.data);
  //         });
  //     });
  //   },
  //   staleTime: 1000 * 60 * 5, // 5 minutes
  //   refetchOnWindowFocus: false,
  // });

  const sortedStatusesByCustomer = useQuery<any>({
    queryKey: ['sorted-statuses-by-customer', customerId],
    queryFn: async () => {
      if (!customerId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await statusService
          .getSortedStatuses({
            customerId: customerId,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerId,
    refetchOnWindowFocus: false,
  });

  const statusesByCustomer = useQuery<any>({
    queryKey: ['statuses-by-customer', customerId],
    queryFn: async () => {
      if (!customerId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await statusService
          .getStatusesByCustomer({
            customerId: customerId,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerId,
    refetchOnWindowFocus: false,
  });

  return {
    // statuses,
    statusesByCustomer,
    sortedStatusesByCustomer,
    executeStatusAction,
    isUpdating,
    setIsUpdating,
  };
};

export default useStatus;
