import TemplateDrawer from '@/app/pages/Templates/TemplateDrawer'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

export const Route = createFileRoute('/_auth/templates/$templateId')({
  component: () => <TemplateDrawer />,
  params: {
    parse: (params) => ({
      templateId: z.number().int().parse(Number(params.templateId)),
    }),
    stringify: ({ templateId }) => ({ templateId: `${templateId}` }),
  },
  validateSearch: (search) => {
    return z
      .object({
        variant: z.enum(['edit', 'new']).default('edit'),
        title: z.string().default(''),
        customerId: z.number().int().default(0),
      })
      .parse(search)
  },
})
