import { Description } from '@mui/icons-material';
import { z } from 'zod';

const FieldGroupCustomerSchema = z.object({
  CustomerId: z.number(),
  IsDefault: z.boolean(),
});

export const FieldGroupSchema = z.object({
  ArchivedBy: z.number(),
  Archivedts: z.number(),
  CustomerList: z.preprocess(
    (val) => val ?? [], 
    z.array(
      z.object({
        CustomerId: z.number(),
        IsDefault: z.boolean(),
      }),
    ),
  ),
  Description: z.string().min(0, { message: 'Description field is required' }),
  FieldCount: z.number(),
  FieldGroupId: z.number(),
  Name: z.string().min(1, { message: 'Name field is required' }),
});
