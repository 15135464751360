// src/data/mockData.ts

import { WorkflowEdge, WorkflowNode } from "./types";

export const initialNodes: WorkflowNode[] = [
  {
    id: 'trigger-1',
    type: 'trigger',
    position: { x: 250, y: 50 },
    data: {
      label: 'Customer Trigger',
      customerId: 'CUST-123',
      customerName: 'John Doe',
      customerEmail: 'john@example.com',
      tags: ['VIP', 'New Customer']
    }
  },
  // {
  //   id: 'condition-1',
  //   type: 'condition',
  //   position: { x: 250, y: 200 },
  //   data: {
  //     label: 'Check Customer Status',
  //     matchType: 'all',
  //     conditions: [
  //       {
  //         type: 'string',
  //         field: 'status',
  //         operator: 'isequal',
  //         value: 'active'
  //       },
  //       {
  //         type: 'number',
  //         field: 'age',
  //         operator: 'greaterthan',
  //         value: '25'
  //       }
  //     ]
  //   }
  // },
  {
    id: 'action-1',
    type: 'action',
    position: { x: 100, y: 350 },
    data: {
      label: 'Send Welcome Email',
      actionType: 'email',
      enabled: true,
      config: {
        to: 'john@example.com',
        subject: 'Welcome to Our Platform',
        template: 'welcome',
        cc: ['support@example.com'],
        bcc: []
      }
    }
  },
  {
    id: 'action-2',
    type: 'action',
    position: { x: 400, y: 350 },
    data: {
      label: 'Create Task',
      actionType: 'task',
      enabled: true,
      config: {
        title: 'Follow up with customer',
        description: 'Schedule initial consultation',
        assignee: 'sales-team',
        priority: 'high',
        dueDate: '2024-12-20'
      }
    }
  }
];

export const initialEdges: WorkflowEdge[] = [
  {
    id: 'edge-1',
    source: 'trigger-1',
    target: 'action-1',
    type: 'custom'
  },
  {
    id: 'edge-2',
    source: 'trigger-1',
    target: 'action-2',
    type: 'custom'
  },
  // {
  //   id: 'edge-3',
  //   source: 'condition-1',
  //   target: 'action-2',
  //   type: 'custom'
  // }
];
