import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import { Controller, FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import {
  Alert,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControlLabel,
  List,
  ListItem,
  Theme,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { Box, Divider, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import { Delete as DeleteIcon, HelpOutlineOutlined } from '@mui/icons-material';
import { MuiColorInput } from 'mui-color-input';
import { zodResolver } from '@hookform/resolvers/zod';
import { StatusSchema } from '@/interfaces/schemas/statuses.schema';
import { z } from 'zod';
import useStatus from '@/hooks/fetches/Statuses/useStatus.service';
import { getColor, hexToNumericalColorValue } from '@/util/getColor.utils';
import LoadingButton from '@mui/lab/LoadingButton';
import ConfirmModal from '@/components/Molecules/Modal/ConfirmModal.component';
import CheckboxInput from '@/components/Atoms/Checkbox/CheckboxInput.component';
import { useQuery } from '@tanstack/react-query';
import { createCustomersQueryOptions, createStatusesQueryOptions } from '@/hooks/fetches/useTicket.service';

const StatusDrawer = () => {
  const navigate = useNavigate();
  const params = useParams({ from: '/_auth/status-editor/$statusId' });
  const { variant } = useSearch({ from: '/_auth/status-editor/$statusId' });
  const customers = useQuery(createCustomersQueryOptions());
  const statuses = useQuery(createStatusesQueryOptions());
  const { executeStatusAction } = useStatus();
  const currentStatus = statuses?.data?.find((status: any) => status.StatusId === params.statusId);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(true);
    setIsUpdating(false);
    setIsDeleting(false);
    setIsDeleting(false);
  }, []);

  const parsedStatus = currentStatus
    ? StatusSchema.parse(currentStatus)
    : {
      Name: '',
      Color: 0,
      IsClosed: false,
      IsDefault: false,
      IsDontTrack: false,
      LinkedCustomers: [],
      StatusId: 0,
      TicketCount: 0,
    };

  const methods = useForm<z.infer<typeof StatusSchema>>({
    mode: 'all',
    defaultValues: {
      Name: parsedStatus?.Name || '',
      Color: parsedStatus?.Color || 0,
      IsClosed: parsedStatus?.IsClosed || false,
      IsDefault: parsedStatus?.IsDefault || false,
      IsDontTrack: parsedStatus?.IsDontTrack || false,
      LinkedCustomers: parsedStatus?.LinkedCustomers || [],
      StatusId: parsedStatus?.StatusId || 0,
      TicketCount: parsedStatus?.TicketCount || 0,
    },
    resolver: zodResolver(StatusSchema),
  });

  const {
    formState: { errors },
  } = methods;
  const { control, setValue, reset } = methods;
  const formValues = useWatch({ control });
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      navigate({ to: '/status-editor' });
    }, 300); // Match this with MUI's transition duration
  };

  const linkedCustomers = useWatch({ control, name: 'LinkedCustomers' });
  const statusName = useWatch({ control, name: 'Name' });
  const statusColor = useWatch({ control, name: 'Color' });
  const canSend = methods.formState.isDirty && statusName !== '' && statusColor !== 0 && linkedCustomers.length > 0;
  const isPending = statuses?.isPending || customers?.isPending;

  const handleSubmit: SubmitHandler<z.infer<typeof StatusSchema>> = (formValues) => {
    setIsUpdating(true);
    if (variant === 'edit') {
      handleUpsert(formValues);
    } else if (variant === 'new') {
      handlePost(formValues);
    }
    return;
  };

  // the flags need to be sent in bitwise b/c of how it's handled on the backend
  const convertFlags = ({ IsClosed, IsDefault, IsDontTrack } = formValues) => {
    return (IsClosed ? 1 : 0) | (IsDefault ? 2 : 0) | (IsDontTrack ? 4 : 0);
  };

  //the backend only accepts an array of ids for linked customers
  const getCustomerIdsOnly = ({ LinkedCustomers } = formValues) => {
    return LinkedCustomers?.map((c) => c.CustomerId);
  };

  const handlePost = (formValues: z.infer<typeof StatusSchema>) => {
    executeStatusAction({
      actionType: 'createStatus',
      payload: {
        CustomerList: getCustomerIdsOnly(formValues),
        Status: {
          Name: formValues?.Name,
          Color: formValues?.Color,
          Flags: convertFlags(formValues),
          StatusID: formValues?.StatusId || 0,
        },
      },
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    executeStatusAction({ actionType: 'deleteStatus', payload: currentStatus.StatusId });
  };

  const handleUpsert = (updatedForm: z.infer<typeof StatusSchema>) => {
    executeStatusAction({
      actionType: 'updateStatus',
      payload: {
        CustomerList: getCustomerIdsOnly(updatedForm),
        Status: {
          Name: updatedForm?.Name,
          Color: updatedForm?.Color,
          Flags: convertFlags(updatedForm),
          StatusID: updatedForm?.StatusId || 0,
        },
      },
    });
  };

  const handleReset = () => {
    reset({
      Name: parsedStatus?.Name || '',
      Color: parsedStatus?.Color || 0,
      IsClosed: parsedStatus?.IsClosed || false,
      IsDefault: parsedStatus?.IsDefault || false,
      IsDontTrack: parsedStatus?.IsDontTrack || false,
      TicketCount: parsedStatus?.TicketCount || 0,
      LinkedCustomers: parsedStatus?.LinkedCustomers || [],
      StatusId: parsedStatus?.StatusId || 0,
    });

    handleClose();
  };

  useEffect(() => {
    if (!isPending && parsedStatus) {
      reset({
        Name: parsedStatus.Name || '',
        Color: parsedStatus.Color || 0,
        IsClosed: parsedStatus.IsClosed || false,
        IsDefault: parsedStatus.IsDefault || false,
        IsDontTrack: parsedStatus.IsDontTrack || false,
        TicketCount: parsedStatus?.TicketCount || 0,
        LinkedCustomers: parsedStatus.LinkedCustomers || [],
        StatusId: parsedStatus?.StatusId || 0,
      });
    }
  }, [isPending]);
  const customersList = customers?.data?.map((customer: any) => {
    const isChecked = linkedCustomers?.some(
      (linkedCustomer: any) => linkedCustomer.CustomerId === customer.CustomerEntId,
    );
    return (
      <ListItem sx={{ p: 0, mb: 0 }} key={customer.CustomerEntId}>
        <CheckboxInput
          sxProps={{ p: 0, m: 1 }}
          checked={isChecked}
          label={customer.EntName}
          id={customer.CustomerEntId}
          onDataChange={(id: number) => {
            let newLinkedCustomers: any[];
            if (linkedCustomers?.some((c: any) => c.CustomerId === id)) {
              newLinkedCustomers = linkedCustomers.filter((c: any) => c.CustomerId !== id);
            } else {
              const customerToAdd = { CustomerId: customer.CustomerEntId };
              newLinkedCustomers = [...linkedCustomers, customerToAdd];
            }
            setValue('LinkedCustomers', newLinkedCustomers, { shouldDirty: true });
          }}
        />
      </ListItem>
    );
  });

  return (
    <DrawerLayout
      open={isOpen}
      onClose={handleClose}
      anchor="right"
      keepMounted
      width={'50%'}
      Header={
        <Box sx={{ display: 'flex' }}>
          <DrawerHeader close={handleClose} title={variant === 'edit' ? 'Edit Status' : 'New Status'} />
          {variant === 'edit' && !isPending && (
            <Chip
              sx={{ position: 'absolute', right: '10%', top: '19px' }}
              label={
                parsedStatus?.TicketCount === 0
                  ? 'This status is not currently being used.'
                  : `This status is being used on ${parsedStatus?.TicketCount?.toLocaleString()} tickets.`
              }
              variant="outlined"
            />
          )}
        </Box>
      }>
      {isPending ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
          }}>
          <CircularProgress />
        </Box>
      ) : (
        <FormProvider {...methods}>
          <Divider sx={{ mt: 3 }} />
          <Container
            maxWidth="md"
            sx={{
              overflowY: 'auto',
              mt: 5,
              height: '98%',
              width: '75%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            component="form"
            onSubmit={methods.handleSubmit(handleSubmit)}>
            <Box>
              <Box
                sx={{
                  width: '100%',
                  mx: 'auto',
                  mt: 8,
                  mb: 4,
                }}>
                <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
                  {variant === 'edit' ? 'Edit the status name or color' : 'Add a status name and color'}
                </Typography>
                <Box sx={{ display: 'flex', gap: 4 }}>
                  <TextBox id="name" name="Name" label="Name" fullWidth required sx={{ flex: 1 }} />
                  <Controller
                    name="Color"
                    control={control}
                    render={({ field: { onChange, onBlur, name, ref, value }, fieldState: { error } }) => {
                      const hexColor = getColor(value);
                      return (
                        <MuiColorInput
                          label="Color*"
                          name={name}
                          inputRef={ref}
                          value={hexColor}
                          format="hex"
                          onChange={(newHex) => {
                            const numericColor = hexToNumericalColorValue(newHex);
                            onChange(numericColor);
                          }}
                          sx={{ flex: 1 }}
                          error={!!error}
                          helperText={error ? error.message : ''}
                        />
                      );
                    }}
                  />
                </Box>
              </Box>
              <Divider sx={{ mb: 6 }} />
              <Box mt={3}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Status Flags
                  <Tooltip title="This is some very helpful information about the statuses">
                    <HelpOutlineOutlined />
                  </Tooltip>
                </Typography>
                <Box sx={{ ml: 4, display: 'flex', justifyContent: 'space-evenly' }}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="IsClosed"
                        control={control}
                        render={({ field }) => <Checkbox {...field} checked={field.value} />}
                      />
                    }
                    label="Closed Status"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        name="IsDefault"
                        control={control}
                        render={({ field }) => <Checkbox {...field} checked={field.value} />}
                      />
                    }
                    label="Default Status"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        name="IsDontTrack"
                        control={control}
                        render={({ field }) => <Checkbox {...field} checked={field.value} />}
                      />
                    }
                    label="Don't Track Time"
                  />
                </Box>
              </Box>
              <Divider sx={{ mb: 6 }} />
              <Box
                sx={{
                  borderColor: 'divider',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                }}>
                <Typography variant="h6">Linked Customers</Typography>
                {linkedCustomers.length === 0 && (
                  <Alert variant="outlined" color="error" severity="info">
                    Select at least 1 linked customer
                  </Alert>
                )}
                <List sx={{ padding: 0, margin: 0, ml: 4 }}>{customersList}</List>
              </Box>
            </Box>
            <Box>
              <Divider sx={{ mt: 3 }} />
              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  p: 5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '1rem',
                }}>
                <LoadingButton
                  size="small"
                  loading={isDeleting}
                  variant="text"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => setOpenModal(true)}>
                  Delete
                </LoadingButton>
                {openModal && (
                  <ConfirmModal
                    open={openModal}
                    setOpen={setOpenModal}
                    title="Are you sure?"
                    content="This action will permanently delete the status."
                    onSuccess={handleDelete}
                    onClose={() => setOpenModal(false)}
                    cancelButtonText="Cancel"
                    confirmButtonText="Delete"
                  />
                )}
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button size="small" variant="outlined" onClick={handleReset}>
                    Cancel & Close
                  </Button>
                  <LoadingButton loading={isUpdating} type="submit" disabled={!canSend} variant="contained">
                    Save
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Container>
        </FormProvider>
      )}
    </DrawerLayout>
  );
};

export default StatusDrawer;
