import { z } from 'zod';

const LinkedCustomersSchema = z.object({
  CustomerId: z.number(),
  CustomerName: z.string().optional(),
});

export const StatusSchema = z.object({
  Name: z.string().min(1, { message: 'This field is required.' }),
  Color: z.number().nonnegative({ message: 'This field is required.'}),
  IsClosed: z.boolean(),
  IsDefault: z.boolean(),
  IsDontTrack: z.boolean(),
  TicketCount: z.number(),
  LinkedCustomers: z.array(LinkedCustomersSchema),
  StatusId: z.number(),
});

export type Status = z.infer<typeof StatusSchema>;
