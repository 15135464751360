import { apiService } from './api.service';
import { BASE_UAPI_URL } from '@/constants/commonStrings.constant';
import { AxiosRequestConfig } from 'axios';

const ReportHeaderService = () => {
  const getReportHeaders = async ({ onSuccess, onError }: { onSuccess?: Function; onError?: Function }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/customfields?reportheader=-1',
      onSuccess,
      onError,
    });
  };

  const upsertReportHeader = async (
      params: any,
      config?: Partial<AxiosRequestConfig>,
    ): Promise<any> => {
      const requestId = `postCreateStatus_${Date.now()}`;
      const response = await apiService({
        method: 'post',
        url: BASE_UAPI_URL + `/customfields/reportheader`,
        data: params,
        ...config,
        id: requestId,
      });
      return response;
    };

  const restoreReportHeader = () => {};
  const archiveReportHeader = () => {};

  return { getReportHeaders, upsertReportHeader, restoreReportHeader, archiveReportHeader };
};
export default ReportHeaderService;
