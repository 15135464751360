import { Button, Card, CardContent, Collapse, Container, Divider, FormControlLabel, Paper, Stack, Switch } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { AutoComplete, Box, TextField, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib'
import React, { FC, ReactNode, useEffect, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import EmailEditor from './EmailEditor'
import { ActionConfig } from './interface'
import TextBox from '@/components/Molecules/Textbox/Textbox.component'
import { EmailAutocomplete } from '@/components/Organisms/EmailAutocomplete/EmailAutocomplete.component'
// import { ActionConfig } from './WorkflowRuleDrawer'
import { createAgentsQueryOptions } from '@/services/queries/workflowOptions'
import { createStatusesByCustomerOptions } from '@/services/queries/statusTypeOptions'
import { useQuery } from '@tanstack/react-query'
import Token from './Token'


interface ConfigurationSectionProps {
  show: boolean;
  children: ReactNode;
}

const WorkflowRuleStep3 = () => {

  const methods = useFormContext()
  const { control, setValue, watch } = methods;


  // const actionsWatch = useWatch({
  //   control,
  //   name: 'actions',
  //   defaultValue: []
  // });

  // Create derived state for each action type
  // const statusSwitchWatch = useMemo(() => {
  //   return actionsWatch.some((action: any) => action.type === 'status' && action.status_switch);
  // }, [actionsWatch]);

  // const emailSwitchWatch = useMemo(() => {
  //   return actionsWatch.some((action: any) => action.type === 'email' && action.email_switch);
  // }, [actionsWatch]);

  // const assigneeSwitchWatch = useMemo(() => {
  //   return actionsWatch.some((action: any) => action.type === 'assignee' && action.assignee_switch);
  // }, [actionsWatch]);


  const assigneeSwitchWatch = useWatch({ control, name: 'assignee_switch' });
  const statusSwitchWatch = useWatch({ control, name: 'status_switch' });
  const emailSwitchWatch = useWatch({ control, name: 'email_switch' });

  const customerWatch = useWatch({ control, name: 'customer' });

  const actions = useWatch({ control, name: 'actions' }) || [];

  const getActionConfig = (type: string) => actions.find((action: ActionConfig) => action.type === type)?.config || {};
  const getActionEnabled = (type: string) => actions.find((action: ActionConfig) => action.type === type)?.enabled || false;

  const updateAction = (type: string, enabled: boolean, config: Partial<ActionConfig['config']> = {}) => {
    const newActions = actions.filter((action: ActionConfig) => action.type !== type);
    if (enabled) {
      newActions.push({ type, enabled, config: { ...getActionConfig(type), ...config } });
    }
    setValue('actions', newActions);
  };

  const agentsByCustomer = useQuery(createAgentsQueryOptions(customerWatch?.id))
  const statusesByCustomer = useQuery(createStatusesByCustomerOptions(customerWatch?.id))

  const ConfigurationSection: FC<ConfigurationSectionProps> = ({
    show,
    children
  }) => {
    return (
      <Collapse in={show}>
        <Paper sx={{ p: 2, mt: 1 }}>
          {children}
        </Paper>
      </Collapse>
    );
  };


  const useActionIndex = (actionType: 'status' | 'email' | 'assignee') => {
    const { getValues } = useFormContext();

    return useMemo(() => {
      return getValues('actions')?.findIndex((a: any) => a.type === actionType) ?? -1;
    }, [getValues, actionType]);
  };


  const statusActionIndex = useActionIndex('status');
  const assigneeActionIndex = useActionIndex('assignee');
  const emailActionIndex = useActionIndex('email');

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant='button' color="text.primary" sx={{ mt: 4 }}>
        Choose Actions
      </Typography>
      <Divider sx={{ mb: 6 }} />

      <Grid container spacing={3}>
        <Grid size={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Action Selection
              </Typography>
              <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Grid size={{ sm: 3 }}>
                  <Controller
                    name="status_switch"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <FormControlLabel
                          control={
                            <Switch
                              id='status_switch'
                              color="primary"
                              onChange={(e) => {
                                onChange(e.target.checked);
                              }}
                              checked={value}
                            />
                          }
                          label="Enable Status Action"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid size={{ sm: 3 }}>
                  <Controller
                    name="assignee_switch"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <FormControlLabel
                          control={
                            <Switch
                              id='assignee_switch'
                              color="primary"
                              onChange={(e) => {
                                onChange(e.target.checked);
                              }}
                              checked={value}
                            />
                          }
                          label="Enable Assignee Action"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid size={{ sm: 3 }}>
                  <Controller
                    name="email_switch"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <FormControlLabel
                          control={
                            <Switch
                              id='email_switch'
                              color="primary"
                              onChange={(e) => {
                                onChange(e.target.checked);
                              }}
                              checked={value}
                            />
                          }
                          label="Enable Email Action"
                        />
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid size={12}>
          <ConfigurationSection show={statusSwitchWatch}>
            <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
              Status Configuration
            </Typography>
            <AutoComplete
              id={`actions.${statusActionIndex}.status_config.statusObject`}
              label={"Select Status"}
              placeholder='Select Status'
              required
              options={statusesByCustomer?.data?.map((status: any, index: number) => ({
                id: status.StatusId,
                label: status.Name,
                key: index
              })) || []}
              loading={statusesByCustomer?.isPending}
              autocompleteProps={{
                id: 'action_status',
                loadingText: 'Loading Statuses...',
                fullWidth: true,
                onChange: (_, value) => {
                  if (statusActionIndex !== -1) {
                    methods.setValue(`actions.${statusActionIndex}.status_config.StatusId`, value?.id);
                  }
                }
              }}
            />
          </ConfigurationSection>
        </Grid>

        <Grid size={12}>
          <ConfigurationSection show={assigneeSwitchWatch}>
            <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
              Assignee Configuration
            </Typography>
            <AutoComplete
              id={`actions.${assigneeActionIndex}.assignee_config.assigneeObject`}
              label={"Select Assignee"}
              placeholder='Select Assignee'
              required
              options={agentsByCustomer?.data?.VisibleAgents.map((agent: any) => ({
                id: agent.AgentId,
                label: agent.Name
              })) || []}
              loading={agentsByCustomer?.isPending}
              autocompleteProps={{
                id: `actions.${assigneeActionIndex}.assignee_config.Assignee`,
                loadingText: 'Loading Assignees...',
                fullWidth: true,
                onChange: (_, value) => {
                  if (assigneeActionIndex !== -1) {
                    methods.setValue(`actions.${assigneeActionIndex}.assignee_config.Assignee`, value?.id);
                  }
                }
              }}
            />
          </ConfigurationSection>
        </Grid>

        <Grid size={12}>
          <ConfigurationSection show={emailSwitchWatch}>
            <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
              Email Configuration
            </Typography>
            <>
              <Box>
                {/* <AutoComplete
                  id={`actions.${emailActionIndex}.email_config.RecipientList`}
                  multiple
                  freeSolo
                  label="Recipients"
                  placeholder='Type or Select Emails'
                  options={agentsByCustomer?.data?.VisibleAgents.map((agent: any) => ({
                    id: agent.AgentId,
                    label: agent.Name
                  })) || []}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label={"Recipients"}
                      placeholder="Enter email addresses"
                    />
                  )}s
                  autocompleteProps={{
                    id: `actions.${emailActionIndex}.email_config.Recipient`,
                    onChange: (e, value) => {
                      if (emailActionIndex !== -1) {
                        methods.setValue(`actions.${emailActionIndex}.email_config.Recipient`, e.target.value);
                      }
                    }
                  }}
                /> */}
                <EmailAutocomplete
                  control={methods.control}
                  name={`actions.${emailActionIndex}.email_config.RecipientList`}
                  // control={methods.control}
                  // name={`actions.${emailActionIndex}.email_config.RecipientList`}
                  options={agentsByCustomer?.data?.data?.VisibleAgents.map((agent: any) => ({
                    id: agent.AgentId,
                    label: agent.Name
                  })) || []}
                />
              </Box>
              <Box style={{ marginTop: '1rem' }}>
                <TextBox
                  id={`actions.${emailActionIndex}.email_config.Subject`}
                  name={`actions.${emailActionIndex}.email_config.Subject`}
                  // required
                  variant="outlined"
                  label="Subject"
                  placeholder='Type Subject'
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    if (emailActionIndex !== -1) {
                      methods.setValue(`actions.${emailActionIndex}.email_config.Subject`, e.target.value);
                    }
                  }}
                />
              </Box>
              <Stack direction="row" spacing={2}>

                <Box style={{ marginTop: '1rem' }}>
                  <EmailEditor name={`actions.${emailActionIndex}.email_config.Body`} control={methods.control} placeHolder='Type Email Body Here...' initialContent='' onChange={(e) => {
                    if (emailActionIndex !== -1) {
                      methods.setValue(`actions.${emailActionIndex}.email_config.Body`, e.target.value);
                    }
                  }} />
                </Box>
                <Token />
              </Stack>
            </>
          </ConfigurationSection>
        </Grid>
      </Grid>



      {/* <Grid spacing={2}>
        <Grid size={3}>
          <Typography variant="body2" color="text.primary" >
            Set Assignee:
          </Typography>
        </Grid>
        <Grid size={8}>
          {assigneeSwitchWatch && (

            <AutoComplete
              id="action_assignee"
              label={"Select Assignee"}
              placeholder='Select Assignee'
              required
              options={
                agentsByCustomer?.isPending ? [] : agentsByCustomer?.data?.VisibleAgents.map((agent: any) => ({
                  id: agent.AgentId,
                  label: agent.Name
                }))
              }
              loading={agentsByCustomer?.isPending}
              autocompleteProps={{
                id: 'action_assignee',
                loadingText: 'Loading Assignees...',
                fullWidth: true
              }}
            // onHandleChange={(event, value) => updateAction('assignee', true, { assignee: value?.id })}
            />
          )}

        </Grid>
        <Grid size={1}>
          <Controller
            name="assignee_switch"
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <Switch id='assignee_switch' color="primary" onChange={onChange} value={value} />
            )}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="body2" color="text.primary" >
            Set Status:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {statusSwitchWatch && (
            <AutoComplete
              id="action_status"
              label={"Select Status"}
              placeholder='Select Status'
              required
              options={[
                { label: 'Test 1', id: "test1" },
                { label: 'Test 2', id: "test2" },
                { label: 'Test 3', id: "test3" },
              ]}
            />
            // <SelectElement
            //   name="action_status"
            //   required
            //   size='small'
            //   fullWidth
            //   label="Select Status"
            //   options={workflowRulesState?.oldConfig?.Stati && workflowRulesState.oldConfig.Stati.map((status: any) => {
            //     return { label: status.Name, id: status.StatusId }
            //   })}
            //   onChangeReturnValue="value"
            //   // objectOnChange={true}
            //   onChange={(value: any) => {
            //     console.log("selected ")
            //     console.log(value)
            //     setValue("action_status_string", value)
            //   }}
            // />
          )}
        </Grid>
        <Grid item xs={1}>
          <Controller
            name="status_switch"
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <Switch id='status_switch' color="primary" onChange={onChange} value={value} />
            )}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="body2" color="text.primary" >
            Set Email:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {emailSwitchWatch && (
            <>
              <Box>
                <AutoComplete
                  id="email_recipients"
                  required
                  multiple
                  label="Recipients"
                  placeholder='Type or Select Emails'
                  options={[]}
                // autocompleteProps={{
                //   id: 'email_recipients',
                //   multiple: true,
                //   freeSolo: true

                // }}
                // autocompleteProps={{
                // }}
                // options={workflowRulesState?.oldConfig?.Agents && workflowRulesState.oldConfig.Agents.map((agent: any) => {
                //   return { label: agent.Name, id: agent.AgentId }
                // })}
                // showAddOption={true}
                />
              </Box>
              <Box style={{ marginTop: '1rem' }}>
                <TextField
                  id="email_subject"
                  name="email_subject"
                  required
                  variant="outlined"
                  label="Subject"
                  placeholder='Type Subject'
                  style={{ width: '100%' }}
                />
              </Box>
              <Box style={{ marginTop: '1rem' }}>
                <EmailEditor name='email_body' control={methods.control} placeHolder='Type Email Body Here...' initialContent='' />
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={1}>
          <Controller
            name="email_switch"
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <Switch id='email_switch' color="primary" onChange={onChange} value={value} />
            )}
          />
        </Grid>
      </Grid> */}
    </Container>
  )
}

export default WorkflowRuleStep3