import { BASE_UAPI_URL } from '@/constants/commonStrings.constant';
import { apiService } from './api.service';
import { AxiosRequestConfig } from 'axios';

const FieldGroupService = () => {
  const getFieldGroups = async ({ onSuccess, onError }: { onSuccess?: Function; onError?: Function }) => {
    return apiService({
      method: 'get',
      url: BASE_UAPI_URL + '/fieldgroup?id=-1',
      onSuccess,
      onError,
    });
  };

  const upsertFieldGroup = async (params: any, config?: Partial<AxiosRequestConfig>) => {
    const requestId = `postCreateStatus_${Date.now()}`;
    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/fieldGroup',
      data: params,
      ...config,
      id: requestId,
    });
    return response;
  };

  const archiveFieldGroup = async (params: any, config?: Partial<AxiosRequestConfig>) => {
    const requestId = `postCreateStatus_${Date.now()}`;
    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/fieldGroup?archive=' + params.fieldGroupId,
      data: params,
      ...config,
      id: requestId,
    });
    return response;
  };

  const restoreFieldGroup = async (params: any, config?: Partial<AxiosRequestConfig>) => {
    const requestId = `postCreateStatus_${Date.now()}`;
    const response = await apiService({
      method: 'post',
      url: BASE_UAPI_URL + '/fieldGroup?restore=' + params.fieldGroupId,
      data: params,
      ...config,
      id: requestId,
    });
    return response;
  };

  return { getFieldGroups, upsertFieldGroup, restoreFieldGroup, archiveFieldGroup };
};

export default FieldGroupService;
