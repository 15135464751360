import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import { FormProvider, SubmitHandler, useForm, useFormContext, useWatch } from 'react-hook-form';
import { Container, Theme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { SmartStepper } from '../WorkflowRules/Stepper';
import WorkflowRuleStep1 from './WorkflowRuleStep1';
import WorkflowRuleStep2 from './WorkflowRuleStep2';
import WorkflowRuleStep3 from './WorkflowRuleStep3';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import WorkflowRuleStep4 from './WorkflowRuleStep4';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { createAgentsQueryOptions, createAllWorkflowRulesQueryOptions } from '@/services/queries/workflowOptions';
import { FieldData, initialWorkflowRule, NewCondition, OldAssigneeAction, OldStatusAction, OldCondition, OldEmailAction, WorkflowRule, workflowRuleSchema } from './interface';
import { Customer } from '@/interfaces/schemas/customer.schema';
import { NewWorkflowRuleAPI, OldWorkflowRuleAPI } from '@/services/workflowRules.service';
import { turnOldNumberToEqualityValue } from '@/constants/filter/filter.constant';
import { createStatusesByCustomerOptions, createTicketTypesByCustomerOptions } from '@/services/queries/statusTypeOptions';
import { CFTypeIdDetail } from '../WorkflowRules/useWorkflowRuleTable';
import { createCustomerOptionsQuery } from '@/services/queries/customerOptions';
import { createBaseFieldsQueryOptions, createCustomerFieldsQueryOptions } from '@/hooks/fetches/useTicket.service';

const WorkflowRuleDrawer = () => {
  const navigate = useNavigate();
  const params = useParams({ from: '/_auth/workflow-rules/$workflowruleId' });
  const { workflowruleId } = params;
  const { variant, step } = useSearch({ from: '/_auth/workflow-rules/$workflowruleId' })
  const [activeStep, setActiveStep] = useState(0);

  const methods = useForm<WorkflowRule>({
    mode: 'all',
    defaultValues: initialWorkflowRule,

    // shouldUnregister: false,
    resolver: zodResolver(workflowRuleSchema)
  });

  // Query workflow rule data based on ID
  const workflowRule = useQuery(createAllWorkflowRulesQueryOptions())

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});

  const [isOpen, setIsOpen] = useState(false)

  // use params to get the associated workflow rule
  // Find either existing rule or new rule based on ID
  const currentWorkflowRule = workflowRule.data?.oldSmartRules?.find(
    (rule: any) => rule.SmartRuleId === workflowruleId
  ) || workflowRule.data?.newSmartRules?.find(
    (rule: any) => rule.SmartRuleId === workflowruleId
  );

  const descriptionWatch = methods.watch('description')
  // const descriptionWatch = useWatch({ control: methods.control, name: 'description' })

  const customers = useQuery(createCustomerOptionsQuery())
  const baseFields = useQuery(createBaseFieldsQueryOptions())

  const customFields = useSuspenseQuery(createCustomerFieldsQueryOptions(currentWorkflowRule?.Customer))
  const ticketTypes = useSuspenseQuery(createTicketTypesByCustomerOptions({ customerId: currentWorkflowRule?.Customer }))
  const statuses = useSuspenseQuery(createStatusesByCustomerOptions({ customerId: currentWorkflowRule?.Customer }))
  const agents = useSuspenseQuery(createAgentsQueryOptions({ customerId: currentWorkflowRule?.Customer }))

  const turnNumberToEqualityValue = ({ numberValue, showDisplayValue, isOldRule }: { numberValue: number, showDisplayValue: boolean, isOldRule: boolean }): string => {

    let equalityMap: { [key: number]: { raw: string; display: string } } = {}

    if (isOldRule) {
      equalityMap = {
        0: { raw: "isNotEqual", display: "Is Not Equal" },
        1: { raw: "isEqual", display: "Is Equal" },
      }

      return showDisplayValue ? equalityMap[numberValue].display : equalityMap[numberValue].raw;
    }

    // Return either raw equality value or display value based on showDisplayValue param
    equalityMap = {
      0: { raw: "isEqual", display: "Is Equal" },
      1: { raw: "isNotEqual", display: "Is Not Equal" },
      2: { raw: "greaterThan", display: "Greater Than" },
      3: { raw: "lessThan", display: "Less Than" },
      4: { raw: "isBetween", display: "Is Between" },
      5: { raw: "isSet", display: "Is Set" },
      6: { raw: "isNotSet", display: "Is Not Set" },
      7: { raw: "today", display: "Today" },
      8: { raw: "onOrBefore", display: "On Or Before" },
      9: { raw: "onOrAfter", display: "On Or After" },
      10: { raw: "withinTheLast", display: "Within The Last" },
      11: { raw: "withinTheNext", display: "Within The Next" },
      12: { raw: "anyValue", display: "Any Value" },
      13: { raw: "contains", display: "Contains" }
    };

    // Return default if number is out of range
    if (numberValue < 0 || numberValue >= Object.keys(equalityMap).length) {
      return showDisplayValue ? "Is Equal" : "isEqual";
    }

    return showDisplayValue ? equalityMap[numberValue].display : equalityMap[numberValue].raw;
  }

  // Helper function to map field types to field names
  const findFieldName = (input: FieldData | undefined) => {
    const fieldMapping: { [key: number]: string } = {
      1: "Status",
      3: "Assignee",
      4: "RaisedBy",
      5: "Type"
    };

    const fieldName = fieldMapping[input?.Fieldtype || 0];
    return fieldName;
  }

  // Helper function to find workflow rule value based on field type
  const findWorkflowRuleByFieldType = useCallback((input: FieldData | undefined) => {
    const fieldName = findFieldName(input);

    if (!fieldName) {
      console.error("Invalid Fieldtype");
      return null;
    }

    if (fieldName === "Type") {
      console.log(input?.FieldString)

      if (!ticketTypes.data) {
        console.log("Ticket types data not yet loaded");
        return "Loading..."; // Or return a default/fallback value
      }

      console.log(ticketTypes.data)
      console.log(ticketTypes.data?.find(
        (type: any) => type.TypeId === input?.FieldString
      )?.Name)
      return ticketTypes.data?.find(
        (type: any) => type.TypeId === input?.FieldString
      )?.Name;
    }

    if (fieldName === "Status") {

      const selectedStatus = statuses.data?.find(
        (status: any) => status.StatusId === input?.FieldString
      );

      console.log(selectedStatus)
      // +1 because of the placeholder value of Select Status is index 0 
      return selectedStatus?.Name

    }

    if (fieldName === "Assignee" || fieldName === "RaisedBy") {
      console.log(agents.data?.data.VisibleAgents?.find(
        (agent: any) => agent.AgentId === input?.FieldString
      ).Name)
      return agents.data?.data.VisibleAgents?.find(
        (agent: any) => agent.AgentId === input?.FieldString
      ).Name;
    }

    return null;
  }, [ticketTypes.data, statuses.data, agents.data])

  // Transform workflow rule data to match schema format
  const transformWorkflowRule = (rule: any): WorkflowRule => {
    if (!rule) return initialWorkflowRule;

    return {
      description: rule.Description || '',
      customer: {
        id: rule.Customer || 0,
        label: customers.data?.find((customer: Customer) => customer.CustomerEntId === rule.Customer)?.EntName || '',
      },
      matchType: rule.MatchType?.toLowerCase() === 'all' ? 'all' : 'any',
      any_conditions: rule.AnyConditions?.map((condition: NewCondition | OldCondition) => {
        // Helper function to determine condition type and map accordingly
        const mapCondition = (c: NewCondition | OldCondition) => {
          // Check if it's a new condition by looking for properties specific to NewCondition
          const isNewCondition = 'condition' in c;


          if (isNewCondition) {
            const newC = c as NewCondition;
            return {
              condition: newC.condition,
              equality: newC.equality,
              equalityNumber: newC.equalityNumber,
              value: newC.value,
              conditionObj: newC.conditionObj,
              type: newC.type,
              allAny: 'Any'
            };
          } else {
            // Handle old condition format working for fieldTypeId 1-5 basefields
            const oldC = c as OldCondition;
            const conditionObject = baseFields?.data.find((item: any) => item?.Meta?.Name === findFieldName(oldC.FieldData))
            console.log(conditionObject)

            if (oldC.FieldData?.Fieldtype < 10) {

              let conditionObject = baseFields?.data.find((item: any) => item?.Meta?.Name === findFieldName(oldC.FieldData))
              console.log(conditionObject)

              let condition = {
                condition: findFieldName(oldC.FieldData) || '',
                equality: turnNumberToEqualityValue({ numberValue: oldC.Equality, showDisplayValue: true, isOldRule: true }) || '',
                equalityNumber: Number(oldC.Equality) || 0,
                value: findWorkflowRuleByFieldType(oldC.FieldData) || '',
                // value: oldC.FieldData?.FieldString?.Json?.Value || '',
                conditionObj: conditionObject || {},
                type: oldC.FieldData?.Fieldtype > 10 ? 'customField' : 'baseField',
                allAny: 'Any'
              };

              if (conditionObject?.Meta?.Cid === 9000) {
                console.warn('TYPE WARNING')
                condition = {
                  ...condition,
                  conditionObj: {
                    ...conditionObject,
                    Type: {
                      ...conditionObject.Type,
                      OptLabels: ticketTypes?.data?.map((item: any) => item.Name) || [],
                      UseOption: true
                    }
                  },
                }
              }

              console.log(condition)
              return condition
            } else {
              //! this is when the old field type is a custom Field 

              console.log(oldC.FieldData?.Fieldtype)
              console.log(oldC.FieldData?.FieldString?.Cid)

              console.log(customFields?.data)
              const currentCustomField = customFields?.data?.find((item: any) => item?.Meta?.Cid === oldC.FieldData?.FieldString?.Cid)

              console.log(currentCustomField)

              const renderDynamicFieldValue = (customField: any) => {
                if (customField && customField?.TypeId && CFTypeIdDetail(customField?.TypeId).ClassBinding) {
                  switch (CFTypeIdDetail(customField.TypeId).ClassBinding) {
                    case 'cfTDropdown': return oldC.FieldData?.FieldString?.Json?.Value.SelectedOpt;
                    case 'cfTDate': return oldC.FieldData?.FieldString?.Json?.Value.IncludeTime;
                    case 'cfTString': return oldC.FieldData?.FieldString?.Json?.Value.Str;
                    case 'cfTInteger': return oldC.FieldData?.FieldString?.Json?.Value;
                    case 'cfTToggle': return oldC.FieldData?.FieldString?.Json?.Value.Checked;
                    default: return []
                  }
                }
              }

              const condition = {
                condition: currentCustomField?.Meta?.Name || '',
                equality: turnNumberToEqualityValue({ numberValue: oldC.Equality, showDisplayValue: true, isOldRule: true }) || '',
                equalityNumber: Number(oldC.Equality) || 0,
                value: renderDynamicFieldValue(currentCustomField) || '',
                conditionObj: currentCustomField || {},
                type: oldC.FieldData?.Fieldtype > 10 ? 'customField' : 'baseField',
                allAny: 'All'
              };

              return condition
            }
          }

        };

        return mapCondition(condition);
      }) || [],
      all_conditions: rule.AllConditions?.map((condition: NewCondition | OldCondition) => {
        // Helper function to determine condition type and map accordingly
        const mapCondition = (c: NewCondition | OldCondition) => {
          // Check if it's a new condition by looking for properties specific to NewCondition
          const isNewCondition = 'condition' in c;

          if (isNewCondition) {
            const newC = c as NewCondition;
            return {
              condition: newC.condition,
              equality: newC.equality,
              equalityNumber: newC.equalityNumber,
              value: newC.value,
              conditionObj: newC.conditionObj,
              type: newC.type,
              allAny: 'Any'
            };
          }
          // } else {
          //   // Handle old condition format
          //   const oldC = c as OldCondition;
          //   let conditionObject = baseFields?.data.find((item: any) => item.Meta.Name === findFieldName(oldC.FieldData))
          //   console.log(conditionObject)
          //   let condition = {
          //     condition: findFieldName(oldC.FieldData) || '',
          //     equality: turnNumberToEqualityValue({ numberValue: oldC.Equality, showDisplayValue: true, isOldRule: true }) || '',
          //     equalityNumber: Number(oldC.Equality) || 0,
          //     value: findWorkflowRuleByFieldType(oldC.FieldData) || '',
          //     // value: oldC.FieldData?.FieldString?.Json?.Value || '',
          //     conditionObj: conditionObject || {},
          //     type: oldC.FieldData?.Fieldtype > 10 ? 'customField' : 'baseField',
          //     allAny: 'All'
          //   };

          //   if (conditionObject?.Meta?.Cid === 9000) {
          //     console.warn('TYPE WARNING')
          //     condition = {
          //       ...condition,
          //       conditionObj: {
          //         ...conditionObject,
          //         Type: {
          //           ...conditionObject.Type,
          //           OptLabels: ticketTypes?.data?.map((item: any) => item.Name) || [],
          //           UseOption: true
          //         }
          //       },
          //     }
          //   }


          //   console.log(condition)
          //   return condition
          // }
          const oldC = c as OldCondition;
          const conditionObject = baseFields?.data.find((item: any) => item?.Meta?.Name === findFieldName(oldC.FieldData))
          console.log(conditionObject)
          if (oldC.FieldData?.Fieldtype < 10) {

            let conditionObject = baseFields?.data.find((item: any) => item?.Meta?.Name === findFieldName(oldC.FieldData))
            console.log(conditionObject)

            let condition = {
              condition: findFieldName(oldC.FieldData) || '',
              equality: turnNumberToEqualityValue({ numberValue: oldC.Equality, showDisplayValue: true, isOldRule: true }) || '',
              equalityNumber: Number(oldC.Equality) || 0,
              value: findWorkflowRuleByFieldType(oldC.FieldData) || '',
              // value: oldC.FieldData?.FieldString?.Json?.Value || '',
              conditionObj: conditionObject || {},
              type: oldC.FieldData?.Fieldtype > 10 ? 'customField' : 'baseField',
              allAny: 'Any'
            };

            if (conditionObject?.Meta?.Cid === 9000) {
              console.warn('TYPE WARNING')
              condition = {
                ...condition,
                conditionObj: {
                  ...conditionObject,
                  Type: {
                    ...conditionObject.Type,
                    OptLabels: ticketTypes?.data?.map((item: any) => item.Name) || [],
                    UseOption: true
                  }
                },
              }
            }

            console.log(condition)
            return condition

          } else {
            //! this is when the old field type is a custom Field 

            console.log(oldC.FieldData?.Fieldtype)
            console.log(oldC.FieldData?.FieldString?.Cid)

            console.log(customFields?.data)
            const currentCustomField = customFields?.data?.find((item: any) => item?.Meta?.Cid === oldC.FieldData?.FieldString?.Cid)

            console.log(currentCustomField)

            const renderDynamicFieldValue = (customField: any) => {
              if (customField && customField?.TypeId && CFTypeIdDetail(customField?.TypeId).ClassBinding) {
                switch (CFTypeIdDetail(customField.TypeId).ClassBinding) {
                  case 'cfTDropdown': return oldC.FieldData?.FieldString?.Json?.Value.SelectedOpt;
                  case 'cfTDate': return oldC.FieldData?.FieldString?.Json?.Value.IncludeTime;
                  case 'cfTString': return oldC.FieldData?.FieldString?.Json?.Value.Str;
                  case 'cfTInteger': return oldC.FieldData?.FieldString?.Json?.Value;
                  case 'cfTToggle': return oldC.FieldData?.FieldString?.Json?.Value.Checked;
                  default: return []
                }
              }
            }

            let condition = {
              condition: currentCustomField?.Meta?.Name || '',
              equality: turnNumberToEqualityValue({ numberValue: oldC.Equality, showDisplayValue: true, isOldRule: true }) || '',
              equalityNumber: Number(oldC.Equality) || 0,
              value: renderDynamicFieldValue(currentCustomField) || '',
              conditionObj: currentCustomField || {},
              type: oldC.FieldData?.Fieldtype > 10 ? 'customField' : 'baseField',
              allAny: 'All'
            };

            if (conditionObject?.Meta?.Cid === 9000) {
              console.warn('TYPE WARNING')
              condition = {
                ...condition,
                conditionObj: {
                  ...conditionObject,
                  Type: {
                    ...conditionObject.Type,
                    OptLabels: ticketTypes?.data?.map((item: any) => item.Name) || [],
                    UseOption: true
                  }
                },
              }
            }


            return condition
          }
        };

        return mapCondition(condition);
      }) || [],
      status_switch: rule.Actions?.some((action: OldEmailAction | OldAssigneeAction | OldStatusAction) => action.Actiontype === 2),
      assignee_switch: rule.Actions?.some((action: OldEmailAction | OldAssigneeAction | OldStatusAction) => action.Actiontype === 3),
      email_switch: rule.Actions?.some((action: OldEmailAction | OldAssigneeAction | OldStatusAction) => action.Actiontype === 1),
      actions: rule.Actions?.map((action: OldEmailAction | OldAssigneeAction | OldStatusAction) => {
        // console.log(action)
        const mapAction = (a: OldEmailAction | OldAssigneeAction | OldStatusAction) => {
          switch (a.Actiontype) {
            case 1:

              const transformEmailRecipient = ({ Actiondata, agents }: any) => {
                const result: { id: number; label: string }[] = [];

                // Handle RecipientList
                if (Array.isArray(Actiondata.RecipientList) && Actiondata.RecipientList.length > 0) {
                  const listResults = Actiondata.RecipientList.map((recipient: string) => {
                    // Handle email strings
                    if (recipient.includes('@')) {
                      return {
                        id: recipient,
                        label: recipient
                      };
                    } else {
                      const recipientId = agents.data?.data.VisibleAgents?.find(
                        (agent: any) => agent.AgentId === Number(recipient)
                      )

                      const label = agents.data?.data?.VisibleAgents?.find(
                        (agent: any) => agent.AgentId === Number(recipient)
                      )?.Name

                      return {
                        id: Number(recipient),
                        label: label
                      }
                    }
                  });

                  result.push(...listResults);
                }

                // Handle single Recipient
                if (Actiondata.Recipient && Actiondata.Recipient > 0) {
                  const agentName = agents.data?.data.VisibleAgents?.find(
                    (agent: any) => agent.AgentId === Actiondata.Recipient
                  )?.Name || '';

                  result.push({
                    id: Actiondata.Recipient,
                    label: agentName
                  });
                }

                return result;
              };


              return {
                type: 'email',
                email_switch: true,
                email_config: {
                  Recipient: a.Actiondata.Recipient,
                  RecipientList: transformEmailRecipient({ Actiondata: a.Actiondata, agents: agents }),
                  Subject: a.Actiondata.Subject,
                  Body: a.Actiondata.Body,
                }
              }
            case 2:
              return {
                type: 'status',
                status_switch: true,
                status_config: {
                  StatusId: a.Actiondata.StatusId,
                  statusObject: {
                    id: a.Actiondata.StatusId,
                    label: statuses.data?.find((status: any) => status.StatusId === a.Actiondata.StatusId)?.Name || ''
                  }

                }
              }
            case 3:
              return {
                type: 'assignee',
                assignee_switch: true,
                assignee_config: {
                  Assignee: a.Actiondata.Assignee,
                  assigneeObject: {
                    id: a.Actiondata.Assignee,
                    label: agents.data?.data.VisibleAgents?.find((agent: any) => agent.AgentId === a.Actiondata.Assignee)?.Name || ''
                  }
                }
              }
          }
        }
        return mapAction(action)
      }) || [],
    }
  }



  // Reset form with transformed data when currentWorkflowRule changes
  useEffect(() => {
    if (currentWorkflowRule) {
      const transformedData = transformWorkflowRule(currentWorkflowRule);
      methods.reset(transformedData);
      console.log(transformedData)
      console.log(methods.getValues())
    }
  }, [currentWorkflowRule]);

  // Open drawer when component mounts
  useEffect(() => {
    setIsOpen(true)
    console.log(workflowruleId)
    console.log({ currentWorkflowRule })
    if (currentWorkflowRule) {
      const transformedData = transformWorkflowRule(currentWorkflowRule);
      methods.reset(transformedData);
      console.log(transformedData)
      console.log(methods.getValues())
    }
  }, [])

  const handleClose = () => {
    setIsOpen(false)
    // Wait for animation to complete before navigating
    setTimeout(() => {
      navigate({ to: '/workflow-rules' })
    }, 300) // Match this with MUI's transition duration
  }

  const handleCloseDrawer = () => {
    handleClose()
    setActiveStep(0);
    setCompleted({});
    // methods.reset(editCustomerValues2);
    navigate({ to: '/workflow-rules' });
  };

  const handleSubmit: SubmitHandler<any> = (formValues: any) => {
    console.log('formValues : ', formValues);

    if (variant === 'edit') {
    } else {
    }
    return undefined
  }

  const handleComplete = () => {
    console.log('handleComplete')
    console.log(methods.getValues())
  }

  const steps = [
    {
      label: 'Step 1',
      key: 'step1',
      component: ({ stepKey }: { stepKey: string }) => <WorkflowRuleStep1 />,
    },
    {
      label: 'Step 2',
      key: 'step2',
      component: ({ stepKey }: { stepKey: string }) => <WorkflowRuleStep2 />,
    },
    {
      label: 'Step 3',
      key: 'step3',
      component: ({ stepKey }: { stepKey: string }) => <WorkflowRuleStep3 />,
    },
    {
      label: 'Step 4',
      key: 'step4',
      component: ({ stepKey }: { stepKey: string }) => <WorkflowRuleStep4 />,
    },
  ]

  return (
    <DrawerLayout
      open={isOpen}
      onClose={handleCloseDrawer}
      anchor="right"
      keepMounted
      width={'80%'}
      Header={
        <DrawerHeader
          close={handleCloseDrawer}
          title={variant === 'edit' ? 'Edit Workflow Rule' : 'New Workflow Rule'}
        />
      }
    >
      <Grid
        container
        style={{
          height: '100%',
          width: '100%',
        }}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent={'space-between'}
        flexWrap="nowrap"
        padding={4}
        overflow="auto"
      >
        <FormProvider {...methods}>
          <Container maxWidth='xl' component='form' onSubmit={methods.handleSubmit(handleSubmit)} sx={{ mt: 4 }}>
            <Grid size={{ xs: 12 }} marginTop={!isDesktop ? 2 : 0} height={'100%'} sx={{ overflow: 'auto', paddingBottom: '90px', mt: 4 }}>
              <SmartStepper
                handleComplete={handleComplete}
                routePath='/workflow-rules'
                steps={steps}
                key={steps.length}
              />
            </Grid>
          </Container>
        </FormProvider>

      </Grid >
    </DrawerLayout >
  );
};

export default WorkflowRuleDrawer;