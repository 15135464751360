// src/store/flowStore.ts
import { create } from 'zustand';
import {
  Node,
  Edge,
  NodeChange,
  EdgeChange,
  Connection,
  applyNodeChanges,
  applyEdgeChanges
} from '@xyflow/react';
import { nanoid } from 'nanoid';
import { WorkflowEdge, WorkflowNode } from './types';
import { initialEdges, initialNodes } from './mockData';

interface FlowState {
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  onNodesChange: (changes: NodeChange[]) => void;
  onEdgesChange: (changes: EdgeChange[]) => void;
  onConnect: (connection: Connection) => void;
}

export const useFlowStore = create<FlowState>((set) => ({
  nodes: initialNodes,
  edges: initialEdges,

  onNodesChange: (changes: NodeChange[]) => {
    set((state) => ({
      ...state,
      nodes: applyNodeChanges(changes, state.nodes) as WorkflowNode[]
    }));
  },

  onEdgesChange: (changes: EdgeChange[]) => {
    set((state) => ({
      ...state,
      edges: applyEdgeChanges(changes, state.edges) as WorkflowEdge[]
    }));
  },

  onConnect: (connection: Connection) => {
    if (!isValidConnection(connection)) return;

    set((state) => ({
      ...state,
      edges: [
        ...state.edges,
        {
          id: nanoid(),
          source: connection.source!,
          target: connection.target!,
          type: 'custom'
        }
      ]
    }));
  }
}));

function isValidConnection(connection: Connection): boolean {
  if (!connection.source || !connection.target) return false;
  if (connection.source === connection.target) return false;
  return true;
}

