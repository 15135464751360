import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2'; // Grid version 2
import { Theme, useMediaQuery } from '@mui/material';
import { AutoComplete, Button, Card, IconButton, Tooltip, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import SimpleTable from '@/components/Molecules/Table/SimpleTable.component';
import useReportTable from './useReportTable';
import useReportTableStore from './useReportTableStore';
import { ToggleLeft, ToggleRight } from 'lucide-react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { defaultValues, reportSchema, ReportFilterSchema } from '@/interfaces/schemas/report.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import { createCustomersQueryOptions } from '@/hooks/fetches/useTicket.service';
import { useQuery } from '@tanstack/react-query';

const Reports = () => {
  const { reportTableState, setCustomFilters, setReportFilter, setIsReportsFiltered } = useReportTableStore()
  // const { reportState, updateReports } = useReports();
  const { reportTable } = useReportTable();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const methods = useForm<ReportFilterSchema>({
    mode: 'all',
    resolver: zodResolver(reportSchema),
    defaultValues,
  });

  const customerValue = useWatch({ control: methods.control, name: 'customer_filter' });
  const categoryValue = useWatch({ control: methods.control, name: 'category_filter' });

  const customers = useQuery(createCustomersQueryOptions())

  const baseCategories = [
    { id: 0, label: 'Active' },
    { id: 1, label: 'Closed' },
    { id: 2, label: 'Invoice' },
    { id: 3, label: 'All' },
  ];

  const getCategories = () => {
    const isGatewayCustomer = customerValue?.id === 9;

    return isGatewayCustomer
      ? [...baseCategories, { id: 4, label: 'Assigned' }]
      : baseCategories;
  };

  const handleSubmit = (formValues: any) => {
    if (reportTableState?.isReportsFiltered) {
      const confirmUnFilter = confirm(`Are you sure you want to abandon your search for "Filtering By: ${reportTableState?.reportFilter?.name}" filter? Doing so will begin a search of "${formValues.category_filter?.label}" ${formValues.customer_filter?.label} tickets.`)

      if (confirmUnFilter) {
        reportTable.resetColumnFilters();
        setCustomFilters({ customer: formValues.customer_filter, category: formValues.category_filter });
        setReportFilter({ id: '', name: '', toggleOn: false, condition: [] });
        setIsReportsFiltered(false);
        reportTable.setColumnFilters(() => [
          {
            id: 'CustomerName',
            value: formValues.customer_filter ? formValues.customer_filter?.label : '',
          },
        ]);
      }
    } else {
      reportTable.resetColumnFilters();
      setCustomFilters({ customer: formValues.customer_filter, category: formValues.category_filter });
      reportTable.setColumnFilters(() => [
        {
          id: 'CustomerName',
          value: formValues.customer_filter ? formValues.customer_filter?.label : '',
        },
      ]);
    }

    // return { customer };
  };

  useEffect(() => {
    methods.setValue('customer_filter', reportTableState?.customFilters?.customer ?? null);
    methods.setValue('category_filter', reportTableState?.customFilters?.category?.label ? { id: reportTableState?.customFilters?.category?.id, label: reportTableState?.customFilters?.category?.label } : null);
  }, []);

  useEffect(() => {
    methods.setValue('customer_filter', reportTableState?.customFilters?.customer ?? null);
    methods.setValue('category_filter', reportTableState?.customFilters?.category?.label ? { id: reportTableState?.customFilters?.category?.id, label: reportTableState?.customFilters?.category?.label } : null);
  }, [reportTableState?.customFilters?.customer, reportTableState?.customFilters?.category?.label]);

  useEffect(() => {
    if (categoryValue?.label === 'Assigned' && customerValue?.id !== 9) {
      methods.setValue('category_filter', null);
    }
  }, [customerValue?.id, categoryValue?.label])


  return (
    <>
      <Grid size={12} display="flex" flexDirection="column" sx={{ mt: -8 }}>
        <Grid
          display={reportTableState?.isFilterVisible ? 'flex' : 'none'}
          sx={{
            marginBottom: reportTableState?.isFilterVisible ? 2 : 0
          }}
        >
          <Card sx={{ p: 4, width: '100%' }} >
            {/* <Typography variant="h4" sx={{ mb: 4 }}>Filters</Typography> */}
            <FormProvider {...methods}>
              <form className="d-flex flex-column row-gap-4" onSubmit={methods.handleSubmit(handleSubmit)}>
                <Grid
                  size={12}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                // height='100px'
                // rowGap={4}
                // paddingX={2}
                // paddingY={1}
                >
                  <Grid
                    // size={9}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    flexWrap="wrap"
                  >

                    <Grid size={3} sx={{ mr: 4, minWidth: "200px", paddingBottom: '4px', paddingTop: '4px' }}>

                      <AutoComplete
                        id="customer_filter"
                        options={
                          customers.isPending
                            ? []
                            : customers?.data?.map((customer: any) => ({
                              id: customer.CustomerEntId,
                              label: customer.EntName,
                            })) || []
                        }
                        loading={customers.isPending}
                        autocompleteProps={{
                          id: 'customer_filter',
                          loadingText: 'Loading Customers...',
                          size: 'small',
                        }}
                        multiple={false}
                        label="Customer"
                      />
                    </Grid>
                    <Grid
                      size={2}
                      sx={{ mr: 4, minWidth: "140px", paddingBottom: '4px', paddingTop: '4px' }}
                    >

                      <AutoComplete
                        id="category_filter"
                        options={getCategories()}
                        label="Category"
                        autocompleteProps={{
                          id: 'category_filter',
                          size: 'small',
                        }}

                      />
                    </Grid>
                    <Grid>

                      <div style={{ display: 'flex', justifyContent: 'end', paddingBottom: '4px', paddingTop: '4px' }}>
                        <Button
                          id="clear"
                          size='small'
                          variant="outlined"
                          sx={{ marginRight: 2 }}
                          onClick={() => {
                            methods.reset();
                            reportTable.resetColumnFilters();
                            setCustomFilters({ customer: undefined, category: undefined });
                            setReportFilter({ id: '', name: '', toggleOn: false, condition: [] });
                            setIsReportsFiltered(false);
                          }}
                        >
                          Clear
                        </Button>
                        <Button id="filterResults" variant="contained" type="submit" size='small' sx={{ minWidth: "96px" }}>
                          Filter Results
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    sx={{ paddingBottom: '4px', paddingTop: '4px' }}
                  >
                    {/* <Permissions target={'createReportFilters'} disableMode> //todo Do we need Permissions to do this? */}
                    {reportTableState?.isReportsFiltered && <Typography sx={{ mr: 2 }}>Filtering By: <b>{reportTableState?.reportFilter?.name}</b></Typography>}
                    <Button
                      size='small'
                      id="createReportFilter"
                      variant='contained'
                      onMouseDown={() => {
                        navigate({
                          to: '/reports/$reportFilter',
                          params: { reportFilter: 'all' },
                          search: {
                            variant: 'reportFilters',
                            step: 0
                          }
                        })
                      }}
                      sx={{ minWidth: "56px" }}
                    >
                      Filters
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Card>
        </Grid>
        <Grid size={12} height={'100%'}>
          <SimpleTable table={reportTable} />
        </Grid>
      </Grid>
    </>
  );
};

export default Reports;
