import TicketList from '@/app/pages/TicketList/TicketList.page'
import { createPermissionsQueryOptions, createRoleIdQueryOptions } from '@/hooks/fetches/usePermission.service'
import { createMergeTemplatePermissionsOptions } from '@/hooks/fetches/useTemplate.service'
import DashboardLayout from '@/layouts/DashboardLayout'
import { useAuth, useUser } from '@/lib/auth'
import InactivityChecker from '@/lib/InactivityChecker.component'
import { queryClient } from '@/lib/queryClient'
import { checkResourceAccess, handleAccess } from '@/permissions/Permissions'
import { setupInterceptors } from '@/services/api.service'
import { createPingQueryOptions } from '@/services/queries/pingOptions'
import { createFileRoute, Outlet, redirect, useRouter } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { useEffect } from 'react'
import { z } from 'zod'

export const Route = createFileRoute('/_auth')({
  beforeLoad: async ({ context, location, }) => {
    // context.queryClient.cancelQueries()

    // console.log('beforeLoad')
    await setupInterceptors(context.queryClient)

    const isAuth = context.auth.checkAuthStatus();
    // console.warn('isAuth: ', isAuth)
    if (!isAuth) {
      console.log('Not authenticated, redirecting to login')
      context.auth.setCurrentPath(location.href)
      console.log(location.href)
      const redirectPath = `${location.href}`
      console.log('Redirect path:', redirectPath) // Debug log
      const encodedPath = encodeURIComponent(redirectPath)
      console.log('Encoded path:', encodedPath)
      throw redirect({
        to: '/login',
        search: {
          redirect: encodedPath
        },
      })
    }

    const ping = await context.auth.checkPing()

    // console.log('ping', ping)

    if (!ping) {
      const encodedPath = encodeURIComponent(location.href)
      throw redirect({
        to: '/login',
        replace: true,
        search: { redirect: encodedPath }
      })
    }

    const permissions = context.auth.permissions

    if (!permissions) {


      console.log(location)

      function sanitizePathname(pathname: string): string {
        const segments = pathname.split('/')
        if (segments.length > 1) {
          return '/' + segments[1]
        }
        return '/'
      }

      const path = sanitizePathname(location.pathname)
      console.log(path)



      const hasAccess = await checkResourceAccess({
        queryClient: context.queryClient,
        pathname: path,
        permissions,
        auth: context.auth
      })

      console.log('hasAccess', hasAccess)

      if (!hasAccess) {
        console.log('toss em out!')
        throw redirect({
          to: '/',
          replace: true,
          search: { drawer: undefined }
        })
      }
    }
  },
  loader: async ({ context }) => {
    const isAuth = context.auth.checkAuthStatus();

    const permissions = context.auth.permissions
    // console.warn('permissions', permissions)

    if (!isAuth && !permissions) {
      console.log('fire ensurequeryData')
      await Promise.all([
        setupInterceptors(context.queryClient),
        context?.queryClient?.prefetchQuery(createPermissionsQueryOptions(context.auth.checkAuthStatus)),
        context?.queryClient?.prefetchQuery(createMergeTemplatePermissionsOptions()),
        context?.queryClient?.prefetchQuery(createRoleIdQueryOptions())
      ])

    }
  },
  component: AuthLayout,
})

function AuthLayout() {
  const router = useRouter()
  const navigate = Route.useNavigate()
  const auth = useAuth()
  // const user = useUser({})
  // const { redirect } = Route.useSearch()

  useEffect(() => {
    if (!auth.isAuthenticated) {
      const redirectPath = `${router.state.location.href}`
      const encodedPath = encodeURIComponent(redirectPath)
      navigate({
        to: '/login',
        search: {
          redirect: encodedPath
        },
        replace: true
      });
    }
  }, [auth.isAuthenticated, router]);

  // useEffect(() => {
  //   async function checkPermissions() {
  //     console.log('auth', auth)
  //     if (!auth.permissions?.permissions) {
  //       const ping = await queryClient.fetchQuery(createPingQueryOptions())
  //       console.log('ping', ping)
  //       if (ping === undefined) {
  //         console.log('ping is true, refetch')
  //         await auth.logout()
  //         // queryClient.refetchQueries()
  //         console.log('no permissions, refetch')
  //         const redirectPath = `${router.state.location.href}`
  //         const encodedPath = encodeURIComponent(redirectPath)
  //         navigate({
  //           to: '/login',
  //           search: {
  //             redirect: encodedPath
  //           },
  //           replace: true
  //         });
  //       }
  //     }
  //   }
  //   checkPermissions()
  // }, [])

  return (
    <>
      <Outlet />
      {/* <InactivityChecker
        renderTimeLeft
        timeoutInMinutes={15}
      /> */}
      {/* <TanStackRouterDevtools /> */}
    </>
  )
}