import { useState } from 'react';
import TicketTypeService from '@/services/ticketType.service';
import { enqueueSnackbar } from 'notistack';
import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

type ActionType = 'createTicketType' | 'updateTicketType' | 'deleteTicketType';

interface MutationAction {
  actionType: ActionType;
  payload: any;
}

const useTicketTypeMutation = (): {
  executeTicketTypeAction: UseMutationResult<any, unknown, MutationAction, unknown>['mutate'];
  isUpdating: Record<string, boolean>;
  setIsUpdating: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
} => {
  const [isUpdating, setIsUpdating] = useState<Record<string, boolean>>({});

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const ticketTypeService = TicketTypeService();

  const actionMap: { [key in ActionType]: Function } = {
    createTicketType: ticketTypeService.createTicketType,
    updateTicketType: ticketTypeService.updateTicketType,
    deleteTicketType: ticketTypeService.deleteTicketType,
  };

  const executeMutation = async ({ actionType, payload }: MutationAction) => {
    const serviceMethod = actionMap[actionType];
    if (!serviceMethod) {
      enqueueSnackbar('Error processing your request', { variant: 'error' });
    } else {
      return serviceMethod(payload);
    }
  };

  const { mutate: executeTicketTypeAction } = useMutation<any, unknown, MutationAction>({
    mutationFn: executeMutation,
    onMutate: ({ actionType }) => {
      setIsUpdating((prev) => ({ ...prev, [actionType]: true }));
    },

    onSuccess: (data: any, { actionType }) => {
      const messages: { [key in ActionType]: string } = {
        deleteTicketType: 'Ticket type successfully deleted',
        createTicketType: 'Ticket type successfully created',
        updateTicketType: 'Ticket type successfully updated',
      };

      const successMessage = messages[actionType] || 'Operation successful';
      const errorMessage = `Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()}`;

      enqueueSnackbar(successMessage, { variant: 'success' });
      navigate({ to: '/type-editor' });
    },
    onError: (error: any, { actionType }) => {
      const errorMessage = `Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });

  return { executeTicketTypeAction, isUpdating, setIsUpdating };
};

export default useTicketTypeMutation;
