import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Box, IconButton, CircularProgress } from '@mui/material';
import { MapContainer, TileLayer, LayersControl, useMap, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.css';
import L from 'leaflet';
import 'leaflet.locatecontrol';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import useTicketList from '@/contexts/stores/ticketList.store';
import { Link, useParams } from '@tanstack/react-router';
import { Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { useQuery } from '@tanstack/react-query';
import { createTicketByIdQueryOptions } from '@/hooks/fetches/useTicket.service';

interface MapMarkersProps {
  ticketId?: string;
  latitude?: number | null;
  longitude?: number | null;
}

interface LatLon {
  Latitude: number;
  Longitude: number;
}

interface LayerChangeHandlerProps {
  onLayerChange: (name: string) => void;
}

const TicketDetailMap = () => {

  const { ticketId } = useParams({
    from: '/_auth/ticket/$ticketId',
  })

  const { ticketListState, updateTicketList } = useTicketList();
  const ticketById = useQuery(createTicketByIdQueryOptions(ticketId))

  // const { data: ticketById?.data } = ticketById || {};

  // console.log('ticketById?.data:', ticketById?.data);

  // const [loading, setLoading] = useState(false);
  const [latLon, setLatLon] = useState<LatLon>();
  const mapRef = useRef<L.Map>(null);
  const { BaseLayer } = LayersControl;
  const savedLayer = localStorage.getItem('selectedLayer') || 'Google Hybrid';
  const [selectedLayer, setSelectedLayer] = useState(savedLayer);
  const locateControlRef = useRef<L.Control.Locate | null>(null);

  const MapMarkers2 = ({ ticketId, latitude, longitude }: MapMarkersProps): JSX.Element | null => {
    const bounds = L.latLngBounds([]);

    const markerData = [
      {
        dueDate: dayjs(ticketById?.data?.Ticket.DueDate).format('MM/DD/YY'),
        ticketId: ticketById?.data?.Ticket.TId,
        latitude: ticketById?.data?.Latitude,
        longitude: ticketById?.data?.Longitude,
        address: ticketById?.data?.Ticket.ClientAddress,
        status: ticketById?.data?.Status.Name,
        assignee: ticketById?.data?.Assignee.Name,
        markerColor: ticketById?.data?.Status.Color,
        criticalPriority: ticketById?.data?.Ticket.Priority && ticketById?.data?.Ticket.Priority === 'critical',
      },
    ];

    const map = useMap();

    function intToRGB(colorInt: number) {
      const b = (colorInt >> 16) & 255;
      const g = (colorInt >> 8) & 255;
      const r = colorInt & 255;

      return `rgb(${r}, ${g}, ${b})`;
    }

    const customIcon = (color: number, criticalPriority: boolean) => {
      const borderStyle = criticalPriority ? 'stroke-width: 2; stroke: red;' : '';
      return L.icon({
        iconUrl: `data:image/svg+xml;charset=UTF-8,
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'>
            <!-- Outer Border with padding -->
            <path d='M13 1C8.13 1 4 5.13 4 10c0 5.25 9 15 9 15s9-9.75 9-15c0-4.87-4.13-9-9-9z' style='${borderStyle}' fill='none'/>
            <!-- Marker -->
            <path fill='${intToRGB(color)}' d='M13 3C9.13 3 6 6.13 6 10c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S11.62 7.5 13 7.5 15.5 8.62 15.5 10 14.38 12.5 13 12.5z'/>
          </svg>`,
        iconSize: [41, 41],
        iconAnchor: [20.5, 41],
        popupAnchor: [1, -41],
      });
    };

    return (
      <>
        {markerData.map((data) => {
          const {
            dueDate,
            ticketId,
            latitude,
            longitude,
            address,
            markerColor,
            criticalPriority,
            status,
            assignee
          } = data;

          const dueDateObj = dayjs(dueDate);
          const formattedDate = dueDateObj.isValid() &&
            dueDate.trim().toLowerCase() !== '0001-01-01t00:00:00z'
            ? dueDateObj.format('MM/DD/YY')
            : '';

          const dateStyle = {
            color: dueDateObj.isBefore(dayjs()) ? 'red' : 'black'
          };

          if (latitude && longitude && latitude !== 0 && longitude !== 0) {
            bounds.extend([latitude, longitude]);

            return (
              <Marker
                key={ticketId}
                position={[latitude, longitude]}
                icon={customIcon(Number(markerColor), criticalPriority)}
                eventHandlers={{
                  add: () => {
                    map.fitBounds(bounds, { padding: [50, 50] });
                  }
                }}
              >
                <Popup>
                  <div className="marker-popup">
                    <p>
                      <b>Ticket Id:</b>{' '}
                      <Link
                        to={`/ticket/$ticketId`}
                        params={{ ticketId: ticketId }}
                      >
                        {ticketId}
                      </Link>
                      <a href={`ticket.html?TicketId=${ticketId}`}>
                      </a>
                    </p>
                    <p><b>Address:</b> {address}</p>
                    <p>
                      <b>Due Date:</b>{' '}
                      <span style={dateStyle}>{formattedDate}</span>
                    </p>
                    <p><b>Assigned To:</b> {assignee}</p>
                    <p><b>Status:</b> {status}</p>
                  </div>
                </Popup>
              </Marker>
            );
          }
          return null;
        })}
      </>
    );
  };

  const handleClose = () => {
    updateTicketList({ ticketDetailMapOpen: false });
  };

  const LayerChangeHandler = ({ onLayerChange }: LayerChangeHandlerProps): null => {
    const map = useMap();

    useEffect(() => {
      const handleLayerChange = (e: any) => {
        if (e.name) {
          onLayerChange(e.name);
        }
      };

      map.on('baselayerchange', handleLayerChange);

      return () => {
        map.off('baselayerchange', handleLayerChange);
      };
    }, [map, onLayerChange]);

    return null;
  };

  const handleLayerChange = (name: any) => {
    setSelectedLayer(name);
  };

  const addLocateControl = (map: L.Map, locateControlRef: React.MutableRefObject<L.Control.Locate | null>) => {
    if (!locateControlRef.current) {
      const locateControl = L.control.locate().addTo(map);
      locateControlRef.current = locateControl;
    }
  };

  // const loadingSpinner = () => {
  //   return (
  //     <Box sx={{ display: 'flex', zIndex: 2000, position: 'absolute', top: '50%', left: '50%' }} >
  //       <CircularProgress sx={{ color: 'rgb(255, 193, 7)' }}/>
  //     </Box>
  //   );
  // }

  const MemoTicketDetailMap = useMemo(() => {
    return (
      <MapContainer
        center={[40.235077, -99.121838]}
        zoom={5}
        ref={mapRef}
        style={{ height: '100%', width: '100%' }}
        maxZoom={20}
      >
        <LayersControl position="topright">
          <BaseLayer checked={selectedLayer === 'Google Hybrid'} name="Google Hybrid">
            <TileLayer url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" maxZoom={20} />
          </BaseLayer>
          <BaseLayer checked={selectedLayer === 'Google Satellite'} name="Google Satellite">
            <TileLayer url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" maxZoom={20} />
          </BaseLayer>
          <BaseLayer checked={selectedLayer === 'OpenStreetMap'} name="OpenStreetMap">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={20} />
          </BaseLayer>
        </LayersControl>
        <LayerChangeHandler onLayerChange={handleLayerChange} />
        {ticketListState.ticketDetailMapOpen &&
          ticketById?.data &&
          ticketById?.data.Latitude !== null &&
          ticketById?.data.Longitude !== null && (
            // <MapMarkers
            //   ticketId={(ticketId).toString()}
            //   latitude={ticketById?.data?.Latitude}
            //   longitude={ticketById?.data?.Longitude}
            // />
            <MapMarkers2 />
          )}
      </MapContainer>
    )

  }, [ticketById?.data, selectedLayer, ticketListState.ticketDetailMapOpen, ticketId])

  useEffect(() => {
    if (ticketListState.ticketDetailMapOpen) {
      // console.log('ticketListState.ticketDetailMapOpen', ticketListState.ticketDetailMapOpen);
      if (!ticketById?.data?.Latitude || !ticketById?.data?.Longitude) {
        alert('No coordinates found\nUpdate address and save to refresh coordinates');
      } else {
        const intervalId = setInterval(() => {
          const map = mapRef.current;
          if (map) {
            addLocateControl(map, locateControlRef);
            clearInterval(intervalId);
          }
        }, 100);
        return () => clearInterval(intervalId);
      }
    }
  }, [ticketListState.ticketDetailMapOpen]);

  useEffect(() => {
    localStorage.setItem('selectedLayer', selectedLayer);
  }, [selectedLayer]);

  useEffect(() => {
    const checkStatusSelectLoaded = () => {
      const statusSelect = document.querySelector('[data-field="status"] select.TicketStatus') as HTMLSelectElement;

      if (
        statusSelect &&
        statusSelect.options[statusSelect.selectedIndex] &&
        statusSelect.options[statusSelect.selectedIndex].text
      ) {
        const selectedStatus = statusSelect.options[statusSelect.selectedIndex].text;

        clearInterval(intervalId);

        // const tdMapButton = document.getElementById("td-map") as HTMLButtonElement;
        // if (tdMapButton) {
        //   tdMapButton.addEventListener("click", handleOpen);
        //   tdMapButton.disabled = false;
        // }
      }
    };

    const intervalId = setInterval(checkStatusSelectLoaded, 100);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Modal
      open={ticketListState.ticketDetailMapOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box
        id="modal-container"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          height: '90%',
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* {loading && loadingSpinner()} */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: 'black',
            zIndex: '1000',
            backgroundColor: 'white',
            borderRadius: '4px',
            width: '48px',
            height: '48px',
            '&:hover': {
              backgroundColor: 'white',
            },
            border: '2px solid rgba(0, 0, 0, 0.2)',
            backgroundClip: 'padding-box',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ flex: 1, position: 'relative' }}>
          {MemoTicketDetailMap}
        </Box>
      </Box>
    </Modal>
  );
};

export default TicketDetailMap;
