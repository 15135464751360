import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext, useWatch } from 'react-hook-form';
import { Button, Box, Container, Theme, useMediaQuery, Typography, Alert, Snackbar, IconButton, Tooltip, DialogContent, Dialog, DialogActions, Checkbox, TextField, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { SmartStepper } from '../WorkflowRules/Stepper';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import TextBox from '@/components/Molecules/Textbox/Textbox.component';
import { LoadingButton } from '@mui/lab';
import { Copy, Trash2Icon, Plus } from 'lucide-react';
import ConfirmModal from '@/components/Molecules/Modal/ConfirmModal.component';
import { ContentCopy } from '@mui/icons-material';
import { createCustomerAdminQuery } from '@/services/queries/customerOptions';
import { AutoComplete } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import DateTimePickerElement from '@/components/Organisms/Date/DateTimePicker.component';
import TimePickerElement from '@/components/Organisms/Date/TimePicker.component';
import utc from 'dayjs/plugin/utc';
import useCustomerMutation from '@/hooks/fetches/useCustomerMutations';

dayjs.extend(utc);

const customerSchema = z.object({
  entName: z.string().min(1, { message: 'Name is required' }),
  entAddress: z.string().optional(),
  phone: z.string()
    .min(10, { message: 'Phone number must be 10 digits' })
    .transform((val) => val.replace(/\D/g, ''))  // First remove all non-digits
    .refine((val) => val.length === 10, { message: 'Phone number must be exactly 10 digits' })
    .transform((val) => {
      return `${val.slice(0, 3)}-${val.slice(3, 6)}-${val.slice(6)}`;
    }),
  open: z.custom<dayjs.Dayjs>((val) => val instanceof dayjs).optional(),
  close: z.custom<dayjs.Dayjs>((val) => val instanceof dayjs).optional(),
  emailMatch: z.string().optional(),
});

const initialCustomer = {
  entName: '',
  entAddress: '',
  phone: '',
  open: '',
  close: '',
  emailMatch: '',
}

type Customer = z.infer<typeof customerSchema>;

const CustomerDrawer = () => {
  const navigate = useNavigate();
  const params = useParams({ from: '/_auth/customers/$customerId' });
  const { variant, title } = useSearch({ from: '/_auth/customers/$customerId' })
  const [activeStep, setActiveStep] = useState(0);

  const methods = useForm<Customer>({
    mode: 'all',
    defaultValues: initialCustomer,
    // shouldUnregister: false,
    resolver: zodResolver(customerSchema)
  });

  const customerAdmin = useQuery(createCustomerAdminQuery())

  const { createCustomerMutation, updateCustomerMutation, } = useCustomerMutation();

  const currentCustomer = customerAdmin?.data?.find((customer: any) => customer?.Customer?.CustomerEntId === params.customerId)
  console.log('currentCustomer : ', currentCustomer)

  // Query workflow rule data based on ID

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));


  const [isOpen, setIsOpen] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [openCustomerFieldsDialog, setOpenCustomerFieldsDialog] = useState(false);

  // Open drawer when component mounts
  useEffect(() => {
    setIsOpen(true)
  }, [])

  const handleClose = () => {
    setIsOpen(false)
    // Wait for animation to complete before navigating
    setTimeout(() => {
      navigate({ to: '/customers' })
    }, 300) // Match this with MUI's transition duration
  }

  const handleCloseDrawer = () => {
    handleClose()
    setActiveStep(0);
    // methods.reset(editCustomerValues2);
    navigate({ to: '/customers' });
  };

  const handleSubmit: SubmitHandler<any> = (formValues: any) => {
    console.log('formValues : ', formValues);

    if (variant === 'edit') {

      console.log('updateCustomerMutation : ', updateCustomerMutation)
      formValues.customerEntId = currentCustomer?.Customer?.CustomerEntId

      updateCustomerMutation.mutate({
        actionType: 'updateCustomer',
        payload: formValues
      })
    } else {
      createCustomerMutation.mutate({
        actionType: 'createCustomer',
        payload: formValues
      })
    }
    return undefined
  }

  const handleComplete = () => {
    console.log('handleComplete')
    console.log(methods.getValues())
  }

  const handleDelete = () => {
    console.log('handleDelete')
    setIsDeleting(true);
    // executeTicketTypeAction({
    //   actionType: 'deleteTicketType',
    //   payload: {
    //     TypeID: parsedTicketType?.TypeID,
    //   },
    // });
  };

  useEffect(() => {
    if (variant === 'edit') {
      methods.setValue('entName', title)
      methods.setValue('entAddress', currentCustomer?.Customer?.EntAddress)
      methods.setValue('phone', currentCustomer?.Customer?.Phone)
      methods.setValue('open', currentCustomer?.Customer?.Open ?
        dayjs(currentCustomer.Customer.Open).utc() : undefined)
      methods.setValue('close', currentCustomer?.Customer?.Close ?
        dayjs(currentCustomer.Customer.Close).utc() : undefined)
      methods.setValue('emailMatch', currentCustomer?.Customer?.EmailMatch)
    }
  }, [variant, title])


  return (
    <DrawerLayout
      open={isOpen}
      onClose={handleCloseDrawer}
      anchor="right"
      keepMounted
      width={'50%'}
      Header={
        <DrawerHeader
          close={handleCloseDrawer}
          title={variant === 'edit' ? 'Edit Customer' : 'New Customer'}
        />
      }
    >
      <FormProvider {...methods}>
        {/* @ts-ignore */}
        <Container maxWidth='xl' component='form' onSubmit={methods.handleSubmit(handleSubmit)} sx={{
          overflowY: 'auto',
          mt: 5,
          height: '98%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          mb: 4,
        }}>
          <Grid size={12} display="flex" flexDirection="column" flexWrap="wrap" rowGap={4} paddingX={2} paddingY={1} >
            {variant === 'edit' && (
              <Alert severity='info'>
                This customer is being used on: {currentCustomer?.TicketCount} tickets
              </Alert>
            )}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Customer Details
            </Typography>
            <Grid size={12} container rowGap={4}>

              <Grid size={6}>
                <TextBox
                  name='entName'
                  fullWidth
                  label='Name'
                  required
                />

              </Grid>
              <Grid size={6}>
                <AutoComplete
                  id="role"
                  options={[]}
                  loading={false}
                  autocompleteProps={{
                    id: 'role',
                    loadingText: 'Loading Roles...',
                  }}
                  label="Role"
                />
              </Grid>
            </Grid>

            <Grid size={12} container rowGap={4}>
              <Grid size={6}>
                <TextBox
                  name='entAddress'
                  fullWidth
                  label='Address'
                  sx={{ mb: 4 }}
                />
              </Grid>
              <Grid size={6}>
                <TextBox
                  name='Phone'
                  fullWidth
                  label='Phone'
                  sx={{ mb: 4 }}
                  required
                />
              </Grid>
            </Grid>
            <Grid size={12} container rowGap={4}>
              <Grid size={6}>

                <TimePickerElement
                  sx={{ m: 0, mt: 0, width: '100%' }}
                  name='open'
                  isDate={true}
                  label='Open Time'
                  control={methods.control}
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                    },
                  }}
                />

              </Grid>
              <Grid size={6}>

                <TimePickerElement
                  sx={{ m: 0, mt: 0, width: '100%' }}
                  name='close'
                  isDate={true}
                  label='Close Time'
                  control={methods.control}
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>
              <Grid size={6}>
                <TextBox
                  name='emailMatch'
                  fullWidth
                  label='Email Match (Ex: *@customer.com, joe.dirt@customer.com[, ...])'
                  sx={{ mb: 4 }}
                />
              </Grid>
              {/* <Grid size={12}>
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Attachment Types
                  </Typography>

                  <div className="flex gap-2 mb-4">
                    <Button
                      variant="contained"
                      startIcon={<Plus size={20} />}
                      className="bg-blue-600"
                    >
                      Add Type
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<Copy size={20} />}
                      className="bg-blue-600"
                    >
                      Copy Existing Type
                    </Button>
                  </div>

                  <Box sx={{ p: 3, border: '1px solid #e0e0e0', borderRadius: 1, mb: 3 }}>
                    <TextField
                      label="Name"
                      defaultValue="Locate Photo"
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      label="Description"
                      defaultValue="Locate Photo"
                      multiline
                      rows={3}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      Number of attachments using this type: 0
                    </Typography>
                    <Button
                      startIcon={<Trash2Icon size={20} />}
                      color="error"
                      sx={{ mt: 2 }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>

          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: (theme: Theme) => theme.palette.background.paper,
              borderTop: '1px solid',
              borderColor: (theme: Theme) => theme.palette.divider,
              p: 5,
              display: 'flex',
              justifyContent: 'space-between',
              gap: '1rem'
            }}>
            <LoadingButton
              loading={isDeleting}
              color="error"
              startIcon={<Trash2Icon />}
              size='small'
              onClick={() => setOpenModal(true)}>
              Delete
            </LoadingButton>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button variant="outlined" onClick={handleCloseDrawer} size='small'>
                Cancel & Close
              </Button>
              {openModal && (
                <ConfirmModal
                  open={openModal}
                  setOpen={setOpenModal}
                  title="Are you sure?"
                  content="This action will permanently delete this customer."
                  onSuccess={handleDelete}
                  onClose={() => setOpenModal(false)}
                  cancelButtonText="Cancel"
                  confirmButtonText="Delete"
                />
              )}
              <LoadingButton loading={isUpdating} variant="contained" type="submit" size='small'>
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Container>

      </FormProvider>
    </DrawerLayout >
  )
}

export default CustomerDrawer