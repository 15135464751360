import { queryOptions, useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';
import AttachmentService from '@/services/attachment.service';
import { AxiosResponse } from 'axios';

interface IUseAttachment {
  attachmentByIdForDownload: UseQueryResult<any>;
  attachmentByIdForView: UseQueryResult<any>;
}

// const useAttachment = (fid: number, enabled: boolean = false): IUseAttachment => {
//   const attachmentService = AttachmentService();

//   const attachmentByIdForDownload = useQuery<any>({
//     queryKey: ['attachment-for-download', fid],
//     queryFn: async () => {
//       console.log('attachmentByIdForDownload fid:', fid);
//       if (!fid) return new Promise((resolve) => resolve(null));
//       return new Promise(async (resolve) => {
//         await attachmentService
//           .getAttachmentByIdForDownload({
//             fid,
//           })
//           .then((res) => {
//             resolve(res?.data);
//           });
//       });
//     },
//     // staleTime: 1000 * 60 * 5, // 5 minutes
//     // refetchInterval: 1000 * 60, // 1 Minute
//     enabled,
//     refetchOnWindowFocus: false,

//   const attachmentByIdForView = useQuery<any>({
//     queryKey: ['attachment-for-view', fid],
//     queryFn: async () => {
//       console.log('attachmentByIdForView fid:', fid);
//       if (!fid) return new Promise((resolve) => resolve(null));
//       return new Promise(async (resolve) => {
//         await attachmentService
//           .getAttachmentByIdForView({
//             fid,
//           })
//           .then((res) => {
//             console.log(res?.data);
//             resolve(res?.data);
//           });
//       });
//     },
//     // staleTime: 1000 * 60 * 5, // 5 minutes
//     // refetchInterval: 1000 * 60, // 1 Minute
//     enabled,
//     refetchOnWindowFocus: false,
//   });

//   return {
//     attachmentByIdForDownload,
//     attachmentByIdForView,
//   };
// };

export const attachmentKeys: any = {
  all: ['attachment'] as const,
  download: (fid: number) => [...attachmentKeys.all, 'download', fid] as const,
  view: (fid: number) => [...attachmentKeys.all, 'view', fid] as const,
};

// Query Options
export const getAttachmentDownloadOptions = (fid: number, enabled: boolean) => {
  const attachmentService = AttachmentService();
  return queryOptions({
    queryKey: attachmentKeys.download(fid),
    queryFn: async () => {
      if (!fid) return null;
      const response = await attachmentService.getAttachmentByIdForDownload({ fid });
      return response?.data;
    },
    enabled,
    refetchOnWindowFocus: false,
  });
};

export const getAttachmentViewOptions = (fid: number, enabled: boolean) => {
  const attachmentService = AttachmentService();
  return queryOptions({
    queryKey: attachmentKeys.view(fid),
    queryFn: async () => {
      if (!fid) return null;
      const response = await attachmentService.getAttachmentByIdForView({ fid });
      return response?.data;
    },
    enabled,
    refetchOnWindowFocus: false,
  });
};

// export default useAttachment;
