import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import { Box, Button, CircularProgress, Container } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { AutoComplete } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import useStatus from '@/hooks/fetches/Statuses/useStatus.service';
import DraggableList from './DraggableList';
import { CustomerEntity } from '@/interfaces/customer.interface';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormValuesSchema } from '@/interfaces/schemas/sortStatuses.schema';
import { z } from 'zod';
import { LoadingButton } from '@mui/lab';
import { SimpleStatus } from '@/interfaces/status.interface';
import { createCustomersQueryOptions } from '@/hooks/fetches/useTicket.service';
import { useQuery } from '@tanstack/react-query';

interface Customer {
  id: number;
  label: string;
}

interface FormValues {
  statuses: SimpleStatus[] | [];
  customer: Customer | null;
}

const EditStatusOrderDrawer = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    setIsOpen(true);
  }, []);

  const customers = useQuery(createCustomersQueryOptions())

  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      statuses: [],
      customer: null,
    },
    resolver: zodResolver(FormValuesSchema),
  });

  const customerWatch = useWatch({ control: methods.control, name: 'customer' });
  const statusesWatch = useWatch({ control: methods.control, name: 'statuses' });
  const {
    sortedStatusesByCustomer,
    executeStatusAction,
    isUpdating,
    setIsUpdating,
  } = useStatus(customerWatch?.id);

  const canSend =
    sortedStatusesByCustomer?.data && JSON.stringify(statusesWatch) !== JSON.stringify(sortedStatusesByCustomer.data);

  useEffect(() => {
    setIsUpdating(false);
    if (sortedStatusesByCustomer?.data) {
      methods.setValue('statuses', sortedStatusesByCustomer.data);
    }
  }, [customerWatch, sortedStatusesByCustomer?.data, methods]);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      navigate({ to: '/status-editor' });
    }, 300);
  };

  const handleReset = () => {
    const originalStatuses = sortedStatusesByCustomer?.data || [];
    methods.reset({
      customer: methods.getValues('customer'),
      statuses: originalStatuses,
    });
    methods.setValue('statuses', originalStatuses);
    navigate({ to: '/status-editor' });
  };

  const getStatusIds = statusesWatch.map((s) => s.StatusID);

  const handleSubmit: SubmitHandler<z.infer<typeof FormValuesSchema>> = (formValues) => {
    executeStatusAction({
      actionType: 'sortStatusOrder',
      payload: {
        getStatusIds,
        customerId: customerWatch?.id,
      },
    });
  };

  return (
    <DrawerLayout
      open={isOpen}
      onClose={handleClose}
      anchor="right"
      keepMounted
      width={'50%'}
      Header={<DrawerHeader close={handleClose} title="Edit Status Order" />}>
      <FormProvider {...methods}>
        <Container
          maxWidth="sm"
          sx={{
            pt: 5,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 1,
            overflow: 'hidden',
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, flexGrow: 1, overflow: 'auto', p: 2 }}>
            <AutoComplete
              id="customer"
              options={
                customers.isPending
                  ? []
                  : customers?.data?.map((customer: CustomerEntity) => ({
                    id: customer.CustomerEntId,
                    label: customer.EntName,
                  })) || []
              }
              control={methods.control}
              loading={customers.isPending}
              autocompleteProps={{
                id: 'customer',
                loadingText: 'Loading Customers...',
              }}
              multiple={false}
              label="Customer"
            />
            {customerWatch &&
              (sortedStatusesByCustomer?.isPending ? (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'center',
                    mt: 2,
                  }}>
                  <CircularProgress />
                  <p>Loading </p>
                </Box>
              ) : (
                <DraggableList
                  initialStatuses={statusesWatch}
                  onChange={(newStatuses: SimpleStatus[]) => methods.setValue('statuses', newStatuses)}
                />
              ))}
            <Box sx={{ position: 'absolute', bottom: '5%', right: '12%', display: 'flex', gap: 2 }}>
              <Button size="small" variant="outlined" onClick={handleReset}>
                Cancel & Close
              </Button>
              <LoadingButton
                loading={isUpdating}
                type="submit"
                disabled={!canSend}
                variant="contained"
                onClick={methods.handleSubmit(handleSubmit)}>
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Container>
      </FormProvider>
    </DrawerLayout>
  );
};

export default EditStatusOrderDrawer;
