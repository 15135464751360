import ReportService from '@/services/report.service';
import { queryOptions, useQuery, UseQueryResult } from '@tanstack/react-query';

interface IUseReport {
  reportsByCustomerAndId: UseQueryResult<any>;
  reportsByFilter: UseQueryResult<any>;
}

const reportService = ReportService();

const useReport = (customerId?: number, category?: string, filterId?: string): IUseReport => {

  const reportsByFilter = useQuery<any>({
    queryKey: ['report', filterId],
    queryFn: async () => {
      if (!filterId) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await reportService
          .getFilteredReport({
            filterId: filterId
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!filterId,
    refetchOnWindowFocus: false,
    // refetchOnMount: 'always',
  });

  const reportsByCustomerAndId = useQuery<any>({
    queryKey: ['report', customerId, category],
    queryFn: async () => {
      if (!customerId || !category) return new Promise((resolve) => resolve(null));
      return new Promise(async (resolve) => {
        await reportService
          .getReports({
            customerId: customerId,
            customFields: true,
            category: category,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    enabled: !!customerId && !!category,
    refetchOnWindowFocus: false,
  });

  return {
    reportsByCustomerAndId,
    reportsByFilter
  };
};

export default useReport;



export const getReportByFilter = (filterId: string) => {
  const reportService = ReportService();
  return queryOptions({
    queryKey: ['agentContext', filterId],
    queryFn: async () => {
      const response = await reportService.getFilteredReport({ filterId });
      return response;
    },
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    enabled: (!!filterId),
    refetchOnWindowFocus: false,
  });
};