import ReportFiltersDrawer from '@/app/pages/Reports/ReportFiltersDrawer'
import { createUserSettingsOptions } from '@/hooks/fetches/useSettings.service'
import { createUserQueryOptions } from '@/services/queries/pingOptions'
import { createStatusesByCustomerOptions } from '@/services/queries/statusTypeOptions'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

export const Route = createFileRoute('/_auth/reports/$reportFilter')({
  component: () => <ReportFiltersDrawer />,
  params: {
    parse: (params) => ({
      reportFilter: z.string().parse(params.reportFilter),
    }),
    stringify: ({ reportFilter }) => ({ reportFilter: `${reportFilter}` }),
  },
  validateSearch: (search) => {
    return z
      .object({
        variant: z.enum(['reportFilters', 'edit', 'new']).default('reportFilters'),
        step: z.number().min(0).max(1).default(0),
        // Based on your steps array length
        // showSecondaryContact: z.boolean().default(false)
      })
      .parse(search)
  },
  loader: async ({ params, context }) => {
    const user = await context?.queryClient?.fetchQuery(createUserQueryOptions({ ticketid: 0, customerid: 0, limittocustomer: false, auth: context?.auth }))

    // console.log('params.reportFilter', params.reportFilter)

    await Promise.all([
      context.queryClient.ensureQueryData(createUserSettingsOptions(user.ContextUser.AgentId))
    ])
    if (params.reportFilter === 'all') return
    // console.log('context', user.ContextUser.AgentId)
    const settings = await context.queryClient.ensureQueryData(createUserSettingsOptions(user.ContextUser.AgentId))
    // console.log('settings', settings)
    const existingFilter = settings?.data?.Data.find((filter: any) => filter.id === params.reportFilter)
    // console.log('existingFilter', existingFilter)
    const customer = existingFilter?.condition[0]?.fieldId
    // console.log('customer', customer)
    await Promise.all([
      context.queryClient.ensureQueryData(createStatusesByCustomerOptions({ customerId: customer }))
    ])
  }
})
