import React, { useEffect, useState } from 'react';
import type { Theme } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { Divider, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import Grid from '@mui/material/Grid2';
import { mediumSideMenuWidth, sideMenuWidth } from '../constants/commonSizes.constant';
import { redirect, useNavigate, useRouteContext } from '@tanstack/react-router';
// import { navItems } from '../constants/routes/navItems.constant'
// import { navItems } from '@/app/router';
import { DefaultSideMenu } from '@/components/Organisms/DefaultSideMenu/DefaultSideMenu.component';
import { useAuth } from '@/lib/auth';
import useTicketTableStore from '@/app/pages/TicketList/useTicketTableStore';
import { LoadingScreen } from '@/app/pages/Loading/Loading';
import { createAgentOptionsQuery } from '@/services/queries/agentOptions';
import { useQuery } from '@tanstack/react-query';

interface IDashboardLayout {
  children: React.ReactNode;
  title?: string;
}

const DashboardLayout = ({ children, title }: IDashboardLayout) => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const [loggingOut, setLoggingOut] = useState<boolean>(false);

  const theme = useTheme()
  const navigate = useNavigate();
  const auth = useAuth();

  const { ticketTableState } = useTicketTableStore()

  const agentsByCustomer = useQuery(createAgentOptionsQuery(ticketTableState?.customFilters?.customer?.id, undefined))


  const handleSignOut = async () => {
    try {
      setLoggingOut(true)
      await auth.logout();
    } catch (error) {
      console.error('Failed to store auth data:', error)
    } finally {
      setLoggingOut(false)
      navigate({ to: '/login', replace: true });
    }
  };

  const routes = [
    {
      index: 0,
      name: 'Tickets',
      title: 'Ticket List',
      hasDividerAfter: false,
      disabled: false,
      selected: false,
      path: '/',
    },
    {
      index: 1,
      name: 'Status Editor',
      hasDividerAfter: false,
      disabled: false,
      selected: false,
      path: '/status-editor',
    },
    {
      index: 2,
      name: 'Type Editor',
      hasDividerAfter: false,
      disabled: false,
      selected: false,
      path: '/type-editor',
    },
    {
      index: 3,
      name: 'Custom Fields',
      hasDividerAfter: false,
      disabled: false,
      selected: false,
      path: '/custom-fields',
    },
    {
      index: 4,
      name: 'Field Groups',
      hasDividerAfter: true,
      disabled: false,
      selected: false,
      path: '/field-groups',
    },
    {
      index: 5,
      name: 'Customers',
      hasDividerAfter: false,
      disabled: false,
      selected: false,
      path: '/customers',
    },
    {
      index: 6,
      name: 'Users',
      hasDividerAfter: false,
      disabled: false,
      selected: false,
      path: '/agents',
    },
    {
      index: 7,
      name: 'Roles & Permissions',
      hasDividerAfter: false,
      disabled: false,
      selected: false,
      path: '/permissions',
    },
    {
      index: 8,
      name: 'Workflow Rules',
      hasDividerAfter: true,
      disabled: false,
      selected: false,
      path: '/workflow-rules',
    },
    // {
    //   index: 8,
    //   name: 'Add Workflow Rule',
    //   hasDividerAfter: true,
    //   disabled: false,
    //   selected: false,
    //   path: '/add-workflow-rule',
    // },
    {
      index: 9,
      name: 'Templates',
      hasDividerAfter: true,
      disabled: false,
      selected: false,
      path: '/templates',
    },
    {
      index: 10,
      name: 'Reports',
      hasDividerAfter: false,
      disabled: false,
      selected: false,
      path: '/reports',
    },
    {
      index: 11,
      name: 'Batch Updates',
      hasDividerAfter: true,
      disabled: false,
      selected: false,
      path: '/batch-updates',
    },
    {
      index: 12,
      name: 'User Profile',
      hasDividerAfter: false,
      disabled: false,
      selected: false,
      path: '/user-profile',
    },
    {
      index: 13,
      name: 'Release Notes',
      hasDividerAfter: false,
      disabled: false,
      selected: false,
      path: '/release-notes',
    },
    {
      index: 14,
      name: 'Sign Out',
      hasDividerAfter: false,
      disabled: false,
      selected: false,
      // path: '/sign-out',
      isCallback: true,
      callback: () => handleSignOut(),
      tooltip: agentsByCustomer?.data?.ContextUser?.Name || '',
    },
  ]

  return (
    <>
      {loggingOut && <LoadingScreen />}
      <DefaultSideMenu
        id="dropsourceSideMenu"
        navBarWidth={-1}
        drawerWidth={isSmallScreen ? 260 : isMediumScreen ? 160 : 260}
        defaultMenuItems={
          routes &&
          routes.map((item: any, index: number) => {
            return {
              title: item.name,
              index: index,
              selected: false,
              hasDividerAfter: item.hasDividerAfter,
              disabled: item.disabled,
              hrefString: item.path,
              isCallback: item.isCallback,
              callback: () => {
                // console.log('Callback in defaultMenuItems');
                item.callback()
              },
              tooltip: item.tooltip
            };
          })
        }
        activeModule="WorkFlow"
        onActiveModuleClick={() => navigate({ to: '/', search: { drawer: undefined } })}
      />
      <Grid
        container
        // xs={12}
        sx={{
          marginTop: '0px',
          marginLeft: isSmallScreen ? '0px' : isMediumScreen ? mediumSideMenuWidth + 'px' : sideMenuWidth + 'px',
          padding: isSmallScreen ? 4 : 12,
          paddingTop: isSmallScreen ? 4 : 12,
          maxWidth: isSmallScreen
            ? '100%'
            : isMediumScreen
              ? `calc(100vw - ${mediumSideMenuWidth}px)`
              : `calc(100vw - ${sideMenuWidth}px)`,
          overflowY: 'auto',
        }}
        spacing={8}
      >
        {title ? (
          <Grid container display="flex" flexDirection="row" flexWrap="wrap" rowGap={4} height="100%" size={12}>
            <Grid display="flex" flexDirection="column" size={12}>
              <Typography variant="h3" color={theme.palette.primary.main} height={isSmallScreen ? 40 : 80}>
                {title}
              </Typography>
              <Divider sx={{ borderBottomWidth: 2 }} />
            </Grid>
            {children}
          </Grid>
        ) : (
          children
        )}
      </Grid>
    </>
  );
};

export default DashboardLayout;
