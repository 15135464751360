import StatusService from '@/services/statuses.service';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import ReportHeaderService from '@/services/reportHeader.service';
import useReportHeadersMutation from './useReportHeadersMutation';

interface IUseReportHeaders {
  reportHeaders: UseQueryResult<any>;
  executeReportHeaderAction: any;
  isUpdating: any;
  setIsUpdating: any;
}

const useReportHeaders = (customerId?: number): IUseReportHeaders => {
  const { executeReportHeaderAction, isUpdating, setIsUpdating } = useReportHeadersMutation();

  const reportHeadersService = ReportHeaderService();

  const reportHeaders = useQuery<any>({
    queryKey: ['report-headers'],
    queryFn: async () => {
      return new Promise(async (resolve) => {
        await reportHeadersService
          .getReportHeaders({
            onSuccess: (res: AxiosResponse) => {
              resolve(res.data);
            },
          })
          .then((res: any) => {
            resolve(res?.data);
          });
      });
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });
  return { reportHeaders, executeReportHeaderAction, isUpdating, setIsUpdating };
};

export default useReportHeaders;
