// Import necessary modules from Zustand
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// Define the ITicketList interface
export interface ITicketList {
  assigneeFilterApplied: boolean;
  selectedCustomer: { id: number; label: string } | null;
  selectedCategory: { id: number; label: string } | null;
  ticketListMapOpen: boolean;
  selectedTicket: { id: number } | null;
  ticketDetailMapOpen: boolean;
  ticketHistoryOpen: boolean;
  accordionsOpen: {
    baseFields?: boolean;
    customFields?: boolean;
    attachments?: boolean;
    notes?: boolean;
  };
  totalVisibleCustomFields: number;
  ticketPrintOpen: boolean;
  areCustomFieldsPopulated: boolean
}

// Initialize the initial state for Ticket List
export const initialTicketListState: ITicketList = {
  assigneeFilterApplied: false,
  selectedCustomer: { id: 0, label: '' },
  selectedCategory: { id: 0, label: '' },
  ticketListMapOpen: false,
  selectedTicket: { id: 0 },
  ticketDetailMapOpen: false,
  ticketHistoryOpen: false,
  accordionsOpen: { baseFields: true, customFields: false, attachments: false, notes: false },
  totalVisibleCustomFields: 0,
  ticketPrintOpen: false,
  areCustomFieldsPopulated: false
};

// Define the Zustand store interface
interface TicketListStore {
  ticketListState: ITicketList;
  updateTicketList: (newState: Partial<ITicketList>) => void;
  setSelectedTicketId: (id: number) => void;
}

// Create the Zustand store using createStore
const useTicketList = create(
  devtools<TicketListStore>((set: any) => ({
    ticketListState: initialTicketListState,
    updateTicketList: (newState: Partial<ITicketList>) =>
      set((state: TicketListStore) => ({
        ticketListState: {
          ...state.ticketListState,
          ...newState,
        },
      })),
    setSelectedTicketId: (id: number) =>
      set((state: TicketListStore) => ({
        ticketListState: {
          ...state.ticketListState,
          selectedTicket: { id },
        },
      })),
  })),
);

// Custom hook to use the modal store
// const useTicketList = () => useTicketListStore()
export default useTicketList;
